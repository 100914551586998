<template>
  <div class="landing-combine">
    <ad currentPage="homepage"></ad>
    <div class="landing-page-middle">
      <div class="landing-page-middle-container">
        <div class="landing-page-middle-right-div">
          <div class="landing-page-middle-text-div">
            <p class="landing-middle-text">Morningstar Investor Newsletters</p>
          </div>
          <div v-if="!this.$store.state.user.LoggedIn || productsubscriptionoverlayStatus == true">
            <p class="border-line"></p>
          </div>
          <div v-if="!this.$store.state.user.LoggedIn || productsubscriptionoverlayStatus == true" class="combine-hero-image-buttons">
            <mds-button-container>
              <mds-button
                variation="secondary" 
                :size="learnMoreButtonSize" 
                icon-right="caret-down"
                @click="toggle = !toggle"
                id="learnmore-trigger"
                class="button-learn-more">Learn More
              </mds-button>
              <mds-popover
                width="300px"
                triggered-by="learnmore-trigger"
                v-model="toggle"
                class="hero-image-learn-more-popover"
              >
              <p class="list-product-trial">START A NEWSLETTER TRIAL</p>
                <mds-list-group size="medium" rightAligned>
                  <mds-list-group-item :to="{ name:'marketing-landing',
                        params: { currentproduct: 'mfi'}}"
                    ><span class="list-product-name">
                      Morningstar FundInvestor
                    </span></mds-list-group-item
                  >
                  <mds-list-group-item :to="{ name:'marketing-landing',
                        params: { currentproduct: 'msi'}}"
                    ><span class="list-product-name">
                      Morningstar StockInvestor
                    </span></mds-list-group-item
                  >
                  <mds-list-group-item :to="{ name:'marketing-landing',
                        params: { currentproduct: 'mdi'}}"
                    ><span class="list-product-name">
                      Morningstar DividendInvestor
                    </span></mds-list-group-item
                  >
                  <mds-list-group-item :to="{ name:'marketing-landing',
                        params: { currentproduct: 'mei'}}"
                    ><span class="list-product-name">
                      Morningstar ETFInvestor
                    </span></mds-list-group-item
                  >
                </mds-list-group>
              </mds-popover>
              <mds-button
                on-dark
                variation="secondary" 
                size="large"
                class="button-sign-in"
                v-if="!this.$store.state.user.LoggedIn"
                @click="login()"
              >
              Sign In</mds-button>
            </mds-button-container>
          </div>
          <div v-if="!this.$store.state.user.LoggedIn" class="landing-bottom-text-div">
            <span class="landing-bottom-text">
              If you are a print subscriber visiting for the first time, complete a
            </span>
            <br v-if="!isResponsiveToggle">
            <router-link
              class="registration-link"
              :to="{
                name: 'print-registration',
                params: {
                  currentproduct: this.$store.state.product.CurrentProduct,
                },
              }"
            >
            one-time registration.
            </router-link>
          </div>
          <div v-if="this.$store.state.user.LoggedIn && productsubscriptionoverlayStatus == false" class="landing-page-after-login">
            <p class="landing-text-after-login">
              Navigate to your newsletter’s homepage:
            </p>
            <div>
              <p class="border-line-after-login"></p>
            </div>
            <div class="common-login-product-navigation" v-if="!isResponsiveToggle">
              <div v-for="(product, index) in this.products"
                :key="index">
                <mds-icon name="angle-double-right" class="common-product-access-icon" v-if="product.access"></mds-icon>
                <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                <span>
                <router-link class="landingpage-navgation-button product-navigate-style" :to="{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}">{{ product.product }}</router-link>
                </span>
              </div>
            </div>
            <div v-else class="common-login-product-navigation">
              <div v-for="(product, index) in this.products"
                :key="index">
                <mds-icon name="angle-double-right" class="common-product-access-icon" v-if="product.access"></mds-icon>
                <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                <router-link class="landingpage-navgation-button product-navigate-style" :to="{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}">{{ product.product }}</router-link>
              </div>
            </div>
          </div>

          
          <div v-if="productsubscriptionoverlayStatus == true" class="landing-page-after-login-for-non-subcribed-user">
            <p class="landing-text-after-login-for-non-subcribed-user">
              Navigate to your newsletter’s homepage:
            </p>
            <div>
              <p class="border-line-after-login"></p>
            </div>
            <div class="common-login-product-navigation" v-if="!isResponsiveToggle && productsubscriptionoverlayStatus == false">
              <div v-for="(product, index) in this.products"
                :key="index">
                <mds-icon name="angle-double-right" class="common-product-access-icon" v-if="product.access"></mds-icon>
                <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                <span>
                <router-link class="landingpage-navgation-button product-navigate-style" :to="{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}">{{ product.product }}</router-link>
                </span>
              </div>
            </div>
            <div v-if="isResponsiveToggle && productsubscriptionoverlayStatus == false" class="common-login-product-navigation">
              <div v-for="(product, index) in this.products"
                :key="index">
                <mds-icon name="angle-double-right" class="common-product-access-icon" v-if="product.access"></mds-icon>
                <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                <router-link class="landingpage-navgation-button product-navigate-style" :to="{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}">{{ product.product }}</router-link>
              </div>
            </div>
              <div v-if="productsubscriptionoverlayStatus == true" class="common-login-product-navigation-for-none-product-access">
              <div v-for="(product, index) in this.products"
                :key="index">
                <mds-icon name="angle-double-right" class="common-product-access-icon" v-if="product.access"></mds-icon>
                <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                <router-link class="landingpage-navgation-button product-navigate-style" :to="{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}">{{ product.product }}</router-link>
              </div>
              

            </div>
          </div>
        </div>
        <div class="landing-page-middle-left-div">
          <banner-cover-images></banner-cover-images>
        </div>
      </div>
    </div>
    <individual-newsletter-images></individual-newsletter-images>
    <editor-featured-commentary></editor-featured-commentary>
    <combine-videos></combine-videos>
    <banner-bottom-cover-image></banner-bottom-cover-image>

    <mds-modal v-model="RedirectFromLagecy" width="600px" size="small"  aria-label="Default Modal" class="modal-For-Redirect-from-lagecy">
    <mds-section  bold>
      <template >
            <mds-button-container right-aligned class="legacy-modal-close-button-div">
                <mds-button @click="ClosingTheWelcomeModel()" variation="icon-only" icon="remove">Close</mds-button>
            </mds-button-container>
        </template>
        <template>
       
            <h1 class="title-for-lagecy-modal">Welcome to the new Investor Newsletters website!</h1>
            <h1 class="modal-lagecy-para-one-border"></h1>
            <p class="modal-lagecy-para-one">We have taken the four, individual websites and combined them into one experience and homepage. 
              The new homepage will give you easy access to your current issues, updated portfolio data, archives, and bonus reports.</p>

              <p class="modal-lagecy-para-second">Check out our <a href="https://www.morningstar.com/company/newsletters-help-center" target="_blank" class="getting-start-modal-anchor-tag">Getting Started</a> page for additional details.</p>
        </template>
       

       
    </mds-section>
</mds-modal>

    <product-subscription-overlay v-if="productsubscriptionoverlayStatus" :subscriptionoverlayStatus="productsubscriptionoverlayStatus" @resetOverlayStatusValue="resetOverlayStatus"></product-subscription-overlay>
  </div>
</template>

<script>
import MdsIcon from '@mds/icon'
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsPopover from "@mds/popover";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import BannerCoverImages from "@/components/Common/CombinedHome/BannerCoverImages.vue";
import IndividualNewsletterImages from "@/components/Common/CombinedHome/IndividualNewsletterImages.vue";
import EditorFeaturedCommentary from "@/components/Common/CombinedHome/EditorFeaturedCommentary.vue";
import CombineVideos from "@/components/Common/CombinedHome/CombineVideos.vue"
import BannerBottomCoverImage from "@/components/Common/CombinedHome/BannerBottomCoverImage.vue";
import ProductSubscriptionOverlay from "@/components/Common/Overlay/ProductSubscriptionOverlay.vue"; 
import { mapState } from "vuex";
import MdsModal from "@mds/modal";
import Ad from "@/components/Common/Ad/Ad.vue"

export default {
  name: "landing-page",
  components: {
    MdsIcon,
    MdsButton,
    MdsButtonContainer,
    BannerCoverImages,
    IndividualNewsletterImages,
    EditorFeaturedCommentary,
    MdsPopover,
    MdsListGroup,
    MdsListGroupItem,
    CombineVideos,
    BannerBottomCoverImage,
    Ad,
    ProductSubscriptionOverlay,
    MdsModal,
  },
  data() {
    return {
      toggle: false,
      products: [],
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      isResponsiveToggle:null,
      isNewRegister:false,
      learnMoreButtonSize:"large",
      productsubscriptionoverlayStatus:false,
      RedirectFromLagecy: false,
    };
  },
  created(){
    this.openmodelForNewLegacyUser();
  },

  methods: {
    ClosingTheWelcomeModel(){
      this.RedirectFromLagecy = false;
      this.$router.replace('/')
    },
    openmodelForNewLegacyUser(){
      if(this.$route.query.redirect == 'true')
      this.RedirectFromLagecy = true;
      this.$store.state.product.CurrentProduct = "Common";
      this.$session.set("current-product", "Common");
    },

    login() {
      this.$auth0.loginWithRedirect();
    },
    addProductOrder(){
      if (this.$store.state.user.LoggedIn) {
        if (this.MFIAccess) {
          this.products.push({
            access: true,
            product:'FundInvestor',
            name: 'MFI',
          });
        }
        if (this.MSIAccess) {
          this.products.push({
            access: true,
            product:'StockInvestor',
            name: 'MSI',
          });
        }
        if (this.MDIAccess) {
          this.products.push({
            access: true,
            product:'DividendInvestor',
            name: 'MDI',
          });
        }
        if (this.MEIAccess) {
          this.products.push({
            access: true,
            product:'ETFInvestor',
            name: 'MEI',
          });
        }
        if (!this.MFIAccess) {
          this.products.push({
            access: false,
            product:'FundInvestor',
            name: 'MFI',
          });
        }
        if (!this.MSIAccess) {
          this.products.push({
            access: false,
            product:'StockInvestor',
            name: 'MSI',
          });
        }
        if (!this.MDIAccess) {
          this.products.push({
            access: false,
            product:'DividendInvestor',
            name: 'MDI',
          });
        }
        if (!this.MEIAccess) {
          this.products.push({
            access: false,
            product:'ETFInvestor',
            name: 'MEI',
          });
        }
      } 
    },
    resetOverlayStatus(toggleValue){
      this.productsubscriptionoverlayStatus = toggleValue;
     }
  },
  mounted() {
    window.scrollTo(0,0);
    if(this.$store.state.responsive.isMobileContent){
      this.learnMoreButtonSize="medium";
    }
    if(this.$store.state.user.NewRegister)
    {
      //this.isNewRegister= this.$store.state.user.NewRegister;
      location.reload();
    }
    this.isResponsiveToggle=this.$store.state.responsive.isResponsive;
    if (this.$store.state.user.LoggedIn) {
      this.productDataRoles =
        this.$auth0.user["https://morningstar.com/uim_roles"];
        if(this.productDataRoles != undefined && this.productDataRoles !=null) {
      this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
      this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
      this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
      this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
    }
    if(!this.MEIAccess && !this.MFIAccess && !this.MSIAccess && !this.MDIAccess)
    {
      this.productsubscriptionoverlayStatus=true;
      this.RedirectFromLagecy = false;
    }
  }
    if (
      this.$session.exists() &&
      this.$session.get("current-product") != "Common"
    ) {
      if (this.$route.fullPath == "/") {
        this.$session.set("current-product", "Common");
        this.$store.state.product.CurrentProduct = "Common";
      }
    } 
    this.addProductOrder();
    this.$forceUpdate();
    //Registrations all data clear
    localStorage.removeItem("userDetails");
        localStorage.removeItem("MFIProductSubscription");
        localStorage.removeItem("MSIProductSubscription");
        localStorage.removeItem("MDIProductSubscription");
        localStorage.removeItem("MEIProductSubscription");
        localStorage.removeItem("ChaseErrorCode");
        localStorage.removeItem("CompleteChasePaymentData");
        localStorage.removeItem("PrintRegistrationDetails");
    //END
    localStorage.setItem("MoreVideosSelectedProduct", "");
  },
  computed:mapState({
    isResponsive: state => state.responsive.isResponsive,
      isResponsiveAlias:'isResponsive',
      isResponsiveState(state){
      return state.responsive.isResponsive
    },
    isMobileContent: state => state.responsive.isMobileContent,
      isMobileContentAlias:'isMobileContent',
      isMobileContentState(state){
      return state.responsive.isMobileContent
    }
    
  }),
  watch:{
    isResponsiveState(){
      this.isResponsiveToggle = this.isResponsiveState;
    },
    isMobileContentState(){
      if(this.isMobileContentState){
        this.learnMoreButtonSize = 'medium';
      }else{
        this.learnMoreButtonSize = "large";
      }
    }
  }
};

</script>

<style lang="scss" scoped>
@import '@mds/constants';
@import '@mds/icon/src/icon_mixins';
@import "@mds/link";
.landing-combine{
  /*width: 100%;*/
  max-width:1280px;
  margin:0 auto;
}


@media only screen and (min-width:600px) and (max-width:60000px){
.title-for-lagecy-modal{
  width: 100%;
  font-size: 45px;
  line-height: 50px;
  color: #1E1E1E !important;
  font-weight: 250;
  padding-left: 40px;
padding-right: 25px;
}

.modal-lagecy-para-one{
  width: 100%;
font-size: 23px;
line-height: 27px;
color: #1E1E1E;
font-weight: 300;
padding-left: 40px;
padding-right: 25px;
}

.modal-lagecy-para-second{
  width: 100%;
  margin-top: 20px;
  font-size: 23px;
line-height: 27px;
color: #1E1E1E;
font-weight: 300;
padding-left: 40px;
padding-right: 25px;
padding-bottom: 26px;
}

.modal-lagecy-para-one-border{
  width: 90%;
  margin-top: 42.5px;
  margin-bottom: 12px;
  border-top: #808080 solid 1px;
  margin-left: 40px;

}

}

@media only screen and (min-width:370px) and (max-width:600px){

.title-for-lagecy-modal{
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  color: #1E1E1E !important;
  font-weight: 250;
  padding-left: 40px;
padding-right: 25px;
}

.modal-lagecy-para-one{
  width: 100%;
font-size: 20px;
line-height: 27px;
color: #1E1E1E;
font-weight: 300;
padding-left: 40px;
padding-right: 25px;
}

.modal-lagecy-para-second{
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
line-height: 27px;
color: #1E1E1E;
font-weight: 300;
padding-left: 40px;
padding-right: 25px;
padding-bottom: 26px;
}

}

@media only screen and (min-width:0px) and (max-width:370px){

.title-for-lagecy-modal{
  width: 100%;
  font-size: 28px;
  line-height: 40px;
  color: #1E1E1E !important;
  font-weight: 250;
  padding-left: 20px;
padding-right: 25px;
}

.modal-lagecy-para-one{
  width: 100%;
font-size: 18px;
line-height: 27px;
color: #1E1E1E;
font-weight: 300;
padding-left: 20px;
padding-right: 20px;
}

.modal-lagecy-para-second{
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
line-height: 27px;
color: #1E1E1E;
font-weight: 300;
padding-left: 20px;
padding-right: 20px;
padding-bottom: 26px;
}

.modal-lagecy-para-one-border{
  width: 88%;
  margin-top: 42.5px;
  margin-bottom: 12px;
  border-top: #808080 solid 1px;
  margin-left: 20px;

}

}

.modal-For-Redirect-from-lagecy{
  
 width: 100%;

}

.modal-For-Redirect-from-lagecy>div>div>mds-section {
      overflow-y: scroll;
    }

    .modal-For-Redirect-from-lagecy ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    .modal-For-Redirect-from-lagecy ::-webkit-scrollbar-thumb {
        background: rgba(171, 171, 171, 0.3);
        border-radius: 6px;
    }

.getting-start-modal-anchor-tag{
 color: #1E1E1E;
}





.landing-page-middle {
  width:100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  /*box-sizing:border-box;*/
  height: 447px;
  background-color: #1e1e1e;
}
.landing-page-middle-container{
  max-width:1280px;
  margin: 0 auto;
  display: flex;
}
.landing-page-middle-right-div{
  /*width:38%;*/
  width:52%;
  height:320px;
  margin-top:52px;
  /*margin-left:8.4%;*/
}
.landing-page-middle-left-div{
  width:350px;
  height:456px;
  margin-top:41px;
  /*margin-right:8.6%;*/
  margin-left: auto;
  order: 2;
}
.landing-page-middle-text-div {
  width:100%;
  height: 134px;
}
.landing-middle-text {
  font-weight: 250;
  font-size: 64px;
  line-height: 75px;
  color: #FFFFFF;
  margin:0;
  padding:0;
}
.border-line {
  border-bottom: #f2f2f2 solid 1px;
  width:90%;
  margin: 48px 0px 0px 0px;
}
.combine-hero-image-buttons{
  margin-top:20px;
}
.button-learn-more.mds-button.mds-button--secondary{
  background: #FFFFFF;
  color: #1E1E1E;
}
.button-sign-in.mds-button.mds-button--secondary{
    color: #FFFFFF;
    border:1px solid #FFFFFF;
}
.list-product-trial {
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 12px;
  letter-spacing: 1.67px !important;
  text-transform: uppercase !important;
  color: #5e5e5e;
  padding-bottom:4px;
}
.landing-bottom-text-div{
  margin:26px 0px 0px 0px;
}
.landing-bottom-text {
  color: #FFFFFF;
  font-style:normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  padding:0;
}
.registration-link {
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  text-decoration:none;
  border-bottom:1px solid #FFFFFF;
}
.landing-page-after-login {
  margin-top: 96px;
}
.landing-text-after-login {
  margin-top: 96px;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #FFFFFF;
}

.landing-page-after-login-for-non-subcribed-user {
  margin-top: 20px;
}
.landing-text-after-login-for-non-subcribed-user {
  margin-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #FFFFFF;
}

.border-line-after-login {
  border-bottom: #e5e5e5 solid 1px;
  margin-top: 22px;
}
.common-login-product-navigation{
  display:inline-flex;
  margin-top:16px;
}

.common-login-product-navigation-for-none-product-access{
  display:inline-flex;
  margin-top:16px;
}

.common-product-access-icon {
  @include mds-icon-color($mds-background-color-white);
  width: 20.03px;
  height: 17.64px;
}
.landingpage-navgation-button{
  color:#FFFFFF !important;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height:27px;
  margin-left: 5px !important;
  text-decoration:none;
  margin-right: 28px !important;
}
@media only screen and (min-width:320px) and (max-width:390px){
  .landing-page-middle-right-div{
    width:45%;
  }
}

@media only screen and (min-width:360px) and (max-width:750px){

.combine-hero-image-buttons{
  margin-top:10px;
}
}

@media only screen and (min-width:0px) and (max-width:360px){

.combine-hero-image-buttons{
  margin-top:5px;
}
}

@media only screen and (min-width:360px) and (max-width:1280px){
  .landing-combine{
    width: 100%;
  }
  
  .landing-page-middle{
    height:400px;
    display:flex;
  }
  .landing-page-middle-text-div{
    height:auto;
  }
  .landing-page-middle-left-div{
    width:300px;
    height:400px;
    margin-top:50px;
  }
 
}

@media only screen and (min-width:370px) and (max-width:600px){
.modal-lagecy-para-one-border{
  width: 83%;
  margin-top: 42.5px;
  margin-bottom: 12px;
  border-top: #808080 solid 1px;
  margin-left: 40px;

}
}

@media only screen and (min-width:1000px) and (max-width:1280px){
  .border-line {
    border-bottom: #f2f2f2 solid 1px;
    width:90%;
    margin: 30px 0px 0px 0px;
  }
}

@media only screen and (min-width:0px) and (max-width:1000px){
  .border-line {
   display: none !important;
  }
}

@media only screen and (max-width:738px){
  .landing-page-middle{
    height:320px;
    display:flex;
  }
  .landing-page-middle-right-div{
    height:250px;
  }
  .landing-middle-text {
    color: #FFFFFF;
    font-weight:250;
    font-style:normal;
    font-size:28px;
    line-height: 35px;
  }
  .landing-page-middle-left-div{
    width:230px;
    height:300px;
    margin-top:50px;
    margin-right:5.5%;
  }
  .landing-bottom-text{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    padding: 0;
  }
  .registration-link{
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
    text-decoration: none;
    border-bottom: 1px solid #FFFFFF;
  }
}
@media only screen and (max-width:600px){
  .landing-page-middle{
    height:320px;
    display:flex;
  }
  .landing-middle-text {
    color: #FFFFFF;
    font-weight:250;
    font-style:normal;
    font-size:28px;
    line-height: 35px;
  }
  .border-line {
    display:none;
  }
  .button-sign-in{
    display:none;
  }
}
@media only screen and (max-width:525px){
  .landing-page-middle{
    height:300px;
    display:flex;
  }
  .landing-page-middle-right-div{
    height:200px;
  }
  .landing-page-middle-left-div{
    width:200px;
    height:280px;
    margin-top:50px;
    margin-right:5.5%;
  }
  .landing-bottom-text-div{
    margin: 13px 0px 0px 0px;
  }
  .landing-bottom-text{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    padding: 0;
  }
  .registration-link[data-v-08f6935f] {
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    color: #FFFFFF;
    text-decoration: none;
    border-bottom: 1px solid #FFFFFF;
  }
}
@media only screen and (max-width: 480px) {
      .landing-combine{
        width: 100%;
        overflow-x:hidden;
      }      
      .landing-page-middle{
        width:100%;
        height:241px;
        display: flex;
      }
      .landing-page-middle-right-div{
        margin-top:18px;
        margin-left:25px;
        height:210px;
      }
      .landing-page-middle-text-div {
        width: 329px;
        height:auto;
      }
      .landing-middle-text {
        color: #FFFFFF;
        font-weight:250;
        font-style:normal;
        font-size:28px;
        line-height: 34px;
      }
      .landing-bottom-text-div{
        width:125px;
        /*width:100%;*/
        height:86px;
      }
      .landing-bottom-text,
      .registration-link {
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 18px;
        color: #FFFFFF;
      }
      .landing-page-middle-left-div{
        width:144px;
        height:187px;
        margin-top: 70px;
      }
}
@media only screen and (max-width:360px){
  .landing-page-middle-text-div {
    width: 300px;
    height:auto;
  }
  .landing-middle-text{
      color: #FFFFFF;
      font-weight: 250;
      font-style: normal;
      font-size: 24px;
      line-height: 30px;
  }
  .registration-link{
    font-weight: 300;
    font-size: 14px !important;
    line-height: 23px;
    color: #FFFFFF;
    text-decoration: none;
    border-bottom: 1px solid #FFFFFF;
  }
}
@media only screen and (max-width:340px){
  .landing-page-middle-right-div{
    margin-top: 18px;
    margin-left: 12px;
    height: 210px;
  }
  .landing-bottom-text-div{
    text-wrap: balance;
  }
  .landing-page-middle-text-div {
    width: 300px;
    height:auto;
  }
  .landing-middle-text{
      color: #FFFFFF;
      font-weight: 250;
      font-style: normal;
      font-size: 24px;
      line-height: 30px;
  }
  .landing-bottom-text{
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 18px;
    color: #FFFFFF;
  }
  .registration-link{
    font-weight: 300;
    font-size: 14px !important;
    line-height: 23px;
    color: #FFFFFF;
    text-decoration: none;
    border-bottom: 1px solid #FFFFFF;
  }
}
@media only screen and (min-width:320px) and (max-width:480px){
  .common-product-access-icon {
    @include mds-icon-color($mds-background-color-white);
    width: 10.85px;
    height: 11.88px;
  }
  .landingpage-navgation-button{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    text-decoration:none;
  }
  .landing-middle-text {
    color: #FFFFFF;
    font-weight:250;
    font-style:normal;
    font-size:28px;
    line-height: 34px;
  }
}
@media only screen and (min-width:320px) and (max-width:999px){
  .border-line-after-login{
    display:none;
  }
  .landing-text-after-login{
    display:none;
  }
  .landing-text-after-login-for-non-subcribed-user {
    display:none;
}

  .common-login-product-navigation{
    margin-top: -80px;
    line-height: 30px;
    display:block;
  }

  .common-login-product-navigation-for-none-product-access{
    line-height: 30px;
    display:block;
    margin-top: -8px;
  }

}

@media only screen and (min-width:360px) and (max-width:999px){
  .common-login-product-navigation-for-none-product-access{
    line-height: 30px;
    display:block;
    margin-top: -8px;
  }
}

@media only screen and (min-width:0px) and (max-width:360px){
  .common-login-product-navigation-for-none-product-access{
    line-height: 30px;
    display:block;
    margin-top: -20px;
  }
}

@media only screen and (min-width:320px) and (max-width:1199px){
  .landing-page-middle-container{
    width:100vw;
  }
  .landing-page-middle-left-div{
    margin-right:5.5%;
  }
  .landing-page-middle-right-div{
    margin-left:5%;
  }
}
@media only screen and (min-width:1200px) and (max-width:1290px){
  .landing-page-middle-container{
    width:90vw;
  }
}
@media only screen and (min-width:1195px) and (max-width:1290px){
  .landing-page-middle-right-div{
    width:64%;
  }
  .landing-middle-text{
    line-height:66px;
  }
}
@media only screen and (min-width:999px) and (max-width:1088px){
  .landing-page-middle-right-div{
    width:57%;
  }
  .landing-middle-text{
    line-height:66px;
  }
  .landingpage-navgation-button{
    font-size:20px;
    line-height:27px;
    margin-right: 22px !important;
  }
  .common-product-access-icon{
    width: 17.03px;
    height: 14.64px;
  }
}
@media only screen and (min-width:1088px) and (max-width:1199px){
  .landing-page-middle-right-div{
    width:61%;
  }
  .landing-middle-text{
    line-height:66px;
  }
}
@media only screen and (min-width:739px) and (max-width:999px){
  .landing-middle-text{
    font-size: 46px;
    line-height: 54px;
  }
  .landing-page-middle-right-div{
      width: 45%;
  }
}
@media only screen and (min-width:601px) and (max-width:999px){
  .landing-combine{
    width: 100%;
    /*margin-top: 110px;*/
  }
}
@media only screen and (min-width:631px) and (max-width:738px){
  .landing-middle-text{
      font-size: 35px;
      line-height: 38px;
  }
}


</style>
<style>
  .button-learn-more.mds-button.mds-button--secondary > span:nth-child(2) > svg > path{
    stroke:#1E1E1E;
  }
  .hero-image-learn-more-popover > div > ul > li > span > div,
  .hero-image-learn-more-popover > div > ul > li > a > div{
    padding:4px 0px !important;
  }
</style>