<template>
	<div
		class="mdc-popover"
		:class="{ 'mdc-popover--expand': expandControl }"
		@keydown.esc="closeIfDismissable"
		@keyup.up.prevent="handleKeyUp"
		@keyup.down.prevent="handleKeyDown"
		@keydown.up.down.prevent
	>
		<div ref="control" class="mdc-popover__control" :class="{ 'mdc-popover__control--expand': expandControl }">
			<slot
				:id="safeId('mdc-popover-target')"
				name="target"
				:class="$style['mds-popover__target']"
				:aria-controls="isOpen ? safeId('mdc-popover') : null"
				:aria-expanded="isOpen"
				aria-haspopup="true"
				:open="open"
				:close="close"
				:toggle="toggle"
			/>
		</div>
					<div
						v-if="isOpen"
						:id="safeId('mdc-popover')"
						ref="popover"
						v-click-outside="closeIfDismissable"
						:aria-labelledby="safeId('mdc-popover-target')"
						:aria-hidden="!isOpen"
						class="mdc-popover__popover"
						:style="popoverStyle"
						tabindex="0"
						:class="{
							[$style['mds-popover']]: true,
							[$style['mds-popover--visible']]: isOpen,
							[$style[`mds-popover--${mdsPlacementModifier}`]]: true,
							'mdc-popover__popover--x-narrow': xNarrow,
							'mdc-popover__popover--narrow': narrow,
							'mdc-popover__popover--standard': !xNarrow && !narrow && !wide,
							'mdc-popover__popover--wide': wide
						}"
						:role="role"
						@keydown.esc="closeIfDismissable"
						@keyup.up.prevent="handleKeyUp"
						@keyup.down.prevent="handleKeyDown"
						@keydown.up.down.prevent
					>
						<header
							v-if="title"
							class="mdc-popover__header"
							:class="{
								[$style['mds-popover__header']]: true
							}"
						>
							<div v-if="closeable" :class="$style['mds-popover__buttons']">
								<mdc-button v-if="closeable" icon-only small icon="remove" :class="$style['mds-popover__button']" @click="close" />
							</div>
							<div
								class="mdc-popover__title"
								:class="{
									[$style['mds-popover__title']]: true
								}"
							>
								{{ title }}
							</div>
						</header>
						<div class="mdc-popover__content">
							<slot name="content" :open="open"	:close="close" :toggle="toggle" />
						</div>
					</div>
	</div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import ClickOutside from 'vue-click-outside';
import MdcIdMixin from "../mixins/MdcIdMixin"; 
import MdcFocusTrapMixin  from "../mixins/MdcFocusTrapMixin";

import MdcButton from './MdcButton.vue';

export default {
	components: {
		MdcButton
	},
	mixins: [
		MdcIdMixin,
		MdcFocusTrapMixin
	],
	directives: {
		ClickOutside
	},
	props: {
		closeable: {
			type: Boolean,
			description: 'Display close icon for dismissing popover',
			default: false
		},
		dismissible: {
			type: Boolean,
			description: 'Allow closing popover by clicking outside, pressing escape, or toggling via target',
			default: false
		},
		title: {
			type: String,
			description: 'Title of popover',
			default: null
		},
		topCenter: {
			type: Boolean,
			description: 'Align popover to the top and center of invoking element',
			default: false
		},
		topRight: {
			type: Boolean,
			description: 'Align popover to the top and right of invoking element',
			default: false
		},
		topLeft: {
			type: Boolean,
			description: 'Align popover to the top and left of invoking element',
			default: false
		},
		bottomCenter: {
			type: Boolean,
			description: 'Align popover to the bottom and center of invoking element',
			default: false
		},
		bottomRight: {
			type: Boolean,
			description: 'Align popover to the bottom and right of invoking element',
			default: false
		},
		bottomLeft: {
			type: Boolean,
			description: 'Align popover to the bottom and left of invoking element',
			default: false
		},
		rightCenter: {
			type: Boolean,
			description: 'Align popover to the right and center of invoking element',
			default: false
		},
		rightTop: {
			type: Boolean,
			description: 'Align popover to the right and top of invoking element',
			default: false
		},
		rightBottom: {
			type: Boolean,
			description: 'Align popover to the right and bottom of invoking element',
			default: false
		},
		leftCenter: {
			type: Boolean,
			description: 'Align popover to the left and center of invoking element',
			default: false
		},
		leftTop: {
			type: Boolean,
			description: 'Align popover to the left and top of invoking element',
			default: false
		},
		leftBottom: {
			type: Boolean,
			description: 'Align popover to the left and bottom of invoking element',
			default: false
		},
		xNarrow: {
			type: Boolean,
			description: 'Extra-narrow-width variation',
			default: false
		},
		narrow: {
			type: Boolean,
			description: 'Narrow-width variation',
			default: false
		},
		wide: {
			type: Boolean,
			description: 'Wide-width variation',
			default: false
		},
		root: {
			type: Boolean,
			description: 'Render in root context of page rather than in local container',
			default: true
		},
		role: {
			type: String,
			description: 'What UI role the popover plays',
			default: 'tooltip'
		},
		noFocusTrap: {
			type: Boolean,
			description: 'Disables the focus trap on inner popover content',
			default: false
		},
		expandControl: {
			type: Boolean,
			description: 'Set the width of the popover control to 100%',
			default: false
		}
	},
	data() {
		return {
			isOpen: false,
			popoverStyle: {},
			popper: null,
			popperPlacement: null,
			popperOffsetModifier: {
				name: 'offset',
				options: {
					offset: () => {
						const skidding = 0;
						const distance = 6;
						return [skidding, distance];
					}
				}
			}
		};
	},
	computed: {
		mdsPlacementModifier() {
			switch (this.popperPlacement) {
			case 'top': return 'top-center';
			case 'top-start': return 'top-right';
			case 'top-end': return 'top-left';
			case 'bottom': return 'bottom-center';
			case 'bottom-start': return 'bottom-right';
			case 'bottom-end': return 'bottom-left';
			case 'right': return 'right-center';
			case 'right-start': return 'right-top';
			case 'right-end': return 'right-bottom';
			case 'left': return 'left-center';
			case 'left-start': return 'left-top';
			case 'left-end': return 'left-end';
			default: return 'bottom-right';
			}
		},
		targetPopperPlacement() {
			if (this.topCenter) return 'top';
			if (this.topRight) return 'top-start';
			if (this.topLeft) return 'top-end';
			if (this.bottomCenter) return 'bottom';
			if (this.bottomRight) return 'bottom-start';
			if (this.bottomLeft) return 'bottom-end';
			if (this.rightCenter) return 'right';
			if (this.rightTop) return 'right-start';
			if (this.rightBottom) return 'right-end';
			if (this.leftCenter) return 'left';
			if (this.leftTop) return 'left-start';
			if (this.leftBottom) return 'left-end';
			return 'bottom-start';
		}
	},
	methods: {
		createPopperSubscriber() {
			return {
				name: 'mdc-popover-subscribe',
				enabled: true,
				phase: 'write',
				fn: ({ state }) => {
					const refHidden = state.attributes.popper['data-popper-reference-hidden'];
					this.popoverStyle = { ...state.styles.popper, ...(refHidden && { display: 'none' }) };
					this.popperPlacement = state.placement;
				}
			};
		},
		createPopper() {
			// see the vue-portal docs: two hops for the portal wormhole
			this.$nextTick(() => {
				this.$nextTick(() => {
					this.popper = createPopper(this.$refs.control, this.$refs.popover, {
						placement: this.targetPopperPlacement,
						modifiers: [
							this.popperOffsetModifier,
							{ name: 'preventOverflow', enabled: false },
							{ name: 'arrow', enabled: false },
							{ name: 'applyStyles', enabled: false },
							this.createPopperSubscriber()
						]
					});
					setTimeout(() => {
						if (!this.noFocusTrap) this.enableFocusTrap({ container: this.$refs.popover, focusFirstChild: false });
						else this.$refs.popover.focus();
						this.$emit('open');
					}, 50);
				});
			});
		},
		open() {
			// delay opening so we have time to process click-outside events; else we may get a pre-mature close
			setTimeout(() => {
				this.isOpen = true;
				this.createPopper();
			}, 50);
		},
		close() {
			if (this.popper) this.popper.destroy();
			this.popper = null;
			this.isOpen = false;
			if (!this.noFocusTrap) this.disableFocusTrap();
			this.$emit('closed');
		},
		toggle() {
			this.$nextTick(() => {
				if (this.isOpen) this.close();
				else this.open();
			});
		},
		closeIfDismissable() {
			if (this.isOpen && this.dismissible) this.close();
		},
		handleKeyUp() {
			this.$emit('arrowUp');
		},
		handleKeyDown() {
			this.$emit('arrowDown');
		}
	}
};
</script>


<style lang="scss" module>
@import '@mds/popover/src/popover.scss';
</style>

<style lang="scss" scoped>
@import "@mds/constants";
@import "@mds/typography";

.mdc-popover__popover-fade {
	&-enter-active, &-leave-active {
		transition: opacity $mds-animation-timing-1-x linear !important;
	}

	&-enter, &-leave-to {
		opacity: 0 !important;
	}
}
.mdc-popover {
	display: inline-flex;

	&--expand {
		display: block;
	}

	&__control {
		display: inline-flex;

		&--expand {
			width: 100%;
		}
	}

	&__popover {
		padding: 0;
		display: block;
		visibility: visible;
		opacity: 1;
		z-index: $mds-z-index-sticky;
		transition: unset;

		&--wide {
			width: 400px;
		}

		&--standard {
			width: 300px;
		}

		&--narrow {
			width: 280px;
            height:610px
		}

		&--x-narrow {
			padding: $mds-space-half-x;
			width: 80px;
		}
	}

	&__header {
		margin-bottom: 0;
	}

	&__title {
		font-weight: $mds-typography-font-weight-bold;
	}

	&__content {
		padding: $mds-space-2-x;
	}
}

</style>
