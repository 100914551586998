<template>
  <div class="email-template" v-if="!postLoading">
    <div class="email-template-product-logo">
      <img :alt="productImageInfo" :src="getProductImage()" />
    </div>
      <div class="email-template-border-seperator"></div>
      <div class="email-template-aticle-subject">{{ postContent.Subject }}</div>
      <div class="email-template-border-seperator-small"></div>
      <div class="email-template-author-name-and-date">
        <span>{{postContent.PostAuthorName}}</span>
        <span>|</span>
        <span>{{ postContent.PostDate | formatDateVal }}</span>
      </div>
      <div v-html="trimContent(postContent.FormattedBody)" class="email-template-body"></div>
  </div>
</template>

<script lang="js">
  import Vue from "vue";
  import axios from "axios";
  import VueAxios from "vue-axios";
  Vue.use(VueAxios, axios);
  export default  {
    name: 'email-template',
    props: {
      sendPostId:{
        type:Number,
        required:true,
      }
    },
    mounted () {
      this.getProductImage();
      this.getPostById(this.sendPostId);
    },
    data () {
      return {
        currentProduct: this.$session.get("current-product"),
        postByIdURL:process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_PostById,
        productImageInfo:null,
        postLoading:true,
        postContent:[],
      }
    },
    methods: {
      getProductImage(){
        var productImage = require.context(
        "../../../../assets/Images/",
        false,
        /\.svg$/
        );
        if(this.currentProduct.toUpperCase() == 'MFI'){
          this.productImageInfo = "FundInvestor Logo";
          return productImage("./" + this.currentProduct.toUpperCase() + ".svg");
        }
        else if(this.currentProduct.toUpperCase() == 'MSI'){
          this.productImageInfo = "StockInvestor Logo";
          return productImage("./" + this.currentProduct.toUpperCase() + ".svg");
        }
        else if(this.currentProduct.toUpperCase() == 'MDI'){
          this.productImageInfo = "DividendInvestor Logo";
          return productImage("./" + this.currentProduct.toUpperCase() + ".svg");
        }
        else if(this.currentProduct.toUpperCase() == 'MEI'){
          this.productImageInfo = "ETFInvestor Logo";
          return productImage("./" + this.currentProduct.toUpperCase() + ".svg");
        }
      },
      getPostById(postId){
        const getPostByIdURL = this.postByIdURL + postId;
        try{
          Vue.axios
          .get(getPostByIdURL)
          .then((response) => {
            this.postContent = response.data;
            this.postLoading = false;
          }).catch((error) => {
            console.log(error);
            this.postLoading=true;
          })
        }catch(ex){
          console.log(ex.message);
          this.postLoading=true;
        }
      },
      trimContent(content) {
        if(content !=null){
          var htmlData = content.replace(/<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g, '');
          //eslint-disable-next-line
          // var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, '');
          return htmlData;
        }
      },
    },
    computed: {

    }
}


</script>

<style scoped>
  .email-template {
    background:white;
    width:70%;
    margin:0 auto;
    padding:10px;
  }
  .email-template-product-logo{
    width:300px;
  }
  .email-template-border-seperator{
    border:2px solid #e5e5e5;
    margin-bottom:20px;
  }
  .email-template-aticle-subject{
    color:red;
    font-size:24px;
    line-height:normal;
    margin-bottom:20px;
  }
  .email-template-border-seperator-small{
    border: 1px solid #e5e5e5;
    margin-bottom:10px;
  }
  .email-template-author-name-and-date{
    font-size:18px;
    line-height:22px;
    color: #000000;
    margin-bottom:20px;
  }
  .email-template-author-name-and-date span{
    margin-right:5px;
  }
  .email-template-body{
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-align: left;
    font-weight: 300;
  }
  @media only screen and (max-width:767px){
    .email-template{
      width:100%;
    }
    .email-template-product-logo{
      width:200px;
    }
    .email-template-aticle-subject{
      font-size:20px;
      line-height:normal;
    }
  }
</style>
<style>
.email-template-body a {
  color: #000000 !important;
  text-decoration: none !important;
  border-bottom: 1px solid #ababab !important;
}

.email-template-body ul,ol{
  padding:20px;
}
 
.email-template-body p{
  margin-bottom:15px;
}

.email-template-body sup {
  font-size: 10px;
  color: #000000 !important;
  font-weight: 500px;
}

.email-template-body table {
  border-collapse: collapse;
  width: 100%;
}

.email-template-body h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
}
</style>
