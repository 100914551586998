<template>
<div>
    <div class="notification-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
    </div>
    <mds-layout-grid>
        <mds-row>
            <mds-col :cols="12">
                <div class="notification-alert" v-if="customAlertSuccess" tinted>
                    <mds-alert variation="success" size="small" :list="lstSuccess" @mds-alert-dismissed="customAlertSuccess=false">
                    </mds-alert>
                </div>
                <div class="notification-alert" v-if="customAlertError" tinted>
                    <mds-alert variation="error" title="Form Error" size="small" :list="lstError" @mds-alert-dismissed="customAlertError=false">
                    </mds-alert>
                </div>
            </mds-col>
        </mds-row>
    </mds-layout-grid>

    <mds-layout-grid>
        <mds-row>
            <mds-col :cols="12">
                <div class="notification-heading">
                    Manage Notifications
                </div>
                <div class="nofification-box">
                    <div style="margin:25px 23px 0px 20px">
                        <div class="nofification-email-text">
                            Receive email commentary, updates, new issue alerts from the editor, and stay abreast on all of the latest developments.
                        </div>
                    </div>
                    <mds-layout-grid>
                        <mds-row>
                            <mds-col :cols="3">
                                <div style="margin:4px 23px 0px 20px">
                                    <div style="margin-top:16px" v-if="MFIAccess">
                                        <mds-switch value="FundInvestor" v-model="MFIEmailNofification" :disabled="MFIdisabled" @change="UpdateEmailNotification('MFI')"><span id="MFIEmailNofificationClass">FundInvestor</span></mds-switch>
                                    </div>
                                    <div style="margin-top:16px" v-if="MSIAccess">
                                        <mds-switch value="StockInvestor" v-model="MSIEmailNofification" :disabled="MSIdisabled" @change="UpdateEmailNotification('MSI')"><span id="MSIEmailNofificationClass">StockInvestor</span></mds-switch>
                                    </div>
                                    <div style="margin-top:16px" v-if="MDIAccess">
                                        <mds-switch value="DividendInvestor" v-model="MDIEmailNofification" :disabled="MDIdisabled" @change="UpdateEmailNotification('MDI')"><span id="MDIEmailNofificationClass">DividendInvestor</span></mds-switch>
                                    </div>
                                    <div style="margin-top:16px" v-if="MEIAccess">
                                        <mds-switch value="ETFInvestor" v-model="MEIEmailNofification" :disabled="MEIdisabled" @change="UpdateEmailNotification('MEI')"><span id="MEIEmailNofificationClass">ETFInvestor</span></mds-switch>
                                    </div>
                                </div>
                            </mds-col>
                        </mds-row>
                        <mds-row>
                            <mds-col :cols="12">
                                <div style="margin: 32px 23px 0px 20px;" class="nofification-email-text">
                                    Receive information about new features, products and member benefits via email.
                                </div>
                                <div style="margin-top:16px;margin-left: 20px;" v-if="DOTCOMAccess">
                                    <mds-switch value="DotCom" v-model="DotComEmailNotification" :disabled="DOTCOMdisabled" @change="UpdateEmailNotification('DOTCOM')"><span id="DotComEmailNofificationClass">All Newsletters</span></mds-switch>
                                </div>
                            </mds-col>
                        </mds-row>
                        <mds-row>
                            <mds-col :cols="12">
                                <div style="margin:0px 23px 0px 20px">
                                    <div class="notification-border"></div>

                                    <div class="notification-switch-text">
                                        Switch toggle on and off to subscribe or unsubscribe to your notifications at any time. Email alerts will be delivered automatically to your inbox.
                                    </div>
                                </div>
                            </mds-col>
                        </mds-row>
                    </mds-layout-grid>
                </div>
            </mds-col>
        </mds-row>
    </mds-layout-grid>
</div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);
import MdsAlert from '@mds/alert';
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import MdsSwitch from '@mds/switch';
import MdsLoader from "@mds/loader";
export default {
    name: "notification",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsSwitch,
        MdsLoader,
        MdsAlert
    },
    data() {
        return {
            isLoading: true,
            userID: "",
            userDetails: [],
            MFIAccess: false,
            MSIAccess: false,
            MDIAccess: false,
            MEIAccess: false,
            DOTCOMAccess: false,
            MFIEmailNofification: false,
            MSIEmailNofification: false,
            MDIEmailNofification: false,
            MEIEmailNofification: false,
            DotComEmailNotification: false,
            customAlertError: false,
            customAlertSuccess: false,
            lstSuccess: [],
            lstError: [],
            MFIdisabled: false,
            MSIdisabled: false,
            MDIdisabled: false,
            MEIdisabled: false,
            DOTCOMdisabled: false,
            productDataRoles: "",
            MEIProductAccess: false,
            MDIProductAccess: false,
            MSIProductAccess: false,
            MFIProductAccess: false

        }
    },
    mounted() {
        this.productDataRoles = this.$auth0.user["https://morningstar.com/uim_roles"];
        if(this.productDataRoles != undefined && this.productDataRoles !=null) {
        this.MEIProductAccess = this.productDataRoles.includes("EI_MEMBER");
        this.MDIProductAccess = this.productDataRoles.includes("DI_MEMBER");
        this.MSIProductAccess = this.productDataRoles.includes("SI_MEMBER");
        this.MFIProductAccess = this.productDataRoles.includes("FI_MEMBER");
        }
        this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
        if (this.userID) {
            this.getUserDetails();
        }

    },
    methods: {
        getUserDetails() {
            var getUserDetailsAPIURL = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserDetails;
            getUserDetailsAPIURL = getUserDetailsAPIURL.replace("{userId}", this.userID);
            try {
                Vue.axios
                    .get(getUserDetailsAPIURL, {
                        headers: {
                            Accept: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    this.userDetails = JSON.parse(response.data);
                                    if (this.userDetails) {
                                        if (this.userDetails["CustomerID"]) {
                                            this.getEmailNotificationList(this.userDetails["CustomerID"]);
                                        }
                                    }
                                }

                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => (this.isLoading = false));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        getEmailNotificationList(customerId) {
            this.isLoading = true;
            var GetEmailNotificationUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_EmailNotification;
            GetEmailNotificationUrl = GetEmailNotificationUrl.replace('{customerId}', customerId);

            try {
                Vue.axios
                    .get(GetEmailNotificationUrl)
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    var responseData = response.data;
                                    if (responseData) {
                                        for (var i in responseData) {
                                            var obj = responseData[i];
                                            if (obj) {
                                                // if (obj.OptionID && obj.OptionValue) {
                                                if (obj.OptionID == 9) {
                                                    //this.MSIAccess = true;
                                                    if (obj.OptionID == 9 && obj.OptionValue == 1) {
                                                        this.MSIEmailNofification = true;
                                                        setTimeout(() => {
                                                            var boldText = document.getElementById("MSIEmailNofificationClass");
                                                            boldText.classList.remove("notification-normal");
                                                            boldText.classList.add("notification-bold");
                                                        }, 100);
                                                    } else {
                                                        this.MSIEmailNofification = false;
                                                        setTimeout(() => {
                                                            var normalText = document.getElementById("MSIEmailNofificationClass");
                                                            normalText.classList.remove("notification-bold");
                                                            normalText.classList.add("notification-normal");
                                                        }, 100);
                                                    }
                                                }
                                                if (obj.OptionID == 147) {
                                                   // this.MDIAccess = true;
                                                    if (obj.OptionID == 147 && obj.OptionValue == 1) {
                                                        this.MDIEmailNofification = true;
                                                        setTimeout(() => {
                                                            var boldText = document.getElementById("MDIEmailNofificationClass");
                                                            boldText.classList.remove("notification-normal");
                                                            boldText.classList.add("notification-bold");
                                                        }, 100);
                                                    } else {
                                                        this.MDIEmailNofification = false;
                                                        setTimeout(() => {
                                                            var normalText = document.getElementById("MDIEmailNofificationClass");
                                                            normalText.classList.remove("notification-bold");
                                                            normalText.classList.add("notification-normal");
                                                        }, 100);
                                                    }
                                                }
                                                if (obj.OptionID == 200) {
                                                    //this.MEIAccess = true;
                                                    if (obj.OptionID == 200 && obj.OptionValue == 1) {
                                                        this.MEIEmailNofification = true;
                                                        setTimeout(() => {
                                                            var boldText = document.getElementById("MEIEmailNofificationClass");
                                                            boldText.classList.remove("notification-normal");
                                                            boldText.classList.add("notification-bold");
                                                        }, 100);
                                                    } else {
                                                        this.MEIEmailNofification = false;
                                                        setTimeout(() => {
                                                            var normalText = document.getElementById("MEIEmailNofificationClass");
                                                            normalText.classList.remove("notification-bold");
                                                            normalText.classList.add("notification-normal");
                                                        }, 100);
                                                    }
                                                }
                                                if (obj.OptionID == 99) {
                                                   // this.MFIAccess = true;
                                                    if (obj.OptionID == 99 && obj.OptionValue == 1) {
                                                        this.MFIEmailNofification = true;
                                                        setTimeout(() => {
                                                            var boldText = document.getElementById("MFIEmailNofificationClass");
                                                            boldText.classList.remove("notification-normal");
                                                            boldText.classList.add("notification-bold");
                                                        }, 100);
                                                    } else {
                                                        this.MFIEmailNofification = false;
                                                        setTimeout(() => {
                                                            var normalText = document.getElementById("MFIEmailNofificationClass");
                                                            normalText.classList.remove("notification-bold");
                                                            normalText.classList.add("notification-normal");
                                                        }, 100);
                                                    }
                                                }
                                                if (obj.OptionID == 19) {

                                                    if (obj.OptionID == 19 && obj.OptionValue == 1) {
                                                        this.DotComEmailNotification = true;
                                                        
                                                        setTimeout(() => {
                                                            var boldText = document.getElementById("DotComEmailNofificationClass");
                                                            boldText.classList.remove("notification-normal");
                                                            boldText.classList.add("notification-bold");
                                                        }, 100);
                                                    } else {
                                                        this.DotComEmailNotification = false;
                                                        setTimeout(() => {
                                                            var normalText = document.getElementById("DotComEmailNofificationClass");
                                                            normalText.classList.remove("notification-bold");
                                                            normalText.classList.add("notification-normal");
                                                        }, 100);
                                                    }
                                                }
                                                // }
                                            }
                                        }
                                    }
                                }

                            }
                            if (this.MEIProductAccess) {
                                this.MEIAccess = true;
                            }
                            if (this.MFIProductAccess) {
                                this.MFIAccess = true;
                            }
                            if (this.MDIProductAccess) {
                                this.MDIAccess = true;
                            }
                            if (this.MSIProductAccess) {
                                this.MSIAccess = true;
                            }

                            this.DOTCOMAccess = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => (this.isLoading = false));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        showSuccessMsg(str) {
            this.lstSuccess = [];
            if (str) {
                this.lstSuccess.push(str);
            }
            this.customAlertSuccess = true;
            this.customAlertError = false;
            setTimeout(() => {
                this.customAlertSuccess = false;
            }, 5000);
        },
        showErrorMsg(str) {
            this.lstError = [];
            if (str) {
                this.lstError.push(str);
            }
            this.customAlertError = true;
            this.customAlertSuccess = false;
            setTimeout(() => {
                this.customAlertError = false;
            }, 5000);
        },
        UpdateEmailNotification(Type) {
            var OptionID;
            var ProductFlag;
            if (Type == "MFI") {
                OptionID = 99;
                ProductFlag = this.MFIEmailNofification;
                //const element = document.querySelector('#elementId');
                // var element = document.getElementById("MFIEmailNofification");
                //  if (test) {
                //     element.classList.add('class-to-add-or-remove');
                // } 
                //else {
                //    element.classList.remove('class-to-add-or-remove');
                //}

            } else if (Type == "MSI") {
                OptionID = 9;
                ProductFlag = this.MSIEmailNofification;
            } else if (Type == "MDI") {
                OptionID = 147;
                ProductFlag = this.MDIEmailNofification;
            } else if (Type == "MEI") {
                OptionID = 200;
                ProductFlag = this.MEIEmailNofification;
            } else if (Type == "DOTCOM") {
                OptionID = 19;
                ProductFlag = this.DotComEmailNotification;
            }
            var omahaCustomerID = "";
            if (this.userDetails["CustomerID"]) {
                omahaCustomerID = this.userDetails["CustomerID"];
            }
            this.MSIdisabled = true;
            this.MDIdisabled = true;
            this.MEIdisabled = true;
            this.MFIdisabled = true;
            this.DOTCOMdisabled = true;

            this.isLoading = true;
            var UpdateEmailNotificationCommand = {};
            UpdateEmailNotificationCommand.OptionID = OptionID;
            UpdateEmailNotificationCommand.ProductFlag = ProductFlag;
            UpdateEmailNotificationCommand.omahaCustomerID = omahaCustomerID;

            try {
                var UpdateEmailNotification = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_UpdateEmailNotification;
                Vue.axios
                    .put(UpdateEmailNotification, UpdateEmailNotificationCommand)
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    var result = response.data;
                                    if (result) {
                                        if (result.StatusCode == 200) {
                                            this.showStateMsg(result.ResponseMessage);
                                            this.getEmailNotificationList(this.userDetails["CustomerID"]);
                                            //this.showSuccessMsg(result.ResponseMessage);
                                        } else {
                                            this.showErrorStateMsg(result.ResponseMessage);
                                        }

                                    }

                                }

                            }

                        }
                    })
                    .catch((error) => {
                        console.log(error);

                    })
                    .finally(() => ((this.isLoading = false), (this.MSIdisabled = false), (this.MDIdisabled = false), (this.MEIdisabled = false), (this.MFIdisabled = false), (this.DOTCOMdisabled = false)));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        showStateMsg(str) {
            this.$store.state.product.lstAlertMsg = [];
            this.$store.state.product.lstAlertMsg.push(str);

            this.$store.state.product.SuccessAlert = true;
            this.$store.state.product.ErrorAlert = false;
            setTimeout(() => {
                this.$store.state.product.SuccessAlert = false;
            }, 5000);
        },
        showErrorStateMsg(str) {
            this.$store.state.product.lstAlertMsg = [];
            this.$store.state.product.lstAlertMsg.push(str);

            this.$store.state.product.SuccessAlert = false;
            this.$store.state.product.ErrorAlert = true;
            setTimeout(() => {
                this.$store.state.product.ErrorAlert = false;
            }, 5000);
        },
    }
}
</script>

<style scoped>
body,
body * {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

.notification-heading {
    color: var(--text-primary, #1E1E1E);
    /* Heading/Level 7: Light */
    font-size: 16px;

    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

.nofification-box {
    border-radius: 6px;
    border: 1px solid #CCC;
    background: var(--background-primary, #FFF);
    width: 100%;
    margin-top: 10px;
}

.nofification-email-text {
    color: var(--text-primary, #1E1E1E);
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
}

.notification-switch-heading {
    color: var(--text-primary, #1E1E1E);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.notification-border {
    border: 1px solid #E5E5E5;
    margin-top: 32px;
}

.notification-switch-text {
    color: var(--text-primary, #1E1E1E);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    margin-top: 12px;
    margin-bottom: 15px;
}

.notification-loader {
    width: "276px";
    height: "69px";
}

.notification-wait {
    cursor: wait;
}

.notification-pointer {
    cursor: pointer;
}

@media only screen and (min-width: 0px) and (max-width: 749px) {
    .notification-alert {
        position: absolute;
        top: 19%;
        left: 10px;
        width: 95%;
        z-index: 99999999999;
    }

    .nofification-box {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1200px) {
    .notification-alert {
        position: absolute;
        top: 15%;
        left: 26%;
        width: 50%;
        z-index: 99999999999;
    }

    .nofification-box {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 2000px) {

    .nofification-box {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 2001px) and (max-width: 6000px) {

    .nofification-box {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        margin-top: 10px;
        margin-bottom: 125px;
    }
}

.notification-normal {
    color: var(--text-primary, #1E1E1E);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

.notification-bold {
    color: var(--text-primary, #1E1E1E);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}
</style><style>
.notification-alert .mds-alert .mds-alert__list li::before {
    content: none;
}
</style>
