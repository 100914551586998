<template>
  <mds-layout-grid class="commentary-section">
    <mds-loader v-if="isLoading && noData == false" aria-label="Medium Loader" class="loader-style"></mds-loader>
    <data-missing v-else-if="!isLoading && noData" :error-message="noArticleMessage"></data-missing>
    <div v-else>
      <mds-row class="product-commentary-name-row">
        <mds-col>
          <div class="product-commentary-name">
            <mds-section border="none" :size="7" title="FundInvestor Commentary" bold
              v-if="CurrentProduct.toUpperCase() == 'MFI'"></mds-section>
            <mds-section border="none" :size="7" title="StockInvestor Commentary" bold
              v-else-if="CurrentProduct.toUpperCase() == 'MSI'"></mds-section>
            <mds-section border="none" :size="7" title="DividendInvestor Commentary" bold
              v-else-if="CurrentProduct.toUpperCase() == 'MDI'"></mds-section>
            <mds-section border="none" :size="7" title="ETFInvestor Commentary" bold v-else></mds-section>
          </div>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col class="left-collapse-section" :cols="2" :cols-at-l="3" :cols-at-xl="3">
          <collapsible-article :article-date="postToDisplay.PostDate" :article-id="postToDisplay.PostID"
            @post-clicked="changePost"></collapsible-article>
        </mds-col>
        <mds-col class="left-collapse-section-responsive" :cols="12">
          <mds-button variation="secondary" size="large" icon-right="caret-down" @click="commentaryLeftPanelPopOVer = !commentaryLeftPanelPopOVer" id="left-collapse-trigger" class="commentary-responsive-left-panel-button"> Commentary Archive </mds-button>
          <mds-popover class="commentary-responsive-left-panel-popover" triggered-by="left-collapse-trigger" v-model="commentaryLeftPanelPopOVer" :position="['bottom-right']">
            <collapsible-article :article-date="postToDisplay.PostDate" :article-id="postToDisplay.PostID"
            @post-clicked="changePost"></collapsible-article>
          </mds-popover>
        </mds-col>
        <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="9" :cols-at-xl="9">
          <div class="commetary-block">
            <div class="commentary-title" id="title">
              <span>{{ postToDisplay.Subject }}</span>
            </div>
            <div class="border-separator"></div>
            <div class="commentary-aurthor-name">
              <div class="commentary-author-name-and-post-date">
                <span id="author">by {{ postToDisplay.PostAuthorName }}</span>
                <span id="date">{{
                  postToDisplay.PostDate | formatDateVal
                }}</span>
              </div>
              <div class="commentary-contact-author-and-print-button">
                <mds-button-container right-aligned>
                  <mds-button v-if="CurrentProduct.toUpperCase() == 'MDI'" variation="flat" size="small" icon="envelope"
                    type="button" href="mailto:mdi@morningstar.com">Contact David</mds-button>
                  <mds-button v-else-if="CurrentProduct.toUpperCase() == 'MEI'" variation="flat" size="small"
                    icon="envelope" type="button" href="mailto:bryan.armour@morningstar.com">Contact Bryan</mds-button>
                  <mds-button v-else-if="CurrentProduct.toUpperCase() == 'MSI'" variation="flat" size="small"
                    icon="envelope" type="button" href="mailto:msi@morningstar.com">Contact David</mds-button>
                  <mds-button v-else-if="CurrentProduct.toUpperCase() == 'MFI'" variation="flat" size="small"
                    icon="envelope" type="button" href="mailto:russel.kinnel@morningstar.com">Contact Russ</mds-button>
                  <mds-button variation="flat" size="small" icon="print" type="button"
                    @click="printWindow()" class="commentary-print-button">Print</mds-button>
                </mds-button-container>
              </div>
            </div>
            <div class="post-content-section" id="postcontent">
              <p v-html="trimContent(postToDisplay.FormattedBody)"></p>
            </div>
          </div>
          <blog-menu class="blog-menu-buttons" :post-id="postToDisplay.PostID" :post-subject="postToDisplay.Subject"
            v-if="isAdmin"></blog-menu>
        </mds-col>
      </mds-row>
    </div>
  </mds-layout-grid>
</template>

<script lang="js">
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import {
  MdsLayoutGrid,
  MdsRow,
  MdsCol
} from "@mds/layout-grid";
import MdsSection from "@mds/section";
import CollapsibleArticle from "./CollapsibleArticle";
import MdsLoader from "@mds/loader";
import { MdsButton, MdsButtonContainer } from '@mds/button'
import BlogMenu from "@/components/Common/Commentary/Blog/BlogMenu";
import DataMissing from "@/components/Common/ErrorPages/DataMissing";
import MdsPopover from '@mds/popover';

Vue.use(VueAxios, axios);

export default {
  name: 'commentary-section',
  components: {
    MdsLayoutGrid,
    MdsCol,
    MdsRow,
    MdsSection,
    CollapsibleArticle,
    MdsLoader,
    MdsButton,
    MdsButtonContainer,
    BlogMenu,
    DataMissing,
    MdsPopover,
  },
  data() {
    return {
      postToDisplay: {},
      latestPostByMonth: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_LatestArticlesURL,
      CurrentProduct: this.$session.get("current-product"),
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      isLoading: true,
      noData: false,
      isAdmin: false,
      postByIdURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_PostById,
      mdiSectionId: process.env.VUE_APP_MDISectionID,
      msiSectionId: process.env.VUE_APP_MSISectionID,
      mfiSectionId: process.env.VUE_APP_MFISectionID,
      meiSectionId: process.env.VUE_APP_MEISectionID,
      noArticleState: false,
      noArticleMessage: null,
      productSectionID: null,
      commentaryLeftPanelPopOVer:false,
      DI_MEMBER_ADMIN:false,
      SI_MEMBER_ADMIN:false,
      EI_MEMBER_ADMIN:false,
      FI_MEMBER_ADMIN:false,
    }
  },
  methods: {
    printWindow: function () {
      var title = document.getElementById('title').innerHTML;
      var author = document.getElementById('author').innerHTML;
      var date = document.getElementById('date').innerHTML;
      var postcontent = document.getElementById('postcontent').innerHTML;
      var myWindow = window.open();
      var printable = myWindow.document.write('<div style="width: 570px; padding-left: 25px;">' + '<h1 style="font-size: 20px;">' + title + '</h1>' + '</br>' + author + '&nbsp;' + date + '</br>' + postcontent + '</div>');
      printable.print();
    },
    getLatestPostDataByMonth() {
      const getLatestPost = this.latestPostByMonth + this.CurrentProduct;
      try {
        Vue.axios
          .get(getLatestPost)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data.length > 0) {
                  this.postToDisplay = response.data.slice(0, 1).map((obj) => ({
                    ...obj,
                  }))[0];
                  this.isLoading = false;
                  const postId = this.postToDisplay.PostID;
                  this.$router.replace({ name: 'commentary', params: { pid: postId } })
                    .catch(err => {
                      if (
                        err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')
                      ) {
                        console.log(err);
                      }
                    });
                } else {
                  this.noData = true;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (ex) {
        console.log(ex.message);
      }
    },
    changePost(postID) {
      this.commentaryLeftPanelPopOVer = false;
      const getPostByIdURL = this.postByIdURL + postID;
      try {
        Vue.axios
          .get(getPostByIdURL)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data != null) {
                  this.postToDisplay = response.data;
                  const postId = this.postToDisplay.PostID;
                  this.productSectionID = this.postToDisplay.SectionID;
                  this.setPostAnchorTarget();
                  this.$router.push({ name: 'commentary', params: { pid: postId } }).catch(err => {
                    if (
                      err.name !== 'NavigationDuplicated' &&
                      !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                      console.log(err);
                    }
                  });
                  const getSectionIDValue = this.getSectionID();
                  if (this.productSectionID != getSectionIDValue) {
                    this.noData = true;
                    this.isLoading = false;
                    this.noArticleMessage = "The requested post doesn't belong to this product.";
                  }
                  else {
                    this.isLoading = false;
                  }

                }
                else {
                  this.noData = true;
                  this.isLoading = false;
                  this.noArticleMessage = "The requested post doesn't exist.";
                }
              }
            }
          }).catch((error) => {
            this.noData = true;
            this.isLoading = false;
            this.noArticleMessage = "The requested post doesn't exist.";
            console.log(error);
          })
      } catch (ex) {
        console.log(ex.message);
      }
    },
    trimContent(content) {
      if (content != null) {
        var htmlData = content.replace(/<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g, '');
        //eslint-disable-next-line
        var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, '');
        return trimInlineCss;
        //return htmlData;
      }
    },
    getSectionID() {
      let sectionId = null;
      if (this.CurrentProduct.toUpperCase() == 'MDI') {
        sectionId = this.mdiSectionId;
      }
      else if (this.CurrentProduct.toUpperCase() == 'MSI') {
        sectionId = this.msiSectionId;
      }
      else if (this.CurrentProduct.toUpperCase() == 'MFI') {
        sectionId = this.mfiSectionId;
      }
      else {
        sectionId = this.meiSectionId;
      }
      return sectionId;
    },
    openResponsiveLeftPanel(){
      this.commentaryLeftPanelPopOVer=true;
    },
    setPostAnchorTarget(){
      setTimeout(() => {
        if(this.postToDisplay.FormattedBody != null){
          var getPostContent = document.getElementById("postcontent");
          var getAnchorTagList = getPostContent.getElementsByTagName("a");
          for(let i=0; i < getAnchorTagList.length; i++){
            getAnchorTagList[i].setAttribute("target", "_blank");
          }
        }
      }, 1000);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    const postId = this.$route.params.pid;
    if (postId) {
      this.changePost(postId);
    }
    else {
      this.getLatestPostDataByMonth();
    }
    this.setPostAnchorTarget();
  },
  created() {
    const isAdminRoles = this.$auth0.user["https://morningstar.com/uim_roles"];
    if(isAdminRoles != undefined && isAdminRoles !=null) {
      this.DI_MEMBER_ADMIN = isAdminRoles.includes("DI_MEMBER_ADMIN");
      this.SI_MEMBER_ADMIN = isAdminRoles.includes("SI_MEMBER_ADMIN");
      this.EI_MEMBER_ADMIN = isAdminRoles.includes("EI_MEMBER_ADMIN");
      this.FI_MEMBER_ADMIN = isAdminRoles.includes("FI_MEMBER_ADMIN");
    }
    if (this.CurrentProduct.toUpperCase() == 'MSI' && this.SI_MEMBER_ADMIN) {
      this.isAdmin = true;
    }
    else if (this.CurrentProduct.toUpperCase() == 'MDI' && this.DI_MEMBER_ADMIN) {
      this.isAdmin = true;
    }
    else if (this.CurrentProduct.toUpperCase() == 'MFI' && this.FI_MEMBER_ADMIN) {
      this.isAdmin = true;
    }
    else if (this.CurrentProduct.toUpperCase() == 'MEI' && this.EI_MEMBER_ADMIN) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }
}
</script>

<style scoped>
.commentary-section {
  overflow-x: hidden;
  width:1280px;
  max-width:1280px;
  margin:0 auto;
}

.product-commentary-name {
  margin-top: 16px;
  /*margin-left: 123px;*/
  width: 613px;
  height: 45px;
}

.left-collapse-section {
  margin-top: 31px;
  /*margin-left: 123px;*/
  margin-bottom: 40px;
}

.commetary-block {
  width: 625px;
  margin-top: 33px;
  /*margin-left: 55px;*/
  /*margin-left: 91px;*/
  text-align: left;
}

.commentary-title {
  height: auto;
  padding-top: 4px;
}

.commentary-title span {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 700;
}

.border-separator {
  background-color: #e5e5e5;
  height: 1.11px;
  margin-top: 12px;
}

.commentary-aurthor-name {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 28px;
  margin-top: 7px;
}

.commentary-aurthor-name div {
  display: table-cell;
  height: 28px;
}

.commentary-author-name-and-post-date {
  float: left;
}

.commentary-author-name-and-post-date span:first-child {
  font-weight: 700;
}

.commentary-author-name-and-post-date span:nth-child(2) {
  margin-left: 10px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
}

.commentary-contact-author-and-print-button {
  float: right;
}

.post-content-section {
  margin-top: 48px;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  text-align: left;
  font-weight: 300;
}

.loader-style {
  margin-left: 750px;
  margin-top: 150px;
}

.contact-tab {
  margin-left: 300px;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
}

.read-full-commentary {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  margin-left: 4.9%;
  cursor: grab;
  margin-top: 7px;
  margin-bottom: 30px;
}

.blog-menu-buttons {
 width:100%;
 max-width: 625px;
 height: auto;
 margin-bottom: 4%;
 background: transparent;
}
.left-collapse-section-responsive{
  display:none;
}
@media only screen and (max-width:1400px){
  .commentary-section{
    width:90%;
  }
}
@media only screen and (min-width:320px) and (max-width:1000px){
  .product-commentary-name-row{
    display:none;
  }
  .left-collapse-section{
    display:none;
  }
  .commentary-print-button{
    display:none;
  }
  .left-collapse-section-responsive{
    display:block;
  }
  .commetary-block{
    width:100%;
    margin-left:0px;
    margin-top:25px;
  }
  .blog-menu-buttons{
    margin-left:0px;
  }
  .commentary-title span {
    color: #1e1e1e;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
  }
  .post-content-section{
    margin-top: 25px;
    font-size: 16px;
    line-height: 21px;
    color: #1E1E1E;
  }
  .commentary-responsive-left-panel-popover{
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-x: overlay;
  } 
  ::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
  }
  .blog-menu-buttons{
    margin-left:0px;
    width: 100%;
    margin-top: 5%;
  }
}
@media only screen and (min-width:1001px) and (max-width:1200px){
  .commentary-section{
    margin-top: 0px;
  }
}
@media only screen and (min-width:1001px) and (max-width:1150px){
  .commetary-block{
    margin-left: 6%;
    width:90%;
  }
  .blog-menu-buttons{
    margin-left:6px;
  }
}
@media only screen and (min-width:1151px) and (max-width:1400px){
  .commetary-block{
    margin-left: 30px;
  }
  .blog-menu-buttons{
    margin-left:30px;
  }
}
@media only screen and (max-width:1000px){
  .commentary-section{
    margin-top:15px;
  }
  .loader-style{
    margin-left:50%;
  }
}
@media only screen and (min-width:1001px) and (max-width:1150px){
  .blog-menu-buttons{
    margin-left:45px;
  }
}
</style>
<style>
.post-content-section a {
  color: #000000 !important;
  text-decoration: none !important;
  border-bottom: 1px solid #ababab !important;
}

.post-content-section sup {
  font-size: 10px;
  color: #000000 !important;
  font-weight: 500px;
}

.post-content-section table {
  border-collapse: collapse;
  width: 100%;
}
.post-content-section p {
  margin-bottom:15px;
}
.post-content-section h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
}
.post-content-section ul,ol{
  padding:20px;
}
.commentary-responsive-left-panel-button:focus {
  box-shadow: none !important;
  outline: none !important;
}
.read-full-commentary .mds-icon {
  padding-top: 4px !important;
  margin-left: 8px !important;
}
</style>
