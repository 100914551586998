const HeaderModule = {
    namespaced: true,
    state() {
        return {
          isResponsiveHeaderActive:false,
          isResponsiveSubscriptionModalActive:false,
      }
    },
  };
  
  export default HeaderModule;