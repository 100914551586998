<template>
<div class="my-account-div">
    <div class="my-account-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
    </div>
    <div class="my-account-main">
        <mds-layout-grid>
            <mds-row>
                <mds-col :cols="3" :cols-at-l="3" :cols-at-xl="3" :cols-at-m="3"></mds-col>
                <mds-col :cols="12" :cols-at-l="6" :cols-at-xl="6" :cols-at-m="6" :cols-at-s="12">
                    <div class="my-account-heading">
                        My Account
                    </div>
                    <div v-if="this.$store.state.product.SuccessAlert" style="margin-top: 10px;" class="notification-alert-for-subscription">
                        <mds-alert variation="success" size="small" :list="this.$store.state.product.lstAlertMsg" @mds-alert-dismissed="$store.state.product.SuccessAlert=false">
                        </mds-alert>
                    </div>
                    <div v-if="this.$store.state.product.ErrorAlert" style="margin-top: 10px;" class="notification-alert-for-subscription-error">
                        <mds-alert variation="error" size="small" title="Form Error" :list="this.$store.state.product.lstAlertMsg" @mds-alert-dismissed="$store.state.product.ErrorAlert=false">
                        </mds-alert>
                    </div>
                    <div class="my-account-tabs">
                        <mds-tabs style="width:100%;" class="my-account-web">
                            <mds-tabs-item v-if="DispProfile" :active="activeProfile"><span class="my-account-tab-item" @click="checkActiveTab('MyProfile')">My Profile</span></mds-tabs-item>
                            <mds-tabs-item v-if="DispNotifications" :active="activeNotifications"><span class="my-account-tab-item" @click="checkActiveTab('Notifications')">Notifications</span></mds-tabs-item>
                            <mds-tabs-item v-if="DispSubscription" :active="activeSubscription"><span class="my-account-tab-item" @click="checkActiveTab('Subscription')">Subscription</span></mds-tabs-item>
                            <mds-tabs-item v-if="DispCustomerSupport" :active="activeCustomerSupport"><span class="my-account-tab-item" @click="checkActiveTab('CustomerSupport')">Customer Support</span></mds-tabs-item>
                        </mds-tabs>
                        <mds-tabs style="width:100%;" class="my-account-mobile">
                            <mds-tabs-item v-if="DispProfile" :active="activeProfile" class="my-account-tab-mobile"><span class="my-account-tab-item" style="" @click="checkActiveTab('MyProfile')">Profile</span></mds-tabs-item>
                            <mds-tabs-item v-if="DispNotifications" :active="activeNotifications" class="my-account-tab-mobile"><span class="my-account-tab-item" style="" @click="checkActiveTab('Notifications')">Notifications</span></mds-tabs-item>
                            <mds-tabs-item v-if="DispSubscription" :active="activeSubscription" class="my-account-tab-mobile"><span class="my-account-tab-item" style="" @click="checkActiveTab('Subscription')">Subscription</span></mds-tabs-item>
                            <mds-tabs-item v-if="DispCustomerSupport" :active="activeCustomerSupport" class="my-account-tab-mobile"><span class="my-account-tab-item" @click="checkActiveTab('CustomerSupport')">Support</span></mds-tabs-item>
                        </mds-tabs>
                    </div>
                    <div class="my-account-body">
                        <div v-if="activeProfile">
                            <my-profile></my-profile>
                        </div>
                        <div v-if="activeNotifications">
                            <notification></notification>
                        </div>
                        <div v-if="activeSubscription">
                            <subscription></subscription>
                        </div>
                        <div v-if="activeCustomerSupport">
                            <customer-support></customer-support>
                        </div>
                    </div>
                </mds-col>
                <mds-col :cols="3" :cols-at-l="3" :cols-at-xl="3" :cols-at-m="3"></mds-col>
            </mds-row>
        </mds-layout-grid>
    </div>
</div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

Vue.use(VueAxios, axios);

import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import {
    MdsTabs,
    MdsTabsItem
} from '@mds/tabs';
import CustomerSupport from "@/components/MyAccount/CustomerSupport.vue";
import MyProfile from "@/components/MyAccount/MyProfile.vue";
import Notification from "@/components/MyAccount/Notification.vue";
import Subscription from "@/components/MyAccount/Subscription.vue";
import MdsLoader from "@mds/loader";
import MdsAlert from '@mds/alert';
export default {
    name: "my-account",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsTabs,
        MdsTabsItem,
        CustomerSupport,
        MyProfile,
        Notification,
        Subscription,
        MdsLoader,
        MdsAlert

    },
    data() {
        return {
            activeProfile: false,
            activeNotifications: false,
            activeSubscription: false,
            activeCustomerSupport: false,
            DispProfile: false,
            DispNotifications: false,
            DispSubscription: false,
            DispCustomerSupport: false,
            isLoading: true,
            userID: "",
            userDetails: [],
            customAlertSuccess: true
        }
    },
    methods: {
        checkActiveTab(activeTab) {
            if (activeTab == "MyProfile") {
                this.activeProfile = true;
                this.activeNotifications = false;
                this.activeSubscription = false;
                this.activeCustomerSupport = false;
            } else if (activeTab == "Notifications") {
                this.activeNotifications = true;
                this.activeProfile = false;
                this.activeSubscription = false;
                this.activeCustomerSupport = false;
            } else if (activeTab == "Subscription") {
                this.activeSubscription = true;
                this.activeNotifications = false;
                this.activeProfile = false;
                this.activeCustomerSupport = false;
            } else if (activeTab == "CustomerSupport") {
                this.activeCustomerSupport = true;
                this.activeSubscription = false;
                this.activeNotifications = false;
                this.activeProfile = false;
            }

        },
        getUserDetails() {
            var getUserDetailsAPIURL = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserDetails;
            getUserDetailsAPIURL = getUserDetailsAPIURL.replace("{userId}", this.userID);
            try {
                Vue.axios
                    .get(getUserDetailsAPIURL, {
                        headers: {
                            Accept: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    this.userDetails = JSON.parse(response.data);
                                    if (this.userDetails) {

                                        if (this.userDetails["CustomerID"]) {
                                            this.$store.state.product.OmahaCustomer = true;
                                            this.DispProfile = true;
                                            this.DispNotifications = true;
                                            this.DispSubscription = true;
                                            this.DispCustomerSupport = true;
                                            this.activeProfile = true;

                                        } else {
                                            this.DispProfile = false;
                                            this.DispNotifications = false;
                                            this.DispSubscription = false;
                                            this.DispCustomerSupport = true;
                                            this.activeCustomerSupport = true;

                                        }
                                    }
                                }

                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => (this.isLoading = false));
            } catch (ex) {
                console.log(ex.message);
            }
        },
    },
    mounted() {
        this.$store.state.product.CurrentProduct = "Common";
        this.$session.set("current-product", "Common");
        this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
        if (this.userID) {
            this.getUserDetails();
        }
    }
}
</script>

<style scoped>
body,
body * {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

.my-account-tab-mobile{
    width:25%;
    text-align: center;
}
.my-account-heading {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin-top: 31px;
}


.my-account-tab-item {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
}



@media only screen and (min-width: 0px) and (max-width: 1000px) {
    .my-account-body {
    margin-top: 24px;
}

.my-account-tabs {
    margin-top: 54px;
    margin-bottom: 24px;
}


}

@media only screen and (min-width: 1000px) and (max-width: 100000px) {
    .my-account-body {
    margin-top: 36px;
}

.my-account-tabs {
    margin-top: 54px;
    margin-bottom: 35px;
}


}


@media only screen and (min-width: 0px) and (max-width: 700px) {
    .my-account-main {
        max-width: 1280px;
        margin: 10px;
    }
/*
    .my-account-tab-item {
        color: #000;
        font-size: 13px;
        font-weight: 300;
        line-height: 21px;
    }
    */
}

@media only screen and (min-width: 701px) and (max-width: 768px) {

    /*
    .my-account-tab-item {
        color: #000;
        font-size: 13px;
        font-weight: 300;
        line-height: 21px;
    }
    */

    .my-account-main {
        max-width: 1280px;
    }

}

@media only screen and (min-width: 769px) and (max-width: 6000px) {

    .my-account-main {
        max-width: 1200px;
        margin: 0px auto;
    }
}

@media only screen and (min-width: 2001px) and (max-width: 6000px) {
    .my-account-div {
        margin-bottom: 233px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 2000px) {
    .my-account-div {
        margin-bottom: 97px;
    }
}

/*.my-account-main{
    max-width: 1280px;
    margin: 0px auto;
}
*/
.my-account-loader {
    width: "276px";
    height: "69px";
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .my-account-web{
        display: none;
    }
    .my-account-mobile{
        display: block;
    }
}
@media only screen and (min-width: 601px) and (max-width: 6000px) {
    .my-account-web{
        display: block;
    }
    .my-account-mobile{
        display: none;
    }
}

.notification-alert-for-subscription .mds-alert .mds-alert__list li::before {
    content: none !important;
}

</style>
<style>
.notification-alert-for-subscription .mds-alert .mds-alert__list li::before {
    content: none !important;
}

.notification-alert-for-subscription>div>div:nth-child(2)>div{
   margin-top: -10px;
}

.notification-alert-for-subscription-error>div>div:nth-child(2)>div{
   margin-top: 10px;
}

.notification-alert-for-subscription-error>div>button{
   margin-top: 10px;
}

</style>
