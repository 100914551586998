<template>
	<div :class="{
		[$style['mdc-carousel']]: true,
		[$style['mdc-carousel--native-scroll']]: isNativeScroll
	}">
		<div ref="scroll" :class="$style['mdc-carousel__scroll']">
			<ul ref="items" :class="{
				[$style['mdc-carousel__items']]: true,
				[$style[`mdc-carousel__items--${gutter}-gutter`]]: gutter
			}">
				<slot />
			</ul>
		</div>

		<div :class="{
			[$style['mdc-carousel__buttons']]: true,
			[$style['mdc-carousel__buttons--inset']]: insetControls
		}">
			<div :class="{
				[$style['mdc-carousel__button-container--left']]: insetControls,
				[$style['mdc-carousel__button-container--on-gray']]: insetControls && onGray,
				[$style['mdc-carousel__button-container--on-dark']]: insetControls && onDark
			}">
			<slot name="left-button" :previousPage="previousPage">
					<!-- <mdc-button
						:class="{
							[$style['mdc-carousel__button']]: true,
							[$style['mdc-carousel__button--small']]: small,
						}"
						flat
						icon-only
						icon="angle-left"
						:small="small"
						@click="previousPage"
					>
						Previous Page
					</mdc-button> -->
					<div v-if="btnPrevcheck" @click="previousPage">
						<mds-icon name="angle-left" size="medium" class="home-commentary-angle-left"></mds-icon>
					</div>
			</slot> 
			</div>

			<div :class="{
				[$style['mdc-carousel__button-container--right']]: insetControls,
				[$style['mdc-carousel__button-container--on-gray']]: insetControls && onGray,
				[$style['mdc-carousel__button-container--on-dark']]: insetControls && onDark
			}">
				<slot name="right-button" :nextPage="nextPage">
					<!--<mdc-button :class="{
						[$style['mdc-carousel__button']]: true,
						[$style['mdc-carousel__button--small']]: small,
					}" flat icon-only icon="angle-right" :small="small" @click="nextPage">
						Next Pagen
					</mdc-button>-->
					<div v-if="btnNextcheck" @click="nextPage" class="angle-right-main-div">
					<mds-icon
                      name="angle-right"
                       size="medium" class="home-commentary-angle-right" 
                    ></mds-icon
                  >
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import anime from 'animejs';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import reduce from 'lodash/reduce';

import { mds_constants as mdsConstants } from '@mds/constants/dist/constants.json';

//import MdcButton from '../Common/MdcButton.vue';
import MdsIcon from "@mds/icon";

const ANIMATION_TIMING = parseInt(mdsConstants.animation.timing['2-x']);

export default {
	components: {
		//MdcButton
		MdsIcon
	},
	props: {
		small: {
			type: Boolean,
			description: 'Renders control elements in small size',
			default: false
		},
		gutter: {
			type: String,
			description: 'Gutter style between items',
			default: null,
			validator: v => ['narrow', 'wide'].includes(v)
		},
		snap: {
			type: Boolean,
			description: 'Force mandatory snapping to item',
			default: false
		},
		hideControls: {
			type: Boolean,
			description: 'Hide next/previous buttons in all contexts',
			default: false
		},
		insetControls: {
			type: Boolean,
			description: 'Push next/previous buttons into the scroll pane',
			default: false
		},
		onGray: {
			type: Boolean,
			description: 'Indicates carousel is placed against gray background',
			default: false
		},
		onDark: {
			type: Boolean,
			description: 'Indicates carousel is placed against dark background',
			default: false
		}
	},
	data() {
		return {
			touchSupport: false,
			resizeObserver: null,
			clientWidth: null,
			scrollWidth: null,
			viewportWidth: null,
			scrollLeft: 0,
			counters: 0,
			btnNextcheck:true,
			Counter_prev:0,
			btnPrevcheck:false,
		};
	},
	computed: {
		hasRightButton() {
			return Math.ceil(this.clientWidth) + Math.ceil(this.scrollLeft) < Math.ceil(this.scrollWidth);
		},
		hasLeftButton() {
			return this.scrollLeft > 0;
		},
		isNativeScroll() {
			return this.touchSupport && this.clientWidth < 800;
		}
	},
	mounted() {
		const { scroll } = this.$refs;
		this.touchSupport = 'ontouchstart' in window;

		this.snapToNearestDebounce = debounce(this.snapToNearest, 100);

		this.onResize();
		this.onResizeDebounce = debounce(this.onResize, 50);
		this.resizeObserver = new ResizeObserver(this.onResizeDebounce);
		this.resizeObserver.observe(scroll);

		this.onScrollDebounce = debounce(this.onScroll, 30);
		scroll.addEventListener('scroll', this.onScrollDebounce);

		scroll.addEventListener('wheel', this.onScrollEnd); // for mouse input
	},
	updated() {
		this.onResizeDebounce();
	},
	beforeDestroy() {
		const { scroll } = this.$refs;

		if (this.resizeObserver) {
			this.resizeObserver.unobserve(scroll);
			this.resizeObserver = null;
		}
		scroll.removeEventListener('scroll', this.onScrollDebounce);
		scroll.removeEventListener('wheel', this.onScrollEnd);
	},
	methods: {
		
		calculateSnapPoint(targetOffset, roundForward = true) {
			// at beginning
			if (targetOffset <= 0) return 0;

			// at end
			if (targetOffset >= this.scrollWidth - this.clientWidth) return this.scrollWidth - this.clientWidth;

			const { items } = this.$refs;
			const { left: containerLeft } = items.getBoundingClientRect();
			let snapTarget = reduce(items.children, (accum, child) => {
				const { left, width } = child.getBoundingClientRect();
				const containerAdjustedLeft = left - containerLeft;

				if (!roundForward && containerAdjustedLeft > targetOffset) return accum;

				// more than halfway past element and rounding enabled
				if (roundForward && containerAdjustedLeft - (width * 0.5) > targetOffset) return accum;

				return containerAdjustedLeft;
			}, 0);

			// offset for inset control button
			if (this.insetControls && snapTarget > 0) snapTarget -= 33;

			return clamp(snapTarget, 0, this.scrollWidth - this.clientWidth);
		},
		nextPage() {
			//this.counters = this.counters + 1;
			this.$store.state.article.ArticleCounter +=1;
			const { scroll } = this.$refs;
			const targetOffset = this.scrollLeft + this.clientWidth;
			const snappedOffset = this.calculateSnapPoint(targetOffset, false);
			//const percentChange = (snappedOffset - this.scrollLeft) / this.clientWidth;
			// if (this.counters === 5) {
			// 	this.previousPage();
			// }
			if(this.$store.state.article.ArticleCounter == 1){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: 350,
				easing: 'easeInOutQuad'
			});
				this.btnNextcheck = true;
				this.btnPrevcheck=true;
			}
			else if(this.$store.state.article.ArticleCounter == 2){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: 970,
				easing: 'easeInOutQuad'
			});
			this.btnNextcheck = true;
			this.btnPrevcheck=true;
			}
			else if(this.$store.state.article.ArticleCounter == 3){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: 1520,
				easing: 'easeInOutQuad'
			});
			this.btnNextcheck = true;
			this.btnPrevcheck=true;
			}
			else if(this.$store.state.article.ArticleCounter == 4){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: 2300,
				easing: 'easeInOutQuad'
			});
				this.btnNextcheck = false;
				this.btnPrevcheck=true;
				console.log("snappedOffset 4=" + snappedOffset);
			}
			// else{
			// anime({
			// 	targets: scroll,
			// 	duration: ANIMATION_TIMING * percentChange,
			// 	scrollLeft: snappedOffset,
			// 	easing: 'easeInOutQuad'
			// });
			// }
		},
		previousPage() {
			const { scroll } = this.$refs;
			//const targetOffset = 0;
			//const snappedOffset = 0;
			//const percentChange = (this.scrollLeft - snappedOffset) / this.clientWidth;

			if(this.$store.state.article.ArticleCounter == 4)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: 1520,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=true;
				this.btnNextcheck = true;
			}
			else if(this.$store.state.article.ArticleCounter == 3)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: 970,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=true;
				this.btnNextcheck = true;
			}
			else if(this.$store.state.article.ArticleCounter == 2)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: 350,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=true;
				this.btnNextcheck = true;
			}
			else if(this.$store.state.article.ArticleCounter  == 1)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: 0,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=false;
				this.btnNextcheck = true;
			}
			//this.counters = this.counters-1;
			this.$store.state.article.ArticleCounter -=1;

		},
		snapToNearest() {
			const { scroll } = this.$refs;
			const snappedOffset = this.calculateSnapPoint(scroll.scrollLeft, true);
			const percentChange = Math.abs(this.scrollLeft - snappedOffset) / this.clientWidth;

			anime({
				targets: scroll,
				duration: ANIMATION_TIMING * Math.max(percentChange, 0.33),
				scrollLeft: snappedOffset,
				easing: 'easeInOutQuad'
			});
		},
		onResize() {
			const { scroll } = this.$refs;
			this.clientWidth = scroll.clientWidth;
			this.scrollWidth = scroll.scrollWidth;
			this.innerWidth = window.innerWidth;
		},
		onScroll() {
			const { scroll } = this.$refs;
			this.scrollLeft = scroll.scrollLeft;
		},
		onScrollEnd() {
			if (!this.snap) return;

			this.snapToNearestDebounce.cancel();
			this.snapToNearestDebounce();
		}
	}
};
</script>

<style lang="scss" module>
@import "@mds/constants";

.mdc-carousel {
	position: relative;

	&__scroll {
		display: flex;
		min-width: 0;
		max-width: 100%;
		overflow: scroll visible;
		scrollbar-width: none;
		-ms-overflow-style: none;
		padding-top: $mds-space-half-x;
		padding-bottom: $mds-space-half-x;
		margin-top: -$mds-space-half-x;
		margin-bottom: -$mds-space-half-x;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			display: none;
			background: transparent;
		}
	}

	&__items {
		position: relative;
		flex-grow: 1;
		display: flex;
		flex-wrap: nowrap;
		margin: 0;
		padding: 0;
		width: 100px;
		min-width: 0;
		list-style: none;

		>* {
			flex-shrink: 0;
			flex-grow: 0;

			&:not(:last-child) {
				margin-right: $mds-space-2-x;
			}
		}

		&--narrow-gutter {
			>*:not(:last-child) {
				margin-right: $mds-space-1-x;
			}
		}

		&--wide-gutter {
			>*:not(:last-child) {
				margin-right: $mds-space-4-x;
			}
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		top: 0;
		left: -15px;
		right: -15px;
		bottom: 0;
		z-index: $mds-z-index-sticky;
		pointer-events: none;

		&--inset {
			left: 0;
			right: 0;
		}
	}

	&__button-container {
		&--left {
			display: flex;
			align-items: center;
			height: 100%;
			background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		}

		&--left.mdc-carousel__button-container--on-gray {
			background: linear-gradient(90deg, rgba($mds-background-color-light-gray, 1) 0%, rgba($mds-background-color-light-gray, 0) 100%);
		}

		&--left.mdc-carousel__button-container--on-dark {
			background: linear-gradient(90deg, rgba($mds-background-color-dark-gray, 1) 0%, rgba($mds-background-color-dark-gray, 0) 100%);
		}

		&--right {
			display: flex;
			align-items: center;
			height: 100%;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		}

		&--right.mdc-carousel__button-container--on-gray {
			background: linear-gradient(90deg, rgba($mds-background-color-light-gray, 0) 0%, rgba($mds-background-color-light-gray, 1) 100%);
		}

		&--right.mdc-carousel__button-container--on-dark {
			background: linear-gradient(90deg, rgba($mds-background-color-dark-gray, 0) 0%, rgba($mds-background-color-dark-gray, 1) 100%);
		}
	}

	&__button {
		background-color: $mds-background-color-white !important;
		box-shadow: $mds-box-shadow-drop;
		border-radius: 50% !important;
		border: $mds-border-container !important;
		height: 29px;
		padding: 3px;
		transition: opacity $mds-animation-timing-1-x;
		width: 29px;
		pointer-events: auto;

		&--small {
			height: 26px;
			width: 26px;
		}
	}
}

</style>
<style>
.home-commentary-angle-right{
	cursor: pointer;
	z-index:10;

	pointer-events: all;
}

@media only screen and (min-width: 0px) and (max-width: 330px) {
.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

.angle-right-main-div{
	margin-left: -60%;
	
}

}

@media only screen and (min-width: 330px) and (max-width: 338px) {
	.angle-right-main-div{
	margin-left: -20%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 338px) and (max-width: 346px) {
	.angle-right-main-div{
	margin-left: 10%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 346px) and (max-width: 354px) {
	.angle-right-main-div{
	padding-left:20%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 354px) and (max-width: 365px) {
	.angle-right-main-div{
	margin-left:45%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}



@media only screen and (min-width: 365px) and (max-width: 375px) {
	.angle-right-main-div{
	margin-left:-35%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 375px) and (max-width: 383px) {
	.angle-right-main-div{
	margin-left:-25%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 383px) and (max-width: 388px) {
	.angle-right-main-div{
	margin-left:-0%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}


@media only screen and (min-width: 388px) and (max-width: 392px) {
	.angle-right-main-div{
	margin-left:-55%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 392px) and (max-width: 400px) {
	.angle-right-main-div{
	margin-left:-30%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 400px) and (max-width: 408px) {
	.angle-right-main-div{
	margin-left:-30%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 408px) and (max-width: 416px) {
	.angle-right-main-div{
	margin-left:20%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 416px) and (max-width: 422px) {
	.angle-right-main-div{
	margin-left:0%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 422px) and (max-width: 430px) {
	.angle-right-main-div{
	margin-left:40%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 430px) and (max-width: 435px) {
	.angle-right-main-div{
	margin-left:50%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 435px) and (max-width: 440px) {
	.angle-right-main-div{
	margin-left:60%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 440px) and (max-width: 445px) {
	.angle-right-main-div{
	margin-left:80%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 445px) and (max-width: 450px) {
	.angle-right-main-div{
	margin-left:100%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 450px) and (max-width: 455px) {
	.angle-right-main-div{
	margin-left:120%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 455px) and (max-width: 460px) {
	.angle-right-main-div{
	margin-left:140%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 460px) and (max-width: 465px) {
	.angle-right-main-div{
	margin-left:160%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 465px) and (max-width: 470px) {
	.angle-right-main-div{
	margin-left:180%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 470px) and (max-width: 475px) {
	.angle-right-main-div{
	margin-left:200%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}

@media only screen and (min-width: 475px) and (max-width: 480px) {
	.angle-right-main-div{
	margin-left:220%;
	
}

.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
	
}

}






@media only screen and (min-width: 0px) and (max-width: 440px) {


.home-commentary-angle-left{
	margin-left: 5px;
	width: 30px;
}
}


@media only screen and (min-width: 440px) and (max-width: 480px) {
	.home-commentary-angle-left{
	margin-left: 5px;
	width: 30px;
}

	.home-commentary-angle-right{
		margin-right: 5%;
	width: 30px;
	
}
}

@media only screen and (min-width: 480px) and (max-width: 510px) {
	.home-commentary-angle-left{
	margin-left: 5px;
	width: 30px;
}

.home-commentary-angle-right{
	margin-right: 5%;
	width: 30px;
	
}
}


@media only screen and (min-width: 510px) and (max-width: 600px) {
	.angle-right-main-div{
	align-content: end;
	
}


.home-commentary-angle-left{
	margin-left: 5px;
	width: 30px;
}

.home-commentary-angle-right{
margin-right: 5%;
	width: 30px;
	
}
}

.home-commentary-angle-left{
	width: 30px;
}


.home-commentary-angle-left{
	cursor: pointer;
	z-index:10;
	pointer-events: all;
	
}
</style>
