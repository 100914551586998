<template>
  <div>
    <mds-page-shell
    :logo="getProductLogo()"
    :logo-responsive="getProductLogo()"
    logo-href="/"
    logo-alt="Back to home page"
   >
    <div class="subscriptionprice-main">
      <div class="subscriptionprice-registration-form-subscribe-selection">
      <div class="subscriptionprice-registration-form-subscribe-selection-container">
        <div class="subscriptionprice-title-for-registration-form">
          <h1 class="subscriptionprice-registration-page-title">
            Digital subscription selection
          </h1>
        </div>
        <div class="subscriptionprice-registration-top-part-text">
          <p>
            Select one or more subscriptions below. <br />
            Annual subscriptions are billed once per year. <br />
            Quarterly subscriptions are billed every three months.
          </p>
        </div>
        <div class="subscriptionprice-registration-top-part-text">
          <p>
            For print subscriptions, please call 1-866-608-9570<br />
            Monday–Friday 8AM–5PM CST.
          </p>
        </div>
        <div class="subscriptionprice-loader" v-if="isLoading">
          <mds-loader aria-label="Medium Loader" size="medium"></mds-loader>
        </div>
        <div v-if="!isLoading">
        <div class="subscriptionprice-registration-form-fields">
          <h1 class="Subscription-newsletter-title">FundInvestor</h1>
        </div>
        <div class="subscriptionprice-registration-border"></div>

        <div class="subscription-selection-type">
          <div class="annual-subscription">
            <h1 class="Annual-Title">ANNUAL</h1>
            <mds-checkbox :disabled="this.MFIAccess" value="accept"
              :microcopy=getAnnualMonthlyPrice(MFIAnnualSubscriptionPrice) @click.native.stop="CheckButtonmfiannual()"
              v-model="mfiannual">${{ MFIAnnualSubscriptionPrice }}/year</mds-checkbox>
          </div>
          <div class="quarterly-subscription">
            <h1 class="Quarterly-Title">QUARTERLY</h1>
            <mds-checkbox :disabled="this.MFIAccess" value="accept"
              :microcopy=getQuaterlyMonthlyPrice(MFIQuterlySubscriptionPrice) @click.native.stop="CheckButtonmfiquarter()"
              v-model="mfiquarter">${{ MFIQuterlySubscriptionPrice }}/quarter</mds-checkbox>
          </div>
        </div>
        <div>
          <h1 class="subscription-bottom-text">Save 30% over quarterly</h1>
        </div>
        <div class="subscriptionprice-registration-form-fields">
          <h1 class="Subscription-newsletter-title">StockInvestor</h1>
        </div>
        <div class="subscriptionprice-registration-border"></div>
        <div class="subscription-selection-type">
          <div class="annual-subscription">
            <h1 class="Annual-Title">ANNUAL</h1>
            <mds-checkbox :disabled="this.MSIAccess" value="accept"
              :microcopy=getAnnualMonthlyPrice(MSIAnnualSubscriptionPrice) @click.native.stop="CheckButtonmsiannual()"
              v-model="msiannual">${{ MSIAnnualSubscriptionPrice }}/year</mds-checkbox>
          </div>
          <div class="quarterly-subscription">
            <h1 class="Quarterly-Title">QUARTERLY</h1>
            <mds-checkbox :disabled="this.MSIAccess" value="accept"
              :microcopy=getQuaterlyMonthlyPrice(MSIQuterlySubscriptionPrice) @click.native.stop="CheckButtonmsiquarter()"
              v-model="msiquarter">${{ MSIQuterlySubscriptionPrice }}/quarter</mds-checkbox>
          </div>
        </div>
        <div>
          <h1 class="subscription-bottom-text">Save 30% over quarterly</h1>
        </div>
        <div class="subscriptionprice-registration-form-fields">
          <h1 class="Subscription-newsletter-title">DividendInvestor</h1>
        </div>
        <div class="subscriptionprice-registration-border"></div>

        <div class="subscription-selection-type">
          <div class="annual-subscription">
            <h1 class="Annual-Title">ANNUAL</h1>
            <mds-checkbox :disabled="this.MDIAccess" value="accept"
              :microcopy=getAnnualMonthlyPrice(MDIAnnualSubscriptionPrice) @click.native.stop="CheckButtonmdiannual()"
              v-model="mdiannual">${{ MDIAnnualSubscriptionPrice }}/year</mds-checkbox>
          </div>
          <div class="quarterly-subscription">
            <h1 class="Quarterly-Title">QUARTERLY</h1>
            <mds-checkbox :disabled="this.MDIAccess" value="accept"
              :microcopy=getQuaterlyMonthlyPrice(MDIQuterlySubscriptionPrice) @click.native.stop="CheckButtonmdiquarter()"
              v-model="mdiquarter">${{ MDIQuterlySubscriptionPrice }}/quarter</mds-checkbox>
          </div>
        </div>
        <div>
          <h1 class="subscription-bottom-text">Save 30% over quarterly</h1>
        </div>

        <div class="subscriptionprice-registration-form-fields">
          <h1 class="Subscription-newsletter-title">ETFInvestor</h1>
        </div>
        <div class="subscriptionprice-registration-border"></div>

        <div class="subscription-selection-type">
          <div class="annual-subscription">
            <h1 class="Annual-Title">ANNUAL</h1>
            <mds-checkbox :disabled="this.MEIAccess" value="accept"
              :microcopy=getAnnualMonthlyPrice(MEIAnnualSubscriptionPrice) @click.native.stop="CheckButtonetfannual()"
              v-model="etfannual">${{ MEIAnnualSubscriptionPrice }}/year</mds-checkbox>
          </div>
          <div class="quarterly-subscription">
            <h1 class="Quarterly-Title">QUARTERLY</h1>
            <mds-checkbox :disabled="this.MEIAccess" value="accept"
              :microcopy=getQuaterlyMonthlyPrice(MEIQuterlySubscriptionPrice) @click.native.stop="CheckButtonetfquarter()"
              v-model="etfquarter">${{ MEIQuterlySubscriptionPrice }}/quarter</mds-checkbox>
          </div>
        </div>
        <div>
          <h1 class="subscription-bottom-text">Save 30% over quarterly</h1>
        </div>
       </div>
        <div class="by-signing-div">
          <p class="by-signing-text">
            <a class="subscription-guarantee-title">The Morningstar Guarantee:</a>
            If you are unhappy with our newsletter(s) for any reason, cancel
            within 30 days of the start date by calling toll free,
            1-866-910-1145 for a full refund.
          </p>
          <br>
          <p class="by-signing-text">
            After that, you may cancel at any time, and we will refund the
            remaining months left on your subscription. Your satisfaction is our
            priority.
          </p>
        </div>

        <div class="subscriptionprice-registration-button-container">
            <mds-button-container>
              <!-- Icon On Left -->
              <mds-button variation="secondary" size="large" onclick="history.back()">
                Back
              </mds-button>
              <div class="subcription-cancel-button">
              <mds-button variation="secondary" size="large" type="button" @click="
              $router.push({
                name: 'combined-home',
                params: { currentproduct: 'Common' },
              })
              ">
              Cancel
            </mds-button>
          </div>
            <!-- Icon On Both Sides -->
            <div class="subcription-continue-button">
              <mds-button variation="primary" size="large" type="button" right-aligned :disabled="checked || isLoading"
                @click="ContinueForm">
                Continue</mds-button>
            </div>
            </mds-button-container>
          </div>
        <div class="subscriptionprice-register-page-bottom"></div>
    
      </div>
    </div>
  </div>
  </mds-page-shell>
  </div>
</template>

<script>
import MdsPageShell from '@mds/page-shell'
import MdsCheckbox from "@mds/checkbox";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MdsLoader from "@mds/loader";
import EncryptDecryptMixin  from "../../mixins/EncryptDecryptMixin";
Vue.use(VueAxios, axios);
export default {
  name: "subscription-selection",
  mixins:[EncryptDecryptMixin],
  components: {
    MdsPageShell,
    MdsCheckbox,
    MdsButton,
    MdsButtonContainer,
    MdsLoader,
  },
  data() {
    return {
      MFIQuterlySubscriptionPrice: null,
      MFIAnnualSubscriptionPrice: null,
      MSIQuterlySubscriptionPrice: null,
      MSIAnnualSubscriptionPrice: null,
      MDIQuterlySubscriptionPrice: null,
      MDIAnnualSubscriptionPrice: null,
      MEIQuterlySubscriptionPrice: null,
      MEIAnnualSubscriptionPrice: null,
      MFISubscribePrice: [],
      MSISubscribePrice: [],
      MDISubscribePrice: [],
      MEISubscribePrice: [],
      SelectedreferID: this.$route.params.referid,
      selectedProductCode: this.$route.params.productcode,
      checked: true,
      mfiannual: false,
      mfiquarter: false,
      msiannual: false,
      msiquarter: false,
      mdiannual: false,
      mdiquarter: false,
      etfannual: false,
      etfquarter: false,
      isLoading: true,
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      userDetails: [],
      OmahaCustID: "",
      MFIsubscrptionData:null,
      MSIsubscrptionData:null,
      MDIsubscrptionData:null,
      MEIsubscrptionData:null,
      userID : null,
    };
  },

  methods: {
    getProductLogo() {
      var images = require.context(
        "../../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    getAnnualMonthlyPrice(MFIAnnualSubscriptionPrice) {
      var monthlyrice = MFIAnnualSubscriptionPrice / 12;
      monthlyrice = (Math.round(monthlyrice * 100) / 100).toFixed(2);
      return "$" + monthlyrice + "/mo";
    },
    getQuaterlyMonthlyPrice(MFIQuaterlySubscriptionPrice) {
      var monthlyrice = MFIQuaterlySubscriptionPrice / 3;
      monthlyrice = (Math.round(monthlyrice * 100) / 100).toFixed(2);
      return "$" + monthlyrice + "/mo";
    },
    setDatatoSessionStorage(data, productSubscription,) {
      localStorage.setItem(productSubscription, JSON.stringify(data));
    },
    async getMFISubscriptionPrice(referID, productCode, OmahaCustID) {
      var getSubscriptionPriceAPIURL =
        process.env.VUE_APP_BackEndAPI +
        process.env.VUE_APP_GetSubscriptionPrice;
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{referID}",
        referID
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{productCode}",
        productCode
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{OmahaCustID}",
        OmahaCustID
      );
      try {
        return Vue.axios
          .get(getSubscriptionPriceAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.MFISubscribePrice = JSON.parse(response.data);
                  this.MFIQuterlySubscriptionPrice =
                    this.MFISubscribePrice[0]["Price"];
                  this.MFIAnnualSubscriptionPrice =
                    this.MFISubscribePrice[1]["Price"];
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
      } catch (ex) {
        console.log(ex.message);
      }
    },
    async getMSISubscriptionPrice(referID, productCode, OmahaCustID) {
      var getSubscriptionPriceAPIURL =
        process.env.VUE_APP_BackEndAPI +
        process.env.VUE_APP_GetSubscriptionPrice;
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{referID}",
        referID
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{productCode}",
        productCode,
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{OmahaCustID}",
        OmahaCustID
      );
      try {
        return Vue.axios
          .get(getSubscriptionPriceAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.MSISubscribePrice = JSON.parse(response.data);
                  this.MSIQuterlySubscriptionPrice =
                    this.MSISubscribePrice[0]["Price"];
                  this.MSIAnnualSubscriptionPrice =
                    this.MSISubscribePrice[1]["Price"];
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
      } catch (ex) {
        console.log(ex.message);
      }
    },
    async getMDISubscriptionPrice(referID, productCode, OmahaCustID) {
      var getSubscriptionPriceAPIURL =
        process.env.VUE_APP_BackEndAPI +
        process.env.VUE_APP_GetSubscriptionPrice;
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{referID}",
        referID
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{productCode}",
        productCode
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{OmahaCustID}",
        OmahaCustID
      );
      try {
        return Vue.axios
          .get(getSubscriptionPriceAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.MDISubscribePrice = JSON.parse(response.data);
                  this.MDIQuterlySubscriptionPrice =
                    this.MDISubscribePrice[0]["Price"];
                  this.MDIAnnualSubscriptionPrice =
                    this.MDISubscribePrice[1]["Price"];
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
      } catch (ex) {
        console.log(ex.message);
      }
    },
    async getMEISubscriptionPrice(referID, productCode, OmahaCustID) {
      var getSubscriptionPriceAPIURL =
        process.env.VUE_APP_BackEndAPI +
        process.env.VUE_APP_GetSubscriptionPrice;
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{referID}",
        referID
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{productCode}",
        productCode
      );
      getSubscriptionPriceAPIURL = getSubscriptionPriceAPIURL.replace(
        "{OmahaCustID}",
        OmahaCustID
      );
      try {
        return Vue.axios
          .get(getSubscriptionPriceAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.MEISubscribePrice = JSON.parse(response.data);
                  this.MEIQuterlySubscriptionPrice =
                    this.MEISubscribePrice[0]["Price"];
                  this.MEIAnnualSubscriptionPrice =
                    this.MEISubscribePrice[1]["Price"];
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
      } catch (ex) {
        console.log(ex.message);
      }
    },
    CheckButtonmfiannual() {
      if (this.mfiannual == true) {
        this.mfiquarter = false;
      } else if (this.mfiannual == false) {
        this.mfiquarter = false;
      }
    },
    CheckButtonmfiquarter() {
      if (this.mfiquarter == true) {
        this.mfiannual = false;
      } else if (this.mfiquarter == false) {
        this.mfiannual = false;
      }
    },
    CheckButtonmdiannual() {
      if (this.mdiannual == true) {
        this.mdiquarter = false;
      } else if (this.mdiannual == false) {
        this.mdiquarter = false;
      }
    },
    CheckButtonmdiquarter() {
      if (this.mdiquarter == true) {
        this.mdiannual = false;
      } else if (this.mdiquarter == false) {
        this.mdiannual = false;
      }
    },
    CheckButtonmsiannual() {
      if (this.msiannual == true) {
        this.msiquarter = false;
      } else if (this.msiannual == false) {
        this.msiquarter = false;
      }
    },
    CheckButtonmsiquarter() {
      if (this.msiquarter == true) {
        this.msiannual = false;
      } else if (this.msiquarter == false) {
        this.msiannual = false;
      }
    },
    CheckButtonetfannual() {
      if (this.etfannual == true) {
        this.etfquarter = false;
      } else if (this.etfannual == false) {
        this.etfquarter = false;
      }
    },
    CheckButtonetfquarter() {
      if (this.etfquarter == true) {
        this.etfannual = false;
      } else if (this.etfquarter == false) {
        this.etfannual = false;
      }
    },
    ContinueForm() {
     
    localStorage.removeItem("MFIProductSubscription");
    localStorage.removeItem("MSIProductSubscription");
    localStorage.removeItem("MDIProductSubscription");
    localStorage.removeItem("MEIProductSubscription");
      if (this.mfiannual) {
        const mfiannualsubscriptiondata = {
          Term: this.MFISubscribePrice[1]["Term"],
          Price: this.MFISubscribePrice[1]["Price"],
          ReferID: this.MFISubscribePrice[1]["ReferID"],
          AutoRenew: this.MFISubscribePrice[1]["AutoRenew"],
          PromotionCode: this.MFISubscribePrice[1]["PromotionCode"],
          SourceCode: this.MFISubscribePrice[1]["SourceCode"],
          PromoCodeType: this.MFISubscribePrice[1]["PromoCodeType"],
          DivisionID: this.MFISubscribePrice[1]["DivisionID"],
          NextPromotionCode: this.MFISubscribePrice[1]["NextPromotionCode"],
          UsedTrial: this.MFISubscribePrice[1]["UsedTrial"],
          IsQ: this.MFISubscribePrice[1]["IsQ"],
          productname: "FundInvestor",
          subscriptionterm: "Annual Digital",
        }
        this.setDatatoSessionStorage(
          mfiannualsubscriptiondata,
          "MFIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'MFI',
        'term': 'Annual Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termMFIAnnual: 'Annual Digital', productNameETFAnnual: 'FundInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      if (this.msiannual) {
        const msiannualsubscriptiondata = {
          Term: this.MSISubscribePrice[1]["Term"],
          Price: this.MSISubscribePrice[1]["Price"],
          ReferID: this.MSISubscribePrice[1]["ReferID"],
          AutoRenew: this.MSISubscribePrice[1]["AutoRenew"],
          PromotionCode: this.MSISubscribePrice[1]["PromotionCode"],
          SourceCode: this.MSISubscribePrice[1]["SourceCode"],
          PromoCodeType: this.MSISubscribePrice[1]["PromoCodeType"],
          DivisionID: this.MSISubscribePrice[1]["DivisionID"],
          NextPromotionCode: this.MSISubscribePrice[1]["NextPromotionCode"],
          UsedTrial: this.MSISubscribePrice[1]["UsedTrial"],
          IsQ: this.MSISubscribePrice[1]["IsQ"],
          productname: "StockInvestor",
          subscriptionterm: "Annual Digital",
        }
        this.setDatatoSessionStorage(
          msiannualsubscriptiondata,
          "MSIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'MSI',
        'term': 'Annual Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termMSIAnnual: 'Annual Digital', productNameMSIAnnual: 'StockInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      if (this.mdiannual) {
        const mdiannualsubscriptiondata = {
          Term: this.MDISubscribePrice[1]["Term"],
          Price: this.MDISubscribePrice[1]["Price"],
          ReferID: this.MDISubscribePrice[1]["ReferID"],
          AutoRenew: this.MDISubscribePrice[1]["AutoRenew"],
          PromotionCode: this.MDISubscribePrice[1]["PromotionCode"],
          SourceCode: this.MDISubscribePrice[1]["SourceCode"],
          PromoCodeType: this.MDISubscribePrice[1]["PromoCodeType"],
          DivisionID: this.MDISubscribePrice[1]["DivisionID"],
          NextPromotionCode: this.MDISubscribePrice[1]["NextPromotionCode"],
          UsedTrial: this.MDISubscribePrice[1]["UsedTrial"],
          IsQ: this.MDISubscribePrice[1]["IsQ"],
          productname: "DividendInvestor",
          subscriptionterm: "Annual Digital",
        }
        this.setDatatoSessionStorage(
          mdiannualsubscriptiondata,
          "MDIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'MDI',
        'term': 'Annual Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termMDIAnnual: 'Annual Digital', productNameMDIAnnual: 'DividendInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      if (this.etfannual) {
        const etfannualsubscriptiondata = {
          Term: this.MEISubscribePrice[1]["Term"],
          Price: this.MEISubscribePrice[1]["Price"],
          ReferID: this.MEISubscribePrice[1]["ReferID"],
          AutoRenew: this.MEISubscribePrice[1]["AutoRenew"],
          PromotionCode: this.MEISubscribePrice[1]["PromotionCode"],
          SourceCode: this.MEISubscribePrice[1]["SourceCode"],
          PromoCodeType: this.MEISubscribePrice[1]["PromoCodeType"],
          DivisionID: this.MEISubscribePrice[1]["DivisionID"],
          NextPromotionCode: this.MEISubscribePrice[1]["NextPromotionCode"],
          UsedTrial: this.MEISubscribePrice[1]["UsedTrial"],
          IsQ: this.MEISubscribePrice[1]["IsQ"],
          productname: "ETFInvestor",
          subscriptionterm: "Annual Digital",
        }
        this.setDatatoSessionStorage(
          etfannualsubscriptiondata,
          "MEIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'MEI',
        'term': 'Annual Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termETFAnnual: 'Annual Digital', productNameETFAnnual: 'ETFInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      if (this.mfiquarter) {
        const mfiquartersubscriptiondata = {
          Term: this.MFISubscribePrice[0]["Term"],
          Price: this.MFISubscribePrice[0]["Price"],
          ReferID: this.MFISubscribePrice[0]["referID"],
          AutoRenew: this.MFISubscribePrice[0]["AutoRenew"],
          PromotionCode: this.MFISubscribePrice[0]["PromotionCode"],
          SourceCode: this.MFISubscribePrice[0]["SourceCode"],
          PromoCodeType: this.MFISubscribePrice[0]["PromoCodeType"],
          DivisionID: this.MFISubscribePrice[0]["DivisionID"],
          NextPromotionCode: this.MFISubscribePrice[0]["NextPromotionCode"],
          UsedTrial: this.MFISubscribePrice[0]["UsedTrial"],
          IsQ: this.MFISubscribePrice[0]["IsQ"],
          productname: "FundInvestor",
          subscriptionterm: "Quarterly Digital",
        }
        this.setDatatoSessionStorage(
          mfiquartersubscriptiondata,
          "MFIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'FundInvestor',
        'term': 'Quarterly Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termMFIQuarterly: 'Quarterly Digital', productNameMFIQuarterly: 'FundInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      if (this.msiquarter) {
        const msiquartersubscriptiondata = {
          Term: this.MSISubscribePrice[0]["Term"],
          Price: this.MSISubscribePrice[0]["Price"],
          ReferID: this.MSISubscribePrice[0]["referID"],
          AutoRenew: this.MSISubscribePrice[0]["AutoRenew"],
          PromotionCode: this.MSISubscribePrice[0]["PromotionCode"],
          SourceCode: this.MSISubscribePrice[0]["SourceCode"],
          PromoCodeType: this.MSISubscribePrice[0]["PromoCodeType"],
          DivisionID: this.MSISubscribePrice[0]["DivisionID"],
          NextPromotionCode: this.MSISubscribePrice[0]["NextPromotionCode"],
          UsedTrial: this.MSISubscribePrice[0]["UsedTrial"],
          IsQ: this.MSISubscribePrice[0]["IsQ"],
          productname: "StockInvestor",
          subscriptionterm: "Quarterly Digital",
        }
        this.setDatatoSessionStorage(
          msiquartersubscriptiondata,
          "MSIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'StockInvestor',
        'term': 'Quarterly Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termMSIQuarterly: 'Quarterly Digital', productNameMSIQuarterly: 'StockInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      if (this.mdiquarter) {
        const mdiquartersubscriptiondata = {
          Term: this.MDISubscribePrice[0]["Term"],
          Price: this.MDISubscribePrice[0]["Price"],
          ReferID: this.MDISubscribePrice[0]["referID"],
          AutoRenew: this.MDISubscribePrice[0]["AutoRenew"],
          PromotionCode: this.MDISubscribePrice[0]["PromotionCode"],
          SourceCode: this.MDISubscribePrice[0]["SourceCode"],
          PromoCodeType: this.MDISubscribePrice[0]["PromoCodeType"],
          DivisionID: this.MDISubscribePrice[0]["DivisionID"],
          NextPromotionCode: this.MDISubscribePrice[0]["NextPromotionCode"],
          UsedTrial: this.MDISubscribePrice[0]["UsedTrial"],
          IsQ: this.MDISubscribePrice[0]["IsQ"],
          productname: "DividendInvestor",
          subscriptionterm: "Quarterly Digital",
        }
        this.setDatatoSessionStorage(
          mdiquartersubscriptiondata,
          "MDIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'DividendInvestor',
        'term': 'Quarterly Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termMDIQuarterly: 'Quarterly Digital', productNameMDIQuarterly: 'DividendInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      if (this.etfquarter) {
        const etfquartersubscriptiondata = {
          Term: this.MEISubscribePrice[0]["Term"],
          Price: this.MEISubscribePrice[0]["Price"],
          ReferID: this.MEISubscribePrice[0]["referID"],
          AutoRenew: this.MEISubscribePrice[0]["AutoRenew"],
          PromotionCode: this.MEISubscribePrice[0]["PromotionCode"],
          SourceCode: this.MEISubscribePrice[0]["SourceCode"],
          PromoCodeType: this.MEISubscribePrice[0]["PromoCodeType"],
          DivisionID: this.MEISubscribePrice[0]["DivisionID"],
          NextPromotionCode: this.MEISubscribePrice[0]["NextPromotionCode"],
          UsedTrial: this.MEISubscribePrice[0]["UsedTrial"],
          IsQ: this.MEISubscribePrice[0]["IsQ"],
          productname: "ETFInvestor",
          subscriptionterm: "Quarterly Digital",
        }
        this.setDatatoSessionStorage(
          etfquartersubscriptiondata,
          "MEIProductSubscription"
        );
        this.$gtag.event('subscription_selection', {
        'product': 'ETFInvestor',
        'term': 'Quarterly Digital',
        'userid': this.userID,
        
                    })
                    this.$router.push({
          name: "billingaddress",
          params: { termETFQuarterly: 'Quarterly Digital', productNameETFQuarterly: 'ETFInvestor' },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      this.$router.push({
        name: "billingaddress",
        params: {
          referid: this.SelectedreferID,
          productcode: this.selectedProductCode,
        },
      }).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      });
     
    },
    async getDatafromLocalStorage(){
      this.MEIsubscrptionData = localStorage.getItem("MEIProductSubscription");
        if ( this.MEIsubscrptionData != "" &&  this.MEIsubscrptionData != null) {
          this.MEIsubscrptionData = JSON.parse( this.MEIsubscrptionData);
          if ( this.MEIsubscrptionData.Price != null) {
            if(this.MEIsubscrptionData.subscriptionterm =="Annual Digital")
            {
                this.etfannual= true;
            }
            else{
              this.etfquarter= true;
            }
           }
          }
        this.MDIsubscrptionData = localStorage.getItem("MDIProductSubscription");
        if (this.MDIsubscrptionData != "" &&  this.MDIsubscrptionData != null) {
          this.MDIsubscrptionData = JSON.parse(this.MDIsubscrptionData);
          if (this.MDIsubscrptionData != null) {
            if(this.MDIsubscrptionData.subscriptionterm =="Annual Digital")
            {
                this.mdiannual= true;
            }
            else{
              this.mdiquarter= true;
            }
           }
        }
        this.MSIsubscrptionData = localStorage.getItem("MSIProductSubscription");
        if (this.MSIsubscrptionData != "" &&  this.MSIsubscrptionData != null) {
          this.MSIsubscrptionData = JSON.parse(this.MSIsubscrptionData);
          if (this.MSIsubscrptionData != null) {
            if(this.MSIsubscrptionData.subscriptionterm =="Annual Digital")
            {
                this.msiannual= true;
            }
            else{
              this.msiquarter= true;
            }
           }
          }
        this.MFIsubscrptionData = localStorage.getItem("MFIProductSubscription");
        if ( this.MFIsubscrptionData != "" &&  this.MFIsubscrptionData != null) {
          this.MFIsubscrptionData = JSON.parse(this.MFIsubscrptionData);
          if (this.MFIsubscrptionData != null) {
            if(this.MFIsubscrptionData.subscriptionterm =="Annual Digital")
            {
                this.mfiannual= true;
            }
            else{
              this.mfiquarter= true;
            }
           }
        }

        if((this.MFIsubscrptionData == "" ||  this.MFIsubscrptionData == null)
           && (this.MSIsubscrptionData == "" ||  this.MSIsubscrptionData == null)
           && (this.MDIsubscrptionData == "" ||  this.MDIsubscrptionData == null)
           && (this.MEIsubscrptionData == "" ||  this.MEIsubscrptionData == null)
        )
        {
          if (this.selectedProductCode == "NF00000001") {
          this.mfiannual = true;
          }
          if (this.selectedProductCode == "NM00000001") {
          this.msiannual = true;
          }
          if (this.selectedProductCode == "NDI0000001") {
          this.mdiannual = true;
          }
          if (this.selectedProductCode == "NET0000001")
          {
          this.etfannual = true;
          }
        
        }
    }
  },
  async mounted() {
    await this.getDatafromLocalStorage();
    this.userDetails = this.Decrypt(localStorage.getItem("userDetails"));
    if (this.userDetails != "" && this.userDetails != null) {
      this.userDetails = JSON.parse(this.userDetails);
      if (this.userDetails.OmahaCustID != null && this.userDetails.OmahaCustID != "") {
        this.OmahaCustID = this.userDetails.OmahaCustID;
      }
      else
        this.OmahaCustID = "";
    }
    window.scrollTo(0, 0);
    if (this.$store.state.user.LoggedIn) {
      this.productDataRoles =
        this.$auth0.user["https://morningstar.com/uim_roles"];
      if(this.productDataRoles != undefined && this.productDataRoles !=null) {
      this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
      this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
      this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
      this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
      }
      this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
    }
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
    if (this.selectedProductCode == "NM00000001") {
      //MSI
      await this.getMFISubscriptionPrice("AFNGRDG7", "NF00000001", this.OmahaCustID);
      await this.getMSISubscriptionPrice(
        this.SelectedreferID,
        this.selectedProductCode,
        this.OmahaCustID
      );
      await this.getMDISubscriptionPrice("ADIGRDG7", "NDI0000001", this.OmahaCustID);
      await this.getMEISubscriptionPrice("ETFGEN1", "NET0000001", this.OmahaCustID);
    }
    if (this.selectedProductCode == "NDI0000001") {
      //MDI
      await this.getMFISubscriptionPrice("AFNGRDG7", "NF00000001", this.OmahaCustID);
      await this.getMSISubscriptionPrice("AMSGRDG7", "NM00000001", this.OmahaCustID);
      await this.getMDISubscriptionPrice(
        this.SelectedreferID,
        this.selectedProductCode,
        this.OmahaCustID
      );
      await this.getMEISubscriptionPrice("ETFGEN1", "NET0000001", this.OmahaCustID);
    }
    if (this.selectedProductCode == "NF00000001") {
      //MFI
      await this.getMFISubscriptionPrice(
        this.SelectedreferID,
        this.selectedProductCode,
        this.OmahaCustID
      );
      await this.getMSISubscriptionPrice("AMSGRDG7", "NM00000001", this.OmahaCustID);
      await this.getMDISubscriptionPrice("ADIGRDG7", "NDI0000001", this.OmahaCustID);
      await this.getMEISubscriptionPrice("ETFGEN1", "NET0000001", this.OmahaCustID);
    }
    if (this.selectedProductCode == "NET0000001") {
      //ETF
      await this.getMFISubscriptionPrice("AFNGRDG7", "NF00000001", this.OmahaCustID);
      await this.getMSISubscriptionPrice("AMSGRDG7", "NM00000001", this.OmahaCustID);
      await this.getMDISubscriptionPrice("ADIGRDG7", "NDI0000001", this.OmahaCustID);
      await this.getMEISubscriptionPrice(
        this.SelectedreferID,
        this.selectedProductCode,
        this.OmahaCustID
      );
    }
    this.isLoading = false;
  },
  watch: {
    mfiannual(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
    mfiquarter(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
    msiannual(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
    msiquarter(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
    mdiannual(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
    mdiquarter(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
    etfannual(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
    etfquarter(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == false) {
          if (
            this.mfiannual ||
            this.mfiquarter ||
            this.msiannual ||
            this.msiquarter ||
            this.mdiannual ||
            this.mdiquarter ||
            this.etfannual ||
            this.etfquarter
          )
            this.checked = false;
          else this.checked = true;
        } else {
          this.checked = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.subcription-continue-button{
  margin-left:auto;
  order:2;
}
.subcription-cancel-button{
  margin-left:auto;
}
.subscriptionprice-registration-form-subscribe-selection-container{
  width:380px;
  margin:0 auto;
}
.subscriptionprice-main{
  height: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}
.subscriptionprice-loader{
  width: "276px";
  height: "651px";
  left: 38.92%;
  right: 37.92%;
}
.subscriptionprice-register-page-bottom {
  background: linear-gradient(360deg,
      #ffffff 12.47%,
      rgba(255, 255, 255, 0) 112.75%);
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
  height: 50px;
}

.subscriptionprice-registration-form-subscribe-selection {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
  min-height: 100vh;
}

.subscriptionprice-registration-page-title {
  margin-top: 0px;
  margin-bottom: 15%;
  height: 40px;
  padding-top: 57px;
  font-weight: 250;
  font-size: 28px;
  line-height: 34px;
}

.subscriptionprice-title-for-registration-form {
  width: 100%;
}

.subscriptionprice-registration-top-part-text {
  margin-top: 5%;
  width: 100%;
  height: 66px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.subscriptionprice-registration-form-fields {
  margin-top: 23px;
  font-size: 10px;
}

.subscriptionprice-registration-border {
  margin-top: 0px;
  width: 100%;
  border-bottom: 1px solid #808080;
}

.subscriptionprice-registration-button-container {
  margin-top: 10%;
}

.by-signing-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 0px;
}

.by-signing-div {
  margin-top: 44px;
  width: 100%;
  height: 159px;
}

.Subscription-newsletter-title {
  margin-top: 0px;
  margin-bottom: 6px;
  width: 274.24px;
  height: 21px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.subscription-selection-type {
  display: flex;
  height: 74px;
}

.annual-subscription {
  width: 190px;
}

.quarterly-subscription {
  width: 190px;
  margin-left: 60px;
}

.Annual-Title {
  margin-top: 8px;
  margin-bottom: 10px;
  margin-left: 25px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
}

.Quarterly-Title {
  margin-top: 8px;
  margin-bottom: 10px;
  margin-left: 25px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
}

.subscription-bottom-text {
  margin-top: 17px;
  margin-bottom: 27px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0077cf;
  height: 16px;
  margin-left: 5.7%;
}

.subscription-guarantee-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
@media only screen and (max-width: 480px){
  .subscriptionprice-registration-form-subscribe-selection-container{
    width:90%;
    margin:0 auto;
  }
  .subscriptionprice-registration-page-title {
    padding-top: 28px;
    font-weight: 250;
    font-size: 28px;
    line-height: 34px;
}
.subscriptionprice-registration-top-part-text {
  margin-top: 10%;
}
  }
</style>

<style>
.annual-subscription .mds-checkbox__text {
    font-size: 18px;
}
.annual-subscription .mds-checkbox__microcopy {
    font-size: 16px;
}
.quarterly-subscription .mds-checkbox__text {
    font-size: 18px;
}
.quarterly-subscription .mds-checkbox__microcopy {
    font-size: 16px;
}
.annual-subscription .mds-checkbox__visible-wrap{
  box-shadow: none !important;
}
.annual-subscription .mds-checkbox__microcopy{
  padding-top:6px;
}
.quarterly-subscription .mds-checkbox__microcopy{
  padding-top:6px;
}
.quarterly-subscription .mds-checkbox__visible-wrap{
  box-shadow: none !important;
}

</style>