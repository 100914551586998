<template>
    <div>
    <div v-if="isMobileContentToggle" class="main-mei-performance-section-responsive">
        <div class="etf-performance-section-border-seperator-responsive"></div>
        <span class="etf-performance-section-headings">Performance</span>
                            <br />
                            <div class="etf-performance-image-section-responsive">
                                <!-- <img :src="etfPerformanceImage" alt=" ETF Performance Graph" border="0" /> -->
                                <img src="https://im.morningstar.com/im/mei_performance.svg" alt=" ETF Performance Graph" border="0" />
                            </div>
    
                            <div class="etf-performance-graph-responsive">
                                <!-- <img :src="etfPerformanceGraph" alt=" ETF Performance Graph" border="0" /> -->
                                <p><strong>Model Portfolios’ Hypothetical Performance</strong>
                                    Hypothetical performance is investment performance returns not actually achieved by any portfolio of a financial institution or professional. Hypothetical performance may include, but is not limited to, model performance returns, backtested performance returns, targeted or projected performance returns, and/or pre-inception returns. Hypothetical performance returns are theoretical, for illustrative purposes only, and are not reflective of an investor’s actual experience. Hypothetical performance returns are based on historic economic and market assumptions. Actual performance returns will vary. Hypothetical performance returns do not reflect actual trading and may not reflect the impact that material economic and market factors had on the decision-making process for this portfolio.
                                    <!--<br /><br />
                      Each of the model portfolios (each, a "Model Portfolio") is designed to showcase a different investment strategy and how using different types of fund vehicles (each, a “Fund Vehicle”) can affect the performance of that strategy. These Model Portfolios are not designed to provide individualized recommendations/advice but instead are meant solely to be used for general, educational purposes. The actual inception date for each Model Portfolio is November 1, 2018. However, for purposes of this modeling exercise, Morningstar has derived hypothetical performance numbers for each Model Portfolio using the percentage-weighted performance numbers (back through December 2007) for each of the Fund Vehicles comprising that Model Portfolio. If, in any particular instance, a Fund Vehicle has an inception date after December 2007, Morningstar uses a proxy performance number for any "gap period" (i.e., December 2007 to the Fund Vehicle’s actual inception date). This proxy performance number is calculated by taking the return performance numbers generated during the relevant gap period by the index that the Fund Vehicle initially used as its primary prospectus benchmark and reducing those numbers by the Fund Vehicle fees (measured in percentages) charged as of its inception date. Model Portfolios rebalance to their target weight for each Fund Vehicle at the end of each year, unless otherwise stated. Dividends are automatically reinvested in Fund Vehicles. Morningstar applies fund expense ratios. No additional management fees are included.
                      -->
                                </p>
                            </div>
    
                            <div class="etf-performance-section-border-seperator-responsive"></div>
                    <span class="etf-performance-section-headings">Resources</span> <br /><br />
                    <div>
                                <span class="etf-performance-resources-sub-headings">Resources</span> <br />
                                <div style="margin-top: 15px">
                                    <mds-table style="width: 90%;" class="etf-tbl-font">
                                        <mds-tbody>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links"  :to="{ name: 'archive', params: { currentproduct: this.CurrentProduct }}">
                                                        Past Issues
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="toggle = !toggle" @click="toggle = !toggle">Investment Strategy</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0"  @keydown.enter="downloadEtfResources('MEI_Handbook_1123_FINAL.pdf','Resources')" v-on:click="downloadEtfResources('MEI_Handbook_1123_FINAL.pdf','Resources')">Morningstar ETFInvestor Subscriber's Handbook </a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="downloadEtfResources('ETF_ModelPort_Introduction_1022.pdf','Resources')" v-on:click="downloadEtfResources('ETF_ModelPort_Introduction_1022.pdf','Resources')">Introduction to ETFInvestor Model Portfolios</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" href="https://community.morningstar.com/s/topic/0TO3o000001yV0WGAU/exchangetraded-funds" target="_blank">Morningstar.com ETF Forum</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" href="mailto:bryan.armour@morningstar.com">Ask Bryan</a>
                                                </mds-td>
                                            </mds-tr>
                                        </mds-tbody>
                                    </mds-table>
                                </div>
                            </div>
                            <br />
                            <div>
                                <span class="etf-performance-resources-sub-headings">Portfolios and Watchlists</span><br />
                                <div style="margin-top: 15px">
                                    <mds-table style="width: 90%;" class="etf-tbl-font">
                                        <mds-tbody>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links" :to="{ name: 'modelPort', params: { currentproduct: this.CurrentProduct }}">
                                                        Model Portfolios
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links" :to="{ name: 'equityWatchlist', params: { currentproduct: this.CurrentProduct }}">
                                                        Equity Watchlist
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links" :to="{ name: 'bondWatchlist', params: { currentproduct: this.CurrentProduct }}">
                                                        Bond Watchlist
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
    
                                        </mds-tbody>
                                    </mds-table>
                                </div>
                            </div>

                            
                            <div class="etf-performance-resources-sub-headings-responsive ">
                                <span class="etf-performance-resources-sub-headings">Bonus Reports</span><br />
                                <div style="margin-top: 15px">
                                    <mds-table style="width: 90%;" class="etf-tbl-font">
                                        <mds-tbody>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="downloadEtfResources('Mkt_Outlook_Q1_2024.pdf','BonusReports')" @click="downloadEtfResources('Mkt_Outlook_Q1_2024.pdf','BonusReports')">Morningstar North America Equity Market Outlook Q1 2024</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td> 
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="downloadEtfResources('Guide_to_CEFs.pdf','BonusReports')" @click="downloadEtfResources('Guide_to_CEFs.pdf','BonusReports')">Morningstar's Guide to Closed-End Funds</a>
                                                </mds-td>
                                            </mds-tr>
    
                                        </mds-tbody>
                                    </mds-table>
                                </div>
                            </div>
                            <!--<mds-dialog class="investment-srategy-modal" v-model="toggle" title="Investment Strategy">
                                <p style="text-align: justify">
                                    <i>Morningstar ETFInvestor</i> scans the globe for value and improving fundamentals
                                    across virtually all asset classes. Editor Bryan Armour draws upon academic and
                                    practitioner research — including Morningstar's sizeable bench of stock, bond and
                                    fund analysts — to find reliable drivers of outperformance.
                                </p>
                                <br/>
                                <p style="text-align: justify">
                                    <i>Morningstar ETFInvestor</i> features five model portfolios.
                                </p>
                                <br/>
                                <p style="text-align: justify">
                                    The Basic Portfolio harnesses the market's collective wisdom with ultra-low-cost
                                    funds and is the baseline portfolio against which the three other portfolios will be compared.
                                </p>
                                <br/>
                                <p style="text-align: justify">
                                    The Defensive Portfolio aims to provide lower volatility, better downside protection,
                                    and better risk-adjusted performance than the basic portfolio over the long-term.
                                </p>
                                <br/>
                                <p style="text-align: justify">
                                    The Factor Portfolio is designed to earn higher returns than the basic portfolio over the long-term.
                                </p>
                                <br/>
                                <p style="text-align: justify">
                                    The Income Portfolio attempts to earn a higher distribution yield than the basic portfolio,
                                    without taking a lot more risk.
                                </p>
                                <br/>
                                <p style="text-align: justify">
                                    The ESG Portfolio targets firms with strong corporate governance and sustainable environmental
                                    and social practices, while offering competitive returns and similar risk to the basic portfolio.
                                </p>
                            </mds-dialog>-->
                            <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired class="investment-strategy-modal">
                                <mds-section class="with-nested-sections" border-position="bottom" bold>
                                    <template #mds-section-title>
                                        <span id="title-id">Investment Strategy</span>
                                    </template>
                                    <template #mds-section-actions>
                                        <mds-button-container right-aligned>
                                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                                        </mds-button-container>
                                    </template>
                                    <mds-section border="none" :size="6">
                                        <p>
                                            <i>Morningstar ETFInvestor</i> scans the globe for value and improving fundamentals
                                            across virtually all asset classes. Editor Bryan Armour draws upon academic and
                                            practitioner research — including Morningstar's sizeable bench of stock, bond and
                                            fund analysts — to find reliable drivers of outperformance.
                                        </p>
                                        <br>
                                        <p>
                                            <i>Morningstar ETFInvestor</i> features five model portfolios.
                                        </p>
                                        <br>
                                        <p>
                                            The Basic Portfolio harnesses the market's collective wisdom with ultra-low-cost
                                            funds and is the baseline portfolio against which the three other portfolios will be compared.
                                        </p>
                                        <br>
                                        <p>
                                            The Defensive Portfolio aims to provide lower volatility, better downside protection,
                                            and better risk-adjusted performance than the basic portfolio over the long-term.
                                        </p>
                                        <br>
                                        <p>
                                            The Factor Portfolio is designed to earn higher returns than the basic portfolio over the long-term.
                                        </p>
                                        <br>
                                        <p>
                                            The Income Portfolio attempts to earn a higher distribution yield than the basic portfolio,
                                            without taking a lot more risk.
                                        </p>
                                        <br>
                                        <p>
                                            The ESG Portfolio targets firms with strong corporate governance and sustainable environmental
                                            and social practices, while offering competitive returns and similar risk to the basic portfolio.
                                        </p>
                                    </mds-section>
                                </mds-section>
                            </mds-modal>
        </div>
    
    
    
    <div class="etf-performance-section" v-else>
        <mds-layout-grid>
            <mds-row>
                <mds-col  :cols="6" :cols-at-s="12" :cols-at-m="12" :cols-at-l="6" :cols-at-xl="6">
                    <div class="etf-performance-section-border-seperator"></div>
                    <mds-row>
                        <mds-col>
                            <span class="etf-performance-section-headings">Performance</span>
                            <br />
                            <div class="etf-performance-image-section">
                                <!-- <img :src="etfPerformanceImage" alt=" ETF Performance Graph" border="0" /> -->
                                <img src="https://im.morningstar.com/im/mei_performance.svg" alt=" ETF Performance Graph" border="0" />
                            </div>
                        </mds-col>
                        <mds-col>
                            <div class="etf-performance-graph">
                                <!-- <img :src="etfPerformanceGraph" alt=" ETF Performance Graph" border="0" /> -->
                                <p><strong>Model Portfolios’ Hypothetical Performance</strong>
                                    Hypothetical performance is investment performance returns not actually achieved by any portfolio of a financial institution or professional. Hypothetical performance may include, but is not limited to, model performance returns, backtested performance returns, targeted or projected performance returns, and/or pre-inception returns. Hypothetical performance returns are theoretical, for illustrative purposes only, and are not reflective of an investor’s actual experience. Hypothetical performance returns are based on historic economic and market assumptions. Actual performance returns will vary. Hypothetical performance returns do not reflect actual trading and may not reflect the impact that material economic and market factors had on the decision-making process for this portfolio.
                                    <!--<br /><br />
                      Each of the model portfolios (each, a "Model Portfolio") is designed to showcase a different investment strategy and how using different types of fund vehicles (each, a “Fund Vehicle”) can affect the performance of that strategy. These Model Portfolios are not designed to provide individualized recommendations/advice but instead are meant solely to be used for general, educational purposes. The actual inception date for each Model Portfolio is November 1, 2018. However, for purposes of this modeling exercise, Morningstar has derived hypothetical performance numbers for each Model Portfolio using the percentage-weighted performance numbers (back through December 2007) for each of the Fund Vehicles comprising that Model Portfolio. If, in any particular instance, a Fund Vehicle has an inception date after December 2007, Morningstar uses a proxy performance number for any "gap period" (i.e., December 2007 to the Fund Vehicle’s actual inception date). This proxy performance number is calculated by taking the return performance numbers generated during the relevant gap period by the index that the Fund Vehicle initially used as its primary prospectus benchmark and reducing those numbers by the Fund Vehicle fees (measured in percentages) charged as of its inception date. Model Portfolios rebalance to their target weight for each Fund Vehicle at the end of each year, unless otherwise stated. Dividends are automatically reinvested in Fund Vehicles. Morningstar applies fund expense ratios. No additional management fees are included.
                      -->
                                </p>
                            </div>
                        </mds-col>
                    </mds-row>
                </mds-col>
    
                <mds-col>
                    <div class="etf-performance-section-border-seperator"></div>
                    <span class="etf-performance-section-headings">Resources</span> <br /><br />
                    <mds-row style="margin-left:0">
                        <mds-col :cols="6" :cols-at-s="6" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6"  class="resources-main-div"  gutter="none">
                            <div class="mei-resource-main-div">
                                <span class="etf-performance-resources-sub-headings">Resources</span> <br />
                                <div style="margin-top: 15px">
                                    <mds-table style="width: 100%;" class="etf-tbl-font">
                                        <mds-tbody>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links" :to="{ name: 'archive', params: { currentproduct: this.CurrentProduct }}">
                                                        Past Issues
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="toggle = !toggle" @click="toggle = !toggle">Investment Strategy</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="downloadEtfResources('MEI_Handbook_1123_FINAL.pdf','Resources')" v-on:click="downloadEtfResources('MEI_Handbook_1123_FINAL.pdf','Resources')">Morningstar ETFInvestor Subscriber's Handbook </a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="downloadEtfResources('ETF_ModelPort_Introduction_1022.pdf','Resources')" v-on:click="downloadEtfResources('ETF_ModelPort_Introduction_1022.pdf','Resources')">Introduction to ETFInvestor Model Portfolios</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" href="https://community.morningstar.com/s/topic/0TO3o000001yV0WGAU/exchangetraded-funds" target="_blank">Morningstar.com ETF Forum</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" href="mailto:bryan.armour@morningstar.com">Ask Bryan</a>
                                                </mds-td>
                                            </mds-tr>
                                        </mds-tbody>
                                    </mds-table>
                                </div>
                            </div>
                            <br />
                            </mds-col>
                            <mds-col :cols="6" :cols-at-s="6" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6" class="resources-main-div"  gutter="none">
                            <div class="mei-resource-main-div">
                                <span class="etf-performance-resources-sub-headings">Portfolios and Watchlists</span><br />
                                <div style="margin-top: 15px">
                                    <mds-table style="width: 100%;" class="etf-tbl-font">
                                        <mds-tbody>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links" :to="{ name: 'modelPort', params: { currentproduct: this.CurrentProduct }}">
                                                        Model Portfolios
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links" :to="{ name: 'equityWatchlist', params: { currentproduct: this.CurrentProduct }}">
                                                        Equity Watchlist
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <router-link class="mei-resources-links" :to="{ name: 'bondWatchlist', params: { currentproduct: this.CurrentProduct }}">
                                                        Bond Watchlist
                                                    </router-link>
                                                </mds-td>
                                            </mds-tr>
    
                                        </mds-tbody>
                                    </mds-table>
                                </div>
                            </div>
                        
                       
                            <div class="mei-resource-main-div-bonus">
                                <span class="etf-performance-resources-sub-headings">Bonus Reports</span><br />
                                <div style="margin-top: 15px">
                                    <mds-table style="width: 100%;" class="etf-tbl-font">
                                        <mds-tbody>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="downloadEtfResources('Mkt_Outlook_Q1_2024.pdf','BonusReports')" @click="downloadEtfResources('Mkt_Outlook_Q1_2024.pdf','BonusReports')">Morningstar North America Equity Market Outlook Q1 2024</a>
                                                </mds-td>
                                            </mds-tr>
                                            <mds-tr>
                                                <mds-td>
                                                    <a class="mei-resources-links" tabindex="0" @keydown.enter="downloadEtfResources('Guide_to_CEFs.pdf','BonusReports')" @click="downloadEtfResources('Guide_to_CEFs.pdf','BonusReports')">Morningstar's Guide to Closed-End Funds</a>
                                                </mds-td>
                                            </mds-tr>
    
                                        </mds-tbody>
                                    </mds-table>
                                </div>
                            </div>
                        </mds-col>

                    </mds-row>
                </mds-col>
            </mds-row>
        </mds-layout-grid>
    
        <!--<mds-dialog v-model="toggle" title="Investment Strategy">
            <p style="text-align: justify">
                <i>Morningstar ETFInvestor</i> scans the globe for value and improving fundamentals
                across virtually all asset classes. Editor Bryan Armour draws upon academic and
                practitioner research — including Morningstar's sizeable bench of stock, bond and
                fund analysts — to find reliable drivers of outperformance.
            </p>
            <br/>
            <p style="text-align: justify">
                <i>Morningstar ETFInvestor</i> features five model portfolios.
            </p>
            <br/>
            <p style="text-align: justify">
                The Basic Portfolio harnesses the market's collective wisdom with ultra-low-cost
                funds and is the baseline portfolio against which the three other portfolios will be compared.
            </p>
            <br/>
            <p style="text-align: justify">
                The Defensive Portfolio aims to provide lower volatility, better downside protection,
                and better risk-adjusted performance than the basic portfolio over the long-term.
            </p>
            <br/>
            <p style="text-align: justify">
                The Factor Portfolio is designed to earn higher returns than the basic portfolio over the long-term.
            </p>
            <br/>
            <p style="text-align: justify">
                The Income Portfolio attempts to earn a higher distribution yield than the basic portfolio,
                without taking a lot more risk.
            </p>
            <br/>
            <p style="text-align: justify">
                The ESG Portfolio targets firms with strong corporate governance and sustainable environmental
                and social practices, while offering competitive returns and similar risk to the basic portfolio.
            </p>
        </mds-dialog>-->

        <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired class="investment-strategy-modal">
            <mds-section class="with-nested-sections" border-position="bottom" bold>
                <template #mds-section-title>
                    <span id="title-id">Investment Strategy</span>
                </template>
                <template #mds-section-actions>
                    <mds-button-container right-aligned>
                        <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                    </mds-button-container>
                </template>
                <mds-section border="none" :size="6">
                    <p>
                        <i>Morningstar ETFInvestor</i> scans the globe for value and improving fundamentals
                        across virtually all asset classes. Editor Bryan Armour draws upon academic and
                        practitioner research — including Morningstar's sizeable bench of stock, bond and
                        fund analysts — to find reliable drivers of outperformance.
                    </p>
                    <br>
                    <p>
                        <i>Morningstar ETFInvestor</i> features five model portfolios.
                    </p>
                    <br>
                    <p>
                        The Basic Portfolio harnesses the market's collective wisdom with ultra-low-cost
                        funds and is the baseline portfolio against which the three other portfolios will be compared.
                    </p>
                    <br>
                    <p>
                        The Defensive Portfolio aims to provide lower volatility, better downside protection,
                        and better risk-adjusted performance than the basic portfolio over the long-term.
                    </p>
                    <br>
                    <p>
                        The Factor Portfolio is designed to earn higher returns than the basic portfolio over the long-term.
                    </p>
                    <br>
                    <p>
                        The Income Portfolio attempts to earn a higher distribution yield than the basic portfolio,
                        without taking a lot more risk.
                    </p>
                    <br>
                    <p>
                        The ESG Portfolio targets firms with strong corporate governance and sustainable environmental
                        and social practices, while offering competitive returns and similar risk to the basic portfolio.
                    </p>
                </mds-section>
            </mds-section>
        </mds-modal>
    </div>
    </div>
    </template>
    
    <script>
    import Vue from "vue";
    import VueAxios from "vue-axios";
    import axios from "axios";
    import { mapState } from "vuex";
    import {
        MdsLayoutGrid,
        MdsRow,
        MdsCol
    } from "@mds/layout-grid";
    import {
        MdsTable,
        MdsTbody,
        MdsTr,
        MdsTd,
    } from "@mds/data-table";
    //import MdsDialog from "@mds/dialog";
    import MdsModal from '@mds/modal';
    import MdsSection from "@mds/section";
    import { MdsButton, MdsButtonContainer } from '@mds/button';
    import etfPerformanceGraphPath from "@/assets/Images/mei/ModelPortfTotRtn.png";
    Vue.use(VueAxios, axios);
    
    export default {
        name: 'etf-performance-section',
        props: [],
        components: {
            MdsLayoutGrid,
            MdsRow,
            MdsCol,
            //MdsDialog,
            MdsModal,
            MdsSection,
            MdsButton, 
            MdsButtonContainer,
            MdsTable,
            MdsTbody,
            MdsTr,
            MdsTd
        },
        mounted() {
            this.isMobileContentToggle=this.$store.state.responsive.isMobileContent;
            this.getETFPerformanceImageFromS3();
        },
        data() {
            return {
                etfPerformanceImage: null,
                etfPerformanceGraph: etfPerformanceGraphPath,
                toggle: false,
                FileFromS3Url: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_File_FromS3_Url,
                fileData: null,
                CurrentProduct: this.$session.get("current-product"),
                isMobileContentToggle: null,
            viewPortWidth: null,
            CurrentDate: new Date(),
            }
        },
        methods: {
            getETFPerformanceImageFromS3() {
                const getETFPerformanceImageFromS3 = this.FileFromS3Url + "ProductCode=" + this.CurrentProduct + "&FileName=perfgraphmini_mfi.gif&Type=Resources"
                try {
                    Vue.axios.get(getETFPerformanceImageFromS3).then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    if (response.data.FilePath) {
                                        this.etfPerformanceImage = response.data.FilePath;
                                    }
                                }
                            }
                        }
                    });
                } catch (ex) {
                    console.log(ex.message);
                }
            },
            downloadEtfResources(FileName, Type) {
                this.$gtag.event('download_pdf', {
        'product': 'MEI',
        'issue_date': this.CurrentDate,
        'issue_title': FileName,
        'type': Type
                    })
                   
                const documentS3URL = this.FileFromS3Url + "ProductCode=" + this.CurrentProduct + "&FileName=" + FileName + "&Type=" + Type
                try {
                    Vue.axios
                        .get(
                            documentS3URL
                        )
                        .then((response) => {
                            if (response.status && response.status == "200") {
                                this.fileData = response.data.FilePath;
                                window.open(this.fileData, "_self")
                            }
                        });
                } catch (ex) {
                    console.log(ex.message);
                }
            },
        },

        computed:mapState({
    isMobileContent: state => state.responsive.isMobileContent,
    isMobileContentAlias:'isMobileContent',
    isMobileContentState(state){
      return state.responsive.isMobileContent
    }

  }),

  watch:{
    isMobileContentState(){
      this.isMobileContentToggle = this.isMobileContentState;
    }
  }
    }
    </script>
    
    <style scoped>

@media only screen and (min-width: 1280px) and (max-width: 12800px) {
    .etf-performance-section {
        width: 1280px;
        margin: auto;
        padding: auto;
        margin-top: 80px;
    }

}
    
    .etf-performance-section-border-seperator {
        border-top: 1px solid #5e5e5e;
        margin-bottom: 10px;
        transform: rotate(180deg);
    }

    .mei-resources-links{
       margin-left: 1.5px;
    }
    
    .etf-performance-section-headings {
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 27px;
        color: #1E1E1E;
    }
    
    .etf-performance-image-section {
        width: 100%;
        height: 391px;
        margin-top: 20px;
    }
    
    .etf-performance-image-section img {
        width: 100%;
        height: 400px;
        margin-left: -8px;
    }
    
    .etf-performance-graph {
        width: 100%;
        color: #1E1E1E;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 45.8px;
    }
    
    .etf-performance-graph img {
        width: 100%;
        height: 387px;
    }
    
    .etf-performance-resources-sub-headings {
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #1E1E1E;
        margin-bottom: 10px;
    }
    
    a.etf-resources-section-links:hover {
        color: #ff0000 !important;
        text-decoration: none;
        cursor: pointer;
    }
    
    .etf-resources-section-links,
    span.etf-resources-section-links {
        color: #666666;
        text-decoration: none;
        width: 274px;
        border-top: 1px dotted #ff0000;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
    }
    
    span.etf-resources-section-links:hover,
    a.etf-resources-section-links:hover {
        color: #ff0000;
        text-decoration: none;
        cursor: pointer;
    }
    
    a.etf-resources-section-links:active {
        color: #666666;
        text-decoration: none;
    }
    
    .etf-resources-section-links,
    a.etf-resources-section-links:link,
    span.etf-resources-section-links {
        color: #666666;
        border-top: none;
    }
    
    .etf-resources-content-dividing-margin {
        margin-top: 4px;
    }
    
    .etf-resources-content-dividing-border {
        border-top: 1px Solid #cccccc;
    }
    
    .etf-tbl-font tr td {
        color: #666666;
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        cursor: pointer;
        pointer-events: all;
    }
    
    .etf-tbl-font tr td a {
        color: #666666;
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        cursor: pointer;
        pointer-events: all;
    }
    
    .etf-tbl-font tr td:hover {
        color: #ff0000;
        text-decoration: none;
    }
    
    .mds-td:first-child {
        padding-left: 0px;
    }
    
    .etf-tbl-font tr td a:hover {
        color: #ff0000;
        text-decoration: none;
        cursor: pointer;
    }
    
    .etf-performance-section-border-seperator-responsive {
        border-top: 1px solid #5e5e5e;
        margin-bottom: 10px;
        width: 90%;
        transform: rotate(180deg);
    }

    @media only screen and (min-width: 0px) and (max-width: 600px) {
    
    .main-mei-performance-section-responsive{
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 6000px) {
    
    .main-mei-performance-section-responsive{
       display: none !important;;
    }
}
    
    .etf-performance-image-section-responsive {
        width: 100%;
        
        margin-top: 20px;
    }
    
    .etf-performance-image-section-responsive img {
        width: 90%;
        height: 100%;
        margin-left: 0px;
    }
    
    .etf-performance-graph-responsive {
        width: 90%;
        color: #1E1E1E;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 25.8px;
        margin-bottom: 40px;
    }

    @media only screen and (min-width: 600px) and (max-width: 700px) {
    .etf-performance-graph {
        width: 100%;
        color: #1E1E1E;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 45.8px;
        margin-left: 0px;
        margin-bottom: 10px;
    }
}


    @media only screen and (min-width: 1281px) and (max-width: 12800px) {
    .mei-resource-main-div{
    margin-left: -15px;
    }

}
    
    .etf-performance-resources-sub-headings-responsive {
        margin-top: 20px;
    }
    
    @media only screen and (min-width: 600px) and (max-width: 1280px) {
        .etf-performance-section {
        width: 90%;
        margin: auto;
        padding: auto;
        margin-top: 80px;
    }
    }

    @media only screen and (min-width: 0px) and (max-width: 600px) {
        .etf-performance-section {
       display: none !important;;
    }
        .investment-strategy-modal .mds-modal__content{
            overflow-y:scroll;
        }
    }
    
    @media only screen and (min-width: 600px) and (max-width: 1280px) {
    .resources-main-div{
    padding-left: 0px;
    }
    }

    @media only screen and (min-width: 600px) and (max-width: 768px) {
        .mei-resource-main-div{
    width: 100%;
    }
    .mei-resource-main-div-bonus{
        margin-top: 14px;
    width: 100%;
    }

    }


    @media only screen and (min-width: 768px) and (max-width: 1280px) {
        .mei-resource-main-div-bonus{
           
        margin-top: 14px;
    width: 100%;
    }
    }

    @media only screen and (min-width: 1281px) and (max-width: 120008px) {
        .mei-resource-main-div-bonus{
           margin-left: -16px;
        margin-top: 14px;
    width: 100%;
    }
    }

    @media only screen and (min-width: 600px) and (max-width: 999px) {
    .etf-performance-image-section {
        width: 100%;
        
        margin-top: 20px;
    }
    
    .etf-performance-image-section img {
        width: 300px;
        height: 100%;
        margin-left: -13px;
    }

}

@media only screen and (min-width: 700px) and (max-width: 800px) {
    .etf-performance-graph {
        width: 110%;
        color: #1E1E1E;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 45.8px;
        margin-left: -40px;
        margin-bottom: 10px;
    }

}

@media only screen and (min-width: 800px) and (max-width: 900px) {
    .etf-performance-graph {
        width:115%;
        color: #1E1E1E;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 45.8px;
        margin-left: -70px;
        margin-bottom: 10px;
    }

}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
    .etf-performance-graph {
        width:125%;
        color: #1E1E1E;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 45.8px;
        margin-left: -120px;
        margin-bottom: 10px;
    }

}

@media only screen and (min-width: 1000px) and (max-width: 1280px) {
    .etf-performance-image-section {
        width: 100%;
        
        margin-top: 20px;
    }
    
    .etf-performance-image-section img {
        width: 250px;
        height: 100%;
        margin-top: -18px;
        margin-left: 0px;
    }

}

.investment-srategy-modal{
    width: 80%;
    padding-left: 8%;
}
    
    </style>
    <style>
        .investment-strategy-modal .mds-section .mds-section__header-container{
            margin-bottom:5px;
        }
    </style>