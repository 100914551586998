const ResponsiveModule = {
    namespaced: true,
    state() {
        return {
          isResponsive:false,
          viewPortSize: 0,
          isDataTableResponsive:false,
          isMobileHeader :false,
          isMobileContent: false,
          isVideoReadMore: false,
          isModelPortfolioResponsive : false
      }
    },
  };
  
  export default ResponsiveModule;