<template>
  <div>
      <cover-images></cover-images>
      <editor-section></editor-section>
      <div v-if="this.CurrentProduct.toUpperCase() == 'MDI'">
          <mdi-performance-section></mdi-performance-section>
      </div>
      <div v-if="this.CurrentProduct.toUpperCase() == 'MSI'">
          <msi-performance-section></msi-performance-section>
      </div>
      <div v-if="this.CurrentProduct.toUpperCase() == 'MFI'">
          <mfi-performance-section></mfi-performance-section>
      </div>
      <div v-if="this.CurrentProduct.toUpperCase() == 'MEI'">
          <mei-performance-section></mei-performance-section>
      </div>
      <video-section></video-section>
  </div>
  </template>
  
  <script>
  import CoverImages from "@/components/Common/Home/CoverImages.vue";
  import EditorSection from "@/components/Common/Home/Editor/EditorSection.vue";
  import MfiPerformanceSection from "@/components/Common/Home/Performance/MfiPerformanceSection.vue";
  import VideoSection from "@/components/Common/Home/VideoSection.vue";
  import MdiPerformanceSection from "@/components/Common/Home/Performance/MdiPerformanceSection.vue";
  import MsiPerformanceSection from "@/components/Common/Home/Performance/MsiPerformanceSection.vue";
  import MeiPerformanceSection from "@/components/Common/Home/Performance/MeiPerformanceSection.vue";
  export default {
      name: "home",
      components: {
          CoverImages,
          EditorSection,
          MfiPerformanceSection,
          VideoSection,
          MdiPerformanceSection,
          MsiPerformanceSection,
          MeiPerformanceSection
      },
      data() {
          return {
              CurrentProduct: this.$session.get("current-product")
          }
      },
  };
  </script>