import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Auth0Plugin } from "./auth/auth0.js";
import VueSession from "vue-session";
import "./filters/Filters";
import VueGtag from "vue-gtag";
import IdleVue from 'idle-vue';

Vue.prototype.$store = store;
var options = {
  persist: true
}
Vue.use(VueSession, options);

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 43200 * 1000, //12 Hours
  startAtIdle: false,
  events:['mousemove', 'keydown', 'mousedown', 'touchstart',"scroll"]
});


Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.VUE_APP_AUTH0_REDIRECT,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  source: process.env.VUE_APP_AUTH0_SOURCE,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetPath
        ? appState.targetPath
        : window.location.pathname
    );
  },
});

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GoogleGtagId }
});


Vue.config.productionTip = false;

new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");







