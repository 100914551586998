<template>
  <mds-list-group has-collapsible-child class="analyst-left-panel-container" no-rules>
    <mds-list-group-item
      v-for="(portfolioDetail, index) in portfolioSelectorLeftNav[applyIndex]"
      :key="index"
      :text="portfolioDetail.fieldName"
      collapsible
      :expanded="portfolioDetail.expanded"
      @click.native.stop="toggleLeftNav(index, portfolioDetail.portfolioTag)"
      class="portfolio-name-design"
    >
      <template #mds-list-item-children>
        <mds-loader
          class="analyst-left-small-loader"
          v-if="isLoading"
          size="small"
          aria-label="Small Loader"
        ></mds-loader>
        <mds-list-group-item
          v-for="(report, index) in reportData"
          :key="index"
          @click.native.stop="activeTab(index)"
          :active="report.isActive"
          >{{ report.HoldingName }}
        </mds-list-group-item>
      </template>
    </mds-list-group-item>
  </mds-list-group>
</template>

<script>
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsLoader from "@mds/loader";
import EncryptDecryptMixin  from "../../mixins/EncryptDecryptMixin.js";
export default {
name: "analyst-reports-left-panel",
props: {
  leftPanelData: {
    type: Array,
    required: true,
  },
  isMobileContentToggle:
  {
    type:Boolean
  }
},
components: {
  MdsListGroup,
  MdsListGroupItem,
  MdsLoader,
},
mixins: [
  EncryptDecryptMixin
],
mounted() {
  let currentPortfolioName = this.Decrypt(this.$session.get("CurrentPortfolioName"));
  if(currentPortfolioName == undefined || currentPortfolioName =="" || currentPortfolioName == null)
  {
    if(this.CurrentProduct.toUpperCase() == "MSI"){
      this.onPageLoadToggle("tortHarePort");
    }else if(this.CurrentProduct.toUpperCase() == "MDI")
    {
      this.onPageLoadToggle("incomeBell");
    }else{
      this.onPageLoadToggle("bondPort");
    }
  }
  else
  {
    this.onPageLoadToggle(currentPortfolioName);
  }
},
data() {
  return {
    clearSession:0,
    isLoading: false,
    mdiData: [],
    msiData: [],
    CurrentProduct: this.$session.get("current-product"),
    applyIndex: null,
    portfolioSelectorLeftNav: [
      (this.mdiData = [
        {
          fieldName: "Dividend Select Portfolio",
          portfolioTag: "divSelect",
          expanded: false,
        },
        {
          fieldName: "Dividend Select Deferred Portfolio",
          portfolioTag: "divDifferSelect",
          expanded: false,
        },
        {
          fieldName: "Income Bellwethers",
          portfolioTag: "incomeBell",
          expanded: false,
        },
      ]),
      (this.msiData = [
        {
          fieldName: "Tortoise Portfolio",
          portfolioTag: "tortoisePort",
          expanded: false,
        },
        {
          fieldName: "Hare Portfolio",
          portfolioTag: "harePort",
          expanded: false,
        },
        {
          fieldName: "Tortoise & Hare Watchlist",
          portfolioTag: "tortHarePort",
          expanded: false,
        },
      ]),
      (this.meiData = [
        {
          fieldName: "Basic Portfolio",
          portfolioTag: "Basic",
          expanded: false,
        },
        {
          fieldName: "Defensive Portfolio",
          portfolioTag: "Defensive",
          expanded: false,
        },
        {
          fieldName: "Factor Portfolio",
          portfolioTag: "Factor",
          expanded: false,
        },
        {
          fieldName: "Income Portfolio",
          portfolioTag: "Income",
          expanded: false,
        },
        {
          fieldName: "ESG Portfolio",
          portfolioTag: "ESG",
          expanded: false,
        },
        {
          fieldName: "Equity Watchlist",
          portfolioTag: "equityPort",
          expanded: false,
        },
        {
          fieldName: "Bond Watchlist",
          portfolioTag: "bondPort",
          expanded: false,
        },
      ]),
    ],
  };
},
methods: {
  activeTab(index) {
    this.reportData.map((p) => (p.isActive = false));
    this.reportData[index].isActive = true;
    if(!this.isMobileContentToggle)
    {
    this.$emit("report-clicked", this.reportData[index]);
    }
    else{
      this.$emit("responsive-report-clicked", this.reportData[index]);
    }
    window.scrollTo(0, 0);
  },
  toggleLeftNav(index, portfolioTag) {
    this.isLoading = true;
    let collapsibleNav = this.portfolioSelectorLeftNav[this.applyIndex][index];
    if (!collapsibleNav.expanded) {
      this.portfolioSelectorLeftNav[this.applyIndex].map(
        (index) => (index.expanded = false)
      );
    }
    collapsibleNav.expanded = !collapsibleNav.expanded;
    if (this.reportData.length > 0) {
      this.reportData.length = 0;
    }
    if(!this.isMobileContentToggle)
    {
    this.$emit("left-nav-clicked", portfolioTag);
    }
    else{
      this.$emit("responsive-left-nav-clicked", portfolioTag);
    }
    window.scrollTo(0, 0);
  },
  async onPageLoadToggle(portfolioName) {
    if (this.CurrentProduct.toUpperCase() == "MDI") {
      this.applyIndex = 0;
    } else if (this.CurrentProduct.toUpperCase() == "MSI") {
      this.applyIndex = 1;
    } else if (
      this.CurrentProduct.toUpperCase() == "MEI" ||
      this.CurrentProduct.toUpperCase() == "ETF"
    ) {
      this.applyIndex = 2;
    } else {
      this.applyIndex = null;
    }
    for (let i = 0; i < this.portfolioSelectorLeftNav.length; i++) {
      for (let j = 0; j < this.portfolioSelectorLeftNav[i].length; j++) {
        if (this.portfolioSelectorLeftNav[i][j].portfolioTag == portfolioName) {
          this.toggleLeftNav(j, portfolioName);
        }
      }
    }
  },
  onLoadActive(secId) {
    for (let i = 0; i < this.leftPanelData.length; i++) {
      if (this.leftPanelData[i].SecId == secId) {
        this.activeTab(i);
      }
    }
  },
},
computed: {
  reportData() {
    return this.leftPanelData;
  },
},
watch: {
  leftPanelData() {
    this.clearSession++;
    let secId = this.Decrypt(this.$session.get("CurrentSecID"));
    if(!this.isMobileContentToggle)
    {
    this.$session.set("CurrentSecID","");
    this.$session.set("CurrentPortfolioName","");
    }
    else{
      if(this.clearSession > 1)
      {
        this.$session.set("CurrentSecID","");
        this.$session.set("CurrentPortfolioName","");
      }
    }
    if(secId == undefined || secId =="" || secId == null){
      if (this.leftPanelData.length > 0) {
        this.isLoading = false;
        for(let i=0;i<1;i++)
        {
          secId = this.leftPanelData[i].SecId;
          this.onLoadActive(secId);
        }
      }
    }
    else
    {
      if (this.leftPanelData.length > 0) {
        this.isLoading = false;
        this.onLoadActive(secId);
      }
    }
  },
},
};
</script>

<style scoped>
.analyst-left-small-loader{
  margin-left:30%;
}
</style>
<style>
.analyst-left-panel-container {
width: 240px;
margin-top: 8px;
}
.analyst-left-panel-container .portfolio-name-design {
width: 240px;
}
.analyst-left-panel-container span:focus {
box-shadow: none !important;
outline: none !important;
}
.analyst-left-panel-container > li > span > div > div:first-child {
margin-left: -12px;
}
.analyst-left-panel-container .portfolio-name-design > span > div > div:nth-child(2) > div > span {
font-weight: 700;
}
.analyst-left-panel-container .portfolio-name-design > ul > li > span > div > div:nth-child(2){
margin-left:-23px;
}
</style>