
const ProductModule = {
    namespaced: true,
    state() {
        return {
            CurrentProduct: "",
            ProductOrderedArray: [],
            SelectedProductBanner:"",
            SelectedProductVideoTranscript:false,
            OmahaCustomer:false,
            SuccessAlert:false,
            ErrorAlert:false,
            lstAlertMsg:[]
      }
    },
    getters: {
        getCurrentProduct(state){
            return state.CurrentProduct;
        },
    },
    mutations: {
        setCurrentProduct(state, payload) {
            state.CurrentProduct = payload.value;
        }
    },
    actions: {
         changeProduct(context,payload) {
            context.commit('setCurrentProduct', { value: payload });
        }
    }
  };
  
  export default ProductModule;