<template>
    <div class="video-transcript-main">
        <ad currentPage="videos"></ad>
        <div class="video-transcript-features-text">
            <div v-if="!this.$store.state.user.LoggedIn">
                <a href="" @click="redirectHomePage()">Newsletters Home</a> /
                <a href="" @click="redirectVideoPage()">Videos</a> / Featured Video
            </div>
            <div v-if="this.$store.state.user.LoggedIn">
                <a href="" @click="redirectHomePage()">Newsletters Home</a>
                <a href="" @click="redirectSelectedProduct()" v-if="bcheckCombinePage"> / {{ productTitle }}</a> /
                <a href="" @click="redirectVideoPage()">Videos</a> / Featured Video
            </div>
        </div>
        <div class="video-transcipt-product-title">
            <span>{{ productTitle }}</span>
        </div>
        <div class="video-transcript-loader">
            <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
        </div>
        <div v-if="isDisplay" class="video-transcript-main-container">
            <div class="video-transcript-videos-container">
                <div class="video-transcript-left-panel">
                    <div class="video-transcript-active-video">
                        <div class="video-transcript-active-video-button">
                            <mds-button variation="primary" id="DefaltVideoButton" style="display:none;"  @click="FirstVideoClick()">
                                <span style="position: absolute; top: 18%">
                                    <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                </span>
                                <span style="margin-left: 20px">Watch</span>
                            </mds-button>
                        </div>
                        <video crossorigin="anonymous" class="video-transcript-active-video-size" :ref="ref" type="video/mp4" preload="auto" data-setup="{}" v-bind:poster="ImageThumb" :src="VideoURL" :id="VideoId">
                            <track kind="subtitles" label="English" :src="closedCaptions" srclang="en" default>
                        </video>
                    </div>
                    <div class="video-transcript-active-video-details">
                        <div class="video-transcript-active-title">{{activeTitle}}</div>
                        <div class="video-transcript-active-description">{{activeDescription}}</div>
                        <div class="video-transcript-active-publishDate">{{activePublishDate}}</div>
                    </div>
                    <div class="video-transcipt-border"></div>
                </div>
                <div class="video-transcript-right-panel" @scroll="scrollToDiv()" id="transcript-scroll">
                    <div class="video-transcript-related-videos">
                        Related Videos
                    </div>
                    <div v-for="video in videos" :key="video.ref" :id="video.VideoClass" class="video-transcript-right-image-and-description-container">
                        <div class="video-transcript-right-image-container">
                            <div class="video-transcript-right-image-play-button">
                                <mds-button variation="primary" @click="onVideoClick(video.buttonWatchId, video.ImageThumb, video.VideoURL, video.VideoId, video.Title, video.VideoDescription, video.ref, video.VideoClass, video.VideoTranscript, video.PublishDate,video.closedCaptions)" :id="video.buttonWatchId">
                                    <span style="position: absolute; top: 18%">
                                        <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                    </span>
                                    <span style="margin-left: 20px">Watch</span>
                                </mds-button>
                            </div>
                            <img :src="video.ImageThumb" class="video-transcript-right-img" />
                        </div>
                        <div class="video-transcript-right-video-details-container">
                            <div class="video-transcript-right-title" @click="onVideoClick(video.buttonWatchId, video.ImageThumb, video.VideoURL, video.VideoId, video.Title, video.VideoDescription, video.ref, video.VideoClass, video.VideoTranscript, video.PublishDate,video.closedCaptions)">{{video.Title}}</div>
                            <div class="video-transcript-right-description">{{video.VideoDescription}}</div>
                            <div class="video-transcript-right-publishDate">{{video.PublishDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="video-transcript-opacity" id="transcript_opacity"></div>
            </div>
            <div class="video-transcript-container" v-html="activeTranscript"></div>
        </div>
    </div>
</template>

<script>
import MdsIcon from '@mds/icon';
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import {
    MdsButton
} from "@mds/button";
import MdsLoader from "@mds/loader";
import Ad from "@/components/Common/Ad/Ad.vue";

Vue.use(VueAxios, axios);
export default {
    name: "video-transcript",
    components: {
        MdsIcon,
        MdsButton,
        MdsLoader,
        Ad,
    },
    data() {
        return {
            VideoUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_Video,
            //VideoUrl:"https://localhost:50377/videos?ProductCode={ProductCode}&Limit={Limit}",
            CombineVideoUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_CombineVideoUrl,
            videos: [],
            ImageThumb: "",
            VideoURL: "",
            VideoId: "",
            activeTitle: "",
            activeDescription: "",
            activePublishDate: "",
            ref: "",
            buttonWatchId: "",
            isLoading: true,
            isDisplay: false,
            id: "",
            headerTitle: "",
            productTitle: "",
            activeTranscript: "",
            queryStringVideoTitle: "",
            checkActiveProduct: false,
            bcheckCombinePage:false,
            closedCaptions:null,
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        // if(localStorage.getItem("SelectedProductVideoTranscript")){
            
        //     this.$store.state.product.CurrentProduct = localStorage.getItem("SelectedProductVideoTranscript").toLowerCase();
        //     this.$session.set("current-product", localStorage.getItem("SelectedProductVideoTranscript").toLowerCase());
        //     this.$store.state.layout.isHeaderRequired = false;
        // }
        // else{
            this.$store.state.product.CurrentProduct = "Common";
             this.$session.set("current-product", "Common");
        // }
        // if (this.$store.state.user.LoggedIn) {
        //    
        // } else {
        //     this.headerTitle = "Newsletters Home /  Videos  / Featured Video";
        // }
        var search = window.decodeURIComponent(window.location.href);

        if (search) {
            const myArray = search.split("/");
            if (myArray[4]) {
                var str = myArray[4];
                //const noSpecialChars = str.replace('%20', '');
                this.queryStringVideoTitle = str;
            }

        }
        this.checkProductCode();
        this.getVideo();

    },
    methods: {
        checkProductCode() {
            if(localStorage.getItem("MoreVideosSelectedProduct")){
                this.bcheckCombinePage = true;
            }
            else{
                this.bcheckCombinePage = false;
            }
            if (localStorage.getItem("SelectedProductVideoTranscript")) {
                if (localStorage.getItem("SelectedProductVideoTranscript") == "MFI") {
                    //this.headerTitle = "Newsletters Home / FundInvestor /  Videos  / Featured Video";
                    this.productTitle = "FundInvestor";
                } else if (localStorage.getItem("SelectedProductVideoTranscript") == "MSI") {
                    //this.headerTitle = "Newsletters Home / StockInvestor /  Videos  / Featured Video";
                    this.productTitle = "StockInvestor";
                } else if (localStorage.getItem("SelectedProductVideoTranscript") == "MDI") {
                    //this.headerTitle = "Newsletters Home / DividendInvestor /  Videos  / Featured Video";
                    this.productTitle = "DividendInvestor";
                } else if (localStorage.getItem("SelectedProductVideoTranscript") == "MEI") {
                    //this.headerTitle = "Newsletters Home / EtfInvestor /  Videos  / Featured Video";
                    this.productTitle = "ETFInvestor";
                }
            }
        },
        getVideo() {
            try {
                if (localStorage.getItem("SelectedProductVideoTranscript")) {
                    var CurrentProduct = localStorage.getItem("SelectedProductVideoTranscript");
                    var Url = this.VideoUrl;
                    Url = Url.replace('{ProductCode}', CurrentProduct);
                    Url = Url.replace('{Limit}', "8");
                    Vue.axios.get(Url).then((response) => {
                        if (response) {
                            if (response.status) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        var lstData = response.data;
                                        for (var i = 0; i < lstData.length; i++) {
                                            var obj = lstData[i];

                                            var ref = "";
                                            var VideoURL = "";
                                            var VideoDescription = "";
                                            var Title = "";
                                            var ImageThumb = "";
                                            var VideoTranscript = "";
                                            var PublishDate = "";
                                            var subtitles = "";

                                            if (obj.Ref_Id) {
                                                ref = obj.Ref_Id;
                                            }

                                            if (obj.VideoURL) {
                                                VideoURL = obj.VideoURL;
                                            }

                                            if (obj.VideoDescription) {
                                                VideoDescription = obj.VideoDescription;
                                            }
                                            if (obj.Title) {
                                                Title = obj.Title;
                                            }

                                            if (obj.ImageThumb) {
                                                ImageThumb = obj.ImageThumb;
                                            }
                                            if (obj.VideoTranscript) {
                                                VideoTranscript = obj.VideoTranscript;
                                            }
                                            if (obj.PublishDate) {
                                                PublishDate = obj.PublishDate;
                                            }
                                            if (obj.ClosedCaptions) {
                                                subtitles = obj.ClosedCaptions;
                                            }
                                            this.videos.push({
                                                ref: ref,
                                                VideoURL: VideoURL,
                                                ImageThumb: ImageThumb,
                                                Title: Title,
                                                VideoDescription: VideoDescription,
                                                buttonWatchId: "WatchId_" + ref,
                                                buttonStopId: "StopId_" + ref,
                                                VideoId: "VideoId_" + ref,
                                                VideoClass: "VideoCls_" + ref,
                                                VideoTranscript: VideoTranscript,
                                                PublishDate: PublishDate,
                                                closedCaptions:subtitles,
                                            });
                                        }
                                    }
                                    this.getFirstVideoData();
                                }
                            }
                        }

                    })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
                }
            } catch (ex) {
                console.log(ex.message);
            }
        },
        onVideoClick(buttonWatchId, ImageThumb, VideoURL, VideoId, Title, VideoDescription, ref, VideoClass, VideoTranscript, PublishDate,closedCaptions) {
            try {
                //console.log("buttonWatchId =" + buttonWatchId);
                document.getElementById("DefaltVideoButton").style.display = "none";
                this.ImageThumb = ImageThumb;
                this.VideoURL = VideoURL;
                this.VideoId = VideoId;
                this.activeTitle = Title;
                this.activeDescription = VideoDescription;
                this.ref = ref;
                this.buttonWatchId = buttonWatchId;
                this.id = VideoId;
                this.activeTranscript = VideoTranscript;
                this.activePublishDate = PublishDate;
                this.closedCaptions = closedCaptions;
                this.updateBrowserUrl();

                for (var i = 0; i < this.videos.length; i++) {
                    var obj = this.videos[i];
                    if (obj.buttonWatchId) {
                        if (this.buttonWatchId == obj.buttonWatchId) {
                            document.getElementById(this.buttonWatchId).style.display = "none";
                            var element = document.getElementById(VideoClass);
                            element.classList.add("combine-overlay-effect");

                        } else {
                            document.getElementById(obj.buttonWatchId).style.display = "block";
                            var element1 = document.getElementById(obj.VideoClass);
                            element1.classList.remove("combine-overlay-effect");
                        }
                    }
                }
                try {
                    //const $video = this.VideoId;
                    //var oVideoTag = document.getElementById(this.VideoId);
                    //oVideoTag.play();
                    var oVideoTag;
                    setTimeout(() => {
                        oVideoTag = document.getElementById(this.id);
                    }, 500);

                    setTimeout(() => {
                        oVideoTag.play();
                        oVideoTag.controls = "controls";
                    }, 500);

                } catch (ex) {
                    console.log(ex.message);
                }

            } catch (ex) {
                console.log(ex.message);
            }
        },
        getFirstVideoData() {
            var bValid = false;
            for (var l = 0; l < this.videos.length; l++) {
                var obj = this.videos[l];
                var VideoTitle = window.decodeURIComponent(this.queryStringVideoTitle);
                VideoTitle = VideoTitle.replaceAll('-', ' ');
                //VideoTitle = VideoTitle.replaceAll('-','-');
                var checkValue = obj.Title.replaceAll('-', ' ');
                if (checkValue.includes(VideoTitle)) {
                    bValid = true;
                    this.ImageThumb = obj.ImageThumb;
                    this.VideoURL = obj.VideoURL;
                    this.VideoId = obj.VideoId;
                    this.activeTitle = obj.Title;
                    this.activeDescription = obj.VideoDescription;
                    this.ref = obj.ref;
                    this.buttonWatchId = obj.buttonWatchId;
                    this.id = obj.VideoId;
                    this.activeTranscript = obj.VideoTranscript;
                    this.activePublishDate = obj.PublishDate;
                    this.closedCaptions = obj.closedCaptions;
                    try {
                        //document.getElementById("WatchId_1").style.display = "none";
                        setTimeout(() => {
                            if (this.buttonWatchId && this.buttonWatchId !=null ) {
                                if(document.getElementById(this.buttonWatchId) != null && document.getElementById(this.buttonWatchId).style != null){
                                    document.getElementById(this.buttonWatchId).style.display = "none";
                                    document.getElementById("DefaltVideoButton").style.display = "block";
                                }
                            }
                        }, 500);

                        setTimeout(() => {
                            if (obj.VideoClass && obj.VideoClass != null) {
                                var element = document.getElementById(obj.VideoClass);
                                if(element != null && element.classList != null){
                                    element.classList.add("combine-overlay-effect");
                                }
                            }
                        }, 500);
                    } catch (ex) {
                        //console.log(ex.message);
                    }
                    break;
                }

            }
            if (!bValid) {
                //this.$store.state.product.SelectedProductVideoTranscript = true;
                this.$store.state.access.PageNotFound = true;
                this.$router.push({ name: 'pageNotFound' }).catch(err => {
                    if (
                        err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(err);
                    }
                });
            }
        },
        FirstVideoClick() {

            document.getElementById("DefaltVideoButton").style.display = "none";
            var oVideoTag;
            oVideoTag = document.getElementById(this.id);
            setTimeout(() => {
                oVideoTag.play();
                oVideoTag.controls = "controls";
            }, 500);


        },
        updateBrowserUrl() {
            var temp = this.$route.path;
            var split = temp.split('/');
            var updateUrl;
            var title = this.activeTitle.replaceAll(' ', '-');
            title = title.replaceAll('-', '-');
            if (split) {
                console.log(split);
                updateUrl = split[0] + "/" + split[1] + "/" + title;
            }
            history.pushState(
                {},
                null,
                updateUrl
            )
        },
        redirectVideosPage() {
            this.$router.push({
                path: '/videos',
                params: {
                    currentproduct: 'Common'
                }
            }).catch(err => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(err);
                }
            });
        },
        scrollToDiv() {
            //const element = this.$refs.myElement;
            var currentElement = "transcript-scroll";
            var currentOpacity = "transcript_opacity";

            var element = document.getElementById(currentElement);
            const Totalheight = element.offsetHeight;
            const currentPosition = element.scrollTop;
            const currentPositionBottom = element.scrollHeight;
            //console.log("Totalheight = " + Totalheight);
            //console.log("currentPosition = " + currentPosition);
            //console.log("currentPositionBottom = " + currentPositionBottom);

            if (currentPosition == 0) {
                document.getElementById(currentOpacity).style.display = "block";
            } else {
                const calPosition = parseFloat(Totalheight) + parseFloat(currentPosition) + 2;
                //console.log("calPosition = " + calPosition);
                if (calPosition < currentPositionBottom) {
                    document.getElementById(currentOpacity).style.display = "block";
                } else {
                    document.getElementById(currentOpacity).style.display = "none";
                }
            }

        },
        redirectHomePage() {
            this.$router.push({ name: 'combined-home' }).catch(err => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(err);
                }
            });
        },
        redirectVideoPage() {
            this.$router.push({ name: 'videos' }).catch(err => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(err);
                }
            });
        },
        redirectSelectedProduct() {
            var product;
            if (this.productTitle == "StockInvestor") {
                product = "msi";
            }
            else if (this.productTitle == "FundInvestor") {
                product = "mfi";
            }
            else if (this.productTitle == "DividendInvestor") {
                product = "mdi";
            }
            else if (this.productTitle == "ETFInvestor") {
                product = "mei";
            }
            if (product) {
                this.$router.push({ name: 'home', params: { currentproduct: product } }).catch(err => {
                    if (
                        err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(err);
                    }
                });
            }
        }
    }

}
</script>

<style scoped>
body {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}
.video-transcript-main {
    max-width:1280px;
    margin:0 auto;
}
.video-transcipt-product-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: #1E1E1E;
    padding-top:34px;
}
.video-transcript-loader {
    width: "276px";
    height: "69px";
}
.video-transcript-main-container{
    width:100%;
    margin-top:42px;
}
.video-transcript-features-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1E1E1E;
    margin-top: 34px;
}

.video-transcript-features-text a {
    text-decoration: none;
    color: #1E1E1E !important;
}
.video-transcript-videos-container{
    width:100%;
    display:flex;
    gap:32px;
}
.video-transcript-left-panel{
    width:60%;
    max-width:747px;
}
.video-transcript-right-panel{
    width:40%;
    max-width:501px;
    height:570px;
    max-height:570px;
    margin-left:auto;
    margin-right:5px;
    overflow: auto;
    position: relative;
}
.video-transcript-active-video{
    width:100%;
    position:relative;
    z-index:1;
}
.video-transcript-active-video video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video-transcript-active-video-button{
    position:absolute;
    bottom:13px;
    left:13px;
    right:0%;
    margin:auto;
    cursor:pointer;
    z-index:2;
}
.video-transcript-active-video-button button{
    cursor:pointer;
}
.video-transcript-active-video-details{
    width:100%;
    margin-top:23px;
}
.video-transcript-active-title{
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; 
}
.video-transcript-active-description{
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
    margin:6px 0px 6px 0px;
}
.video-transcript-active-publishDate{
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    color: rgb(94, 94, 94);
}
.video-transcript-related-videos {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #1E1E1E;
    position: relative;
    margin-bottom:15px;
}
.video-transcript-right-image-and-description-container{
    width:100%;
    height:120px;
    max-height:120px;
    margin-bottom:15px;
    display:flex;
}
.video-transcript-right-image-container{
    width:100%;
    max-width:214px;
    height:100%;
    position:relative;
}
.video-transcript-right-video-details-container{
    width:100%;
    max-width:100%;
    height:100%;
    padding:15px;
    box-sizing: border-box; 
}
.video-transcript-right-img{
    width:100%;
    height:100%;
    object-fit:cover;
}
.video-transcript-right-image-play-button{
    position:absolute;
    bottom:13px;
    left:13px;
    right:0%;
    margin:auto;
}
.video-transcript-right-title{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor:pointer;
}
.video-transcript-right-description{
    margin-top:4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.video-transcript-right-publishDate{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color:#5E5E5E;
    margin-top:8px;
}
.combine-overlay-effect {
    background-color: #F2F2F2;
    box-shadow: 5px 0px #000000;
    width:99%;
}
.video-transcript-opacity {
    position: absolute;
    width: 41%;
    height: 121px;
    opacity: 1;
    z-index: 2;
    background: linear-gradient(0deg,#fff,hsla(0,0%,100%,0));
    transform: rotate(0deg);
    margin-top: 450px;
    margin-left: auto;
    right: 0;
}
.video-transcipt-border {
    height: 1px;
    background-color:#000000;
    margin-top:69px;
    width:100%;
}
.video-transcript-container{
    width:58%;
    margin-top:21px;
    margin-bottom:76px;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
}
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-x: overlay;
} 
::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
}
@media only screen and (max-width:1400px){
    .video-transcript-main{
        width:90%;
    }
}
@media only screen and (min-width:320px) and (max-width:999px){
    .video-transcript-videos-container{
        display:block;
    }
    .video-transcript-left-panel{
        width:100%;
        max-width:100%;
    }
    .video-transcript-right-panel{
        display:none;
    }
    .video-transcript-container{
        width:100%;
    }

    /*=================*/
        .video-transcipt-product-title {
        display:none;
    }
    .video-transcript-features-text{
        margin-top:15px;
    }
    .video-transcript-main-container{
        margin-top:20px;
    }
    .video-transcript-active-video-details{
        margin-top:16px;
    }
    .video-transcript-active-title{
        font-size: 20px;
        line-height: 23px; 
    }
    .video-transcript-active-description{
        font-size: 16px;
        line-height: 21px; 
    }
    .video-transcript-active-publishDate{
        font-size: 14px;
        line-height: 18px; 
    }
    .video-transcipt-border{
        margin-top:44px;
    }
    .video-transcript-container{
        margin-top:9px;
        margin-bottom:32px;
        font-size:16px;
        line-height:21px;
    }
    .video-transcript-opacity{
        display:none !important;
    }
}
@media only screen and (min-width:1600px) and (max-width:1999px){
    .video-transcript-opacity{
        width:43%;
    }
}
@media only screen and (min-width:2000px){
    .video-transcript-opacity{
        width:46.5%;
    }
}
@media only screen and (min-width:4000px){
    .video-transcript-opacity{
        width:48%;
    }
}
</style>
<style>
.video-transcript-container p{
    margin-bottom:15px;
}
.video-transcript-container ul{
    padding:20px;
}
.video-transcript-container a{
    color: #000000;
    text-decoration:none;
    border-bottom:1px solid #e5e5e5e5;
}
.video-transcript-container mark{
    background-color:transparent;
}
</style>