<template>
  <div>
    <mds-page-shell
    :logo="getProductLogo()"
    :logo-responsive="getProductLogo()"
    logo-href="/"
    logo-alt="Back to home page"
   >
    <div class="subscription-confirmation">
    <div class="subscription-confirmation-main">
      <div class="subscription-confirmation-container">
        <div v-if="!ismultipleProductSubscribe" class="subscription-confirmation-exists">
          <h1 class="confirmation-page-title"><span>Thank you for subscribing to </span> <br /><span
            ><i>Morningstar {{ currentProduct }}</i></span
          ></h1>
        </div>
        <div v-if="ismultipleProductSubscribe" class="subscription-confirmation-exists">
          <h1 class="confirmation-page-title"><span>Thank you for subscribing to </span> <br /><span
            >Morningstar Investor Newsletters</span
          ></h1>
        </div>
      <div class="proudct-registration-border-line"></div>
      <div class="subscription-confirmation-info">
        <span
          >You will receive a confirmation email shortly. If you don't receive
          an email from us, please check your spam folder and make sure to allow emails from
          Morningstar.</span
        >
      </div>
      <div>
        <mds-button
          variation="secondary"
          type="button"
          size="large"
          class="subscription-final-button"
          @click="login()"
        >
          Sign in
        </mds-button>
      </div>
    </div>
  </div>
</div>
  </mds-page-shell>
  </div>
</template>
<script>
import MdsPageShell from '@mds/page-shell'
import { MdsButton } from "@mds/button";
export default {
  name: "subscription-confirmation",
  components: { MdsPageShell, MdsButton },
  data() {
    return {
      currentProduct: "",
      productSubscribe:[],
      ismultipleProductSubscribe:null
    };
  },
  methods:{
    login() {
                this.$auth0.loginWithRedirect();
            },    
    getProductLogo() {
      var images = require.context(
        "../../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    async getDataFromlocalStorage() {
        var MFIsubscrptionData = localStorage.getItem("MFIProductSubscription");
        if (MFIsubscrptionData != "" && MFIsubscrptionData != null) {
          MFIsubscrptionData = JSON.parse(MFIsubscrptionData);
          if (MFIsubscrptionData.Price != null) {
             this.productSubscribe.push({
              isMFISubscribe:true
             })
          }
        }
        var MSIsubscrptionData = localStorage.getItem("MSIProductSubscription");
        if (MSIsubscrptionData != "" && MSIsubscrptionData != null) {
          MSIsubscrptionData = JSON.parse(MSIsubscrptionData);
          if (MSIsubscrptionData.Price != null) {
            this.productSubscribe.push({
              isMSISubscribe:true
             })
          }
        }
        var MDIsubscrptionData = localStorage.getItem("MDIProductSubscription");
        if (MDIsubscrptionData != "" && MDIsubscrptionData != null) {
          MDIsubscrptionData = JSON.parse(MDIsubscrptionData);
          if (MDIsubscrptionData.Price != null) {
            if (MDIsubscrptionData.Price != null) {
            this.productSubscribe.push({
              isMDISubscribe:true
             })
          }
          }
        }
        var MEIsubscrptionData = localStorage.getItem("MEIProductSubscription");
        if (MEIsubscrptionData != "" && MEIsubscrptionData != null) {
          MEIsubscrptionData = JSON.parse(MEIsubscrptionData);
          if (MEIsubscrptionData.Price != null) {
            this.productSubscribe.push({
              isMEISubscribe:true
             })
          }
        }
        if(this.productSubscribe.length>1)
        {
          this.currentProduct = "Investor Newsletters"
          this.ismultipleProductSubscribe = true;
        }
        else
        {
          this.ismultipleProductSubscribe = false;
          if(this.productSubscribe[0]['isMFISubscribe'])
          this.currentProduct = "FundInvestor"
          if(this.productSubscribe[0]['isMSISubscribe'])
          this.currentProduct = "StockInvestor"
          if(this.productSubscribe[0]['isMDISubscribe'])
          this.currentProduct = "DividendInvestor"
          if(this.productSubscribe[0]['isMEISubscribe'])
          this.currentProduct = "ETFInvestor"
        }
      }
},
  async mounted()
  {
    window.scrollTo(0, 0);
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
    await this.getDataFromlocalStorage();
    if(this.$store.state.user.LoggedIn)
    {
      this.$store.state.user.NewRegister = true;
    }
  }
};
</script>
<style scoped>
.login-redirect-homepage{
  margin-top:3%;
}
.subscription-confirmation-container{
  max-width:710px;
  margin:0 auto;
  height: 100vh;
}
.confirmation-page-title {
    font-size: 60px;
    font-weight: 200;
    height: 78px;
    padding-top: 200px;
    padding-bottom: 20px;
    opacity: 0.7;
}
.subscription-confirmation
{
  width:100%;
  margin:0 auto;
  background: #F2F2F2;
  background-image: url("../../../assets/final_confirmation.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.proudct-registration-border-line {
  width: 100%;
  background: #1e1e1e;
  border: 0.1px solid #1e1e1e;
  margin-top:25%;
  opacity: 0.7;
}
.subscription-confirmation-main {
  max-width:1208px;
  margin:0 auto;
  height: 100vh;
}
.subscription-confirmation-exists {
  opacity:1;
  font-weight: 200;
  font-size: 60px;
  line-height: 64px;
  color: #1e1e1e;
  mix-blend-mode: normal;
}
.subscription-confirmation-info {
  opacity:1;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 0.5%;
}
.subscription-final-button {
  margin-top: 2%;
  float:right;
}
@media only screen and (max-width:480px){
  .subscription-confirmation-main{
    width:100%;
    background:transparent;
  }
  .subscription-confirmation-container{
    width:90%;
    margin: 0 auto;
    background:transparent;
  }
  .subscription-confirmation-exists{
    font-size:24px;
    line-height:normal;
  }
  .confirmation-page-title {
    font-size: 24px;
    font-weight: 200;
    padding-top: 100px;
    opacity: 0.7;
}
.subscription-confirmation-info {
  margin-top: 6px !important;
  margin-bottom: 6% !important;
}
}
@media only screen and (min-width:500px) and (max-width:550px)
{
  .proudct-registration-border-line {
    margin-top:35%;
  }
}
@media only screen and (min-width:500px)and (max-width:767px){
  .subscription-confirmation-info {
    margin-top: 6px !important;
    margin-bottom: 6% !important;
  }
  .subscription-confirmation-main{
    width:100%;
    background:transparent;
  }
  .subscription-confirmation-container{
    width:90%;
    margin: 0 auto;
    background:transparent;
  }
  .subscription-confirmation-exists{
    font-size:24px;
    line-height:normal;
  }
  .confirmation-page-title {
    font-size: 41px;
    font-weight: 200;
    padding-top: 100px;
    padding-bottom: 20px;
    opacity: 0.7;
}
}
</style>