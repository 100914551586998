<template>
<div>
    <mds-layout-grid>
        <mds-row>
            <mds-col :cols="12">
                <div v-if="!this.$store.state.product.OmahaCustomer" class="customer-support-alert" tinted>
                    <mds-alert variation="error" size="small" :list="lstOmahaCustomerError" persistent>
                    </mds-alert>
                </div>
                <div class="customer-support-heading">
                    Product Support
                </div>
                <div style="margin-top: 9px;">
                    <div class="customer-support-product-body">
                        <div style="margin:21px 23px 21px 23px;">
                            <div class="customer-support-product-text">
                                Inquiries regarding your subscription such as address changes, missing/damaged issues, etc.
                            </div>

                            <div class="customer-support-subscription-heading">
                                Subscription Support
                            </div>
                            <div class="customer-support-product-text" style="margin-top: 2px;">
                                Phone: 1-800-957-6021 | Mon-Fri 8:30AM-5PM CST
                            </div>

                            <div class="customer-support-border"></div>

                            <div class="customer-support-product-text" style="margin-top: 17px;">
                                Inquiries regarding technical issues such as logging in or downloading
                            </div>

                            <div class="customer-support-technical-heading">
                                Technical Support
                            </div>

                            <div class="customer-support-product-text" style="margin-top: 2px;">
                                <div>Phone: 1-312-424-4288 | Mon-Fri 8AM-6PM CST </div>
                                <div>E-mail: <a class="customer-support-links" href="mailto:newslettersupport@morningstar.com">newslettersupport@morningstar.com</a></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="customer-support-heading" style="margin-top: 24px;">
                    Product Sales
                </div>

                <div style="margin-top: 9px;">
                    <div class="customer-support-product-sales-body">
                        <div style="margin:21px 23px 21px 23px;">
                            <div class="customer-support-product-text">
                                Inquiries regarding your subscription renewal, billing or to learn about other Morningstar investment publications and resources
                            </div>

                            <div class="customer-support-subscription-inquiries">
                                Subscription Inquiries
                            </div>
                            <div class="customer-support-product-text" style="margin-top: 2px;">
                                <div>Phone: 1-866-608-9570 | Mon-Fri 8AM-5PM CST </div>
                                <div>E-mail: <a class="customer-support-links" href="mailto:newslettersupport@morningstar.com">newslettersupport@morningstar.com</a></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="customer-support-heading" style="margin-top: 24px;">
                    Contact Your Editor
                </div>

                <div style="margin-top: 9px;">
                    <div class="customer-support-editor-body">
                        <div style="margin:21px 23px 21px 23px;">
                            <div class="customer-support-editor-heading">
                                FundInvestor Editor
                            </div>
                            <div class="customer-support-editor-email" style="margin-top: 2px;">
                                <a class="customer-support-links" href="mailto:russel.kinnel@morningstar.com">russel.kinnel@morningstar.com</a>
                            </div>

                            <div class="customer-support-editor-heading" style="margin-top: 24px;">
                                StockInvestor Editor
                            </div>
                            <div class="customer-support-editor-email" style="margin-top: 2px;">
                                <a class="customer-support-links" href="mailto:msi@morningstar.com">msi@morningstar.com</a>
                            </div>

                            <div class="customer-support-editor-heading" style="margin-top: 24px;">
                                DividendInvestor Editor
                            </div>
                            <div class="customer-support-editor-email" style="margin-top: 2px;">
                                <a class="customer-support-links" href="mailto:mdi@morningstar.com">mdi@morningstar.com</a>
                            </div>

                            <div class="customer-support-editor-heading" style="margin-top: 24px;">
                                ETFInvestor Editor
                            </div>
                            <div class="customer-support-editor-email" style="margin-top: 2px;">
                                <a class="customer-support-links" href="mailto:bryan.armour@morningstar.com">bryan.armour@morningstar.com</a>
                            </div>

                        </div>
                    </div>
                </div>
            </mds-col>
        </mds-row>
    </mds-layout-grid>
</div>
</template>

<script>
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import MdsAlert from '@mds/alert';
export default {
    name: "customer-support",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsAlert
    },
    data(){
        return{
            lstOmahaCustomerError:[]
        }
    },
    mounted(){
        this.showErrorCustomerMsg();
    },
    methods:{
        showErrorCustomerMsg() {
            this.lstOmahaCustomerError = [];
            this.lstOmahaCustomerError.push("Your subscription is not valid. Please call us to know more.");
            //this.OmahaCustomer = true;
        },
    }
}
</script>

<style scoped>
body,
body * {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

.customer-support-heading {
    color: var(--text-primary, #1E1E1E);
    /* Heading/Level 7: Light */
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
}

.customer-support-product-body {
    border-radius: 6px;
    border: 1px solid #CCC;
    background: var(--background-primary, #FFF);
    width: 100%;
   /* height: 273px;*/
}

.customer-support-product-sales-body {
    border-radius: 6px;
    border: 1px solid #CCC;
    background: var(--background-primary, #FFF);
    width: 100%;
  /*  height: 167px;*/
}

.customer-support-editor-body {
    border-radius: 6px;
    border: 1px solid #CCC;
    background: var(--background-primary, #FFF);
    width: 100%;
   /* height: 286px;*/
}

.customer-support-product-text {
    color: var(--text-primary, #1E1E1E);
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
}

.customer-support-subscription-heading {
    color: var(--text-primary, #1E1E1E);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 20px;
}

.customer-support-border {
    border: 1px solid #E5E5E5;
    margin-top: 22px;
}

.customer-support-technical-heading {
    color: var(--text-primary, #1E1E1E);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 11px;
}

.customer-support-subscription-inquiries {
    color: var(--text-primary, #1E1E1E);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 15px;
}

.customer-support-editor-heading {
    color: var(--text-primary, #1E1E1E);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}

.customer-support-editor-email {
    color: var(--text-primary, #1E1E1E);
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    text-decoration-line: underline;
}
.customer-support-alert {
    width: 100%;
    margin-top: 9px;
    margin-bottom: 24px;
}
.customer-support-links{
    color: #000000 !important;
   
}
</style>
<style>
.customer-support-alert .mds-alert .mds-alert__list li::before {
   content: none;
}
.customer-support-links{
    color: #000000 !important;
   
}
</style>
