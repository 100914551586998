<template>
    <div class="glossary-info">
      <diV class="glossary-page-title">FundInvestor Glossary</diV>
      <diV class="glossary-page-sub-title">Glossary Terms</diV>
      <div>
        <mds-layout-grid>
                <mds-row>
                <mds-col :cols="12"  :cols-at-m="3" :cols-at-s="3" :cols-at-l="3" :cols-at-xl="3"  class="glossary-section-left-collapse">
                  <mds-list-group class="glossary-section-list-grp-left-collapse">
                    <mds-list-group-item class="glossary-leftpanel-style" v-for="(glossaryList,index) in glossaryLists" :key="index"  @click.native="renderGlossaryData(index)" :active="glossaryList.isActive">
                      <div v-html="glossaryList.title"></div>
                    </mds-list-group-item>
                  </mds-list-group>
                </mds-col>
                <mds-col :cols="12" :cols-at-m="12"  :cols-at-s="12" class="responsive-glossary-section-left-collapse">
                 <mds-button id="glossary-btn-trigger" class="glossary-terms-btn"  @click="glossaryPopoverToggle=!glossaryPopoverToggle" size="large" variation="secondary" icon-right="caret-down">Glossary Terms</mds-button>
                  <mds-popover class="glossary-list-popover"  :position="['bottom-right']" triggered-by="glossary-btn-trigger" v-model="glossaryPopoverToggle">
                    <mds-list-group class="glossary-section-list-grp-left-collapse">
                    <mds-list-group-item class="glossary-leftpanel-style" v-for="(glossaryList,index) in glossaryLists" :key="index"  @click.native="renderGlossaryData(index)" :active="glossaryList.isActive">
                      <div v-html="glossaryList.title"></div>
                    </mds-list-group-item>
                  </mds-list-group>  
                  </mds-popover>
                </mds-col>
                <mds-col :cols="12"  :cols-at-m="9" :cols-at-s="9" :cols-at-l="9" :cols-at-xl="9">
                  <div class="glossary-data-container">
                    <p class="glossary-title">{{ glossaryLists[currentGlossaryIndex].title }}</p>
                    <mds-section border="tertiary"></mds-section>
                    <div class="glossary-content1" v-html="glossaryLists[currentGlossaryIndex]['sub-content-1']"></div>
                    <div class="glossary-content2" v-html="glossaryLists[currentGlossaryIndex]['sub-content-2']"></div>
                    <div class="glossary-content3" v-html="glossaryLists[currentGlossaryIndex]['sub-content-3']"></div>  
                    <mds-section v-if="glossaryLists[currentGlossaryIndex]['sub-content-5']!=null" style="padding-bottom: 1px;" border="tertiary" ></mds-section>
                    <p  class="glossary-content-sub-content-5" v-html="glossaryLists[currentGlossaryIndex]['sub-content-5']"></p>
                    <p  class="glossary-content" v-html="glossaryLists[currentGlossaryIndex]['sub-content-6']"></p>
                  </div>
                </mds-col>
        </mds-row>
        </mds-layout-grid>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { MdsListGroup, MdsListGroupItem } from '@mds/list-group'
  import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid'
  import MdsSection from '@mds/section'
  import { MdsButton } from '@mds/button'
  import MdsPopover from "@mds/popover"
    export default  {
      name: "glossary",
      props: [],
      components:{
        MdsListGroup, 
        MdsListGroupItem,
        MdsLayoutGrid, 
        MdsRow, 
        MdsCol,
        MdsSection,
        MdsButton,
        MdsPopover
      },
      mounted(){
           window.scrollTo(0,0);
      },
      data () {
        return {
          glossaryLists:[
            {
              "title":"Alpha",
              "sub-content-1" :  "<p>A measure of the difference between a fund's actual returns and its expected performance, given its level of risk as measured by beta.</p><br/>"
                        + "<p> A positive alpha figure indicates the fund has performed better than its beta would predict. In contrast, a negative alpha indicates the fund's underperformance, given the expectations established by the fund's beta.</p><br/>"
                        + "<p> All MPT statistics (alpha, beta, and R-squared) are based on a least-squares regression of the fund's return over Treasury bills (called excess return) and the excess returns of the fund's benchmark index.</p><br/>"
                        + "<p> Alpha can be used to directly measure the value added or subtracted by a fund's manager.</p><br/>",
              "sub-content-2":  "<p>Alpha depends on two factors:</p><br/>",
              "sub-content-3":  "<ol class=\"custom-ol\"><li>&nbsp;&nbsp;&nbsp;The assumption that market risk, as measured by beta, is the only risk measure necessary.</li>"
                        +"<li>&nbsp;&nbsp;&nbsp;The strength of the linear relationship between the fund and the index, as it has been measured by R-squared.</li></ol><br/>"
                        +"<p style=\"margin-top:-17px;margin-left:18px;\">In addition, a negative alpha can sometimes result from the expenses that are present in a fund's returns, but not in the returns of the comparison index.</p><br/>",
             
               "sub-content-5"  : "<p>Example </p><br/>",
               "sub-content-6"  : "<p>A fund has an alpha of 0.86, a beta of 0.96 and an R-squared of 97. The high R-squared lends further credibility to the accuracy of the fund's alpha and beta. The alpha of 0.86 indicates that the fund produced a return 0.86% higher than its beta would predict.</p><br/>",
                          "isActive":true,
            },
            {
              "title":"Average Market Capitalization",
              "sub-content-1" : "<p>The average market capitalization of a fund's equity portfolio gives you a measure of the size of the companies in which the fund invests.</p><br/>"
                        + "<p>Market capitalization is calculated by multiplying the number of a company's shares outstanding by its price per share.</p><br/>"
                        + "<p>At Morningstar we calculate this figure by taking the geometric mean of the market capitalizations of the stocks a fund owns.</p><br/>",
              "isActive":false,
            },
            {
              "title":"Category",
              "sub-content-1" : "<p>This is a proprietary Morningstar data point.</p><br/>"
              +"<p>While the investment objective stated in a fund's prospectus may or may not reflect how the fund actually invests, the Morningstar category is assigned based on the underlying securities in each portfolio.</p><br/>"
              +"<p>Morningstar categories help investors and investment professionals make meaningful comparisons between funds. The categories make it easier to build well-diversified portfolios, assess potential risk, and identify top-performing funds. Morningstar places funds in a given category based on their portfolio statistics and compositions over the past three years.</p><br/>"
              +"<p>If the fund is new and has no portfolio history, Morningstar estimates where it will fall before giving it a more permanent category assignment. When necessary, Morningstar may change a category assignment based on recent changes to the portfolio.</p><br/>",
              "isActive":false,
            },
            {
              "title":"Duration",
              "sub-content-1" :"<p>Duration is a time measure of a bond's interest-rate sensitivity, based on the weighted average of the time periods over which a bond's cash flows accrue to the bondholder.</p><br/>"
                        +"<p>Time periods are weighted by multiplying by the present value of its cash flow divided by the bond's price. (A bond's cash flows consist of coupon payments and repayment of capital.)</p><br/>"
                        +"<p>A bond's duration will almost always be shorter than its maturity, with the exception of zero-coupon bonds, for which maturity and duration are equal.</p>",
              "isActive":false,
            },
            {
              "title":"Morningstar Risk",
              "sub-content-1" : "<p>This is a proprietary Morningstar data point.</p><br/>"
                        +"<p>An assessment of the variations in a fund's monthly returns, with an emphasis on downside variations, in comparison to similar funds.</p><br/>"
                        +"<p>In each Morningstar Category, the 10% of funds with the lowest measured risk are described as Low Risk, the next 22.5% Below Average, the middle 35% Average, the next 22.5% Above Average, and the top 10% High. Morningstar Risk is measured for up to three time periods (three, five, and 10 years). These separate measures are then weighted and averaged to produce an overall measure for the fund. Funds with less than three years of performance history are not rated.</p>",
              "isActive":false,
            },
            {
              "title":"Net Asset Value",
              "sub-content-1" : "<p>This figure is recorded in millions of dollars and represents the fund's total asset base, net of fees and expenses.</p><br/>",
                      "isActive":false,
            },
            {
              "title":"R-Squared",
              "sub-content-1" : "<p>R-Squared is the measure of correlation between a fund and the market (benchmark). It is calculated by regressing the fund against an appropriate index over time. Values range between 0 and 1. The higher the value of R-Square, the greater the correlation between the two. R-Squared is calculated over the last 36 months. Canadian Equity fund R-Squared is typically calculated relative to members of the S&P/TSX Composite family of indexes, Canadian Bond fund R-Squared is typically calculated relative to the TSX DEX Universe Bond Index, and US Equity fund R-Squared is typically calculated relative to the S&P 500 Index.</p><br/>"
                        +"<p>An R-squared of 1 means that all movements of a fund are completely explained by movements in the index. Thus, index funds that invest only in S&P/TSX Composite Index stocks will have an R-squared very close to 1. Conversely, a low R-squared indicates that very few of the fund's movements are explained by movements in its benchmark index.</p><br/><br/>",
               "sub-content-5": "<p>Example </p><br/>",
               "sub-content-6": "<p>An R-squared measure of 0.35, means that only 35% of the fund's movements can be explained by movements in its benchmark index. Therefore, R-squared can be used to ascertain the significance of a particular beta or alpha. Generally, a higher R-squared will indicate a more useful beta figure. If the R-squared is lower, then the beta is less relevant to the fund's performance.</p><br/>",
              "isActive":false,
            },
            {
              "title":"Standard Deviation",
              "sub-content-1" :  "<p>This statistical measurement of dispersion about an average, depicts how widely a mutual fund's returns varied over a certain period of time. Investors use the standard deviation of historical performance to try to predict the range of returns that are most likely for a given fund. When a fund has a high standard deviation, the predicted range of performance is wide, implying greater volatility.</p><br/>"
                        +"<p>Standard deviation is most appropriate for measuring the risk a fund that is an investor's only holding. The figure cannot be combined for more than one fund because the standard deviation for a portfolio of multiple funds is a function of not only the individual standard deviations, but also of the degree of correlation among the funds' returns.</p><br/>"
                        +"<p>If a fund's returns follow a normal distribution, then approximately 68% of the time they will fall within one standard deviation of the mean return for the fund, and 95% of the time within two standard deviations.</p><br/><br/>",
              "sub-content-5" : "<p>Example </p><br/>",
              "sub-content-6"  : "<p>For a fund with a mean annual return of 10% and a standard deviation of 2%, you would expect the return to be between 8% and 12% about 68% of the time, and between 6% and 14% about 95% of the time.</p><br/>"
                        +"<p>At Morningstar, the standard deviation is computed using the trailing monthly total returns for the appropriate time period. All of the monthly standard deviations are then annualized. Standard deviation is also a component in the Sharpe Ratio, which assesses risk-adjusted performance.</p><br/>"
                        +"<p>Additionally, if Fund A has a standard deviation of 23.56%. This means that approximately 68% of the time, Fund A will be within 23.56% of its mean of 25.33.</p><br/>",
              "isActive":false,
            },
            {
              "title":"Style Box",
              "sub-content-1" : "<p>This is a proprietary Morningstar data point. The Morningstar Style Box is a nine-square grid that provides a graphical representation of the \"investment style\" of stocks and mutual funds. For stocks and stock funds, it classifies securities according to market capitalization (the vertical axis) and growth and value factors (the horizontal axis). Fixed income funds are classified according to credit quality (the vertical axis) and sensitivity to changes in interest rates (the horizontal axis).</p><br/>"
                       +"<p>By providing an easy-to-understand visual representation of stock and fund characteristics, the Morningstar Style Box allows for informed comparisons and portfolio construction based on actual holdings, as opposed to assumptions based on a fund's name or how it is marketed. The Style Box also forms the basis for Morningstar's style-based fund categories and market indexes.</p>",
              "isActive":false,
            },
            {
              "title":"Tax Cost Ratio",
              "sub-content-1" : "<p>The Morningstar Tax Cost Ratio measures how much a fund's annualized return is reduced by the taxes investors pay on distributions. Mutual funds regularly distribute stock dividends, bond dividends and capital gains to their shareholders. Investors then must pay taxes on those distributions during the year they were received.</p><br/>"
                        +"<p>Like an expense ratio, the tax cost ratio is a measure of how one factor can negatively impact performance. Also like an expense ratio, it is usually concentrated in the range of 0-5%. 0% indicates that the fund had no taxable distributions and 5% indicates that the fund was less tax efficient.</p><br/><br/>",
              "sub-content-5" : "<p>Example </p><br/>",
              "sub-content-6" : "<p>If a fund had a 2% tax cost ratio for the three-year time period, it means that on average each year, investors in that fund lost 2% of their assets to taxes. If the fund had a three-year annualized pre-tax return of 10%, an investor in the fund took home about 8% on an after-tax basis. (Because the returns are compounded, the after-tax return is actually 7.8%.)</p><br/>",
              "isActive":false,
            },
            {
              "title":"Total Return",
              "sub-content-1" : "<p>All references to total return represent a fund's gains over a specified period of time. Total return includes both income (in the form of dividends or interest payments) and capital gains or losses (the increase or decrease in the value of a security). Morningstar calculates total return by taking the change in a fund's NAV, assuming the reinvestment of all income and capital gains distributions (on the actual reinvestment date used by the fund) during the period, and then dividing by the initial NAV.</p><br/>"
                        +"<p>Unless marked as load-adjusted total returns, Morningstar does not adjust total return for sales charges or for redemption fees. (Morningstar Return and Morningstar Risk-Adjusted Ratings do incorporate those fees.) Total returns do account for management, administrative, and 12b-1 fees and other costs automatically deducted from fund assets. The quarterly returns, listed in the Performance section, express the fund's return for each individual quarter; the total shown on the right is the compounded return for the four quarters of that year. An asterisk next to the total return number indicates that the return is calculated for a partial quarter or partial year because the fund began operations during that time period.</p>",
              "isActive":false,
            },
            {
              "title":"Turnover Ratio",
              "sub-content-1" : "<p>A turnover ratio of 100% or more does not necessarily suggest that all securities in the portfolio have been traded. In practical terms, the resulting percentage loosely represents the percentage of the portfolio's holdings that have changed over the past year.</p><br/>"
                        +"<p>A low turnover figure (20% to 30%) would indicate a buy-and-hold strategy. High turnover (more than 100%) would indicate an investment strategy involving considerable buying and selling of securities. Morningstar does not calculate turnover ratios. The figure is culled directly from the financial highlights of the fund's annual report.</p>",
              "isActive":false,
            }
          ],
          currentGlossaryIndex:0,
          glossaryPopoverToggle:false,
        }
      },
      methods: {
        renderGlossaryData(i){
          this.currentGlossaryIndex = i;
          this.activeGlossaryTab(i)
         
        },
        activeGlossaryTab(i){
          this.glossaryLists.map((p) => (p.isActive = false));
          this.glossaryLists[i].isActive = true;
          if ( this.glossaryLists[i].isActive == true)
          this.glossaryPopoverToggle=false;
        }
      }
  }
  </script>
  
  <style scoped>

.glossary-section-left-collapse{
 display: block;
}
.responsive-glossary-section-left-collapse{
    display: none;
  }

  .glossary-info{
    max-width: 1280px;
    margin:0 auto;
    /* margin:0 auto; */
    margin-bottom: 63px;
  }

  .glossary-data-container{
    width: 76%;
    /* max-width: 625px; */
    margin-top: -30px;
    margin-left: 75px;
    margin-bottom: 63px;
  }
  .glossary-title{
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom:10px;
  }

  .glossary-page-title{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin-top: 18px;
  }
  .glossary-page-sub-title{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 131.25% */
  margin-top: 46px;
  }

  .glossary-content{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  .glossary-content1{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    /* margin-top: 9px; */
  }
  .glossary-content2{
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  .glossary-content3{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -20px;
    margin-top: -20px;
  }
  .glossary-content-sub-content-5{
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    font-style: normal;
    text-align: left;
    margin-top: -19px;
  }
  
  .glossary-section-list-grp-left-collapse{
    display: block;
    /* margin-left: 10px; */
  }
  .glossary-terms-btn{
display: none;
  }


  @media only screen and (min-width:1222px) and (max-width:1355px)
  {
    .glossary-info{
    width: 90%;
    margin:0 auto;
  }
  }
  @media only screen and (min-width:971px) and (max-width:1309px)
  {
    .glossary-page-title{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin-top: 18px;
  }

    .glossary-info{
    width: 90%;
    margin:0 auto;
  }
  }

  @media only screen and (min-width:150px) and (max-width:600px)
  {
    .glossary-page-title{
  display: none;
  }
  .glossary-page-sub-title{
  display: none;
  }

  .glossary-content-sub-content-5{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    font-style: normal;
    text-align: left;
    margin-top: -15px;
  }
  .glossary-terms-btn{
    /* left: 16px;
    padding: 8px 24px 8px 24px;
    border-radius: 24px;
    gap: 8px;
    margin-bottom: 25px; */
    display: flex;
width: 196px;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 24px;
border: 1px solid var(--border-button, #808080);
margin-left: 16px;
margin-top: 14px;
  }   
  .glossary-section-left-collapse{
    display: none;
  }
 
    .glossary-info{
    width: 90%;
    margin:0 auto;
    /* margin:0 auto; */
  }
  .responsive-glossary-section-left-collapse{
    display: block;
    margin-left: -20px;
    margin-top: 10px;
  }
  .glossary-data-container{
    margin-top: 25px;
    margin-left: 0px;
    margin-right: 0px;
    width: 106%;
    /* width: 1200px; */
  }

  .glossary-content{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  .glossary-content1{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-top:20px;
  }
  .glossary-content3{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -20px;
    margin-top: -20px;
  }
  .glossary-content2{
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  }
  @media only screen and (min-width:601px) and (max-width:1000px)
  {
    .glossary-page-title{
  display: none;
  }
  .glossary-page-sub-title{
  display: none;
  }

  .glossary-terms-btn{
    /* left: 16px;
    padding: 8px 24px 8px 24px;
    border-radius: 24px;
    gap: 8px;
    margin-bottom: 25px; */
    display: flex;
width: 196px;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 24px;
border: 1px solid var(--border-button, #808080);
margin-left: 16px;
margin-top: 14px;
  }   
  .glossary-section-left-collapse{
    display: none;
  }
 
    .glossary-info{
    width: 90%;
    margin:0 auto;
    /* margin:0 auto; */
  }
  .responsive-glossary-section-left-collapse{
    display: block;
    margin-left: -20px;
    margin-top: 10px;
  }
  .glossary-data-container{
    margin-top: 25px;
    margin-left: 0px;
    margin-right: 0px;
    width: 139%;
    /* width: 1200px; */
  }

  .glossary-content1{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-top:20px;
  }
  .glossary-content-sub-content-5{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    font-style: normal;
    text-align: left;
    margin-top: -15px;
  }
  }
  </style>
  <style>
    .glossary-content > ol{
    margin-left:20px;
  }
 
.glossary-leftpanel-style span:focus {
    box-shadow: none !important;
    line-height:29px;
    /* outline: none !important; */
}
.custom-ol {
  list-style-type: none; 
  counter-reset: itemCounter; 
}
  /* Apply counter-increment only to the li:before of the first item */
  .custom-ol li:before {
    content: counter(itemCounter) " "; /* Display the counter and a period */
  counter-increment: itemCounter; /* Increment the counter for all items */

}


</style>
  