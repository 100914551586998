<template>
    <div>
        <mds-button v-if="this.$store.state.user.LoggedIn" variation="icon-only" @click="openUserProfile = !openUserProfile" icon="person" type="button" class="profile-person-icon" id="profile-icon-trigger">
        </mds-button>
        <mds-button v-if="!LoggedIn && !isMobileHeaderToggle" variation="secondary" @click="login()" icon="person" type="button" class="profile-sign-in">
            Sign In</mds-button>
        <mds-button v-if="!LoggedIn && isMobileHeaderToggle" variation="flat" @click="login()" icon="person" type="button" size="medium" class="profile-sign-in-responsive">
            Sign In</mds-button>
        <mds-popover triggered-by="profile-icon-trigger" v-model="openUserProfile" :position="['bottom-left', 'bottom-center']" width="200px" class="Account-popover">
            <mds-list-group size="medium" withIcon rightAligned>
                <mds-list-group-item @click.native="openMyAccount">
                    <span>Account</span>
                </mds-list-group-item>
                <mds-list-group-item to="/logout" text="Logout"></mds-list-group-item>
            </mds-list-group>
        </mds-popover>
    </div>
    </template>
    
    <script>
    import MdsPopover from '@mds/popover';
    import {
        MdsListGroup,
        MdsListGroupItem
    } from '@mds/list-group';
    import {
        MdsButton
    } from '@mds/button';
    import { mapState } from "vuex";
    
    export default {
        name: "user-profile",
        components: {
            MdsPopover,
            MdsListGroup,
            MdsListGroupItem,
            MdsButton
        },
        data() {
            return {
                openUserProfile: false,
                LoggedIn :false,
                isMobileHeaderToggle: null,
                viewPortWidth: null,
                currentProduct: this.$session.get("current-product")                
            };
        },
        methods: {
            login() {
                this.$auth0.loginWithRedirect();
            },            
            openMyAccount() {
            if (this.currentProduct) {
                this.$router.push({
                    name: 'myAccount',
                    params: {
                        currentproduct: this.currentProduct.toLowerCase()
                    }
                });
                this.openUserProfile = false;
            }
        }
          
        },
        mounted() {
            this.isMobileHeaderToggle=this.$store.state.responsive.isMobileHeader;
            this.LoggedIn = this.$auth0.isAuthenticated && this.$session.exists();
            this.$store.state.user.LoggedIn = this.LoggedIn;        
        },
    
        computed:mapState({
    isMobileHeader: state => state.responsive.isMobileHeader,
      isMobileHeaderAlias:'isMobileHeader',
      isMobileHeaderState(state){
      return state.responsive.isMobileHeader
    }

  }),

  watch:{
    isMobileHeaderState(){
      this.isMobileHeaderToggle = this.isMobileHeaderState;
    }
  }
    };
    </script>
    
    <style scoped>
    .profile-person-icon {
      position: absolute;
      right: 1.4000%;
      cursor: pointer;
      float:right;
    }
    
    .profile-sign-in {
        float: right;
        right: 20px;
        bottom: 7px;
    }
    
    @media only screen and (min-width: 0px) and (max-width: 480px) {
    
        .profile-person-icon {
            position: absolute;
            right: 0px;
             top: 11px; 
            cursor: pointer;
           
        }
    }
    
    
    
 
    
    @media only screen and (min-width: 481px) and (max-width: 701px) {
    
        .profile-sign-in {
        float: right;
        right: 0px;
        bottom: 7px;
    }
    .mds-button {
    margin-right: 0px;
    height: 35px;
    font-weight: 300;
    font-size: 80%;
    line-height: 100%;
    color: #1e1e1e;
    padding-left: 2.5%;
    padding-right: 0%;
    }
    
    .profile-person-icon {
            position: absolute;
            right: 17px;
            top: 7px; 
            cursor: pointer;
           
        }
    }
    
    @media only screen and (min-width: 701px) and (max-width: 1000px) {
    
    .profile-sign-in {
    float: right;
    right: 3px;
    bottom: 7px;
    }
    .mds-button {
    
    height: 30px;
    font-weight: 300;
    font-size: 15px;
    line-height: 100%;
    color: #1e1e1e;
    padding-left: 1.5%;
    padding-right: 0%;
    }
    .profile-person-icon {
            position: absolute;
            right: 20px;
            top: 9.5px; 
            cursor: pointer;
           
        }
    }
    
    
    @media only screen and (min-width: 1000px) and (max-width: 1200px) {
    
    .profile-sign-in {
    float: right;
    right: 4px;
    bottom: 7px;
    }
    .mds-button {
    width: 9%;
    height: 30px;
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;
    color: #1e1e1e;
    padding-left: 1.5%;
    padding-right: 0%;
    }
    .profile-person-icon {
            position: absolute;
            right: -10px;
            /* top: 11px; */
            cursor: pointer;
           
        }
    }
    
    @media only screen and (min-width: 1800px) and (max-width: 2200px) {
        .profile-sign-in {
        float: right;
        right: 20px;
        bottom: 7px;
    }
    
    
    }
    
    
    
    
    @media only screen and (max-width: 480px) {
    .user-main{
    margin-top: 500px;
    }
    
    .profile-person-icon {
            position: absolute;
            right: 15px;
            /* top: 11px; */
            cursor: pointer;
           
            top: 13px; 
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 480px) {
    
    .profile-sign-in-responsive{
        position: absolute;
        top: 9px;
        right: 15px;
    }

}

@media only screen and (min-width: 480px) and (max-width: 1000px) {
    
    .profile-sign-in-responsive{
        position: absolute;
        top: 7px;
        right: 15px;
    }

}

    
    .Account-popover{
        margin-top: 5px;
    }
    </style>
    