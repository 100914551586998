<template>
  <div >
  <!--Responsive Component-->
  <div v-if="isMobileContentToggle">
<div class="mfi-search-bar-anddescription-div-responsive">
  <div class="mfi-fund-selector-title-div-responsive">
              <h1 class="mfi-fund-selector-title-responsive">
                Does your fund pass our FundInvestor Selector Test?
              </h1>
            </div>
            <div class="mfi-search-and-button-responsive">
              <div class="mfi-Serach-field-responsive">
                <mds-search-field placeholder="Enter a ticker" label="Search" size="large"
                  v-model.trim="modelSearchField" :error="errormessage" oninput="this.value = this.value.toUpperCase()"
                  @keydown.enter="submit()" maxlength="5"></mds-search-field>
              </div>
              <div class="mfi-fund-selector-submit-button-responsive">
                <mds-button class="mfi-button-responsive" size="large" on-dark variation="secondary" @click="submit()">
                  Get Results
                </mds-button>
              </div>
            </div>
            <div v-if="errormessage" class="mfi-fund-spy-error-message">
              <span>{{ this.errormessageText }}</span>
            </div>
            <div class="mfi-fund-selector-description-div">
              <h1 class="mfi-fund-selector-description-title">
                FundInvestor Selector Pass/Fail Thresholds
              </h1>
              <p class="mfi-fund-selector-description-responsive">
                A mutual fund passes the FundInvestor Selector test if it meets
                all the requirements listed. If it receives a failing grade on
                any of the criteria, that fund fails the overall test. Index
                funds, however, are only judged on Expense Ratio and Risk. Only
                a handful of funds in each Morningstar Category pass the test,
                so don't give up on your fund if it fails. It might still have
                enough going for it to be worth owning, but you should investigate 
                further to be sure you're comfortable with why it failed the test.
              </p>
            </div>
            <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
            <div class="mfi-fund-info-card-responsive" v-if="rightSideCardDiv">
            <mds-card title="" class="mfi-card-css-responsive">
              <template #mds-card-action>
                <div class="mfi-fund-info-under-card">
                  <div class="mfi-card-title-ticker-rating-responsive">
                    
                    <div>
                      <h1 class="mfi-card-title-text-responsive" v-html="FundName"></h1>
                    </div>

                    <div class="ticker-and-star-responsive">
                    <div>
                      <p class="mfi-manager-data-ticker-responsive-one" v-html="FundTicker"></p>
                    </div>
                  
                    <div>
                      <p class="mfi-morningstar-rating-pragraph-responsive" :v-html="MorningstarRating">
                        <mds-star-rating :rating="parseInt(MorningstarRating)" :custom-size="18"></mds-star-rating>
                      </p>
                    </div>
                  </div>
                  </div>
                  <h1 class="mfi-title-ticker-text-responsive" v-if="Category == 'Allocation--50% to 70% Equity'">
                    Moderate Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text-responsive" v-else-if="Category == 'Allocation--15% to 30% Equity'">
                    Conservative Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text-responsive" v-else-if="Category == 'Allocation--30% to 50% Equity'">
                    Moderately Conservative Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text-responsive" v-else-if="Category == 'Allocation--70% to 85% Equity'">
                    Moderately Aggressive Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text-responsive" v-else-if="Category == 'Allocation--85%+ Equity'">
                    Aggressive Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text-responsive" v-else v-html="Category"></h1>              
    <mds-table class="mfi-card-table-css">
      <mds-tbody>   
        <mds-tr>
            <mds-td class="first-td-expense-ratio" style="width: 157px;"> 
              <h1  class="mfi-card-table-points-title-responsive">Data Point</h1>
               <h1 class="mfi-data-points-name-text-responsive">
                        Expense Ratio<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ExpenseRationModal()"></mds-icon>
                      </h1>
                    </mds-td>
            <mds-td class="second-td-expense-ratio" style="width: 85px;"> 
              <h1  class="mfi-card-table-points-title-responsive">Value</h1>
              <h1 class="mfi-value-points-data" v-html="ExpenseRatio"></h1>
            </mds-td>
            <mds-td right-aligned> 
              <h1  class="mfi-card-table-points-title-responsive-score">Score</h1>
                     <mds-inline-message v-if="ExpenseRatio_Test == 'Pass'" class="mfi-inline-message" size="small"
                       variation="success" tinted>
                       Pass
                     </mds-inline-message>

                     <mds-inline-message v-if="ExpenseRatio_Test == 'Fail'" class="mfi-inline-message" size="small"
                       variation="error" tinted>
                       Fail
                     </mds-inline-message>                    
            </mds-td>
        </mds-tr>
        <mds-tr>
            <mds-td> 
              <h1 class="mfi-data-points-name-text-responsive">
                        Risk<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="RiskModal()"></mds-icon>
                      </h1>  
            </mds-td>
            <mds-td> 
              <h1 class="mfi-value-points-data" v-html="Risk"></h1>  
            </mds-td>
            <mds-td right-aligned> 
              <mds-inline-message v-if="Risk_Test == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="Risk_Test == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail
                      </mds-inline-message>
            </mds-td>
        </mds-tr>
        <mds-tr>
            <mds-td> 
              <h1 class="mfi-data-points-name-text-responsive">
                        Manager Investment<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ManagerInvestment()"></mds-icon>
                      </h1>  
            </mds-td>
            <mds-td> 
              <h1 class="mfi-value-points-data" v-html="ManagerRange"></h1>  
            </mds-td>
            <mds-td right-aligned>
              <mds-inline-message v-if="(ManagerRange == '$100K-$500K') |
                        (ManagerRange == 'More Than $1Mil') |
                        (ManagerRange == '$500K-$1Mil')
                        " class="mfi-inline-message" size="small" variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="(ManagerRange == 'None') | 
                      (ManagerRange == 'N/A') |
                        (ManagerRange == '$50K-$100K')
                        " class="mfi-inline-message" size="small" variation="error" tinted>
                        Fail
                      </mds-inline-message>
            </mds-td>
        </mds-tr>
        <mds-tr>
            <mds-td> 
              <h1 class="mfi-data-points-name-text-responsive">
                        People Pillar<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="PeoplePillar()"></mds-icon>
                      </h1>  
            </mds-td>
            <mds-td> 
              <h1 class="mfi-value-points-data" v-html="People"></h1>
            </mds-td>
            <mds-td right-aligned> 
              <mds-inline-message v-if="People_PillarTest == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="People_PillarTest == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail
                      </mds-inline-message>
            </mds-td>
        </mds-tr>
        <mds-tr>
            <mds-td> 
              <h1 class="mfi-data-points-name-text-responsive">
                        Parent Pillar<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ParentPillar()"></mds-icon>
                      </h1>  
            </mds-td>
            <mds-td> 
              <h1 class="mfi-value-points-data" v-html="Parent"></h1>  
            </mds-td>
            <mds-td right-aligned> 
              <mds-inline-message v-if="Parent_PillarTest == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="Parent_PillarTest == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail
                      </mds-inline-message>
            </mds-td>
        </mds-tr>

        <mds-tr>
            <mds-td> 
              <h1 class="mfi-data-points-name-text-responsive">
                        Manager Returns<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ManagerReturns()"></mds-icon>
                      </h1>
            </mds-td>
            <mds-td> </mds-td>
            <mds-td right-aligned>
              <mds-inline-message v-if="ManagerPerformance_Test == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>Pass</mds-inline-message>
                      <mds-inline-message v-if="ManagerPerformance_Test == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail</mds-inline-message>
            </mds-td>
        </mds-tr>
    </mds-tbody>
</mds-table>
<div class="mfi-card-table-part-responsive">
                    <div class="mfi-card-data-point-responsive">
                      <div class="mfi-card-header-responsive">                     
                    </div>
                      <h1 class="mfi-manager-returns-one">Manager Tenure</h1>
                      <h1 class="mfi-manager-returns">Manager Start Date</h1>
                      <h1 class="mfi-manager-returns">Benchmark Return</h1>
                      <h1 class="mfi-manager-returns">Fund Return</h1>
                    </div>
                    <div class="mfi-card-value-point-responsive">                    
                      <br />
                      <br />
                      <p class="mfi-manager-values-one" v-html="Year -
                        ManagerStartDate.substring(
                          ManagerStartDate.indexOf('M') - 11,
                          ManagerStartDate.lastIndexOf('M') - 15
                        ) +
                        ' ' +
                        'Years'
                        "></p>
                      <h1 class="mfi-manager-values">
                        {{ ManagerStartDate | formatDateValForFundSpy }}
                      </h1>
                      <h1 class="mfi-manager-values" v-html="BenchmarkReturn"></h1>
                      <h1 class="mfi-manager-values" v-html="FundReturn"></h1>
                    </div>
                    
                  </div>
                  <h1 class="mfi-performance-text-responsive">
                    Performance Measure is from manager start date through
                    <span class="mfi-performance-text-responsive">{{ PreviousDayDate | formatDateValForFundSpy }}.</span>
                    Fund returns and benchmark returns are cumulative.
                  </h1>
                </div>
              </template>
            </mds-card>
          </div>
          <div class="mfi-loader-position-responsive">
          <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
        </div>
          <div class="mfi-threshold-below-div" v-if="leftdivMnagement">
              <h1 class="mfi-border-below-threshold"></h1>
              <div class="mfi-left-side-management-data-titles">
                <div>
                  <p class="mfi-manager-data-title-responsive" v-html="FundName"></p>
                </div>
                <div>
                  <p class="mfi-manager-data-ticker-responsive" v-html="FundTicker"></p>
                </div>
              </div>
              <h1 class="mfi-medalist-rating-title-responsive">
                Morningstar Medalist Rating<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                  @click.native.stop="MedalistRating()"></mds-icon>
              </h1>
              <div class="mfi-medalist-rating-div-leftside-responsive">
                <div>
                  <p :v-html="overall_rating" class="mfi-morningstar-medalist-rating-pragraph">
                    <mds-medalist-rating :rating="overall_rating" :custom-size="36"></mds-medalist-rating>
                  </p>
                </div>
                <div class="mfi-analyst-points-div-responsive">
                <div class="mfi-data-anad-driven-div-responsive">
                  <h1 class="mfi-driven-and-data-titles">
                    Analyst-Driven Proportion
                    <mds-icon class="mfi-info-icon" size="small" name="info-circle"
                      @click.native.stop="AnalystDriven()"></mds-icon>
                  </h1>
                  <h1 class="mfi-border-in-table-driven-and-data-responsive"></h1>
                  <h1 class="mfi-driven-and-data-titles">
                    % Data Coverage
                    <mds-icon class="mfi-info-icon" size="small" name="info-circle"
                      @click.native.stop="DataCoverage()"></mds-icon>
                  </h1>
                </div>
                <div class="mfi-rating-numerical-div">
                  <h1 class="mfi-analyst-driven-and-percentage-responsive" v-html="overall_rating_analyst_driven_percentage"></h1>
                  <h1 class="mfi-border-in-table-driven-responsive"></h1>
                  <h1 class="mfi-analyst-driven-and-percentage-responsive">{{ overall_data_availability_percentage | dataCoverageFilter }}
                  </h1>
                </div>
              </div>
              </div>
              <div class="mfi-management-team-final-div-responsive">
                <h1 class="mfi-title-for-management-team-responsive">Management Team</h1>
                <div :class="[this.manager.length > 3 ? blurreddivresponsive : '', noblurredresponsive]" v-if="!readMore">
                  <mds-table>
                  <mds-tbody>
                    <mds-tr v-for="(item, index) in manager" :key="index">
                      <mds-td class="mfi-list-for-management-team-responsive">
                        <div class="mfi-management-bar-and-data-responsive">
                          <div class="mfi-management-teams-names-responsive">
                            <div class="mfi-manager-name-div">
                            <p class="mfi-management-team-name-display">
                              {{
                                item.ManagerName
                              }}
                            </p>
                          </div>
                          <div class="middle-div-forname-and-date"></div>
                          <div class="mfi-manager-date-div">
                            <p class="mfi-management-team-date-display-responsive">
                              <span>{{
                                item.ManagerStartDate | formatDateVal
                              }}</span>
                              <span> - Present</span>
                            </p>
                          </div>
                          </div>
                          <div class="mfi-management-range-level-div-responsive">
                            <div class="mfi-title-level-bar-responsive">
                              Position in Investment
                            </div>
                            <span v-if="(item.ManagerInvestmentLevel == '7')">
                              <svg height="30" width="100%">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="83.4%" y1="5" x2="88%"
                                y2="5" stroke-linecap="butt"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="98%" y1="5" x2="86%"
                                y2="5" stroke-linecap="round"></line>

                                    <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '6'">
                              <svg height="30" width="100%">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="66.5%" y1="5" x2="83.2%"
                                    y2="5" stroke-linecap="butt"></line>
                                    <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '5'">
                              <svg height="30" width="100%">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="66.5%" y1="5" x2="50%"
                                y2="5" stroke-linecap="butt"></line>
                                    <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '4'">
                              <svg height="30" width="100%">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                    stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="33.5%" y1="5" x2="50%"
                                    y2="5" stroke-linecap="butt"></line>
                                    <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '3'">
                              <svg height="30" width="100%">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                    stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="16.9%" y1="5" x2="33.4%"
                                    y2="5" stroke-linecap="butt"></line>
                                    <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '2'">
                              <svg height="30" width="100%">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="1.8%" y1="5" x2="98%" y2="5" stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="1.8%" y1="5" x2="10.4%" y2="5" stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="10%" y1="5" x2="16.5%" y2="5" stroke-linecap="butt"></line>
                                  <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="(item.ManagerInvestmentLevel == '1')">
                              <svg height="30" width="100%">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                    stroke-linecap="round"></line>
                                  <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else>
                              N/A
                            </span>
                          </div>
                        </div>
                      </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
                </div>
                <div class="mfi-team-management-linear-div1" v-if="readMore">
                  <mds-table>
                  <mds-tbody>
                    <mds-tr v-for="(item, index) in manager" :key="index">
                      <mds-td class="mfi-list-for-management-team-responsive">
                        <div class="mfi-management-bar-and-data-responsive">
                          <div class="mfi-management-teams-names-responsive">
                            <div class="mfi-manager-name-div">
                            <p class="mfi-management-team-name-display">
                              {{
                                item.ManagerName
                              }}
                            </p>
                          </div>
                          <div class="middle-div-forname-and-date"></div>
                          <div class="mfi-manager-date-div">
                            <p class="mfi-management-team-date-display-responsive">
                              <span>{{
                                item.ManagerStartDate | formatDateVal
                              }}</span>
                              <span> - Present</span>
                            </p>
                          </div>
                          </div>
                          <div class="mfi-management-range-level-div-responsive">
                            <div class="mfi-title-level-bar-responsive">
                              Position in Investment
                            </div>
                        <span v-if="item.ManagerInvestmentLevel == '7'">
                            <svg height="30" width="100%">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="83.4%" y1="5" x2="88%"
                                y2="5" stroke-linecap="butt"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="98%" y1="5" x2="86%"
                                y2="5" stroke-linecap="round"></line>

                                <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '6'">
                          <svg height="30" width="100%">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="66.5%" y1="5" x2="83.2%"
                                y2="5" stroke-linecap="butt"></line>
                                <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '5'">
                          <svg height="30" width="100%">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="66.5%" y1="5" x2="50%"
                                y2="5" stroke-linecap="butt"></line>
                                <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '4'">
                          <svg height="30" width="100%">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="33.5%" y1="5" x2="50%"
                                y2="5" stroke-linecap="butt"></line>
                                <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '3'">
                          <svg height="30" width="100%">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="16.9%" y1="5" x2="33.4%"
                                y2="5" stroke-linecap="butt"></line>
                                <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '2'">
                          <svg height="30" width="100%">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5" stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="5" y1="5" x2="20" y2="5" stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="20" y1="5" x2="40" y2="5" stroke-linecap="butt"></line>
                              <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                          </svg>
                        </span>
                        <span v-else-if="(item.ManagerInvestmentLevel == '1')">
                          <svg height="30" width="100%">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="98%" y2="5"
                                stroke-linecap="round"></line>
                                <rect x="16.66%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="33.26%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="49.86%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="66.46%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="83.06%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="100%" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                             <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="15%">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="32%">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="47%">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="64%">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="81.5%">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(0,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="98%" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                          </svg>
                        </span>
                        <span v-else>
                          N/A
                        </span>
                          </div>
                        </div>
                      </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
                </div>
                <div>
                
                  <h1></h1>
                </div>
                <div class="mfi-readmore-tab-responsive" v-if="readmoreTeam">
                  <span @click="readMore = !readMore">
                    <span v-if="readMore" class="mfi-readmore-text-responsive">Hide Full Management
                      Team</span>
                    <span v-else class="mfi-readmore-text-responsive">View Full Management Team</span>
                    &nbsp;
                    <span class="mfi-read-more-icon">
                      <mds-icon v-if="readMore" class="view-team-angle-responsive" name="angle-up" size="small"></mds-icon>
                      <mds-icon v-else class="view-team-angle-responsive" name="angle-down" size="small"></mds-icon>
                    </span>
                  </span>
                </div>
                <div class="bottom-margine-container-responsive">
      </div>
              </div>
            </div>
            <div class="mfi-main-modal-div-responsive">
      <mds-modal class="mfi-modal-class-responsive" v-model="toggle" aria-labelledby="title-id" >
        <mds-section class="fundinvestor-model-section" border="none">
          <div class="mfi-modal-main-responsive">
            <div class="mfi-right-side-modal-part-responsive">
              <div class="mfi-modal-right-side-one-part-responsive" v-if="MedalistRatingModal">
                <h1 class="mfi-modal-right-side-title-responsive">
                  Morningstar Medalist Rating
                </h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  The Morningstar Medalist Rating is the summary expression of
                  Morningstar’s forward-looking analysis of investment
                  strategies as offered via specific vehicles using a rating
                  scale of Gold, Silver, Bronze, Neutral, and Negative. The
                  Medalist Ratings indicate which investments Morningstar
                  believes are likely to outperform a relevant index or peer
                  group average on a risk-adjusted basis over time. Investment
                  products are evaluated on three key pillars (People, Parent,
                  and Process) which, when coupled with a fee assessment, forms
                  the basis for Morningstar’s conviction in those products’
                  investment merits and determines the Medalist Rating they’re
                  assigned. Pillar ratings take the form of Low, Below Average,
                  Average, Above Average, and High. Pillars may be evaluated via
                  an analyst’s qualitative assessment (either directly to a
                  vehicle the analyst covers or indirectly when the pillar
                  ratings of a covered vehicle are mapped to a related uncovered
                  vehicle) or using algorithmic techniques. Vehicles are sorted
                  by their expected performance into rating groups defined by
                  their Morningstar Category and their active or passive status.
                  When analysts directly cover a vehicle, they assign the three
                  pillar ratings based on their qualitative assessment, subject
                  to the oversight of the Analyst Rating Committee, and monitor
                  and reevaluate them at least every 14 months. When the
                  vehicles are covered either indirectly by analysts or by
                  algorithm, the ratings are assigned monthly. For more detailed
                  information about the Medalist Ratings, including their
                  methodology, please go to the section titled “Methodology
                  Documents and Disclosures” at
                  <a class="mfi-href-link" href="https://global.morningstar.com/managerdisclosures">https://
                    global.morningstar.com/managerdisclosures.</a>
                </p>
                <p class="mfi-paragraph-modal-second-para-responsive">
                  The Morningstar Medalist Ratings are not statements of fact,
                  nor are they credit or risk ratings. The Morningstar Medalist
                  Rating (i) should not be used as the sole basis in evaluating
                  an investment product, (ii) involves unknown risks and
                  uncertainties which may cause expectations not to occur or to
                  differ significantly from what was expected, (iii) are not
                  guaranteed to be based on complete or accurate assumptions or
                  models when determined algorithmically, (iv) involve the risk
                  that the return target will not be met due to such things as
                  unforeseen changes in changes in management, technology,
                  economic development, interest rate development, operating
                  and/or material costs, competitive pressure, supervisory law,
                  exchange rate, tax rates, exchange rate changes, and/or
                  changes in political and social conditions, and (v) should not
                  be considered an offer or solicitation to buy or sell the
                  investment product. A change in the fundamental factors
                  underlying the Morningstar Medalist Rating can mean that the
                  rating is subsequently no longer accurate.
                </p>
                <p class="mmfi-paragraph-modal-second-para">
                  Analysts do not have any other material conflicts of interest
                  at the time of publication. Users wishing to obtain further
                  information should contact their local Morningstar office or
                  refer to the Analyst Conflicts of Interest and Other
                  Disclosures for North America, EMEA, or APAC at
                  <a class="mfi-href-link"
                    href="https://global.morningstar.com/managerdisclosures">https://global.morningstar.com/managerdisclosures</a>
                  under “Methodology Documents and Disclosures.”
                </p>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="AnalystDrivenText">
                <h1 class="mfi-modal-right-side-title-responsive">
                  Analyst-Driven Proportion
                </h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  The Analyst-Driven Proportion data point displays the weighted
                  percentage of a vehicle’s pillar ratings assigned directly or
                  indirectly by analysts. For example, if the People and Parent
                  ratings are assigned directly or indirectly by analysts but
                  the Process rating is assigned algorithmically, the
                  Analyst-Driven Proportion for an actively managed vehicle
                  would disclose that 55% of the pillar weight was assigned by
                  analysts and the Analyst-Driven Proportion for a passively
                  managed vehicle would disclose that 20% of the pillar weight
                  was assigned by analysts.
                </p>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="DataCovergeText">
                <h1 class="mfi-modal-right-side-title-responsive">% Data Coverage</h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  The % Data Coverage data point is a summary metric describing
                  the level of data completeness used to generate the overall
                  rating. If the pillar is assigned directly or indirectly by
                  analysts, the pillar has complete data availability, as no
                  model was used to estimate the pillar score. If the pillar is
                  assigned directly by algorithm, Morningstar counts the number
                  of data points feeding both the positive and negative models
                  and counts whether the vehicle has strategy-specific data
                  available. A simple percentage is calculated per pillar. The
                  overall % data coverage is then scaled by pillar weights.
                </p>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="ExpenseRationModalText">
                <h1 class="mfi-modal-right-side-title-responsive">Expense Ratio</h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  The expense ratio is the annual fee that all mutual funds or
                  exchange-traded funds charge their shareholders. It expresses
                  the percentage of assets deducted each fiscal year for fund
                  expenses, including 12b-1 fees, management fees,
                  administrative fees, operating costs, and all other
                  asset-based costs incurred by the fund.
                </p>
                <p class="mfi-modal-right-side-border-threshold-responsive"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para-responsive">
                  The Expense Ratio receives a passing score if a fund's expense
                  ratio is in the cheapest quintile of that fund's category.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="RiskText">
                <h1 class="mfi-modal-right-side-title-responsive">Risk</h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  This is a proprietary Morningstar data point. It's an
                  assessment of the variations in a fund's monthly returns, with
                  an emphasis on downside variations, in comparison to similar
                  funds.
                </p>
                <p class="mfi-modal-right-side-border-threshold-responsive"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para-responsive">
                  Any Risk level lower than High receives a passing grade.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="ManagerInvestmentText">
                <h1 class="mfi-modal-right-side-title-responsive">Manager Investment</h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  This is the amount of money that fund managers invest in their
                  funds. (These are reported in ranges, with the top level being
                  more than $1 million.)
                </p>
                <p class="mfi-modal-right-side-border-threshold-responsive"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para-responsive">
                  Manager Investment receives a passing score if a fund's
                  manager has $500,000 or more invested in the fund.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="PeoplePillarText">
                <h1 class="mfi-modal-right-side-title-responsive">People Pillar</h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  Morningstar evaluates funds based on three key pillars:
                  Process, People, and Parent. (Fees and return potential are
                  factored in using quantitative measures.) Analysts assign a
                  rating of High, Above Average, Average, Below Average, or Low
                  to each pillar. The People Pillar reflects our view of the
                  competitive advantage that the managers, analysts, and traders
                  possess.
                </p>
                <p class="mfi-modal-right-side-border-threshold-responsive"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para-responsive">
                  The People Pillar receives a passing score if the management
                  receives a rating of High or Above Average.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="ParentPillarText">
                <h1 class="mfi-modal-right-side-title-responsive">Parent Pillar</h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  A fund's Parent Pillar reflects our view of the competitive
                  advantages of the firm as a whole and the priorities placed on
                  serving fundholders. We visit fund companies to assess their
                  culture, stewardship, and talent.
                </p>
                <p class="mfi-modal-right-side-border-threshold-responsive"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para-responsive">
                  The Parent Pillar receives a passing score if the firm
                  receives a rating of High or Above Average.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part-responsive" v-if="ManagerReturnsText">
                <h1 class="mfi-modal-right-side-title-responsive">Manager Returns</h1>
                <h1 class="mfi-modal-right-side-border-responsive"></h1>
                <p class="mfi-paragraph-modal-responsive">
                  We compare a fund's performance to that of its benchmark over
                  the longest-serving manager's tenure.
                </p>
                <p class="mfi-modal-right-side-border-threshold-responsive"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para-responsive">
                  The Manager Returns requirement receives a passing score if
                  that fund's return is greater than its benchmark's return over
                  the tenure of the longest-serving manager. If the tenure of
                  longest-termed manager is less than five years, the fund
                  receives a failing grade.
                  Index funds, however, are only judged on expense ratio and risk.
                </h1>
              </div>
              <div v-if="definitionTitle">
                <div>
                  <div class="definitions-name-and-close-icon-responsive">
                    <div class="definition-title-div-responsive">
                  <h1 class="mfi-modal-right-side-title-responsive-definition">Definitions and Thresholds</h1>
                </div>
                <div class="mfi-modal-right-side-second-part-responsive-definition" >             
                <mds-button class="mfi-remove-icon-responsive-definition" @click="toggle = !toggle" variation="flat" icon="remove"
                  size="large"></mds-button>
              </div>
                </div>
                  <h1 class="mfi-modal-right-side-border-responsive-definition"></h1>
                </div>
              <mds-list-group size="large">
                <mds-list-group-item class="mfi-list-group" @click.native.stop="MedalistRating()"
                  :active="medalistmodalButton"><span class="mfi-list-title-tesxts">Morningstar Medalist
                    Rating</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="AnalystDriven()"
                  :active="ActiveAnalystDriven"><span class="mfi-list-title-tesxts">Analyst-Driven
                    Proportion</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="DataCoverage()"
                  :active="activeDataCoverage"><span class="mfi-list-title-tesxts">% Data Coverage
                  </span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ExpenseRationModal()"
                  :active="ExpenseActive"><span class="mfi-list-title-tesxts">Expense Ratio</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="RiskModal()" :active="activeRisk"><span
                    class="mfi-list-title-tesxts">Risk</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ManagerInvestment()"
                  :active="activeManagerInvestment"><span class="mfi-list-title-tesxts">Manager
                    Investment</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="PeoplePillar()"
                  :active="activePeoplePillar"><span class="mfi-list-title-tesxts">People
                    Pillar</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ParentPillar()"
                  :active="activeParentPillar"><span class="mfi-list-title-tesxts">Parent
                    Pillar</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ManagerReturns()"
                  :active="activeManagerReturns"><span class="mfi-list-title-tesxts">Manager
                    Returns</span></mds-list-group-item>
              </mds-list-group>
            </div>
            </div>
            <div class="mfi-modal-right-side-second-part-responsive" v-if="backbutton">
              <div >
              <mds-button class="mfi-remove-icon-responsive" @click.native.stop="toggleone()" variation="flat" icon="caret-circle-left"
                  size="large"></mds-button>
                </div>
                <div>
                <mds-button class="mfi-remove-icon-responsive" @click="toggle = !toggle" variation="flat" icon="remove"
                  size="large"></mds-button>
                </div>
              </div>
          </div>
        </mds-section>
      </mds-modal>
    </div>
</div>
  </div>


  <!--Non Responsive Component-->
  <div v-else class="mfi-fund-selector-main-div">
    <mds-layout-grid>
      <mds-row>
        <mds-col :cols="12" :cols-at-m="6" :cols-at-xl="6" :cols-at-l="6">
          <div class="mfi-fund-selector-main-container">
            <div class="mfi-fund-selector-title-div">
              <h1 class="mfi-fund-selector-title">
                Does your fund pass our FundInvestor Selector Test?
              </h1>
            </div>
            <div class="mfi-search-and-button">
              <div class="mfi-Serach-field">
                <mds-search-field placeholder="Enter a ticker to see how it rates" label="Search" size="large"
                  v-model.trim="modelSearchField" :error="errormessage" oninput="this.value = this.value.toUpperCase()"
                  @keydown.enter="submit()" maxlength="5"></mds-search-field>
              </div>
              <div class="mfi-fund-selector-submit-button">
                <mds-button class="mfi-button" size="large" on-dark variation="secondary" @click="submit()">
                  Get Results
                </mds-button>
              </div>
            </div>
            <div v-if="errormessage" class="mfi-fund-spy-error-message">
              <span>{{ this.errormessageText }}</span>
            </div>
            <div class="mfi-fund-selector-description-div">
              <h1 class="mfi-fund-selector-description-title">
                FundInvestor Selector Pass/Fail Thresholds
              </h1>
              <p class="mfi-fund-selector-description">
                A mutual fund passes the FundInvestor Selector test if it meets
                all the requirements listed. If it receives a failing grade on
                any of the criteria, that fund fails the overall test. Index
                funds, however, are only judged on Expense Ratio and Risk. Only
                a handful of funds in each Morningstar Category pass the test,
                so don't give up on your fund if it fails. It might still have
                enough going for it to be worth owning, but you should investigate
                further to be sure you're comfortable with why it failed the test.
              </p>
            </div>
            <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
            <div class="mfi-threshold-below-div" v-if="leftdivMnagement">
              <h1 class="mfi-border-below-threshold"></h1>
              <div class="mfi-left-side-management-data-titles">
                <div>
                  <p class="mfi-manager-data-title" v-html="FundName"></p>
                </div>
                <div>
                  <p class="mfi-manager-data-ticker" v-html="FundTicker"></p>
                </div>
              </div>
              <h1 class="mfi-medalist-rating-title">
                Morningstar Medalist Rating<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                  @click.native.stop="MedalistRating()"></mds-icon>
              </h1>
              <div class="mfi-medalist-rating-div-leftside">
                <div>
                  <p :v-html="overall_rating" class="mfi-morningstar-medalist-rating-pragraph">
                    <mds-medalist-rating :rating="overall_rating" :custom-size="36"></mds-medalist-rating>
                  </p>
                </div>
                <div class="mfi-data-anad-driven-div">
                  <h1 class="mfi-driven-and-data-titles">
                    Analyst-Driven Proportion
                    <mds-icon class="mfi-info-icon" size="small" name="info-circle"
                      @click.native.stop="AnalystDriven()"></mds-icon>
                  </h1>
                  <h1 class="mfi-border-in-table-driven-and-data"></h1>
                  <h1 class="mfi-driven-and-data-titles">
                    % Data Coverage
                    <mds-icon class="mfi-info-icon" size="small" name="info-circle"
                      @click.native.stop="DataCoverage()"></mds-icon>
                  </h1>
                </div>
                <div>
                  <h1 class="mfi-analyst-driven-and-percentage" v-html="overall_rating_analyst_driven_percentage"></h1>
                  <h1 class="mfi-border-in-table-driven"></h1>
                  <h1 class="mfi-analyst-driven-and-percentage">{{ overall_data_availability_percentage | dataCoverageFilter }}
                  </h1>
                </div>
              </div>
              <div class="mfi-management-team-final-div">
                <h1 class="mfi-title-for-management-team">Management Team</h1>
                <div :class="[this.manager.length > 3 ? blurreddiv : '', noblurred]"  v-if="!readMore">
                  <mds-table>
                  <mds-tbody>
                    <mds-tr v-for="(item, index) in manager" :key="index">
                      <mds-td class="mfi-list-for-management-team">
                        <div class="mfi-management-bar-and-data">
                          <div class="mfi-management-teams-names">
                            <p class="mfi-management-team-name-display">
                              {{
                                item.ManagerName
                              }}
                            </p>
                            <p class="mfi-management-team-date-display">
                              <span>{{
                                item.ManagerStartDate | formatDateVal
                              }}</span>
                              <span> - Present</span>
                            </p>
                          </div>
                          <div class="mfi-management-range-level-div">
                            <div class="mfi-title-level-bar">
                              Position in Investment
                            </div>
                            <span v-if="(item.ManagerInvestmentLevel == '7')">
                              <svg height="30" width="240">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                    stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="200" y1="5" x2="216"
                                    y2="5" stroke-linecap="butt"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="216" y1="5" x2="235"
                                    y2="5" stroke-linecap="round"></line>

                                  <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(235,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '6'">
                              <svg height="30" width="240">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                    stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="160" y1="5" x2="200"
                                    y2="5" stroke-linecap="butt"></line>
                                  <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(235,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '5'">
                              <svg height="30" width="240">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                    stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="120" y1="5" x2="160"
                                    y2="5" stroke-linecap="butt"></line>
                                  <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(235,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '4'">
                              <svg height="30" width="240">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                    stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="80" y1="5" x2="120"
                                    y2="5" stroke-linecap="butt"></line>
                                  <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(235,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '3'">
                              <svg height="30" width="240">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                    stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="40" y1="5" x2="80"
                                    y2="5" stroke-linecap="butt"></line>
                                  <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(235,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="item.ManagerInvestmentLevel == '2'">
                              <svg height="30" width="240">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5" stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="5" y1="5" x2="20" y2="5" stroke-linecap="round"></line>
                                  <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="20" y1="5" x2="40" y2="5" stroke-linecap="butt"></line>
                                  <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(235,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else-if="(item.ManagerInvestmentLevel == '1')">
                              <svg height="30" width="240">
                                <g fill="none">
                                  <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                    stroke-linecap="round"></line>
                                  <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                  <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                                </g>
                                <g>
                                  <g class="x axis" transform="translate(0,0)">
                                    <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">0</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">10K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">50K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">100K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">500K</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0">1M</text>
                                    </g>
                                    <g class="managerteam-range-price" transform="translate(235,0)">
                                      <line y2="0" x2="0"></line>
                                      <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                        &gt;
                                      </text>
                                    </g>
                                    <path class="domain" d="M0,0V0H441V0"></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span v-else>
                              N/A
                            </span>
                          </div>
                        </div>
                      </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
                </div>
                <div class="mfi-team-management-linear-div1" v-if="readMore">
                  <mds-table>
                  <mds-tbody>
                    <mds-tr v-for="(item, index) in manager" :key="index">
                      <mds-td class="mfi-list-for-management-team">
                        <div class="mfi-management-bar-and-data">
                          <div class="mfi-management-teams-names">
                            <p class="mfi-management-team-name-display">
                              {{
                                item.ManagerName
                              }}
                            </p>
                            <p class="mfi-management-team-date-display">
                              <span>{{
                                item.ManagerStartDate | formatDateVal
                              }}</span>
                              <span> - Present</span>
                            </p>
                          </div>
                          <div class="mfi-management-range-level-div">
                            <div class="mfi-title-level-bar">
                              Position in Investment
                            </div>
                        <span v-if="item.ManagerInvestmentLevel == '7'">
                            <svg height="30" width="240">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="200" y1="5" x2="216"
                                y2="5" stroke-linecap="butt"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="216" y1="5" x2="235"
                                y2="5" stroke-linecap="round"></line>

                              <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                              <g class="x axis" transform="translate(0,0)">
                                <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">0</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">10K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">50K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">100K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">500K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">1M</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(235,0)">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                    &gt;
                                  </text>
                                </g>
                                <path class="domain" d="M0,0V0H441V0"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '6'">
                          <svg height="30" width="240">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="160" y1="5" x2="200"
                                y2="5" stroke-linecap="butt"></line>
                              <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                              <g class="x axis" transform="translate(0,0)">
                                <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">0</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">10K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">50K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">100K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">500K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">1M</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(235,0)">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                    &gt;
                                  </text>
                                </g>
                                <path class="domain" d="M0,0V0H441V0"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '5'">
                          <svg height="30" width="240">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="120" y1="5" x2="160"
                                y2="5" stroke-linecap="butt"></line>
                              <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                              <g class="x axis" transform="translate(0,0)">
                                <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">0</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">10K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">50K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">100K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">500K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">1M</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(235,0)">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                    &gt;
                                  </text>
                                </g>
                                <path class="domain" d="M0,0V0H441V0"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '4'">
                          <svg height="30" width="240">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="80" y1="5" x2="120"
                                y2="5" stroke-linecap="butt"></line>
                              <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                              <g class="x axis" transform="translate(0,0)">
                                <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">0</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">10K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">50K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">100K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">500K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">1M</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(235,0)">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                    &gt;
                                  </text>
                                </g>
                                <path class="domain" d="M0,0V0H441V0"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '3'">
                          <svg height="30" width="240">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="40" y1="5" x2="80"
                                y2="5" stroke-linecap="butt"></line>
                              <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                              <g class="x axis" transform="translate(0,0)">
                                <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">0</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">10K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">50K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">100K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">500K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">1M</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(235,0)">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                    &gt;
                                  </text>
                                </g>
                                <path class="domain" d="M0,0V0H441V0"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span v-else-if="item.ManagerInvestmentLevel == '2'">
                          <svg height="30" width="240">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5" stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="5" y1="5" x2="20" y2="5" stroke-linecap="round"></line>
                              <line class="indicator-line" stroke="#1f55a5" stroke-width="10" x1="20" y1="5" x2="40" y2="5" stroke-linecap="butt"></line>
                              <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                              <g class="x axis" transform="translate(0,0)">
                                <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">0</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">10K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">50K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">100K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">500K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">1M</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(235,0)">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                    &gt;
                                  </text>
                                </g>
                                <path class="domain" d="M0,0V0H441V0"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span v-else-if="(item.ManagerInvestmentLevel == '1')">
                          <svg height="30" width="240">
                            <g fill="none">
                              <line class="bar" stroke="#cccccc" stroke-width="10" x1="5" y1="5" x2="235" y2="5"
                                stroke-linecap="round"></line>
                              <rect x="40" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="80" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="120" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="160" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="200" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                              <rect x="240" y="0" width="1" height="10" fill="#ffffff" stroke="none"></rect>
                            </g>
                            <g>
                              <g class="x axis" transform="translate(0,0)">
                                <g class="managerteam-range-price" transform="translate(0,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">0</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(32,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">10K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(72,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">50K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(108,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">100K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(148,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">500K</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(192,0)" style="opacity: 1">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0">1M</text>
                                </g>
                                <g class="managerteam-range-price" transform="translate(235,0)">
                                  <line y2="0" x2="0"></line>
                                  <text dy=".71em" y="15" x="0" style="text-anchor: middle">
                                    &gt;
                                  </text>
                                </g>
                                <path class="domain" d="M0,0V0H441V0"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span v-else>
                          N/A
                        </span>
                          </div>
                        </div>
                      </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
                </div>
                <div>
                  <h1></h1>
                </div>
                <div class="mfi-readmore-tab" v-if="readmoreTeam">
                  <span @click="readMore = !readMore">
                    <span v-if="readMore" class="mfi-readmore-text">Hide Full Management
                      Team</span>
                    <span v-else class="mfi-readmore-text">View Full Management Team</span>
                    &nbsp;
                    <span class="mfi-read-more-icon">
                      <mds-icon v-if="readMore" class="view-team-angle" name="angle-up" size="small"></mds-icon>
                      <mds-icon v-else class="view-team-angle" name="angle-down" size="small"></mds-icon>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </mds-col>
        <div class="mfi-loader-position">
          <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
        </div>
        <mds-col :cols="12" :cols-at-m="6" :cols-at-xl="6" :cols-at-l="6" class="funndselector-card-layout" v-if="rightSideCardDiv">
          <div class="mfi-fund-info-card">
            <mds-card title="" class="mfi-card-css">
              <template #mds-card-action>
                <div class="mfi-fund-info-under-card">
                  <div class="mfi-card-title-ticker-rating">
                    <div class="mfi-card-main-title-div">
                      <h1 class="mfi-card-title-text" v-html="FundName"></h1>
                      
                        <h1 class="mfi-title-ticker-text" v-if="Category == 'Allocation--50% to 70% Equity'">
                    Moderate Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text" v-else-if="Category == 'Allocation--15% to 30% Equity'">
                    Conservative Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text" v-else-if="Category == 'Allocation--30% to 50% Equity'">
                    Moderately Conservative Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text" v-else-if="Category == 'Allocation--70% to 85% Equity'">
                    Moderately Aggressive Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text" v-else-if="Category == 'Allocation--85%+ Equity'">
                    Aggressive Allocation
                  </h1>
                  <h1 class="mfi-title-ticker-text" v-else v-html="Category"></h1>
                    </div>
                  
                    <div>
                      <h1 class="mfi-manager-data-ticker" v-html="FundTicker"></h1>
                    </div>
                    <div>
                      <p class="mfi-morningstar-rating-pragraph" :v-html="MorningstarRating">
                        <mds-star-rating :rating="parseInt(MorningstarRating)" :custom-size="18"></mds-star-rating>
                      </p>
                    </div>

                    
                  </div>
                  <div>
                 
                </div>
              


                  <div class="mfi-card-table-part">
                    <div class="mfi-card-data-point">
                      <h1 class="mfi-card-table-points-title">Data Point</h1>
                      <h1 class="mfi-data-points-name-text">
                        Expense Ratio<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ExpenseRationModal()"></mds-icon>
                      </h1>
                      <h1 class="mfi-border-in-table-one"></h1>
                      <h1 class="mfi-data-points-name-text">
                        Risk<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="RiskModal()"></mds-icon>
                      </h1>
                      <h1 class="mfi-border-in-table-one"></h1>
                      <h1 class="mfi-data-points-name-text">
                        Manager Investment<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ManagerInvestment()"></mds-icon>
                      </h1>
                      <h1 class="mfi-border-in-table-one"></h1>
                      <h1 class="mfi-data-points-name-text">
                        People Pillar<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="PeoplePillar()"></mds-icon>
                      </h1>
                      <h1 class="mfi-border-in-table-one"></h1>
                      <h1 class="mfi-data-points-name-text">
                        Parent Pillar<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ParentPillar()"></mds-icon>
                      </h1>
                      <h1 class="mfi-border-in-table-one"></h1>
                      <h1 class="mfi-data-points-name-text">
                        Manager Returns<mds-icon class="mfi-info-icon" size="small" name="info-circle"
                          @click.native.stop="ManagerReturns()"></mds-icon>
                      </h1>
                      <h1 class="mfi-manager-returns-one">Manager Tenure</h1>
                      <h1 class="mfi-manager-returns">Manager Start Date</h1>
                      <h1 class="mfi-manager-returns">Benchmark Return</h1>
                      <h1 class="mfi-manager-returns">Fund Return</h1>
                    </div>
                    <div class="mfi-card-value-point">
                      <h1 class="mfi-card-table-points-title">Value</h1>
                      <h1 class="mfi-value-points-data" v-html="ExpenseRatio"></h1>
                      <h1 class="mfi-border-in-table"></h1>
                      <h1 class="mfi-value-points-data" v-html="Risk"></h1>
                      <h1 class="mfi-border-in-table"></h1>
                      <h1 class="mfi-value-points-data" v-html="ManagerRange"></h1>
                      <h1 class="mfi-border-in-table"></h1>
                      <h1 class="mfi-value-points-data" v-html="People"></h1>
                      <h1 class="mfi-border-in-table"></h1>
                      <h1 class="mfi-value-points-data" v-html="Parent"></h1>
                      <h1 class="mfi-border-in-table"></h1>
                      <br />
                      <br />
                      <p class="mfi-manager-values-one" v-html="Year -
                        ManagerStartDate.substring(
                          ManagerStartDate.indexOf('M') - 11,
                          ManagerStartDate.lastIndexOf('M') - 15
                        ) +
                        ' ' +
                        'Years'
                        "></p>
                      <h1 class="mfi-manager-values">
                        {{ ManagerStartDate | formatDateValForFundSpy }}
                      </h1>
                      <h1 class="mfi-manager-values" v-html="BenchmarkReturn"></h1>
                      <h1 class="mfi-manager-values" v-html="FundReturn"></h1>
                    </div>
                    <div class="mfi-card-score-point">
                      <h1 class="mfi-card-table-points-title">Score</h1>

                      <mds-inline-message v-if="ExpenseRatio_Test == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>
                        Pass
                      </mds-inline-message>

                      <mds-inline-message v-if="ExpenseRatio_Test == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail
                      </mds-inline-message>

                      <h1 class="mfi-border-in-table"></h1>
                      <mds-inline-message v-if="Risk_Test == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="Risk_Test == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail
                      </mds-inline-message>
                      <h1 class="mfi-border-in-table"></h1>
                      <mds-inline-message v-if="(ManagerRange == 'More Than $1Mil') |
                        (ManagerRange == '$500K-$1Mil')
                        " class="mfi-inline-message" size="small" variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="(ManagerRange == 'None') | 
                      (ManagerRange == 'N/A') |
                      (ManagerRange == '$1-$10K')| 
                      (ManagerRange == '$10K-$50K') | 
                      (ManagerRange == '$50K-$100K') | 
                      (ManagerRange == '$100K-$500K') 
                        " class="mfi-inline-message" size="small" variation="error" tinted>
                        Fail
                      </mds-inline-message>
                      <h1 class="mfi-border-in-table"></h1>
                      <mds-inline-message v-if="People_PillarTest == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="People_PillarTest == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail
                      </mds-inline-message>
                      <h1 class="mfi-border-in-table"></h1>
                      <mds-inline-message v-if="Parent_PillarTest == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>
                        Pass
                      </mds-inline-message>
                      <mds-inline-message v-if="Parent_PillarTest == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail
                      </mds-inline-message>
                      <h1 class="mfi-border-in-table"></h1>
                      <mds-inline-message v-if="ManagerPerformance_Test == 'Pass'" class="mfi-inline-message" size="small"
                        variation="success" tinted>Pass</mds-inline-message>
                      <mds-inline-message v-if="ManagerPerformance_Test == 'Fail'" class="mfi-inline-message" size="small"
                        variation="error" tinted>
                        Fail</mds-inline-message>
                    </div>
                  </div>

                  
                  <h1 class="mfi-performance-text">
                    Performance Measure is from manager start date through
                    <span class="mfi-performance-text">{{ PreviousDayDate | formatDateValForFundSpy }}.</span>
                    Fund returns and benchmark returns are cumulative.
                  </h1>
                </div>
              </template>
            </mds-card>
          </div>
        </mds-col>
      </mds-row>
      <div class="bottom-margine-container">
      </div>
    </mds-layout-grid>
    <div class="mfi-main-modal-div">
      <mds-modal class="mfi-modal-class" v-model="toggle" aria-labelledby="title-id" >
        <mds-section class="fundinvestor-model-section" border="none">
          <div class="mfi-modal-main">
            <div>
              <mds-list-group size="large">
                <mds-list-group-item class="mfi-list-group" @click.native.stop="MedalistRating()"
                  :active="medalistmodalButton"><span class="mfi-list-title-tesxts">Morningstar Medalist
                    Rating</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="AnalystDriven()"
                  :active="ActiveAnalystDriven"><span class="mfi-list-title-tesxts">Analyst-Driven
                    Proportion</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="DataCoverage()"
                  :active="activeDataCoverage"><span class="mfi-list-title-tesxts">% Data Coverage
                  </span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ExpenseRationModal()"
                  :active="ExpenseActive"><span class="mfi-list-title-tesxts">Expense Ratio</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="RiskModal()" :active="activeRisk"><span
                    class="mfi-list-title-tesxts">Risk</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ManagerInvestment()"
                  :active="activeManagerInvestment"><span class="mfi-list-title-tesxts">Manager
                    Investment</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="PeoplePillar()"
                  :active="activePeoplePillar"><span class="mfi-list-title-tesxts">People
                    Pillar</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ParentPillar()"
                  :active="activeParentPillar"><span class="mfi-list-title-tesxts">Parent
                    Pillar</span></mds-list-group-item>
                <mds-list-group-item class="mfi-list-group" @click.native.stop="ManagerReturns()"
                  :active="activeManagerReturns"><span class="mfi-list-title-tesxts">Manager
                    Returns</span></mds-list-group-item>
              </mds-list-group>
            </div>
            <div class="mfi-right-side-modal-part">
              <div class="mfi-modal-right-side-one-part" v-if="MedalistRatingModal">
                <h1 class="mfi-modal-right-side-title">
                  Morningstar Medalist Rating
                </h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  The Morningstar Medalist Rating is the summary expression of
                  Morningstar’s forward-looking analysis of investment
                  strategies as offered via specific vehicles using a rating
                  scale of Gold, Silver, Bronze, Neutral, and Negative. The
                  Medalist Ratings indicate which investments Morningstar
                  believes are likely to outperform a relevant index or peer
                  group average on a risk-adjusted basis over time. Investment
                  products are evaluated on three key pillars (People, Parent,
                  and Process) which, when coupled with a fee assessment, forms
                  the basis for Morningstar’s conviction in those products’
                  investment merits and determines the Medalist Rating they’re
                  assigned. Pillar ratings take the form of Low, Below Average,
                  Average, Above Average, and High. Pillars may be evaluated via
                  an analyst’s qualitative assessment (either directly to a
                  vehicle the analyst covers or indirectly when the pillar
                  ratings of a covered vehicle are mapped to a related uncovered
                  vehicle) or using algorithmic techniques. Vehicles are sorted
                  by their expected performance into rating groups defined by
                  their Morningstar Category and their active or passive status.
                  When analysts directly cover a vehicle, they assign the three
                  pillar ratings based on their qualitative assessment, subject
                  to the oversight of the Analyst Rating Committee, and monitor
                  and reevaluate them at least every 14 months. When the
                  vehicles are covered either indirectly by analysts or by
                  algorithm, the ratings are assigned monthly. For more detailed
                  information about the Medalist Ratings, including their
                  methodology, please go to the section titled “Methodology
                  Documents and Disclosures” at
                  <a class="mfi-href-link" href="https://global.morningstar.com/managerdisclosures">https://
                    global.morningstar.com/managerdisclosures.</a>
                </p>
                <p class="mfi-paragraph-modal-second-para">
                  The Morningstar Medalist Ratings are not statements of fact,
                  nor are they credit or risk ratings. The Morningstar Medalist
                  Rating (i) should not be used as the sole basis in evaluating
                  an investment product, (ii) involves unknown risks and
                  uncertainties which may cause expectations not to occur or to
                  differ significantly from what was expected, (iii) are not
                  guaranteed to be based on complete or accurate assumptions or
                  models when determined algorithmically, (iv) involve the risk
                  that the return target will not be met due to such things as
                  unforeseen changes in changes in management, technology,
                  economic development, interest rate development, operating
                  and/or material costs, competitive pressure, supervisory law,
                  exchange rate, tax rates, exchange rate changes, and/or
                  changes in political and social conditions, and (v) should not
                  be considered an offer or solicitation to buy or sell the
                  investment product. A change in the fundamental factors
                  underlying the Morningstar Medalist Rating can mean that the
                  rating is subsequently no longer accurate.
                </p>
                <p class="mmfi-paragraph-modal-second-para">
                  Analysts do not have any other material conflicts of interest
                  at the time of publication. Users wishing to obtain further
                  information should contact their local Morningstar office or
                  refer to the Analyst Conflicts of Interest and Other
                  Disclosures for North America, EMEA, or APAC at
                  <a class="mfi-href-link"
                    href="https://global.morningstar.com/managerdisclosures">https://global.morningstar.com/managerdisclosures</a>
                  under “Methodology Documents and Disclosures.”
                </p>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="AnalystDrivenText">
                <h1 class="mfi-modal-right-side-title">
                  Analyst-Driven Proportion
                </h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  The Analyst-Driven Proportion data point displays the weighted
                  percentage of a vehicle’s pillar ratings assigned directly or
                  indirectly by analysts. For example, if the People and Parent
                  ratings are assigned directly or indirectly by analysts but
                  the Process rating is assigned algorithmically, the
                  Analyst-Driven Proportion for an actively managed vehicle
                  would disclose that 55% of the pillar weight was assigned by
                  analysts and the Analyst-Driven Proportion for a passively
                  managed vehicle would disclose that 20% of the pillar weight
                  was assigned by analysts.
                </p>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="DataCovergeText">
                <h1 class="mfi-modal-right-side-title">% Data Coverage</h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  The % Data Coverage data point is a summary metric describing
                  the level of data completeness used to generate the overall
                  rating. If the pillar is assigned directly or indirectly by
                  analysts, the pillar has complete data availability, as no
                  model was used to estimate the pillar score. If the pillar is
                  assigned directly by algorithm, Morningstar counts the number
                  of data points feeding both the positive and negative models
                  and counts whether the vehicle has strategy-specific data
                  available. A simple percentage is calculated per pillar. The
                  overall % data coverage is then scaled by pillar weights.
                </p>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="ExpenseRationModalText">
                <h1 class="mfi-modal-right-side-title">Expense Ratio</h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  The expense ratio is the annual fee that all mutual funds or
                  exchange-traded funds charge their shareholders. It expresses
                  the percentage of assets deducted each fiscal year for fund
                  expenses, including 12b-1 fees, management fees,
                  administrative fees, operating costs, and all other
                  asset-based costs incurred by the fund.
                </p>
                <p class="mfi-modal-right-side-border-threshold"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para">
                  The Expense Ratio receives a passing score if a fund's expense
                  ratio is in the cheapest quintile of that fund's category.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="RiskText">
                <h1 class="mfi-modal-right-side-title">Risk</h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  This is a proprietary Morningstar data point. It's an
                  assessment of the variations in a fund's monthly returns, with
                  an emphasis on downside variations, in comparison to similar
                  funds.
                </p>
                <p class="mfi-modal-right-side-border-threshold"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para">
                  Any Risk level lower than High receives a passing grade.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="ManagerInvestmentText">
                <h1 class="mfi-modal-right-side-title">Manager Investment</h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  This is the amount of money that fund managers invest in their
                  funds. (These are reported in ranges, with the top level being
                  more than $1 million.)
                </p>
                <p class="mfi-modal-right-side-border-threshold"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para">
                  Manager Investment receives a passing score if a fund's
                  manager has $500,000 or more invested in the fund.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="PeoplePillarText">
                <h1 class="mfi-modal-right-side-title">People Pillar</h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  Morningstar evaluates funds based on three key pillars:
                  Process, People, and Parent. (Fees and return potential are
                  factored in using quantitative measures.) Analysts assign a
                  rating of High, Above Average, Average, Below Average, or Low
                  to each pillar. The People Pillar reflects our view of the
                  competitive advantage that the managers, analysts, and traders
                  possess.
                </p>
                <p class="mfi-modal-right-side-border-threshold"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para">
                  The People Pillar receives a passing score if the management
                  receives a rating of High or Above Average.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="ParentPillarText">
                <h1 class="mfi-modal-right-side-title">Parent Pillar</h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  A fund's Parent Pillar reflects our view of the competitive
                  advantages of the firm as a whole and the priorities placed on
                  serving fundholders. We visit fund companies to assess their
                  culture, stewardship, and talent.
                </p>
                <p class="mfi-modal-right-side-border-threshold"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para">
                  The Parent Pillar receives a passing score if the firm
                  receives a rating of High or Above Average.
                </h1>
              </div>
              <div class="mfi-modal-right-side-one-part" v-if="ManagerReturnsText">
                <h1 class="mfi-modal-right-side-title">Manager Returns</h1>
                <h1 class="mfi-modal-right-side-border"></h1>
                <p class="mfi-paragraph-modal">
                  We compare a fund's performance to that of its benchmark over
                  the longest-serving manager's tenure.
                </p>
                <p class="mfi-modal-right-side-border-threshold"></p>
                <h1 class="mfi-threshold-title">Threshold</h1>
                <h1 class="mfi-threshold-title-para">
                  The Manager Returns requirement receives a passing score if
                  that fund's return is greater than its benchmark's return over
                  the tenure of the longest-serving manager. If the tenure of
                  longest-termed manager is less than five years, the fund
                  receives a failing grade.
                  Index funds, however, are only judged on expense ratio and risk.
                </h1>
              </div>
              <div class="mfi-modal-right-side-second-part">
                <mds-button class="mfi-remove-icon" @click="toggle = !toggle" variation="flat" icon="remove"
                  size="large"></mds-button>
              </div>
            </div>
          </div>
        </mds-section>
      </mds-modal>
    </div>
  </div>
</div>
</template>

<script>
import MdsSearchField from "@mds/search-field";
import MdsCard from "@mds/card";
import { MdsButton } from "@mds/button";
import MdsLoader from "@mds/loader";
import MdsStarRating from "@mds/star-rating";
import MdsInlineMessage from "@mds/inline-message";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsMedalistRating from "@mds/medalist-rating";
import MdsModal from "@mds/modal";
import Vue from "vue";
import axios from "axios";
import MdsIcon from "@mds/icon";
import VueAxios from "vue-axios";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsSection from '@mds/section';
import { MdsTable,MdsTbody,MdsTr,MdsTd} from "@mds/data-table";
import { mapState } from "vuex";
Vue.use(VueAxios, axios);

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
today.toDateString();
yesterday.toDateString();
export default {
  name: "fund-selector",
  components: {
    MdsSearchField,
    MdsMedalistRating,
    MdsCard,
    MdsButton,
    MdsStarRating,
    MdsLoader,
    MdsIcon,
    MdsInlineMessage,
    MdsModal,
    MdsListGroup,
    MdsListGroupItem,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSection,
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTd,
  },
  mounted() {
    this.isMobileContentToggle=this.$store.state.responsive.isResponsive;
  },
  data() {
    return {
      isMobileContentToggle: null,
      errormessage: false,
      errormessageText: null,
      PreviousDayDate: yesterday.toDateString(),
      Year: new Date().toISOString().slice(0, 4),
      medalistmodalButton: false,
      toggle: false,
      backbutton: true,
     
      isLoading: false,
      MedalistRatingModal: false,
      ExpenseRationModalText: false,
      AnalystDrivenText: false,
      activeDataCoverage: false,
      ExpenseActive: false,
      DataCovergeText: false,
      ManagerInvestmentText: false,
      activeManagerInvestment: false,
      ActiveAnalystDriven: false,
      RiskText: false,
      activeRisk: false,
      PeoplePillarText: false,
      activePeoplePillar: false,
      ParentPillarText: false,
      activeParentPillar: false,
      ManagerReturnsText: false,
      activeManagerReturns: false,
      readmoreTeam: false,
      definitionTitle: false,
      obj: "",
      leftdiv: false,
      list: [],
      pfData: [],
      oneNow: [],
      manager: [],
      readMore: false,
      leftdivMnagement: false,
      rightSideCardDiv: false,
      modelSearchField: "",
      ExpenseTest: this.ExpenseRatio_Test,
      RiskTest: this.Risk_Test,
      ManagerPerformanceTest: this.ManagerPerformance_Test,
      ParentPillarTest: this.Parent_PillarTest,
      PeoplePillarTest: this.People_PillarTest,
      ManagerRangeTest: this.ManagerRange_Test,
      FundSpyUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_Fund_Spy,
      blurreddiv:"blurred-div-for-manager",
      noblurred:"non-blurred-div-for-manager",
      blurreddivresponsive:"blurred-div-for-manager-responsive",
      noblurredresponsive:"non-blurred-div-for-manager-responsive",
    };
  },
  methods: {
    toggleone(){
      this.definitionTitle = true;
      this.backbutton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },

    ExpenseRationModal() {
      this.definitionTitle = false;
      this.ExpenseRationModalText = true;
      this.ExpenseActive = true;
      this.toggle = true;
      this.backbutton = true;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    MedalistRating() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.MedalistRatingModal = true;
      this.medalistmodalButton = true;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    AnalystDriven() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.AnalystDrivenText = true;
      this.ActiveAnalystDriven = true;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    DataCoverage() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.DataCovergeText = true;
      this.activeDataCoverage = true;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    RiskModal() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.RiskText = true;
      this.activeRisk = true;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    ManagerReturns() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.ManagerReturnsText = true;
      this.activeManagerReturns = true;
      this.RiskText = false;
      this.activeRisk = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    ParentPillar() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.ParentPillarText = true;
      this.activeParentPillar = true;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    PeoplePillar() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.PeoplePillarText = true;
      this.activePeoplePillar = true;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
      this.ManagerInvestmentText = false;
      this.activeManagerInvestment = false;
    },
    ManagerInvestment() {
      this.backbutton = true;
      this.definitionTitle = false;
      this.toggle = true;
      this.ManagerInvestmentText = true;
      this.activeManagerInvestment = true;
      this.PeoplePillarText = false;
      this.activePeoplePillar = false;
      this.ParentPillarText = false;
      this.activeParentPillar = false;
      this.ManagerReturnsText = false;
      this.activeManagerReturns = false;
      this.RiskText = false;
      this.activeRisk = false;
      this.DataCovergeText = false;
      this.activeDataCoverage = false;
      this.AnalystDrivenText = false;
      this.ActiveAnalystDriven = false;
      this.MedalistRatingModal = false;
      this.medalistmodalButton = false;
      this.ExpenseRationModalText = false;
      this.ExpenseActive = false;
    },
    submit() {
      if (this.modelSearchField != null && this.modelSearchField != "") {
        this.readMore = false;
        this.leftdivMnagement = false;
        this.rightSideCardDiv = false;
        this.isLoading = true;
        var tickerValue = this.modelSearchField;
        var URL = this.FundSpyUrl;
        URL = URL.replace("{ticker}", tickerValue);
        Vue.axios.get(URL).then((response) => {
          if (response) {
            if (response.status == 200) {
              if (response.data) {
                this.manager = response.data;
                if (this.modelSearchField.charAt(4) == "X") {
                  this.errormessage = true;
                  this.errormessageText =                  
                    "We could not find the mutual fund you are looking for. Please try again.";
                }else if(this.modelSearchField.length)
                {
                  this.errormessage = true;
                  this.errormessageText =
                    "Please enter a valid ticker";
                }
                var pfData = response.data;
                if (pfData) {
                  for (var i = 0; i < pfData.length; i++) {
                    var obj = pfData[i];
                    if (obj) {
                      if (i == 0) {
                          this.FundName = obj.FundName == "" ?"—":obj.FundName;
                          this.FundTicker = obj.FundTicker == "" ?"—":obj.FundTicker;
                          this.MorningstarRating = obj.MorningstarRating == "" ?"—":obj.MorningstarRating;
                          this.overall_rating_analyst_driven_percentage =
                            obj.overall_rating_analyst_driven_percentage == ""?"_":obj.overall_rating_analyst_driven_percentage;
                          this.overall_data_availability_percentage =
                            obj.overall_data_availability_percentage == ""?"_": obj.overall_data_availability_percentage;
                          this.Category = obj.Category == "" ?"—":obj.Category;
                          this.ExpenseRatio = obj.ExpenseRatio == "" ?"—":obj.ExpenseRatio;
                          this.Risk = obj.Risk == "" ?"—":obj.Risk;
                          this.ManagerRange = obj.ManagerRange == "" ?"—":obj.ManagerRange;
                          this.People = obj.People == "" ?"—":obj.People;
                          this.Parent = obj.Parent == "" ?"—":obj.Parent;
                          this.ManagerStartDate = obj.ManagerStartDate == "" ?"—":obj.ManagerStartDate;
                          this.BenchmarkReturn = obj.BenchmarkReturn == "" ?"—":obj.BenchmarkReturn;
                          this.FundReturn = obj.FundReturn == "" ?"—":obj.FundReturn;
                          this.ExpenseRatio_Test = obj.ExpenseRatio_Test == "" ?"—":obj.ExpenseRatio_Test;
                          this.MorningstarRating = obj.MorningstarRating == "" ?"—":obj.MorningstarRating;
                          this.overall_rating = obj.overall_rating == "" ?"—":obj.overall_rating;
                          this.Risk_Test = obj.Risk_Test == "" ?"—":obj.Risk_Test;
                          this.ManagerRange_Test = obj.ManagerRange_Test == "" ?"—":obj.ManagerRange_Test;
                          this.People_PillarTest = obj.People_PillarTest == "" ?"—":obj.People_PillarTest;
                          this.Parent_PillarTest = obj.Parent_PillarTest == "" ?"—":obj.Parent_PillarTest;
                          this.ManagerPerformance_Test =
                            obj.ManagerPerformance_Test == "" ?"—":obj.ManagerPerformance_Test;
                          this.ManagerName = obj.ManagerName == "" ?"—":obj.ManagerName;
                        this.oneNow.push(this.MorningstarRating);
                        this.oneNow.push(this.overall_rating);
                      }
                    }
                  }
                }
                this.isLoading = false;
                if (this.manager.length) {
                  this.errormessage = false;
                  this.leftdivMnagement = true;
                  this.rightSideCardDiv = true;
                }
                if (this.manager.length > 3) {
                  this.readmoreTeam = true;
                } else {
                  this.readmoreTeam = false;
                }
              }
            }
          }
        }).catch(error => {
          if(error.response == undefined || error.response.status == 400){
            this.isLoading = false;
            this.errormessage = true;
        this.errormessageText = "Please enter a valid ticker";
          }
        });
      } else {
        this.errormessage = true;
        this.errormessageText = "Please enter a ticker to see how it rates.";
      }
    },
  },

  computed:mapState({
    isResponsive: state => state.responsive.isResponsive,
    isMobileContentAlias:'isResponsive',
    isMobileContentState(state){
      return state.responsive.isResponsive
    }

  }),

 

  watch: {
    modelSearchField(newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          newVal == "" ||
          newVal == null
        ) {
          this.errormessage = true;
          this.errormessageText = "Please enter a ticker to see how it rates.";
        }
        else if (!/^[A-Za-z]+$/.test(newVal)) {
          this.errormessage = true;
          this.errormessageText = "Please enter a valid ticker";
        }else if (/^[A-Z]{0,4}$/.test(newVal)){
          this.errormessage = true;
          this.errormessageText = "Please enter a valid ticker";
        } else {
          this.errormessage = false;
        }
      }
    },

    
    isMobileContentState(){
      this.isMobileContentToggle = this.isMobileContentState;
    },
   
  },
};
</script>
<style scoped>

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-x: overlay;
  } 
  ::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
  }

@media only screen and (min-width: 900px) and (max-width: 2500px) {
.bottom-margine-container{
  padding-bottom: 84px;
}
}
@media only screen and (min-width: 2500px) and (max-width: 3000px) {
.bottom-margine-container{
  padding-bottom: 184px;
}
}
@media only screen and (min-width: 3000px) and (max-width: 40000px) {
.bottom-margine-container{
  padding-bottom: 530px;
}
}


input::placeholder{
  font-size: 10px;
}

.mfi-fund-selector-submit-button {
  margin: auto;
}

.bottom-margine-container-responsive{
  padding-bottom: 10px;
}

.mfi-search-and-button {
  display: flex;
}

.mfi-fund-selector-main-div {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 0 auto;
}

.mfi-fund-selector-main-container {
  max-width: 480px;
}

.mfi-fund-selector-title-div {

}

.mfi-fund-selector-title {
  width: 450px;
  height: 106px;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
  margin-top: 41px;
  margin-bottom: 0px;
}

.mfi-Serach-field {
  width: 345px;
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {

.mfi-Serach-field ::-webkit-input-placeholder {
  font-size: 14px;
  margin: 0px;
  padding: 0px;

}
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {

.mfi-Serach-field ::-webkit-input-placeholder {
  font-size: 16px;
  margin: 0px;
  padding: 0px;

}
}


@media only screen and (min-width: 1100px) and (max-width: 1280px) {

.mfi-Serach-field ::-webkit-input-placeholder {
  font-size: 19px;
  margin: 0px;
  padding: 0px;

}
}

.mfi-fund-selector-description-div {}

.mfi-fund-selector-description-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  margin-bottom: 0px;
  margin-top: 32px;
}

.mfi-fund-selector-description {
  margin-top: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
}

.mfi-button {
  background: black;
  order: 2;
  color:#ffffff !important;
}

.mfi-button-responsive {
  width: 120px;
  padding-left: 19px;
  padding-right: 0px;
  height: 42px;
  background: black;
  order: 2;
  color:#ffffff !important;
}

.mfi-button-responsive>span{
  font-size: 10px;
}

.mfi-fund-info-card {
  margin-top: 41px;
  width: 645px;
  height: 650px;
  margin-left: -0.7%;
}

.mfi-card-title-text {
  font-weight: 700;
  font-size: 23px;
  line-height: 27px;
  color: #1e1e1e;
  margin-top: 0px;
  margin-bottom: 5px;
}

.mfi-title-ticker-text {
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 0px;
}

.mfi-card-table-part {
  display: flex;
  margin-top: 45px;
}

.mfi-loader-position {
  width: "276px";
  height: "69px";
  left: 60%;
  right: 50%;
  top: 35%;
  position: absolute;
}

.mfi-card-data-point {
  width: 195px;
  height: 400px;
  padding-right: 13px;
}

.mfi-card-value-point {
  width: 100%;
  height: 400px;
}

.mfi-card-score-point {
  width: 56px;
  height: 29px;
}

.mfi-card-table-points-title {
  height: 29px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mfi-border-in-table {
  border-top: #e5e5e5 solid 1px;
  margin-top: 12px;
  margin-bottom: 0px;
}

.mfi-border-in-table-one {
  width: 211px;
  margin-top: 12px;
  margin-bottom: 0px;
  border-top: #e5e5e5 solid 1px;
}

.mfi-border-in-table-driven {
  width: 60px;
  margin-top: 6px;
  margin-bottom: 4px;
  border-top: #e5e5e5 solid 1px;
}

.mfi-border-in-table-driven-and-data {
  width: 211px;
  margin-top: 6px;
  margin-bottom: 4px;
  border-top: #e5e5e5 solid 1px;
}

.mfi-data-points-name-text {
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin-right: 0px;
  color: #1e1e1e;
}

.mfi-info-icon {
  padding-top: 2px;
  padding-left: 1px;
  margin-left: 5px;
  cursor: pointer;
}

.mfi-value-points-data {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  margin-top: 10px;
  margin-bottom: 0px;
}

.mfi-manager-returns-one {
  margin-top: 12px;
  margin-bottom: 9px;
  margin-left: auto;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-manager-returns {
  margin-top: 0px;
  margin-bottom: 9px;
  margin-left: auto;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-manager-values-one {
  margin-top: 3px;
  margin-bottom: 9px;
  margin-left: auto;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-manager-values {
  margin-top: 0px;
  margin-bottom: 9px;
  margin-left: auto;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-performance-text {
  width: 552px;
  margin-top: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  margin-bottom: 0px;
}

.mfi-inline-message {
  width: 55px;
  margin-top: 5px;
}

.mfi-modal-main {
  height: 650px;
  display: flex;
  margin-top:-16px;
}

.mfi-right-side-modal-part {
  margin-left: auto;
  width: 625px;
  position: relative;
}

.mfi-modal-right-side-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
  margin-top: 5px;
  margin-bottom: 14px;
}

.mfi-modal-right-side-border {
  width: 625px;
  border-top: #333333 solid 2px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mfi-modal-right-side-second-part {
  margin-right: 200px;
}

.mfi-remove-icon {
  margin-left: 600px;
}

.mfi-modal-right-side-one-part {
  position: absolute;
}

.mfi-modal-class {
  height: 900pxt;
}

.mfi-paragraph-modal {
  width: 625px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 12px;
}
.mfi-paragraph-modal-second-para {
  width: 625px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 16px;
}

.mfi-main-modal-div>div>div>div {
  height: 1000px;
}

.mfi-border-below-threshold {
  margin-top: 24px;
  margin-bottom: 5px;
  border-top: #cccccc solid 1px;
}

.mfi-threshold-below-div {
  margin-left: auto;
}

.mfi-manager-data-title {
  margin-top: 0px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
}

.mfi-manager-data-ticker {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
}

.mfi-manager-data-title-responsive {
  margin-top: 0px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
}

.mfi-manager-data-ticker-responsive-one {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
}


.mfi-manager-data-ticker-responsive {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
}

.mfi-medalist-rating-title {
  margin-top: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
  margin-bottom: 12px;
}
.mfi-medalist-rating-title-responsive {
  margin-top: 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #5e5e5e;
  margin-bottom: 12px;
}

.mfi-medalist-rating-div-leftside {
  display: flex;
}

.mfi-left-side-management-data-titles {
  display: flex;
}

.mfi-card-title-ticker-rating {
  display: flex;
  height: 27px;
}

.mfi-morningstar-medalist-rating-pragraph {
  margin-top: 0px;
  padding-top: 3px;
  margin-left: 0px;
  margin-bottom: 0px;
  width: 122px;
  height: 49px;
}

.mfi-morningstar-rating-pragraph {
  margin-top: 0px;
  padding-top: 3px;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 122px;
  height: 49px;
}

.mfi-analyst-driven-and-percentage {
  width: 60px;
  text-align: right;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-driven-and-data-titles {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-data-anad-driven-div {
  margin-left: 82px;
}

.mfi-management-team-final-div {
  margin-top: 32px;
}

.mfi-title-for-management-team {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
  margin-top: 0px;
}
.mfi-title-for-management-team-responsive {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #5e5e5e;
  margin-top: 0px;
}

.mfi-management-team-name-display {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  margin-top: 0px;
  margin-bottom: 4px;
}

.mfi-management-team-date-display {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mfi-list-for-management-team {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 474px;
}

.mfi-threshold-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
  margin-top: 5px;
}

.mfi-threshold-title-para {
  width: 625px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  margin-top:1.5%
}
.mfi-management-bar-and-data {
  display: flex;
}
.managerteam-range-price {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-investoment-range {
  padding-left: 22.5px;
}

.mfi-management-range-level-div {
  margin-left: 40px;
}

.mfi-title-level-bar {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-management-teams-names {
  width: 190px;
}

.blurred-div-for-manager{
  min-height: 245px;
  max-height: 245px;
  overflow-y: hidden;
  background: transparent;
  -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.non-blurred-div-for-manager{

}

.blurred-div-for-manager-responsive{
  min-height: 330px;
  max-height: 330px;
  overflow-y: hidden;
  background: transparent;
  -webkit-mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.non-blurred-div-for-manager-responsive{
 
}

.mfi-team-management-linear-div {
  min-height: 245px;
  max-height: 245px;
  overflow-y: hidden;
  background: transparent;
  -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.mfi-readmore-tab {
  margin-left: 140px;
}

.mfi-readmore-text {
  cursor: pointer;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
}

.mfi-modal-right-side-border-threshold {
  width: 625px;
  border-top: #e5e5e5 solid 1px;
  margin-top: 3%;
  margin-bottom: 0px;
}

.mfi-href-link {
  color: #000000;
}
.mfi-list-group>div>ul>li>span>div>div>div>span {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
}
.mfi-list-title-tesxts {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
}
.mfi-fund-spy-error-message {
  font-size: 14px;
  color: red;
  margin-left: auto;
  margin-top: 8px;
}

.view-team-angle {
  cursor: pointer;
}
.mfi-modal-class>div>div>mds-section {
  overflow-y: scroll;
}
.fundinvestor-model-section{
  margin-top:ma-110px;
}



/*Responsive*/
.mfi-search-bar-anddescription-div-responsive{
  width: 90%;
  margin-left: 5%;
}
.mfi-fund-selector-title-div-responsive{
  width: 100%;
}
.mfi-fund-selector-title-responsive {
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #1e1e1e;
  margin-top: 41px;
  margin-bottom: 24px;
}
.mfi-fund-selector-submit-button {
 margin-left: 16px;
 margin-right: 0px;
 padding: 0px;
}





.mfi-fund-info-card-responsive>div:nth-child(1){
  background-color: #ffffff !important;
  border: solid 1px #cccccc !important;
  border-radius: 6px !important;
  display: inline-block !important;
  max-width: 100% !important;
  overflow: hidden !important;
  width: 100% !important;
  height: 100% !important;
  padding: 16px !important;
  margin-top: 40px;
  text-decoration: none !important;
}

.ticker-and-star-responsive{
  display: flex;
}


.mfi-card-header-responsive{
  display: flex;
}

.mfi-card-table-points-title-responsive{
  height: 29px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  margin-top: 0px;
  margin-bottom: 0px;

}

.mfi-card-table-points-title-responsive-score{
 
  height: 29px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 20px;

}

.mfi-analyst-points-div-responsive{
  width: 100%;
  display: flex;
}

.mfi-data-anad-driven-div-responsive {
  width: 70%;
  margin-left: 0px;
}

.mfi-rating-numerical-div{
  width: 30%;
  text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
}

.mfi-management-teams-names-responsive{
  width: 100%;
  display: flex;
}

.mfi-manager-name-div{
  width: 55%;
}

.mfi-manager-date-div{
  width: 45%;
  
}
.mfi-modal-right-side-one-part-responsive {
  width: 100%;
}

.mfi-right-side-modal-part-responsive {
  width: 100%;
  height: 100%;
  position: relative;
}

.mfi-modal-right-side-border-responsive {
  width: 100%;
  border-top: #333333 solid 2px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mfi-modal-right-side-border-threshold-responsive {
  width: 100%;
  border-top: #e5e5e5 solid 1px;
  margin-top: 3%;
  margin-bottom: 0px;
}

.mfi-paragraph-modal-responsive {
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-top: 16px;
}
.mfi-paragraph-modal-second-para-responsive {
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 16px;
}

.mfi-modal-right-side-second-part-responsive {
  height: 40px;
margin-left: -75px;
margin-right: -10px;
display: flex;
}

.mfi-threshold-title-para-responsive {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  margin-top:1.5%
}


.mfi-remove-icon-responsive {
  margin-left: 6px;
  padding-top: 4px;
}

.mds-modal__content{
  height: 500px;
}

.definitions-name-and-close-icon-responsive{
  display: flex;
}

.definitions-close-button-div{
  padding-left: 250px;

}

.mfi-modal-right-side-border-responsive-definition {
  width: 100%;
  border-top: #333333 solid 2px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.mfi-remove-icon-responsive-definition {
  padding-top:4px;
  padding-left: 70%;
}

.mfi-modal-right-side-second-part-responsive-definition {
 width: 20%;

}
.mfi-modal-right-side-title-responsive{
  width: 70%;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  margin-top: 5px;
  margin-bottom: 14px;
}

.mfi-modal-right-side-title-responsive-definition{
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  margin-top: 5px;
 
  margin-bottom: 14px;
}
.definition-title-div-responsive{
  
  width: 80%;
}
.mfi-management-range-level-div-responsive {
  width: 100%;
  margin-left: 0px;
}

.mfi-title-level-bar-responsive {
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-list-for-management-team-responsive {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
}

.mfi-management-team-date-display-responsive {
  text-align: justify; /* For Edge */
  -moz-text-align-last: right; /* For Firefox prior 58.0 */
  text-align-last: right;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  margin-top: 0px;
  margin-bottom: 0px;

}

.mfi-management-team-final-div-responsive {
  margin-top: 32px;
  
}

.mfi-team-management-linear-div-responsive {
  min-height: 400px;
  max-height: 410px;
  overflow-y: hidden;
  background: transparent;
  -webkit-mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.mfi-readmore-text-responsive {
 
  cursor: pointer;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #5e5e5e;
}

.mfi-readmore-tab-responsive {
  text-align: justify; /* For Edge */
  text-align-last: center;
  margin-bottom: 30px;
  margin-top: 10px;
}
.view-team-angle-responsive {
  padding-top: 2px;
  cursor: pointer;
}

.mfi-analyst-driven-and-percentage-responsive {
  width: 100%;
  text-align: right;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.mfi-border-in-table-driven-responsive {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 4px;
  border-top: #e5e5e5 solid 1px;
}

.mfi-border-in-table-driven-and-data-responsive {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 4px;
  border-top: #e5e5e5 solid 1px;
}

.mfi-card-css{
  padding: 32px;
}

.mfi-card-css-responsive{
  padding: 16px;
}

.mfi-card-table-part-responsive {
  width: 110%;
  margin-left: 19px;
  display: flex;
  margin-top: 0px;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {

.mfi-card-data-point-responsive {
  width: 41.5%;
  height: 100%;
  padding-right: 0px;
}
}

@media only screen and (min-width: 480px) and (max-width: 600px) {

.mfi-card-data-point-responsive {
  width: 41.5%;
  height: 100%;
  margin-right: 4px;
}
}

@media only screen and (min-width: 600px) and (max-width: 700px) {

.mfi-card-data-point-responsive {
  width: 42%;
  height: 100%;
  margin-right: 4px;
}
}

@media only screen and (min-width: 700px) and (max-width: 800px) {

.mfi-card-data-point-responsive {
  width: 42.3%;
  height: 100%;
  margin-right: 4px;
}
}

@media only screen and (min-width: 800px) and (max-width: 900px) {

.mfi-card-data-point-responsive {
  width: 42.3%;
  height: 100%;
  margin-right: 4px;
}
}
@media only screen and (min-width: 900px) and (max-width: 1000px) {

.mfi-card-data-point-responsive {
  width: 43.5%;
  height: 100%;
  margin-right: 4px;
}
}



.mfi-card-value-point-responsive{
  margin-top: -30px;
  height: 100%;
}
.mfi-performance-text-responsive {
  width: 100%;
  margin-top: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  margin-bottom: 0px;
}

.mfi-title-ticker-text-responsive {
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #5E5E5E;
  margin-top: 5px;
  margin-bottom: 25px;
}
.mfi-morningstar-rating-pragraph-responsive {
  margin-top: 0px;
  padding-top: 3px;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 122px;
  height: 18px;
}
.mfi-card-title-text-responsive {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
  margin-top: 0px;
  margin-bottom: 5px;
}
.first-td-expense-ratio td{
  width: 167px;
}

.second-td-expense-ratio{
  width: 95px;
}

.mfi-data-points-name-text-responsive {
  width: 167px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin-right: 0px;
  color: #1e1e1e;
}

.mfi-card-table-css{
  width: 100%;
}

.mfi-card-table-css>tbody>tr>td{
  width: 25%;
}

.mds-td:first-child {
        width: 10px;
    }

.mfi-card-table-titles-responsive{
  width: 100%;
  display: flex;
}
   
.mfi-fund-selector-description-responsive {
  margin-top: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  margin-bottom: 10px;
}

.mfi-loader-position-responsive {
  position: relative;
  margin-top: 38px;
  margin-bottom: 5px;
  
}

@media only screen and (min-width: 900px) and (max-width: 1280px) {

  .mfi-card-table-points-title {
  height: 29px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
  margin-top: 35px;
  margin-bottom: 0px;
}

.mfi-fund-info-card {
  margin-top: 41px;
  width: 100%;
  height: 650px;
  margin-left: -0.7%;
}

.mfi-fund-selector-main-div {
  width: 90%;
  margin-left: 5%;
  
}
.mfi-medalist-rating-div-leftside {
  width: 100%;
  display: flex;
}

.mfi-performance-text {
  width: 100%;
  margin-top: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  margin-bottom: 0px;
}
.mfi-card-title-text {
 
 
  font-weight: 700;
  font-size: 23px;
  line-height: 27px;
  color: #1e1e1e;
  margin-top: 0px;
  margin-bottom: 5px;
}
.mfi-title-ticker-text {
  
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mfi-search-and-button{
  width: 100%;
}

.mfi-Serach-field {
  width: 65%;
}

.mfi-fund-selector-submit-button {
  width: 35%;
 margin-left: 16px;
 margin-right: 0px;
 padding: 0px;
}

}



@media only screen and (min-width: 1280px) and (max-width: 12000px) {

  .mfi-Serach-field {
  width: 67%;
}

.mfi-fund-selector-submit-button {
  width: 36%;
 margin-left: 16px;
 margin-right: 0px;
 padding: 0px;
}

}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
.mfi-fund-selector-main-div {
  width: 90%;
  margin-left: 5%;
  margin-top: 0px;
  
}
}


@media only screen and (min-width: 6000px) and (max-width: 12000px) {
.mfi-fund-selector-main-div {
  
  margin-top: 40px;
  
}
}
@media only screen and (min-width: 1201px) and (max-width: 1280px) {
.mfi-fund-selector-main-div {
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
}
}

@media only screen and (min-width: 900px) and (max-width: 950px) {
  .mfi-data-anad-driven-div {
 
 margin-left: 0%;
}
}

@media only screen and (min-width: 900px) and (max-width: 98000px) {
.mfi-modal-right-side-one-part {
margin-left: 10px;
}
}

@media only screen and (min-width: 950px) and (max-width: 1000px) {
  .mfi-data-anad-driven-div {
 
 margin-left: 4%;
}
}

@media only screen and (min-width: 1000px) and (max-width: 1050px) {
  .mfi-data-anad-driven-div {
 
 margin-left: 10%;
}
}
@media only screen and (min-width: 1050px) and (max-width: 1100px) {
  .mfi-data-anad-driven-div {
 
 margin-left: 13%;
}
}
.mfi-search-and-button-responsive{
  width: 100%;
  display: flex;
}

.mfi-Serach-field-responsive {
  width: 85%;
}

.mfi-fund-selector-submit-button-responsive {
 
 margin-left: 16px;
 margin-right: 0px;
 padding: 0px;
}

.mfi-modal-main-responsive {
  max-height: 415px;
  display: flex;
  margin-top: -16px;
 
}
@media only screen and (min-width:320px) and (max-width:900px){
  .mfi-fund-selector-description-title{
    margin-top: 24px;
  }
}

</style>
<style>
.fundinvestor-model-section>div:first-child {
  border-top: none !important;
}
.funndselector-card-layout{
  padding:0 !important;
}

.mfi-modal-class>div>div{
 

 padding-left: 0px !important;
 padding-right: 0px !important;
 padding-top: 0px !important;
 padding-bottom: 16px !important;
}


.mfi-modal-class-responsive>div>div{
 

  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 16px !important;
}

.mfi-main-modal-div>div>div>div>section{
  
  padding-right: 16px !important;

  overflow-y: scroll !important;
 }




.mfi-main-modal-div-responsive>div>div>div>section{
  
  padding: 18px !important;

  overflow-y: scroll !important;
 }

@media only screen and (min-width: 600px) and (max-width: 12000px) {
  .mfi-fund-info-card-responsive>div:nth-child(1){
  background-color: #ffffff !important;
  border: solid 1px #cccccc !important;
  border-radius: 6px !important;
  display: inline-block !important;
  max-width: 100% !important;
  overflow: hidden !important;
  width: 650px !important;
  height: 680px !important;
  padding-bottom: 32px !important;
  text-decoration: none !important;
}

}

.mfi-fund-info-card>div:nth-child(1)>h2{
  margin-bottom: 0px !important;
}
.mfi-main-modal-div .mds-modal__content{
 
 
 padding-left: 32px !important;
 padding-top: 32px !important;
 padding-bottom: 32px !important;
 padding-right: 32px !important;
 
}
</style>
