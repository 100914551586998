var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-combine"},[_c('ad',{attrs:{"currentPage":"homepage"}}),_c('div',{staticClass:"landing-page-middle"},[_c('div',{staticClass:"landing-page-middle-container"},[_c('div',{staticClass:"landing-page-middle-right-div"},[_vm._m(0),(!this.$store.state.user.LoggedIn || _vm.productsubscriptionoverlayStatus == true)?_c('div',[_c('p',{staticClass:"border-line"})]):_vm._e(),(!this.$store.state.user.LoggedIn || _vm.productsubscriptionoverlayStatus == true)?_c('div',{staticClass:"combine-hero-image-buttons"},[_c('mds-button-container',[_c('mds-button',{staticClass:"button-learn-more",attrs:{"variation":"secondary","size":_vm.learnMoreButtonSize,"icon-right":"caret-down","id":"learnmore-trigger"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_vm._v("Learn More ")]),_c('mds-popover',{staticClass:"hero-image-learn-more-popover",attrs:{"width":"300px","triggered-by":"learnmore-trigger"},model:{value:(_vm.toggle),callback:function ($$v) {_vm.toggle=$$v},expression:"toggle"}},[_c('p',{staticClass:"list-product-trial"},[_vm._v("START A NEWSLETTER TRIAL")]),_c('mds-list-group',{attrs:{"size":"medium","rightAligned":""}},[_c('mds-list-group-item',{attrs:{"to":{ name:'marketing-landing',
                        params: { currentproduct: 'mfi'}}}},[_c('span',{staticClass:"list-product-name"},[_vm._v(" Morningstar FundInvestor ")])]),_c('mds-list-group-item',{attrs:{"to":{ name:'marketing-landing',
                        params: { currentproduct: 'msi'}}}},[_c('span',{staticClass:"list-product-name"},[_vm._v(" Morningstar StockInvestor ")])]),_c('mds-list-group-item',{attrs:{"to":{ name:'marketing-landing',
                        params: { currentproduct: 'mdi'}}}},[_c('span',{staticClass:"list-product-name"},[_vm._v(" Morningstar DividendInvestor ")])]),_c('mds-list-group-item',{attrs:{"to":{ name:'marketing-landing',
                        params: { currentproduct: 'mei'}}}},[_c('span',{staticClass:"list-product-name"},[_vm._v(" Morningstar ETFInvestor ")])])],1)],1),(!this.$store.state.user.LoggedIn)?_c('mds-button',{staticClass:"button-sign-in",attrs:{"on-dark":"","variation":"secondary","size":"large"},on:{"click":function($event){return _vm.login()}}},[_vm._v(" Sign In")]):_vm._e()],1)],1):_vm._e(),(!this.$store.state.user.LoggedIn)?_c('div',{staticClass:"landing-bottom-text-div"},[_c('span',{staticClass:"landing-bottom-text"},[_vm._v(" If you are a print subscriber visiting for the first time, complete a ")]),(!_vm.isResponsiveToggle)?_c('br'):_vm._e(),_c('router-link',{staticClass:"registration-link",attrs:{"to":{
                name: 'print-registration',
                params: {
                  currentproduct: this.$store.state.product.CurrentProduct,
                },
              }}},[_vm._v(" one-time registration. ")])],1):_vm._e(),(this.$store.state.user.LoggedIn && _vm.productsubscriptionoverlayStatus == false)?_c('div',{staticClass:"landing-page-after-login"},[_c('p',{staticClass:"landing-text-after-login"},[_vm._v(" Navigate to your newsletter’s homepage: ")]),_vm._m(1),(!_vm.isResponsiveToggle)?_c('div',{staticClass:"common-login-product-navigation"},_vm._l((this.products),function(product,index){return _c('div',{key:index},[(product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"angle-double-right"}}):_vm._e(),(!product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"lock-close"}}):_vm._e(),_c('span',[_c('router-link',{staticClass:"landingpage-navgation-button product-navigate-style",attrs:{"to":{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}}},[_vm._v(_vm._s(product.product))])],1)],1)}),0):_c('div',{staticClass:"common-login-product-navigation"},_vm._l((this.products),function(product,index){return _c('div',{key:index},[(product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"angle-double-right"}}):_vm._e(),(!product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"lock-close"}}):_vm._e(),_c('router-link',{staticClass:"landingpage-navgation-button product-navigate-style",attrs:{"to":{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}}},[_vm._v(_vm._s(product.product))])],1)}),0)]):_vm._e(),(_vm.productsubscriptionoverlayStatus == true)?_c('div',{staticClass:"landing-page-after-login-for-non-subcribed-user"},[_c('p',{staticClass:"landing-text-after-login-for-non-subcribed-user"},[_vm._v(" Navigate to your newsletter’s homepage: ")]),_vm._m(2),(!_vm.isResponsiveToggle && _vm.productsubscriptionoverlayStatus == false)?_c('div',{staticClass:"common-login-product-navigation"},_vm._l((this.products),function(product,index){return _c('div',{key:index},[(product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"angle-double-right"}}):_vm._e(),(!product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"lock-close"}}):_vm._e(),_c('span',[_c('router-link',{staticClass:"landingpage-navgation-button product-navigate-style",attrs:{"to":{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}}},[_vm._v(_vm._s(product.product))])],1)],1)}),0):_vm._e(),(_vm.isResponsiveToggle && _vm.productsubscriptionoverlayStatus == false)?_c('div',{staticClass:"common-login-product-navigation"},_vm._l((this.products),function(product,index){return _c('div',{key:index},[(product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"angle-double-right"}}):_vm._e(),(!product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"lock-close"}}):_vm._e(),_c('router-link',{staticClass:"landingpage-navgation-button product-navigate-style",attrs:{"to":{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}}},[_vm._v(_vm._s(product.product))])],1)}),0):_vm._e(),(_vm.productsubscriptionoverlayStatus == true)?_c('div',{staticClass:"common-login-product-navigation-for-none-product-access"},_vm._l((this.products),function(product,index){return _c('div',{key:index},[(product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"angle-double-right"}}):_vm._e(),(!product.access)?_c('mds-icon',{staticClass:"common-product-access-icon",attrs:{"name":"lock-close"}}):_vm._e(),_c('router-link',{staticClass:"landingpage-navgation-button product-navigate-style",attrs:{"to":{ name: product.access ? 'home' : 'marketing-landing',
                  params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase()}}}},[_vm._v(_vm._s(product.product))])],1)}),0):_vm._e()]):_vm._e()]),_c('div',{staticClass:"landing-page-middle-left-div"},[_c('banner-cover-images')],1)])]),_c('individual-newsletter-images'),_c('editor-featured-commentary'),_c('combine-videos'),_c('banner-bottom-cover-image'),_c('mds-modal',{staticClass:"modal-For-Redirect-from-lagecy",attrs:{"width":"600px","size":"small","aria-label":"Default Modal"},model:{value:(_vm.RedirectFromLagecy),callback:function ($$v) {_vm.RedirectFromLagecy=$$v},expression:"RedirectFromLagecy"}},[_c('mds-section',{attrs:{"bold":""}},[[_c('mds-button-container',{staticClass:"legacy-modal-close-button-div",attrs:{"right-aligned":""}},[_c('mds-button',{attrs:{"variation":"icon-only","icon":"remove"},on:{"click":function($event){return _vm.ClosingTheWelcomeModel()}}},[_vm._v("Close")])],1)],[_c('h1',{staticClass:"title-for-lagecy-modal"},[_vm._v("Welcome to the new Investor Newsletters website!")]),_c('h1',{staticClass:"modal-lagecy-para-one-border"}),_c('p',{staticClass:"modal-lagecy-para-one"},[_vm._v("We have taken the four, individual websites and combined them into one experience and homepage. The new homepage will give you easy access to your current issues, updated portfolio data, archives, and bonus reports.")]),_c('p',{staticClass:"modal-lagecy-para-second"},[_vm._v("Check out our "),_c('a',{staticClass:"getting-start-modal-anchor-tag",attrs:{"href":"https://www.morningstar.com/company/newsletters-help-center","target":"_blank"}},[_vm._v("Getting Started")]),_vm._v(" page for additional details.")])]],2)],1),(_vm.productsubscriptionoverlayStatus)?_c('product-subscription-overlay',{attrs:{"subscriptionoverlayStatus":_vm.productsubscriptionoverlayStatus},on:{"resetOverlayStatusValue":_vm.resetOverlayStatus}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-page-middle-text-div"},[_c('p',{staticClass:"landing-middle-text"},[_vm._v("Morningstar Investor Newsletters")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"border-line-after-login"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"border-line-after-login"})])
}]

export { render, staticRenderFns }