<template>
	<ul
		role="listbox"
		class="mdc-list-group"
		:class="{
			[$style['mds-list-group']]: true,
			[$style['mds-list-group--large']]: large,
			[$style['mds-list-group--small']]: small,
			[$style['mds-list-group--no-rules']]: borderless,
			[$style['mds-list-group--spacious']]: spacious,
			[$style['mds-list-group--with-icon']]: withIcon,
			[$style['mds-component--on-dark']]: onDark,
		}"
		tabindex="-1"
	>
		<slot />
	</ul>
</template>

<style lang="scss" module>
@import '@mds/list-group/src/list-group.scss';
</style>

<script>
export default {
	props: {
		large: {
			type: Boolean,
			description: 'Render as large style variant',
			default: false
		},
		small: {
			type: Boolean,
			description: 'Render as small style variant',
			default: false
		},
		borderless: {
			type: Boolean,
			description: 'Remove rules between items',
			default: false
		},
		spacious: {
			type: Boolean,
			description: 'Render as with additional whitespace between items',
			default: false
		},
		withIcon: {
			type: Boolean,
			description: 'Indicate icons will be included within items',
			default: false
		},
		onDark: {
			type: Boolean,
			description: 'Render in context with a dark background',
			default: false
		}
	}
};
</script>
