<template>
	<mdc-popover
		narrow
		:bottom-center="bottomCenter"
		:bottom-right="bottomRight"
		:right-center="rightCenter"
		:top-right="topRight"
		:top-center="topCenter"
		:top-left="topLeft"
		:left-center="leftCenter"
		:bottom-left="bottomLeft"
		:dismissible="!isEditing"
		:expand-control="truncateDropdownButton"
		role="dialog"
		class="mdc-dropdown-select"
		@open="onOpen"
		@closed="onClose"
		@arrowUp="moveActive(-1)"
		@arrowDown="moveActive(1)"
	>
		<template slot="target" slot-scope="{ open, close, toggle, ariaControls, id }">
			<slot
				:id="id"
				name="target"
				:aria-controls="ariaControls"
				:open="open"
				:close="close"
				:toggle="toggle"
			>
				<mdc-button
					:id="id"
					ref="button"
					:disabled="disabled"
					:flat="flat"
					:aria-label="ariaLabel"
					:aria-controls="ariaControls"
					aria-haspopup="true"
					:aria-expanded="`${isOpen}`"
					:small="small"
					:large="large"
					:truncate-content="truncateDropdownButton"
					:icon="icon"
					:icon-only="iconOnly"
					:right-icon="iconOnly ? null : 'caret-down'"
					@click="toggle"
				>
					{{ activeLabel }}
				</mdc-button>
			</slot>
		</template>

		<template slot="content" slot-scope="{ open, close, toggle }">
			<div
				ref="options"
				class="mdc-dropdown-select__options"
				:class="{'mdc-dropdown-select__options--editable': showEditableState}"
			>
				<template
					v-for="(optionGroup, header, idx) in groupedOptions"
				>
					<mdc-header
						v-if="hasHeaders"
						:key="'header' + idx"
						bold
						size="7"
						class="mdc-dropdown-select__header"
					>
						{{ header }}
					</mdc-header>
					<mdc-list-group
						:key="header + idx"
						borderless
						aria-label="Dropdown"
					>
						<mdc-list-group-item
							v-for="option in optionGroup"
							:key="option.value"
							ref="option"
							:active="equals(option.value, value)"
							:editable="isEditing && !option.readOnly"
							:truncate-text="truncateOptionsText"
							:icon="option.icon"
							@keydown.space="onSelect(option, close)"
							@keydown.enter.prevent="onSelect(option, close)"
							@click="onSelect(option, close)"
						>
							{{ option.text }}
						</mdc-list-group-item>
					</mdc-list-group>
				</template>
			</div>

			<slot name="actions" :open="open" :close="close" :toggle="toggle" />

			<div v-if="showEditableState" class="mdc-dropdown-select__state">
				<mdc-button small flat aria-label="Show Edit Controls" :icon="isEditing ? null : 'gear'" class="mdc-dropdown-select__edit" @click="onToggleEditing">
					{{ isEditing ? 'Done': 'Edit' }}
				</mdc-button>
			</div>
		</template>
	</mdc-popover>
</template>

<script>
import every from 'lodash/every';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import findIndex from 'lodash/findIndex';

import MdcIdMixin from "../mixins/MdcIdMixin"; 

import MdcButton from './MdcButton.vue';
import MdcListGroup from './MdcListGroup';
import MdcListGroupItem from './MdcListGroupItem';
import MdcHeader from './MdcHeader';
import MdcPopover from './MdcPopover';


export default {
	components: {
		MdcButton,
		MdcListGroup,
		MdcListGroupItem,
		MdcHeader,
		MdcPopover
	},
	mixins: [
		MdcIdMixin
	],
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		ariaLabel: {
			type: String,
			description: 'Descriptive label for use with assistive technology',
			default: null
		},
		title: {
			type: String,
			description: 'Title of dropdown to display as prefix of current value',
			default: null
		},
		editable: {
			type: Boolean,
			description: 'Indicate entities in dropdown can be edited',
			default: false
		},
		error: {
			type: [Boolean, String],
			description: 'Error message or error indicator',
			default: null
		},
		label: {
			type: String,
			description: 'Descriptive label of text field',
			default: null
		},
		name: {
			type: String,
			description: 'Form field name',
			default: null
		},
		icon: {
			type: String,
			description: 'Icon for use on target',
			default: undefined
		},
		iconOnly: {
			type: Boolean,
			description: 'Renders target as an icon only button',
			default: false
		},
		onDark: {
			type: Boolean,
			description: 'Renders target in dark contexts',
			default: false
		},
		disabled: {
			type: Boolean,
			description: 'Render element as disabled',
			default: false
		},
		required: {
			type: Boolean,
			description: 'Indicate field is required',
			default: false
		},
		small: {
			type: Boolean,
			description: 'Small size variation',
			default: false
		},
		large: {
			type: Boolean,
			description: 'Large size variation',
			default: false
		},
		topCenter: {
			type: Boolean,
			description: 'Align popover to the top and center of invoking element',
			default: false
		},
		topRight: {
			type: Boolean,
			description: 'Align popover to the top and right of invoking element',
			default: false
		},
		topLeft: {
			type: Boolean,
			description: 'Align popover to the top and left of invoking element',
			default: false
		},
		bottomCenter: {
			type: Boolean,
			description: 'Align popover to the bottom and center of invoking element',
			default: false
		},
		bottomRight: {
			type: Boolean,
			description: 'Align popover to the bottom and right of invoking element',
			default: false
		},
		bottomLeft: {
			type: Boolean,
			description: 'Align popover to the bottom and left of invoking element',
			default: false
		},
		rightCenter: {
			type: Boolean,
			description: 'Align popover to the right and center of invoking element',
			default: false
		},
		rightTop: {
			type: Boolean,
			description: 'Align popover to the right and top of invoking element',
			default: false
		},
		rightBottom: {
			type: Boolean,
			description: 'Align popover to the right and bottom of invoking element',
			default: false
		},
		leftCenter: {
			type: Boolean,
			description: 'Align popover to the left and center of invoking element',
			default: false
		},
		leftTop: {
			type: Boolean,
			description: 'Align popover to the left and top of invoking element',
			default: false
		},
		leftBottom: {
			type: Boolean,
			description: 'Align popover to the left and bottom of invoking element',
			default: false
		},
		flat: {
			type: Boolean,
			description: 'Display as flat button',
			default: false
		},
		options: {
			type: Array,
			description: 'Key/value pairs of presented options of the form [{ text, value, readOnly }]',
			default: () => ([]),
			validator: values => every(values, v => !isUndefined(v.text) && !isUndefined(v.value))
		},
		value: {
			type: [Array,String],
			description: 'Current selected value',
			default: () => ([])
		},
		truncateOptionsText: {
			type: Boolean,
			description: 'Truncate text within the options list group items',
			default: false
		},
		truncateDropdownButton: {
			type: Boolean,
			description: 'Truncate text within the dropdown button',
			default: false
		},
		staticLabel: {
			type: Boolean,
			description: 'Show label rather than value in closed state',
			default: false
		}
	},
	data() {
		return {
			isEditing: false,
			isOpen: false,
			scrollPosition: 0,
		};
	},
	computed: {
		activeLabel() {
			const active = this.staticLabel ? null : find(this.options, o => this.equals(this.value.replace("\r", ""), o.value));
			const label = active ? active.text : this.label;
			return this.title ? `${this.title}: ${label}` : label;
		},
		showEditableState() {
			return this.editable && filter(this.options, o => !o.readOnly).length > 0;
		},
		hasHeaders() {
			return every(this.options, option => !isUndefined(option.group));
		},
		groupedOptions() {
			return groupBy(this.options, 'group');
		}
	},
	watch: {
		showEditableState(isEditableState) {
			if (this.isEditing && !isEditableState) this.isEditing = false;
		}
	},
	methods: {
		onToggleEditing() {
			this.isEditing = !this.isEditing;
		},
		onSelect(option, cb) {
			if (this.isEditing) {
				if (!option.readOnly) this.onEdit(option);
			} else {
				this.$emit('change', option.value);
				if (cb) cb();
			}

		},
		onOpen() {
			this.isOpen = true;
			const index = this.getIndexByValue(this.value);
			this.scrollByIndex(index);
		},
		onClose() {
			this.isOpen = false;
			this.isEditing = false;
		},
		equals(v1, v2) {
			if (v1 === null || v2 === null) return false;
			return `${v1}` === `${v2}`;
		},
		getIndexByValue(value) {
			return findIndex(this.options, option => option.value === value);
		},
		scrollByIndex(index) {
			const container = this.$refs.options;
			if (!container) return;

			if (index === 0) {
				container.scrollTop = 0;
			} else if (container.scrollHeight > container.clientHeight && index >= 0) {
				if (this.$refs.option && this.$refs.option[index]) {
					const element = this.$refs.option[index].$el;
					const scrollBottom = container.clientHeight + container.scrollTop;
					const elementBottom = element.offsetTop + element.offsetHeight;
					if (elementBottom > scrollBottom) {
						container.scrollTop = elementBottom - container.clientHeight;
					} else if (element.offsetTop < container.scrollTop) {
						container.scrollTop = element.offsetTop;
					}
				}
			}
		},
		moveActive(steps) {
			const currentFocusIndex = findIndex(this.$refs.option, item => item.$el.firstChild === document.activeElement);
			const newIndex = currentFocusIndex + steps;

			if (currentFocusIndex >= 0 && newIndex > -1 && newIndex < this.options.length) {
				if (this.isOpen) {
					this.$refs.option[newIndex].$el.firstChild.focus();
					this.scrollByIndex(newIndex);
				}
			}
		},
		focus() {
			if (this.$refs.button) this.$refs.button.focus();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@mds/constants";
@import '@mds/typography';
.mdc-dropdown-select {
	&--truncated {
		width: 100%;
	}

	&__select {
		width: 100%;
	}

	&__state {
		text-align: right;
		position: absolute;
		top: $mds-space-2-x;
		right: $mds-space-2-x;
	}

	&__options {
		&--editable {
			margin-top: $mds-space-3-x;
		}

		position: relative;
		margin: -$mds-space-1-x;
		padding: $mds-space-1-x;
		max-height: 595px;
		overflow-y: auto;
	}

	&__header {
		margin-top: $mds-space-3-x;
		margin-bottom: $mds-space-1-x;

		&:first-child {
			margin-top: 0;
		}
	}
}
</style>
