<template>
<div class="more-videos-main-div">
    <ad currentPage="videos"></ad>
    <div class="more-videos-title">
        <div v-if="!this.$store.state.user.LoggedIn">
            <a href="" @click="redirectHomePage()">Newsletters Home</a> / Videos
        </div>
        <div v-if="this.$store.state.user.LoggedIn && this.bProductSelect == false">
            <a href="" @click="redirectHomePage()">Newsletters Home</a> / Videos
        </div>
        <div v-if="this.$store.state.user.LoggedIn && this.bProductSelect==true" :key="bProductSelect">
            <a href="" @click="redirectHomePage()">Newsletters Home</a> /
            <a href="" @click="redirectSelectedProduct()">{{activeProductText}}</a> / Videos
        </div>
    </div>
    <div class="more-videos-product-selector">
          <mds-button size="large" variation="secondary" icon-right="caret-down" @click="moreVideosProductSelectorToggle = !moreVideosProductSelectorToggle" id="more-videos-product-selector-trigger" class="more-videos-product-selector-button"> {{ productSelectorButtonName }} </mds-button>
          <mds-popover class="more-videos-product-selector-popover" triggered-by="more-videos-product-selector-trigger" v-model="moreVideosProductSelectorToggle" :position="['bottom-right']">
            <mds-list-group v-for="(productSelector, index) in productSelectorData" :key="index">
                <mds-list-group-item :text="productSelector.productName" aria-current="true" :active="productSelector.isActive" @click.native.stop="popoverProductSelected(index)"></mds-list-group-item>
            </mds-list-group>
          </mds-popover>
    </div>
     <!-- Section MSI -->
    <div class="more-videos-section more-videos-section-first-div" id="sectionMSI">
        <div class="more-videos-top-border"></div>
        <div class="more-video-product-title">
            <span>StockInvestor</span>
        </div>
        <div class="video-loader" v-if="isLoadingVideoMSI">
            <mds-loader  aria-label="Medium Loader"></mds-loader>
        </div>
        
        <div v-if="isDisplayMSI" class="more-videos-section-container">
            <div class="more-videos-left-panel">
                <div class="more-videos-active-video">
                    <div class="more-videos-active-video-button">
                        <mds-button variation="primary" id="DefaltVideoButton" style="display:none;"  @click="FirstVideoClick('MSI')">
                            <span style="position: absolute; top: 18%">
                                <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                            </span>
                            <span style="margin-left: 20px">Watch</span>
                        </mds-button>
                    </div>
                    <video crossorigin="anonymous" class="more-videos-active-video-size" :ref="ref" type="video/mp4" preload="auto" data-setup="{}" v-bind:poster="ImageThumb" :src="VideoURL" :id="VideoId">
                        <track kind="subtitles" label="English" :src="closedCaptionsMSI" srclang="en" default>
                    </video>
                </div>
                <div class="more-videos-active-video-details">
                    <div class="more-videos-active-title">{{activeTitleMSI}}</div>
                    <div class="more-videos-active-description">{{activeDescriptionMSI}}</div>
                    <div class="more-videos-active-publishDate">{{activePublishDateMSI}}</div>
                    <a href="" @click="redirectVideoTranscript('MSI')">
                        <mds-button variation="secondary" size="small" style="position: relative;cursor:pointer;" class="button-transcript">
                            View Transcript <mds-icon name="angle-right" size="small" class="icon-transcript"></mds-icon>
                        </mds-button>
                    </a>
                </div>
            </div>
            <div class="more-videos-right-panel" @scroll="scrollToDiv('MSI')" id="msi-scroll">
                <div v-for="video in videosMSI" :key="video.ref" :id="video.VideoClass" class="more-videos-right-image-and-description-container">
                    <div class="more-videos-right-image-container">
                        <div class="more-videos-right-image-play-button">
                            <mds-button variation="primary" @click="onVideoClick(video.buttonWatchId,video.ImageThumb,video.VideoURL,video.VideoId,video.Title,video.VideoDescription,video.ref,video.VideoClass,'MSI',video.PublishDate,video.closedCaptionsMSI)" :id="video.buttonWatchId">
                                <span style="position: absolute; top: 18%">
                                    <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                </span>
                                <span style="margin-left: 20px">Watch</span>
                            </mds-button>
                        </div>
                        <img :src="video.ImageThumb" class="more-videos-right-img" />
                    </div>
                    <div class="more-videos-right-video-details-container">
                        <div class="more-videos-right-title" @click="onVideoClick(video.buttonWatchId,video.ImageThumb,video.VideoURL,video.VideoId,video.Title,video.VideoDescription,video.ref,video.VideoClass,'MSI',video.PublishDate,video.closedCaptionsMSI)">{{video.Title}}</div>
                        <div class="more-videos-right-description">{{video.VideoDescription}}</div>
                        <div class="more-videos-right-publishDate">{{video.PublishDate}}</div>
                    </div>
                </div>
            </div>
            <div class="more-videos-opacity" id="msi_opacity"></div>
        </div>
        
        <div v-if="isDisplayMSI" class="view-more-and-less" @click="viewMoreLess('MSI')">
            <span>{{viewMoreLessTextMSI}}</span>
            <mds-icon :name="vieMoreLessIconMSI" size="small" class="view-more-and-less-icon"></mds-icon>
        </div>
        <div class="more-videos-border-seperator"></div>
    </div>
     <!-- Section MSI -->

     <!-- Section MFI -->
    <div class="more-videos-section" id="sectionMFI">
        <div class="more-video-product-title">
            <span>FundInvestor</span>
        </div>
        <div class="video-loader" v-if="isLoadingVideoMFI">
            <mds-loader  aria-label="Medium Loader"></mds-loader>
        </div>
        
        <div v-if="isDisplayMFI" class="more-videos-section-container">
            <div class="more-videos-left-panel">
                <div class="more-videos-active-video">
                    <div class="more-videos-active-video-button">
                        <mds-button variation="primary" id="DefaltVideoButtonMFI" style="display:none;"  @click="FirstVideoClick('MFI')">
                            <span style="position: absolute; top: 18%">
                                <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                            </span>
                            <span style="margin-left: 20px">Watch</span>
                        </mds-button>
                    </div>
                    <video crossorigin="anonymous" class="more-videos-active-video-size" :ref="refMFI" type="video/mp4" preload="auto" data-setup="{}" v-bind:poster="ImageThumbMFI" :src="VideoURLMFI" :id="VideoIdMFI">
                    <track kind="subtitles" label="English" :src="closedCaptionsMFI" srclang="en" default>
                    </video>
                </div>
                <div class="more-videos-active-video-details">
                    <div class="more-videos-active-title">{{activeTitleMFI}}</div>
                    <div class="more-videos-active-description">{{activeDescriptionMFI}}</div>
                    <div class="more-videos-active-publishDate">{{activePublishDateMFI}}</div>
                    <a href="" @click="redirectVideoTranscript('MFI')">
                        <mds-button variation="secondary" size="small" style="position: relative;cursor:pointer;" class="button-transcript">
                            View Transcript <mds-icon name="angle-right" size="small" class="icon-transcript"></mds-icon>
                        </mds-button>
                    </a>
                </div>
            </div>
            <div class="more-videos-right-panel" @scroll="scrollToDiv('MFI')" id="mfi-scroll">
                <div v-for="v_Mfi in videosMFI" :key="v_Mfi.refMFI" :id="v_Mfi.VideoClassMFI" class="more-videos-right-image-and-description-container">
                    <div class="more-videos-right-image-container">
                        <div class="more-videos-right-image-play-button">
                            <mds-button variation="primary" @click="onVideoClick(v_Mfi.buttonWatchIdMFI,v_Mfi.ImageThumbMFI,v_Mfi.VideoURLMFI,v_Mfi.VideoIdMFI,v_Mfi.TitleMFI,v_Mfi.VideoDescriptionMFI,v_Mfi.refMFI,v_Mfi.VideoClassMFI,'MFI',v_Mfi.PublishDateMFI,v_Mfi.closedCaptionsMFI)" :id="v_Mfi.buttonWatchIdMFI">
                                <span style="position: absolute; top: 18%">
                                    <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                </span>
                                <span style="margin-left: 20px">Watch</span>
                            </mds-button>
                        </div>
                        <img :src="v_Mfi.ImageThumbMFI" class="more-videos-right-img" />
                    </div>
                    <div class="more-videos-right-video-details-container">
                        <div class="more-videos-right-title" @click="onVideoClick(v_Mfi.buttonWatchIdMFI,v_Mfi.ImageThumbMFI,v_Mfi.VideoURLMFI,v_Mfi.VideoIdMFI,v_Mfi.TitleMFI,v_Mfi.VideoDescriptionMFI,v_Mfi.refMFI,v_Mfi.VideoClassMFI,'MFI',v_Mfi.PublishDateMFI,v_Mfi.closedCaptionsMFI)">{{v_Mfi.TitleMFI}}</div>
                        <div class="more-videos-right-description">{{v_Mfi.VideoDescriptionMFI}}</div>
                        <div class="more-videos-right-publishDate">{{v_Mfi.PublishDateMFI}}</div>
                    </div>
                </div>
            </div>
            <div class="more-videos-opacity" id="mfi_opacity"></div>
        </div>
        
        <div v-if="isDisplayMFI" class="view-more-and-less" @click="viewMoreLess('MFI')">
            <span>{{viewMoreLessTextMFI}}</span>
            <mds-icon :name="vieMoreLessIconMFI" size="small" class="view-more-and-less-icon"></mds-icon>
        </div>
        <div class="more-videos-border-seperator"></div>
    </div>
     <!-- Section MFI -->

     <!-- Section MDI -->
    <div class="more-videos-section" id="sectionMDI">
        <div class="more-video-product-title">
            <span>DividendInvestor</span>
        </div>
        <div class="video-loader" v-if="isLoadingVideoMDI">
            <mds-loader  aria-label="Medium Loader"></mds-loader>
        </div>
        
        <div v-if="isDisplayMDI" class="more-videos-section-container">
            <div class="more-videos-left-panel">
                <div class="more-videos-active-video">
                    <div class="more-videos-active-video-button">
                        <mds-button variation="primary" id="DefaltVideoButtonMDI" style="display:none;"  @click="FirstVideoClick('MDI')">
                            <span style="position: absolute; top: 18%">
                                <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                            </span>
                            <span style="margin-left: 20px">Watch</span>
                        </mds-button>
                    </div>
                    <video crossorigin="anonymous" class="more-videos-active-video-size" :ref="refMDI" type="video/mp4" preload="auto" data-setup="{}" v-bind:poster="ImageThumbMDI" :src="VideoURLMDI" :id="VideoIdMDI">
                        <track kind="subtitles" label="English" :src="closedCaptionsMDI" srclang="en" default>
                    </video>           
                </div>
                <div class="more-videos-active-video-details">
                    <div class="more-videos-active-title">{{activeTitleMDI}}</div>
                    <div class="more-videos-active-description">{{activeDescriptionMDI}}</div>
                    <div class="more-videos-active-publishDate">{{activePublishDateMDI}}</div>
                    <a href="" @click="redirectVideoTranscript('MDI')">
                        <mds-button variation="secondary" size="small" style="position: relative;cursor:pointer;" class="button-transcript">
                            View Transcript <mds-icon name="angle-right" size="small" class="icon-transcript"></mds-icon>
                        </mds-button>
                    </a>
                </div>
            </div>
            <div class="more-videos-right-panel" @scroll="scrollToDiv('MDI')" id="mdi-scroll">
                <div v-for="v_Mdi in videosMDI" :key="v_Mdi.refMDI" :id="v_Mdi.VideoClassMDI" class="more-videos-right-image-and-description-container">
                    <div class="more-videos-right-image-container">
                        <div class="more-videos-right-image-play-button">
                            <mds-button variation="primary" @click="onVideoClick(v_Mdi.buttonWatchIdMDI,v_Mdi.ImageThumbMDI,v_Mdi.VideoURLMDI,v_Mdi.VideoIdMDI,v_Mdi.TitleMDI,v_Mdi.VideoDescriptionMDI,v_Mdi.refMDI,v_Mdi.VideoClassMDI,'MDI',v_Mdi.PublishDateMDI,v_Mdi.closedCaptionsMDI)" :id="v_Mdi.buttonWatchIdMDI">
                                <span style="position: absolute; top: 18%">
                                    <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                </span>
                                <span style="margin-left: 20px">Watch</span>
                            </mds-button>
                        </div>
                        <img :src="v_Mdi.ImageThumbMDI" class="more-videos-right-img" />
                    </div>
                    <div class="more-videos-right-video-details-container">
                        <div class="more-videos-right-title" @click="onVideoClick(v_Mdi.buttonWatchIdMDI,v_Mdi.ImageThumbMDI,v_Mdi.VideoURLMDI,v_Mdi.VideoIdMDI,v_Mdi.TitleMDI,v_Mdi.VideoDescriptionMDI,v_Mdi.refMDI,v_Mdi.VideoClassMDI,'MDI',v_Mdi.PublishDateMDI,v_Mdi.closedCaptionsMDI)">{{v_Mdi.TitleMDI}}</div>
                        <div class="more-videos-right-description">{{v_Mdi.VideoDescriptionMDI}}</div>
                        <div class="more-videos-right-publishDate">{{v_Mdi.PublishDateMDI}}</div>
                    </div>
                </div>
            </div>
            <div class="more-videos-opacity" id="mdi_opacity"></div>
        </div>
        
        <div v-if="isDisplayMDI" class="view-more-and-less" @click="viewMoreLess('MDI')">
            <span>{{viewMoreLessTextMDI}}</span>
            <mds-icon :name="vieMoreLessIconMDI" size="small" class="view-more-and-less-icon"></mds-icon>
        </div>
        <div class="more-videos-border-seperator"></div>
    </div>
     <!-- Section MDI -->

     <!-- Section MEI -->
    <div class="more-videos-section more-videos-end-section" id="sectionMEI">
        <div class="more-video-product-title">
            <span>ETFInvestor</span>
        </div>
        <div class="video-loader" v-if="isLoadingVideoMEI">
            <mds-loader  aria-label="Medium Loader"></mds-loader>
        </div>
        
        <div v-if="isDisplayMEI" class="more-videos-section-container">
            <div class="more-videos-left-panel">
                <div class="more-videos-active-video">
                    <div class="more-videos-active-video-button">
                        <mds-button variation="primary" id="DefaltVideoButtonMEI" style="display:none;"  @click="FirstVideoClick('MEI')">
                            <span style="position: absolute; top: 18%">
                                <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                            </span>
                            <span style="margin-left: 20px">Watch</span>
                        </mds-button>
                    </div>
                    <video crossorigin="anonymous" class="more-videos-active-video-size" :ref="refMEI" type="video/mp4" preload="auto" data-setup="{}" v-bind:poster="ImageThumbMEI" :src="VideoURLMEI" :id="VideoIdMEI">
                        <track kind="subtitles" label="English" :src="closedCaptionsMEI" srclang="en" default>
                    </video>                           
                </div>
                <div class="more-videos-active-video-details">
                    <div class="more-videos-active-title">{{activeTitleMEI}}</div>
                    <div class="more-videos-active-description">{{activeDescriptionMEI}}</div>
                    <div class="more-videos-active-publishDate">{{activePublishDateMEI}}</div>
                    <a href="" @click="redirectVideoTranscript('MEI')">
                        <mds-button variation="secondary" size="small" style="position: relative;cursor:pointer;" class="button-transcript">
                            View Transcript <mds-icon name="angle-right" size="small" class="icon-transcript"></mds-icon>
                        </mds-button>
                    </a>
                </div>
            </div>
            <div class="more-videos-right-panel" @scroll="scrollToDiv('MEI')" id="mei-scroll">
                <div v-for="v_Mei in videosMEI" :key="v_Mei.refMEI" :id="v_Mei.VideoClassMEI" class="more-videos-right-image-and-description-container">
                    <div class="more-videos-right-image-container">
                        <div class="more-videos-right-image-play-button">
                            <mds-button variation="primary" @click="onVideoClick(v_Mei.buttonWatchIdMEI,v_Mei.ImageThumbMEI,v_Mei.VideoURLMEI,v_Mei.VideoIdMEI,v_Mei.TitleMEI,v_Mei.VideoDescriptionMEI,v_Mei.refMEI,v_Mei.VideoClassMEI,'MEI',v_Mei.PublishDateMEI,v_Mei.closedCaptionsMEI)" :id="v_Mei.buttonWatchIdMEI">
                                <span style="position: absolute; top: 18%">
                                    <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                </span>
                                <span style="margin-left: 20px">Watch</span>
                            </mds-button>
                        </div>
                        <img :src="v_Mei.ImageThumbMEI" class="more-videos-right-img" />
                    </div>
                    <div class="more-videos-right-video-details-container">
                        <div class="more-videos-right-title" @click="onVideoClick(v_Mei.buttonWatchIdMEI,v_Mei.ImageThumbMEI,v_Mei.VideoURLMEI,v_Mei.VideoIdMEI,v_Mei.TitleMEI,v_Mei.VideoDescriptionMEI,v_Mei.refMEI,v_Mei.VideoClassMEI,'MEI',v_Mei.PublishDateMEI,v_Mei.closedCaptionsMEI)">{{v_Mei.TitleMEI}}</div>
                        <div class="more-videos-right-description">{{v_Mei.VideoDescriptionMEI}}</div>
                        <div class="more-videos-right-publishDate">{{v_Mei.PublishDateMEI}}</div>
                    </div>
                </div>
            </div>
            <div class="more-videos-opacity" id="mei_opacity"></div>
        </div>
        
        <div v-if="isDisplayMEI" class="view-more-and-less" @click="viewMoreLess('MEI')">
            <span>{{viewMoreLessTextMEI}}</span>
            <mds-icon :name="vieMoreLessIconMEI" size="small" class="view-more-and-less-icon"></mds-icon>
        </div>
    </div>
     <!-- Section MEI -->
</div>
</template>

<script>
import MdsIcon from '@mds/icon';
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import {
    MdsButton
} from "@mds/button";
import MdsLoader from "@mds/loader";
import Ad from "@/components/Common/Ad/Ad.vue";
import MdsPopover from '@mds/popover';
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
Vue.use(VueAxios, axios);
export default {
    name: "more-videos",
    components: {
        MdsIcon,
        MdsButton,
        MdsLoader,
        Ad,
        MdsPopover,
        MdsListGroup, 
        MdsListGroupItem
    },
    data() {
        return {
            VideoUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_Video,
            videosMSI: [],
            ImageThumb: "",
            VideoURL: "",
            VideoId: "",
            activeTitleMSI: "",
            activeDescriptionMSI: "",
            activePublishDateMSI:"",
            ref: "",
            buttonWatchId: "",
            isLoading: true,
            isDisplay: false,
            id: "",
            isLoadingVideoMSI:true,
            isDisplayMSI:false,
            closedCaptionsMSI:null,

            // MFI
            videosMFI: [],
            ImageThumbMFI: "",
            VideoURLMFI: "",
            VideoIdMFI: "",
            activeTitleMFI: "",
            activeDescriptionMFI: "",
            activePublishDateMFI:"",
            refMFI: "",
            buttonWatchIdMFI: "",
            idMFI: "",
            isLoadingVideoMFI:true,
            isDisplayMFI:false,
            closedCaptionsMFI:null,

            // MDI
            videosMDI: [],
            ImageThumbMDI: "",
            VideoURLMDI: "",
            VideoIdMDI: "",
            activeTitleMDI: "",
            activeDescriptionMDI: "",
            activePublishDateMDI:"",
            refMDI: "",
            buttonWatchIdMDI: "",
            idMDI: "",
            isLoadingVideoMDI:true,
            isDisplayMDI:false,
            closedCaptionsMDI:null,

             // MEI
            videosMEI: [],
            ImageThumbMEI: "",
            VideoURLMEI: "",
            VideoIdMEI: "",
            activeTitleMEI: "",
            activeDescriptionMEI: "",
            activePublishDateMEI:"",
            refMEI: "",
            buttonWatchIdMEI: "",
            idMEI: "",
            isLoadingVideoMEI:true,
            isDisplayMEI:false,
            activeSection:"",
            activeProductText:"",
            bProductSelect:false,
            session_Product : "",
            moreVideosProductSelectorToggle:false,
            productSelectorButtonName:"All Newsletters",
            closedCaptionsMEI:null,
            productSelectorData:[
                {
                    "productName":"StockInvestor",
                    "isActive":false,
                    "activeProduct":"sectionMSI",
                },
                {
                    "productName":"FundInvestor",
                    "isActive":false,
                    "activeProduct":"sectionMFI",
                },
                {
                    "productName":"DividendInvestor",
                    "isActive":false,
                    "activeProduct":"sectionMDI",
                },
                {
                    "productName":"ETFInvestor",
                    "isActive":false,
                    "activeProduct":"sectionMEI",
                },
                {
                    "productName":"All Newsletters",
                    "isActive":true,
                    "activeProduct":"All"
                },
            ],
            viewMoreLessStatusMSI:false,
            viewMoreLessStatusMFI:false,
            viewMoreLessStatusMDI:false,
            viewMoreLessStatusMEI:false,
            viewMoreLessTextMSI:"View More",
            viewMoreLessTextMFI:"View More",
            viewMoreLessTextMDI:"View More",
            viewMoreLessTextMEI:"View More",
            vieMoreLessIconMSI:"angle-down",
            vieMoreLessIconMFI:"angle-down",
            vieMoreLessIconMDI:"angle-down",
            vieMoreLessIconMEI:"angle-down",
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.session_Product = localStorage.getItem("MoreVideosSelectedProduct");
        //console.log("this.session_Product = " + this.session_Product);
        if(this.session_Product){
            console.log('session product : ' + this.session_Product);
            this.activeSection = this.session_Product;
            if(this.activeSection !=null || this.activeSection !=undefined || this.activeSection!=""){
                var active;
                if(this.activeSection.toLowerCase() == "mfi"){
                    active = "sectionMFI";
                    this.activeProductText="FundInvestor";
                    this.bProductSelect = true;
                }
                else if(this.activeSection.toLowerCase() == "msi"){
                    active = "sectionMSI";
                    this.activeProductText="StockInvestor";
                    this.bProductSelect = true;
                }
                else if(this.activeSection.toLowerCase() == "mdi"){
                    active = "sectionMDI";
                    this.activeProductText="DividendInvestor";
                    this.bProductSelect = true;
                }
                else if(this.activeSection.toLowerCase() == "mei"){
                    active = "sectionMEI";
                    this.activeProductText="ETFInvestor";
                    this.bProductSelect = true;
                }
                
                setTimeout(function () {
                    var section = document.getElementById(active);
                    if(section){
                        section.scrollIntoView();
                    }
                 }, 5000);
                
            }
        }
       
        this.getVideo("MSI");
        this.getVideo("MFI");
        this.getVideo("MDI");
        this.getVideo("MEI");
        this.$store.state.product.CurrentProduct = "Common";
        this.$session.set("current-product","Common");
       
        // setTimeout(function () {
        // this.$store.state.product.CurrentProduct = "Common";
        // this.$session.set("current-product","Common");
        // },1000);
        
    },
    methods: {
        getVideo(CurrentProduct) {
            try {
                var Url = this.VideoUrl;
                Url = Url.replace('{ProductCode}', CurrentProduct);
                Url = Url.replace('{Limit}', "8");
                Vue.axios.get(Url).then((response) => {
                        if (response) {
                            if (response.status) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        var lstData = response.data;
                                        for (var i = 0; i < lstData.length; i++) {
                                            var obj = lstData[i];

                                            var ref = "";
                                            var VideoURL = "";
                                            var VideoDescription = "";
                                            var Title = "";
                                            var ImageThumb = "";
                                            var PublishDate="";
                                            var subtitles = "";

                                            if (obj.Ref_Id) {
                                                ref = obj.Ref_Id;
                                            }

                                            if (obj.VideoURL) {
                                                VideoURL = obj.VideoURL;
                                            }

                                            if (obj.VideoDescription) {
                                                VideoDescription = obj.VideoDescription;
                                            }
                                            if (obj.Title) {
                                                Title = obj.Title;
                                            }

                                            if (obj.ImageThumb) {
                                                ImageThumb = obj.ImageThumb;
                                            }
                                            if(obj.PublishDate){
                                                PublishDate = obj.PublishDate;
                                            }
                                            if(obj.ClosedCaptions)
                                            {
                                                subtitles = obj.ClosedCaptions;
                                            }
                                            if (CurrentProduct == "MSI") {
                                                this.videosMSI.push({
                                                    ref: ref,
                                                    VideoURL: VideoURL,
                                                    ImageThumb: ImageThumb,
                                                    Title: Title,
                                                    VideoDescription: VideoDescription,
                                                    buttonWatchId: "WatchId_MSI_" + ref,
                                                    buttonStopId: "StopId_MSI_" + ref,
                                                    VideoId: "VideoId_MSI_" + ref,
                                                    VideoClass: "VideoCls_MSI_" + ref,
                                                    PublishDate:PublishDate,
                                                    closedCaptionsMSI:subtitles,
                                                });
                                                this.isLoadingVideoMSI=false;
                                                this.isDisplayMSI = true;
                                            }
                                            else if (CurrentProduct == "MFI") {
                                                this.videosMFI.push({
                                                    refMFI: ref,
                                                    VideoURLMFI: VideoURL,
                                                    ImageThumbMFI: ImageThumb,
                                                    TitleMFI: Title,
                                                    VideoDescriptionMFI: VideoDescription,
                                                    buttonWatchIdMFI: "WatchId_MFI_" + ref,
                                                    buttonStopIdMFI: "StopId_MFI_" + ref,
                                                    VideoIdMFI: "VideoId_MFI" + ref,
                                                    VideoClassMFI: "VideoCls_MFI_" + ref,
                                                    PublishDateMFI:PublishDate,
                                                    closedCaptionsMFI:subtitles,
                                                });
                                                this.isLoadingVideoMFI=false;
                                                this.isDisplayMFI = true;
                                            }
                                            else if (CurrentProduct == "MDI") {
                                                this.videosMDI.push({
                                                    refMDI: ref,
                                                    VideoURLMDI: VideoURL,
                                                    ImageThumbMDI: ImageThumb,
                                                    TitleMDI: Title,
                                                    VideoDescriptionMDI: VideoDescription,
                                                    buttonWatchIdMDI: "WatchId_MDI_" + ref,
                                                    buttonStopIdMDI: "StopId_MDI_" + ref,
                                                    VideoIdMDI: "VideoId_MDI" + ref,
                                                    VideoClassMDI: "VideoCls_MDI_" + ref,
                                                    PublishDateMDI:PublishDate,
                                                    closedCaptionsMDI:subtitles,
                                                });
                                                this.isLoadingVideoMDI=false;
                                                this.isDisplayMDI = true;
                                            }
                                            else if (CurrentProduct == "MEI") {
                                                this.videosMEI.push({
                                                    refMEI: ref,
                                                    VideoURLMEI: VideoURL,
                                                    ImageThumbMEI: ImageThumb,
                                                    TitleMEI: Title,
                                                    VideoDescriptionMEI: VideoDescription,
                                                    buttonWatchIdMEI: "WatchId_MEI_" + ref,
                                                    buttonStopIdMEI: "StopId_MEI_" + ref,
                                                    VideoIdMEI: "VideoId_MEI" + ref,
                                                    VideoClassMEI: "VideoCls_MEI_" + ref,
                                                    PublishDateMEI:PublishDate,
                                                    closedCaptionsMEI:subtitles,
                                                });
                                                this.isLoadingVideoMEI=false;
                                                this.isDisplayMEI = true;
                                            }
                                        }
                                    }
                                    this.getFirstVideoData(CurrentProduct);
                                }
                            }
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally();
            } catch (ex) {
                console.log(ex.message);
            }
        },
        getFirstVideoData(CurrentProduct) {
            if (CurrentProduct == "MSI") {
                for (var l = 0; l < this.videosMSI.length; l++) {
                    var obj1 = this.videosMSI[l];
                    if (l == 0) {
                        this.ImageThumb = obj1.ImageThumb;
                        this.VideoURL = obj1.VideoURL;
                        this.VideoId = obj1.VideoId;
                        this.activeTitleMSI = obj1.Title;
                        this.activeDescriptionMSI = obj1.VideoDescription;
                        this.activePublishDateMSI=obj1.PublishDate;
                        this.ref = obj1.ref;
                        this.buttonWatchId = obj1.buttonWatchId;
                        this.id = obj1.VideoId;
                        this.closedCaptionsMSI = obj1.closedCaptionsMSI;
                        try {
                            //document.getElementById("WatchId_1").style.display = "none";
                            setTimeout(() => {
                                if (this.buttonWatchId && this.buttonWatchId !=null ) {
                                    if(document.getElementById(this.buttonWatchId) != null && document.getElementById(this.buttonWatchId).style != null){
                                        document.getElementById(this.buttonWatchId).style.display = "none";
                                        document.getElementById("DefaltVideoButton").style.display = "block";
                                    }
                                }
                            }, 2000);

                            setTimeout(() => {
                                var element = document.getElementById(obj1.VideoClass);
                                if(element){
                                    element.classList.add("combine-overlay-effect");
                                }
                            }, 500);
                        } catch (ex) {
                            //console.log(ex.message);
                        }
                        break;
                    }
                }
            } else if (CurrentProduct == "MFI") {
                for (var f = 0; f < this.videosMFI.length; f++) {
                    var objMFI = this.videosMFI[f];
                    if (f == 0) {
                        this.ImageThumbMFI = objMFI.ImageThumbMFI;
                        this.VideoURLMFI = objMFI.VideoURLMFI;
                        this.VideoIdMFI = objMFI.VideoIdMFI;
                        this.activeTitleMFI = objMFI.TitleMFI;
                        this.activeDescriptionMFI = objMFI.VideoDescriptionMFI;
                        this.activePublishDateMFI=objMFI.PublishDateMFI;
                        this.refMFI = objMFI.refMFI;
                        this.buttonWatchIdMFI = objMFI.buttonWatchIdMFI;
                        this.idMFI = objMFI.VideoIdMFI;
                        this.closedCaptionsMFI = objMFI.closedCaptionsMFI;
                        try {
                            //document.getElementById("WatchId_1").style.display = "none";
                            setTimeout(() => {
                                if (this.buttonWatchIdMFI && this.buttonWatchIdMFI !=null ) {
                                    if(document.getElementById(this.buttonWatchIdMFI) != null && document.getElementById(this.buttonWatchIdMFI).style != null){
                                        document.getElementById(this.buttonWatchIdMFI).style.display = "none";
                                        document.getElementById("DefaltVideoButtonMFI").style.display = "block";
                                    }
                                }
                            }, 2000);

                            setTimeout(() => {
                                var element = document.getElementById(objMFI.VideoClassMFI);
                                if(element){
                                    element.classList.add("combine-overlay-effect");
                                }
                            }, 500);
                        } catch (ex) {
                            //console.log(ex.message);
                        }
                        break;
                    }
                }
            }
            else if (CurrentProduct == "MDI") {
                for (var d = 0; d < this.videosMDI.length; d++) {
                    var objMDI = this.videosMDI[d];
                    if (d == 0) {
                        this.ImageThumbMDI = objMDI.ImageThumbMDI;
                        this.VideoURLMDI = objMDI.VideoURLMDI;
                        this.VideoIdMDI = objMDI.VideoIdMDI;
                        this.activeTitleMDI = objMDI.TitleMDI;
                        this.activeDescriptionMDI = objMDI.VideoDescriptionMDI;
                        this.activePublishDateMDI=objMDI.PublishDateMDI;
                        this.refMDI = objMDI.refMDI;
                        this.buttonWatchIdMDI = objMDI.buttonWatchIdMDI;
                        this.idMDI = objMDI.VideoIdMDI;
                        this.closedCaptionsMDI= objMDI.closedCaptionsMDI;
                        try {
                            //document.getElementById("WatchId_1").style.display = "none";
                            setTimeout(() => {
                                if (this.buttonWatchIdMDI && this.buttonWatchIdMDI !=null ) {
                                    if(document.getElementById(this.buttonWatchIdMDI) != null && document.getElementById(this.buttonWatchIdMDI).style != null){
                                        document.getElementById(this.buttonWatchIdMDI).style.display = "none";
                                        document.getElementById("DefaltVideoButtonMDI").style.display = "block";
                                    }
                                }
                            }, 2000);

                            setTimeout(() => {
                                var element = document.getElementById(objMDI.VideoClassMDI);
                                if(element){
                                    element.classList.add("combine-overlay-effect");
                                }
                            }, 500);
                        } catch (ex) {
                           // console.log(ex.message);
                        }
                        break;
                    }
                }
            }
            else if (CurrentProduct == "MEI") {
                for (var e = 0; e < this.videosMEI.length; e++) {
                    var objMEI = this.videosMEI[e];
                    if (e == 0) {
                        this.ImageThumbMEI = objMEI.ImageThumbMEI;
                        this.VideoURLMEI = objMEI.VideoURLMEI;
                        this.VideoIdMEI = objMEI.VideoIdMEI;
                        this.activeTitleMEI = objMEI.TitleMEI;
                        this.activeDescriptionMEI = objMEI.VideoDescriptionMEI;
                        this.activePublishDateMEI=objMEI.PublishDateMEI;
                        this.refMEI = objMEI.refMEI;
                        this.buttonWatchIdMEI = objMEI.buttonWatchIdMEI;
                        this.idMEI = objMEI.VideoIdMEI;
                        this.closedCaptionsMEI=objMEI.closedCaptionsMEI;
                        try {
                            //document.getElementById("WatchId_1").style.display = "none";
                            setTimeout(() => {
                                if (this.buttonWatchIdMEI && this.buttonWatchIdMEI !=null ) {
                                    if(document.getElementById(this.buttonWatchIdMEI) != null && document.getElementById(this.buttonWatchIdMEI).style != null){
                                        document.getElementById(this.buttonWatchIdMEI).style.display = "none";
                                        document.getElementById("DefaltVideoButtonMEI").style.display = "block";
                                    }
                                }
                            }, 2000);

                            setTimeout(() => {
                                var element = document.getElementById(objMEI.VideoClassMEI);
                                if(element){
                                    element.classList.add("combine-overlay-effect");
                                }
                            }, 500);
                        } catch (ex) {
                            //console.log(ex.message);
                        }
                        break;
                    }
                }
            }
        },
        onVideoClick(buttonWatchId, ImageThumb, VideoURL, VideoId, Title, VideoDescription, ref, VideoClass, products,PublishDate,closedCaptions) {
            var oVideoTagStopMFI = document.getElementById(this.idMFI);
            var oVideoTagStopMSI = document.getElementById(this.id);
            var oVideoTagStopMDI = document.getElementById(this.idMDI);
            var oVideoTagStopMEI = document.getElementById(this.idMEI);
            var setProductSectionName = ""
            if (products == "MSI") {
                try {
                    setProductSectionName = "sectionMSI";
                    document.getElementById("DefaltVideoButton").style.display = "none";
                    this.ImageThumb = ImageThumb;
                    this.VideoURL = VideoURL;
                    this.VideoId = VideoId;
                    this.activeTitleMSI = Title;
                    this.activeDescriptionMSI = VideoDescription;
                    this.activePublishDateMSI=PublishDate;
                    this.ref = ref;
                    this.buttonWatchId = buttonWatchId;
                    this.id = VideoId;
                    this.closedCaptionsMSI = closedCaptions;

                    for (var i = 0; i < this.videosMSI.length; i++) {
                        var obj = this.videosMSI[i];
                        if (obj.buttonWatchId) {
                            if (this.buttonWatchId == obj.buttonWatchId) {
                                document.getElementById(this.buttonWatchId).style.display = "none";
                                var element = document.getElementById(VideoClass);
                                element.classList.add("combine-overlay-effect");

                            } else {
                                document.getElementById(obj.buttonWatchId).style.display = "block";
                                var element1 = document.getElementById(obj.VideoClass);
                                element1.classList.remove("combine-overlay-effect");
                            }
                        }
                    }
                    try {

                        
                        oVideoTagStopMFI.pause();
                        oVideoTagStopMFI.controls=false;
                        document.getElementById("DefaltVideoButtonMFI").style.display="block";

                        oVideoTagStopMDI.pause();
                        oVideoTagStopMDI.controls=false;
                        document.getElementById("DefaltVideoButtonMDI").style.display="block";

                        oVideoTagStopMEI.pause();
                        oVideoTagStopMEI.controls=false;
                        document.getElementById("DefaltVideoButtonMEI").style.display="block";
                        var oVideoTag;
                        setTimeout(() => {
                            oVideoTag = document.getElementById(this.id);
                        }, 500);

                        setTimeout(() => {
                            oVideoTag.play();
                            oVideoTag.controls = "controls";
                        }, 500);

                    } catch (ex) {
                        console.log(ex.message);
                    }

                } catch (ex) {
                    console.log(ex.message);
                }
            } else if (products == "MFI") {
                try {
                    //console.log("buttonWatchId =" + buttonWatchId);
                    setProductSectionName = "sectionMFI";
                    document.getElementById("DefaltVideoButtonMFI").style.display = "none";
                    this.ImageThumbMFI = ImageThumb;
                    this.VideoURLMFI = VideoURL;
                    this.VideoIdMFI = VideoId;
                    this.activeTitleMFI = Title;
                    this.activeDescriptionMFI = VideoDescription;
                    this.activePublishDateMFI=PublishDate;
                    this.refMFI = ref;
                    this.buttonWatchIdMFI = buttonWatchId;
                    this.idMFI = VideoId;
                    this.closedCaptionsMFI = closedCaptions;

                    for (var f = 0; f < this.videosMFI.length; f++) {
                        var objMFI = this.videosMFI[f];
                        if (objMFI.buttonWatchIdMFI) {
                            if (this.buttonWatchIdMFI == objMFI.buttonWatchIdMFI) {
                                document.getElementById(this.buttonWatchIdMFI).style.display = "none";
                                var elementMFIAdd = document.getElementById(VideoClass);
                                elementMFIAdd.classList.add("combine-overlay-effect");

                            } else {
                                document.getElementById(objMFI.buttonWatchIdMFI).style.display = "block";
                                var elementMFIRemove = document.getElementById(objMFI.VideoClassMFI);
                                elementMFIRemove.classList.remove("combine-overlay-effect");
                            }
                        }
                    }
                    try {

                      
                        oVideoTagStopMSI.pause();
                        oVideoTagStopMSI.controls=false;
                        document.getElementById("DefaltVideoButton").style.display = "block";

                        oVideoTagStopMDI.pause();
                        oVideoTagStopMDI.controls=false;
                        document.getElementById("DefaltVideoButtonMDI").style.display="block";

                        oVideoTagStopMEI.pause();
                        oVideoTagStopMEI.controls=false;
                        document.getElementById("DefaltVideoButtonMEI").style.display="block";

                        var oVideoTagMFI;
                        setTimeout(() => {
                            oVideoTagMFI = document.getElementById(this.idMFI);
                        }, 500);

                        setTimeout(() => {
                            oVideoTagMFI.play();
                            oVideoTagMFI.controls = "controls";
                        }, 500);

                    } catch (ex) {
                        console.log(ex.message);
                    }

                } catch (ex) {
                    console.log(ex.message);
                }
            }
            else if (products == "MDI") {
                try {
                    setProductSectionName = "sectionMDI";
                    //console.log("buttonWatchId =" + buttonWatchId);
                    document.getElementById("DefaltVideoButtonMDI").style.display = "none";
                    this.ImageThumbMDI = ImageThumb;
                    this.VideoURLMDI = VideoURL;
                    this.VideoIdMDI = VideoId;
                    this.activeTitleMDI = Title;
                    this.activeDescriptionMDI = VideoDescription;
                    this.activePublishDateMDI=PublishDate;
                    this.refMDI = ref;
                    this.buttonWatchIdMDI = buttonWatchId;
                    this.idMDI = VideoId;
                    this.closedCaptionsMDI = closedCaptions;

                    for (var d = 0; d < this.videosMDI.length; d++) {
                        var objMDI = this.videosMDI[d];
                        if (objMDI.buttonWatchIdMDI) {
                            if (this.buttonWatchIdMDI == objMDI.buttonWatchIdMDI) {
                                document.getElementById(this.buttonWatchIdMDI).style.display = "none";
                                var elementMDIAdd = document.getElementById(VideoClass);
                                elementMDIAdd.classList.add("combine-overlay-effect");

                            } else {
                                document.getElementById(objMDI.buttonWatchIdMDI).style.display = "block";
                                var elementMDIRemove = document.getElementById(objMDI.VideoClassMDI);
                                elementMDIRemove.classList.remove("combine-overlay-effect");
                            }
                        }
                    }
                    try {

                     
                        oVideoTagStopMSI.pause();
                        oVideoTagStopMSI.controls=false;
                        document.getElementById("DefaltVideoButton").style.display = "block";

                        oVideoTagStopMFI.pause();
                        oVideoTagStopMFI.controls=false;
                        document.getElementById("DefaltVideoButtonMFI").style.display = "block";

                        oVideoTagStopMEI.pause();
                        oVideoTagStopMEI.controls=false;
                        document.getElementById("DefaltVideoButtonMEI").style.display="block";

                        var oVideoTagMDI;
                        setTimeout(() => {
                            oVideoTagMDI = document.getElementById(this.idMDI);
                        }, 500);

                        setTimeout(() => {
                            oVideoTagMDI.play();
                            oVideoTagMDI.controls = "controls";
                        }, 500);

                    } catch (ex) {
                        console.log(ex.message);
                    }

                } catch (ex) {
                    console.log(ex.message);
                }
            }
            else if (products == "MEI") {
                try {
                    setProductSectionName = "sectionMEI";
                    //console.log("buttonWatchId =" + buttonWatchId);
                    document.getElementById("DefaltVideoButtonMEI").style.display = "none";
                    this.ImageThumbMEI = ImageThumb;
                    this.VideoURLMEI = VideoURL;
                    this.VideoIdMEI = VideoId;
                    this.activeTitleMEI = Title;
                    this.activeDescriptionMEI = VideoDescription;
                    this.activePublishDateMEI=PublishDate;
                    this.refMEI = ref;
                    this.buttonWatchIdMEI = buttonWatchId;
                    this.idMEI = VideoId;
                    this.closedCaptionsMEI= closedCaptions;

                    for (var e = 0; e < this.videosMEI.length; e++) {
                        var objMEI = this.videosMEI[e];
                        if (objMEI.buttonWatchIdMEI) {
                            if (this.buttonWatchIdMEI == objMEI.buttonWatchIdMEI) {
                                document.getElementById(this.buttonWatchIdMEI).style.display = "none";
                                var elementMEIAdd = document.getElementById(VideoClass);
                                elementMEIAdd.classList.add("combine-overlay-effect");

                            } else {
                                document.getElementById(objMEI.buttonWatchIdMEI).style.display = "block";
                                var elementMEIRemove = document.getElementById(objMEI.VideoClassMEI);
                                elementMEIRemove.classList.remove("combine-overlay-effect");
                            }
                        }
                    }
                    try {

                     
                        oVideoTagStopMSI.pause();
                        oVideoTagStopMSI.controls=false;
                        document.getElementById("DefaltVideoButton").style.display = "block";

                        oVideoTagStopMFI.pause();
                        oVideoTagStopMFI.controls=false;
                        document.getElementById("DefaltVideoButtonMFI").style.display = "block";

                        oVideoTagStopMDI.pause();
                        oVideoTagStopMDI.controls=false;
                        document.getElementById("DefaltVideoButtonMDI").style.display="block";

                        var oVideoTagMEI;
                        setTimeout(() => {
                            oVideoTagMEI = document.getElementById(this.idMEI);
                        }, 500);

                        setTimeout(() => {
                            oVideoTagMEI.play();
                            oVideoTagMEI.controls = "controls";
                        }, 500);

                    } catch (ex) {
                        console.log(ex.message);
                    }

                } catch (ex) {
                    console.log(ex.message);
                }
            }
            this.setFocusToVideoSection(setProductSectionName);

        },
        FirstVideoClick(CurrentProduct) {
            var oVideoTagMSI = document.getElementById(this.id);
            var oVideoTagMFI = document.getElementById(this.idMFI);
            var oVideoTagMDI = document.getElementById(this.idMDI);
            var oVideoTagMEI = document.getElementById(this.idMEI);
            if (CurrentProduct == "MSI") {
                document.getElementById("DefaltVideoButton").style.display = "none";

                try {
                    oVideoTagMFI.pause();
                    oVideoTagMFI.controls = false;
                    document.getElementById("DefaltVideoButtonMFI").style.display = "block";

                    oVideoTagMDI.pause();
                    oVideoTagMDI.controls = false;
                    document.getElementById("DefaltVideoButtonMDI").style.display = "block";

                    oVideoTagMEI.pause();
                    oVideoTagMEI.controls = false;
                    document.getElementById("DefaltVideoButtonMEI").style.display = "block";
                } catch (e) {
                    console.log(e.message);
                }
                setTimeout(() => {
                    oVideoTagMSI.play();
                    oVideoTagMSI.controls = "controls";
                }, 500);

            } else if (CurrentProduct == "MFI") {
                document.getElementById("DefaltVideoButtonMFI").style.display = "none";
                try {
                    oVideoTagMSI.pause();
                    oVideoTagMSI.controls = false;
                    document.getElementById("DefaltVideoButton").style.display = "block";

                    oVideoTagMDI.pause();
                    oVideoTagMDI.controls = false;
                    document.getElementById("DefaltVideoButtonMDI").style.display = "block";

                    oVideoTagMEI.pause();
                    oVideoTagMEI.controls = false;
                    document.getElementById("DefaltVideoButtonMEI").style.display = "block";
                } catch (e) {
                    console.log(e.message);
                }

                setTimeout(() => {
                    oVideoTagMFI.play();
                    oVideoTagMFI.controls = "controls";
                }, 500);

            }
            else if (CurrentProduct == "MDI") {
                document.getElementById("DefaltVideoButtonMDI").style.display = "none";
                try {
                    oVideoTagMSI.pause();
                    oVideoTagMSI.controls = false;
                    document.getElementById("DefaltVideoButton").style.display = "block";

                    oVideoTagMFI.pause();
                    oVideoTagMFI.controls = false;
                    document.getElementById("DefaltVideoButtonMFI").style.display = "block";

                    oVideoTagMEI.pause();
                    oVideoTagMEI.controls = false;
                    document.getElementById("DefaltVideoButtonMEI").style.display = "block";

                } catch (e) {
                    console.log(e.message);
                }

                setTimeout(() => {
                    oVideoTagMDI.play();
                    oVideoTagMDI.controls = "controls";
                }, 500);

            }
            else if (CurrentProduct == "MEI") {
                document.getElementById("DefaltVideoButtonMEI").style.display = "none";
                try {
                    oVideoTagMSI.pause();
                    oVideoTagMSI.controls = false;
                    document.getElementById("DefaltVideoButton").style.display = "block";

                    oVideoTagMFI.pause();
                    oVideoTagMFI.controls = false;
                    document.getElementById("DefaltVideoButtonMFI").style.display = "block";

                    oVideoTagMDI.pause();
                    oVideoTagMDI.controls = false;
                    document.getElementById("DefaltVideoButtonMDI").style.display = "block";

                } catch (e) {
                    console.log(e.message);
                }

                setTimeout(() => {
                    oVideoTagMEI.play();
                    oVideoTagMEI.controls = "controls";
                }, 500);

            }
        },
        scrollToDiv(product) {
            //const element = this.$refs.myElement;
            var currentElement;
            var currentOpacity;
            if(product == "MSI"){
                currentElement  ="msi-scroll";
                currentOpacity = "msi_opacity";
            }
            else if(product == "MFI"){
                currentElement  ="mfi-scroll";
                currentOpacity = "mfi_opacity";
            }
            else if(product == "MDI"){
                currentElement  ="mdi-scroll";
                currentOpacity = "mdi_opacity";
            }
            else if(product == "MEI"){
                currentElement  ="mei-scroll";
                currentOpacity = "mei_opacity";
            }
            var element = document.getElementById(currentElement);
            const Totalheight = element.offsetHeight;
            const currentPosition = element.scrollTop;
            const currentPositionBottom = element.scrollHeight;

            if(currentPosition == 0){
                document.getElementById(currentOpacity).style.display = "block";
            }
            else{
                const calPosition = parseFloat(Totalheight) + parseFloat(currentPosition) + 2;
                if(calPosition < currentPositionBottom){
                    document.getElementById(currentOpacity).style.display = "block";
                }
                else {
                    document.getElementById(currentOpacity).style.display = "none";
                }
            }
            
        },
        redirectVideoTranscript(product){
            var title="";
            if(product == "MSI"){
                title = this.activeTitleMSI;
            }
            else if(product == "MDI"){
                title = this.activeTitleMDI;
            }
            else if(product == "MFI"){
                title = this.activeTitleMFI;
            }
            else if(product == "MEI"){
                title = this.activeTitleMEI;
            }
            localStorage.setItem("SelectedProductVideoTranscript", product);
            //this.$store.state.product.SelectedProductVideoTranscript = product;
            //console.log("MoreVideos - SelectedProductVideoTranscript =" +this.$store.state.product.SelectedProductVideoTranscript);
            //console.log("title =" + title);
           
            title = window.encodeURIComponent(title);
            title = title.replaceAll('%20','-');
            //title = title.replaceAll('-','-');
            //console.log("title =" + title);
            this.$router.push({ name: 'videoTranscript', params: { title: title } }).catch(err => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(err);
                }
            });
        },
        redirectHomePage(){
            this.$router.push({ name: 'combined-home' }).catch(err => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(err);
                }
            });
        },
        redirectSelectedProduct(){
            var product;
            if(this.activeProductText == "StockInvestor"){
                product="msi";
            }
            else if(this.activeProductText == "FundInvestor"){
                product="mfi";
            }
            else if(this.activeProductText == "DividendInvestor"){
                product="mdi";
            }
            else if(this.activeProductText == "ETFInvestor"){
                product="mei";
            }
            if(product){
                this.$router.push({ name: 'home', params: { currentproduct: product } }).catch(err => {
                    if (
                        err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(err);
                    }
                });
            }
        },
        popoverProductSelected(index){
            this.moreVideosProductSelectorToggle = false;
            for(let i=0; i < this.productSelectorData.length; i++){
                if(index == i){
                    this.productSelectorButtonName = this.productSelectorData[i].productName;
                    var selectedProductID = this.productSelectorData[i].activeProduct;
                    this.setFocusToVideoSection(selectedProductID);
                }
            }
            this.productSelectorData.map((p) => (p.isActive = false));
            this.productSelectorData[index].isActive = true;
        },
        setFocusToVideoSection(sectionName){
            setTimeout(function () {
                if(sectionName != "All"){
                    var redirectToSelectedProductSection = document.getElementById(sectionName);
                    if(redirectToSelectedProductSection){
                        redirectToSelectedProductSection.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }
                }else{
                    window.scrollTo(0, 0);
                }
            }, 500);
        },
        viewMoreLess(selectedProduct){
            var getDivElement = document.getElementsByClassName("more-videos-right-panel");
            if(selectedProduct == 'MSI'){
                this.viewMoreLessStatusMSI = !this.viewMoreLessStatusMSI;
                if(this.viewMoreLessStatusMSI){
                    this.viewMoreLessTextMSI = "View Less";
                    this.vieMoreLessIconMSI = "angle-up";
                    getDivElement[0].style.height = "auto";
                }else{
                    this.viewMoreLessTextMSI = "View More";
                    this.vieMoreLessIconMSI = "angle-down";  
                    getDivElement[0].removeAttribute('style');
                    this.setFocusToVideoSection("sectionMSI");
                }
            }
            else if(selectedProduct == 'MFI'){
                this.viewMoreLessStatusMFI = !this.viewMoreLessStatusMFI;
                if(this.viewMoreLessStatusMFI){
                    this.viewMoreLessTextMFI = "View Less";
                    this.vieMoreLessIconMFI = "angle-up";
                    getDivElement[1].style.height = "auto";
                }else{
                    this.viewMoreLessTextMFI = "View More";
                    this.vieMoreLessIconMFI = "angle-down"; 
                    getDivElement[1].removeAttribute('style');
                    this.setFocusToVideoSection("sectionMFI"); 
                }
            }else if(selectedProduct == 'MDI'){
                this.viewMoreLessStatusMDI = !this.viewMoreLessStatusMDI;
                if(this.viewMoreLessStatusMDI){
                    this.viewMoreLessTextMDI = "View Less";
                    this.vieMoreLessIconMDI = "angle-up";
                    getDivElement[2].style.height = "auto";
                }else{
                    this.viewMoreLessTextMDI = "View More";
                    this.vieMoreLessIconMDI = "angle-down"; 
                    getDivElement[2].removeAttribute('style');
                    this.setFocusToVideoSection("sectionMDI"); 
                }
            }else if(selectedProduct == 'MEI'){
                this.viewMoreLessStatusMEI = !this.viewMoreLessStatusMEI;
                if(this.viewMoreLessStatusMEI){
                    this.viewMoreLessTextMEI = "View Less";
                    this.vieMoreLessIconMEI = "angle-up";
                    getDivElement[3].style.height = "auto";
                }else{
                    this.viewMoreLessTextMEI = "View More";
                    this.vieMoreLessIconMEI = "angle-down";  
                    getDivElement[3].removeAttribute('style');
                    this.setFocusToVideoSection("sectionMEI");
                }
            }
        }
    }    
}
</script>

<style scoped>
body {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}
.more-videos-main-div {
    max-width:1280px;
    margin:0 auto;
}
.more-videos-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1E1E1E;
    margin-top: 34px;
}
.more-videos-title a{
    text-decoration: none;
    color: #1E1E1E !important;
}
.more-videos-product-selector{
    display:none;
}
.more-videos-product-selector-button{
    background-color:#333;
    color:#FFF;
}
.more-videos-top-border{
    display:none;
}
.more-videos-section-first-div{
    margin-top:34px !important;
}
.more-videos-section{
    width:100%;
    margin-top:10px;
}
.more-videos-section-container{
    width:100%;
    display:flex;
    gap:32px;
}
.more-video-product-title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #1E1E1E;
    margin-bottom:10px;
}
.more-videos-left-panel{
    width:60%;
    max-width:747px;
}
.more-videos-right-panel{
    width:40%;
    max-width:501px;
    height:530px;
    max-height:530px;
    margin-left:auto;
    margin-right:5px;
    overflow: auto;
    position: relative;
}
.more-videos-active-video{
    width:100%;
    position:relative;
    z-index:1;
}
.more-videos-active-video video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.more-videos-active-video-button{
    position:absolute;
    bottom:13px;
    left:13px;
    right:0%;
    margin:auto;
    cursor:pointer;
    z-index:2;
}
.more-videos-active-video-button button{
    cursor:pointer;
}

.more-videos-active-video-details{
    width:100%;
    margin-top:23px;
}
.more-videos-active-title{
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; 
}
.more-videos-active-description{
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
    margin:6px 0 6px 0;
}
.more-videos-active-publishDate{
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    margin-bottom:34px; 
    color: rgb(94, 94, 94);
}
.more-videos-right-image-and-description-container{
    width:100%;
    height:120px;
    max-height:120px;
    margin-bottom:15px;
    display:flex;
}
.more-videos-right-image-container{
    width:100%;
    max-width:214px;
    height:100%;
    position:relative;
}
.more-videos-right-video-details-container{
    width:100%;
    max-width:100%;
    height:100%;
    padding:15px;
    box-sizing: border-box; 
}
.more-videos-right-img{
    width:100%;
    height:100%;
    object-fit:cover;
}
.more-videos-right-image-play-button{
    position:absolute;
    bottom:13px;
    left:13px;
    right:0%;
    margin:auto;
}
.more-videos-right-title{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor:pointer;
}
.more-videos-right-description{
    margin-top:4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.more-videos-right-publishDate{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color:#5E5E5E;
    margin-top:8px;
}
.more-videos-opacity {
    position: absolute;
    width: 41%;
    height: 121px;
    opacity: 1;
    z-index: 2;
    background: linear-gradient(0deg,#fff,hsla(0,0%,100%,0));
    transform: rotate(0deg);
    margin-top: 415px;
    margin-left: auto;
    right: 0;
}
.more-videos-border-seperator {
    width: 100%;
    height: 1px;
    background-color:#000000;
    margin-top:56px;
}
.combine-overlay-effect {
    background-color: #F2F2F2;
    box-shadow: 5px 0px #000000;
    width:99%;
}
.icon-transcript {
    margin-left: 2px;
    position: absolute;
    margin-top: 2.2px;
}
.icon-transcript.mds-icon.mds-icon--small{
    height: 15px;
    width: 13px;
}

.icon-transcript mds-icon mds-icon--small:hover {
    color: white !important;
}

.button-transcript.mds-button.mds-button--secondary {
    width: 115px;
    background: #ffffff;
}
.button-transcript.mds-button.mds-button--secondary:hover{
    color:#5E5E5E;
}
.video-loader {
    width: "276px";
    height: "69px";
}
.more-videos-end-section{
    margin-bottom:105px;
}
.more-videos-product-selector-popover{
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
}
.view-more-and-less{
    display:none;
}
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-x: overlay;
} 
::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
}
@media only screen and (min-width:320px) and (max-width:1400px){
    .more-videos-main-div{
        width:90%;
    }
}
@media only screen and (min-width:320px) and (max-width:999px){
   .more-videos-product-selector{
        margin-top:17px;
        display:block;
    }
    .more-videos-section{
        margin-top:10px;
    }
    .more-videos-section-first-div{
        margin-top:19px !important;
    }
   .more-videos-section-container{
       display:block;
    }
   .more-videos-left-panel{
       width:100%;
       max-width:100%;
   }
   .more-videos-right-panel{
       width:100%;
       max-width:100%;
       margin-left:0;
       margin-top:30px;
       height:260px;
       max-height:260px;
       max-height:1100px;
       overflow: hidden;
   }
   .more-videos-product-selector{
        margin-top:17px;
        display:block;
    }
    .more-videos-top-border{
        display:block;
        width:100%;
        height:1px;
        background-color:#000000;
        margin-bottom:10px;
    }
    .more-videos-opacity{
        display:none !important;
    }
    .view-more-and-less{
        display:block;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin-top:16px;
        cursor:pointer;
    }
    .view-more-and-less-icon{
        margin-left:10px;
    }

}
@media only screen and (min-width:320px) and (max-width:500px){
    .more-videos-right-image-and-description-container{
        height: 100px;
        max-height: 100px;
    }
    .more-video-product-title {
        font-size: 18px;
        line-height: 22px;
    }
    .more-videos-active-video-details{
        margin-top:16px;
    }
    .more-videos-active-title{
        font-size: 20px;
        line-height: 23px; 
    }
    .more-videos-active-description{
        font-size: 16px;
        line-height: 21px;
    }
    .more-videos-active-publishDate{
        font-size: 14px;
        margin-bottom: 17px;
    }
    .more-videos-right-panel{
        height: 210px;
        max-height: 210px;
        max-height: 900px;
    }
    .more-videos-right-image-container{
        max-width: 170px;
    }
    .more-videos-right-title{
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
    }
    .more-videos-right-description{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
    .more-videos-border-seperator{
        margin-top:36px;
    }
    .more-videos-right-video-details-container{
        padding:8px;
    }
}
@media only screen and (min-width:1600px) and (max-width:1999px){
    .more-videos-opacity{
        width:43%;
    }
}
@media only screen and (min-width:2000px){
    .more-videos-opacity{
        width:46.5%;
    }
}
@media only screen and (min-width:4000px){
    .more-videos-opacity{
        width:48%;
    }
}
</style>
<style>
.more-videos-product-selector-button:focus {
  box-shadow: none !important;
  outline: none !important;
}
.view-more-and-less-icon > svg{
    margin-top:3px;
}
.more-videos-product-selector-button.mds-button.mds-button--secondary > span:nth-child(2) > svg > path{
    stroke:#FFFFFF;
}
</style>

