<template>
	<div
		:class="{
			'mdc-header': true,
			[$style['mds-section']]: true,
			[$style['mds-component--on-dark']]: onDark,
		}"
	>
		<div
			class="mdc-header__container"
			:class="{
				[$style['mds-section__header-container']]: true,
				[$style['mds-section--level-' + size]]: true,
				[$style['mds-section--primary']]: primary,
				[$style['mds-section--secondary']]: secondary,
				[$style['mds-section--tertiary']]: tertiary,
				[$style['mds-section--border-bottom']]: borderBottom
			}"
		>
			<component
				:is="hTag"
				class="mdc-header__title"
				:class="{
					[$style['mds-section__title']]: true,
					'mdc-header__title--thin': thin,
					'mdc-header__title--light': light,
					'mdc-header__title--regular': regular,
					'mdc-header__title--bold': bold,
				}"
			>
				<slot />
			</component>

			<div v-if="$slots.actions" :class="['mdc-header__actions', $style['mds-section__actions']]">
				<slot name="actions" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	props: {
		primary: {
			type: Boolean,
			description: 'Render a primary border on header',
			default: false
		},
		secondary: {
			type: Boolean,
			description: 'Render a secondary border on header',
			default: false
		},
		tertiary: {
			type: Boolean,
			description: 'Render a tertiary border on header',
			default: false
		},
		onDark: {
			type: Boolean,
			description: 'Render in context with a dark background',
			default: false
		},
		thin: {
			type: Boolean,
			description: 'Override to render with a thin font weight',
			default: false
		},
		light: {
			type: Boolean,
			description: 'Override to render with a light font weight',
			default: false
		},
		regular: {
			type: Boolean,
			description: 'Override to render with a regular font weight',
			default: false
		},
		bold: {
			type: Boolean,
			description: 'Override to render with a bold font weight',
			default: false
		},
		borderBottom: {
			type: Boolean,
			description: 'Reposition the border and increase padding above and below the title',
			default: false
		},
		size: {
			type: [Number, String],
			description: 'Sizing affects text size and internal padding ranging from level 1 to level 9',
			default: '2'
		},
		level: {
			type: [Number, String],
			description: 'Establishes the semantic positioning of the header, rendering from h1 to h6',
			default: undefined
		}
	},
	computed: {
		hTag() {
			if (this.level) return `h${this.level}`;
			return `h${Math.min(this.size, 6)}`;
		}
	}
};
</script>

<style lang="scss" module>
@import '@mds/section/src/section.scss';
</style>

<style lang="scss" scoped>
@import "@mds/constants";
@import '@mds/typography';
.mdc-header {
	&__container {
		display: flex;
		align-items: center;
		margin-bottom: 0 !important;
	}

	&__title {
		&--thin {
			font-weight: $mds-typography-font-weight-thin;
			a {
				font-weight: $mds-typography-font-weight-thin;
			}
		}

		&--light {
			font-weight: $mds-typography-font-weight-light;
			a {
				font-weight: $mds-typography-font-weight-light;
			}
		}

		&--regular {
			font-weight: $mds-typography-font-weight-regular;
			a {
				font-weight: $mds-typography-font-weight-regular;
			}
		}

		&--bold {
			font-weight: $mds-typography-font-weight-bold;
			a {
				font-weight: $mds-typography-font-weight-bold;
			}
		}
	}

	&__actions {
		padding-left: $mds-space-1-x;
	}
}
</style>
