<template>
    <mds-loader v-if="isLoadingState" aria-label="Medium Loader" class="analyst-main-loader-style"></mds-loader>
    <div v-else-if="onLoadState" class="analyst-no-report-message">
        <mds-loader aria-label="Medium Loader" class="analyst-loader-style"></mds-loader>
    </div>
    <div class="analyst-no-report-message" v-else-if="
          (((this.CurrentProduct.toLowerCase() == 'msi' || this.CurrentProduct.toLowerCase() == 'mdi') && 
            this.medalistReportsData.Thesis == null &&
            this.medalistReportsData.Moat == null &&
            this.medalistReportsData.Valuation == null &&
            this.medalistReportsData.Risk == null &&
            this.medalistReportsData.Mgmt == null &&
            this.medalistReportsData.Profile == null &&
            this.medalistReportsData.FinancialHealth_MDI == null &&
            this.medalistReportsData.Sustainability_ETF == null &&
            this.medalistReportsData.FundamentalView_ETF == null &&
            this.medalistReportsData.ETFFees_ETF == null &&
            this.medalistReportsData.PortfolioConstruction_ETF == null &&
            this.medalistReportsData.AnalystNote_Update_Date == null) || (this.CurrentProduct.toLowerCase() == 'mei' || this.CurrentProduct.toLowerCase() == 'etf') && this.medalistReportsData.overall_written_analysis == null)
        ">
        <mds-empty-state size="large" title="No Analyst Report" message="There is no Analyst Report for this security because Morningstar doesn't currently cover it.">
            <template #mds-empty-state-icon>
                <img src="@/assets/happy-face-100.svg" role="presentation" />
            </template>
        </mds-empty-state>
    </div>
    <div v-else class="analyst-reports-right-section" id="printable-area">
        <div v-if="CurrentProduct == 'msi' || CurrentProduct == 'mdi'">
            <div class="analyst-report-title">
                <span id="title">{{ this.medalistReportsData.Title }}</span>
            </div>
            <div class="border-separator"></div>
            <div class="author-and-button">
                <div class="analyst-report-authorName-and-date">
                    <span id="author">by {{ this.medalistReportsData.Author }}</span>
                    <!-- <span id="date" v-if="this.medalistReportsData.bCheckAnalystDate">{{
              this.medalistReportsData.AnalystNote_Update_Date | formatDateVal
            }}</span> -->
            <span id="date">{{
                this.medalistReportsData.Date | formatDateVal
              }}</span>
                </div>
                <div class="print-button non-printable-area">
                    <mds-button-container>
                        <mds-button variation="flat" size="small" icon="print" type="button" @click="printPage()">Print</mds-button>
                    </mds-button-container>
                </div>
            </div>
            <div class="analyst-reports-content-section">
                <div class="analyst-report-sub-heading-and-content">
                    <div>
                        <div class="subhead-title" v-if="this.medalistReportsData.AnalystNote_Content != null">
                            <span>Analyst Note</span>
                            <span>
                                | Updated
                                {{ this.medalistReportsData.AnalystNote_Update_Date | formatDateVal }}</span>
                            <p v-html="this.medalistReportsData.AnalystNote_Content">
                            </p>
                        </div>
                    </div>
    
                    <div class="subhead-title" v-if="this.medalistReportsData.Thesis != null">
                        <span id="mdiandmsititlebusiness">Business Strategy and Outlook</span>
                        <span id="authornameforbusinesstitle">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="updateddateforbusiness">
                            | Updated
                            {{
                  this.medalistReportsData.InvestmentThesis_Update_Date | formatDateVal
                }}</span>
                        <p id="postcontent" v-html="this.medalistReportsData.Thesis">
                        </p>
                    </div>
    
                    <div v-if="this.medalistReportsData.Moat != null">
                        <span id="economictitle">Economic Moat</span>
                        <span id="economicauthor">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="economicdate">
                            | Updated
                            {{
                  this.medalistReportsData.Moat_Update_Date | formatDateVal
                }}</span>
                        <p id="EconomicMoat" v-html="this.medalistReportsData.Moat"></p>
                    </div>
    
                    <div v-if="this.medalistReportsData.Valuation != null">
                        <span id="fairvalue">Fair Value and Profit Drivers</span>
                        <span id="fairvalueauthor">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="fairvaluedate">
                            | Updated
                            {{
                  this.medalistReportsData.Valuation_Update_Date | formatDateVal
                }}</span>
                        <p id="FairValue" v-html="this.medalistReportsData.Valuation"></p>
                    </div>
    
                    <div v-if="this.medalistReportsData.Risk != null">
                        <span id="riskanduncertainty">Risk and Uncertainty</span>
                        <span id="riskauthor">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="riskdate">
                            | Updated
                            {{
                  this.medalistReportsData.EnterpriseRisk_Update_Date | formatDateVal
                }}</span>
                        <p id="RiskandUncertainty" v-html="this.medalistReportsData.Risk"></p>
                    </div>
    
                    <div v-if="this.medalistReportsData.Mgmt != null">
                        <span id="capitalallocationtitle">Capital Allocation</span>
                        <span id="capitalallocationauthor">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="capitalallocationdate">
                            | Updated
                            {{
                  this.medalistReportsData.ManagementAndStewardship_Update_Date | formatDateVal
                }}</span>
                        <P id="CapitalAllocationData" v-html="this.medalistReportsData.Mgmt"></P>
                    </div>
    
                    <div v-if="this.medalistReportsData.Sustainability_ETF != null">
                        <span id="summarytitle">Summary</span>
                        <span id="summaryauthor">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="summarydate">
                            | Updated
                            {{
                  this.medalistReportsData.Date | formatDateVal
                }}</span>
                        <p id="summary" v-html="this.medalistReportsData.Sustainability_ETF">
                        </p>
                    </div>
    
                    <div v-if="
                this.medalistReportsData.FundamentalView_ETF != null &&
                this.medalistReportsData.PortfolioConstruction_ETF != null
              ">
                        <span id="processtitle">Process</span>
                        <span id="processauthor">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="processdate">
                            | Updated
                            {{
                  this.medalistReportsData.Date | formatDateVal
                }}</span>
                        <p id="processfirst">
                            {{ this.medalistReportsData.FundamentalView_ETF }}
                        </p>
                        <p id="processsecond">
                            {{ this.medalistReportsData.PortfolioConstruction_ETF }}
                        </p>
                    </div>
    
                    <div v-if="this.medalistReportsData.ETFFees_ETF != null">
                        <span id="pricetitle">Price</span>
                        <span id="priceauthor">
                            | by {{ this.medalistReportsData.Author }}</span>
                        <span id="pricedate">
                            | Updated
                            {{
                  this.medalistReportsData.Date | formatDateVal
                }}</span>
                        <p id="price">{{ this.medalistReportsData.ETFFees_ETF }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="CurrentProduct == 'mei'" id="sectionEtf">
            <div class="analyst-report-title">
                <div class="analyst-report-sub-title">
                    <span>{{this.medalistReportsData.HoldingName}}</span>
                    <span>{{this.medalistReportsData.Ticker}}</span>
                    <span>&nbsp;</span>
                    <mds-star-rating class="analyst-report-star-rating" :rating="parseInt(this.medalistReportsData.MorningstarRating)" :custom-size="14"></mds-star-rating>
                    <span>&nbsp;</span>
                    <span class="analyst-report-analyst-icon">
                        <span id="overall_icon">
                            <mds-medalist-rating size="large" :rating="analystRatingClassMap[this.medalistReportsData.overall_rating]"></mds-medalist-rating>
                        </span>
                    </span>
                </div>
                <span id="etf_title" v-html="this.medalistReportsData.analysis_one_line_commentary"></span>
            </div>
            <div class="border-separator"></div>
            <div class="author-and-button">
                <div class="analyst-report-authorName-and-date">
                    <span id="etf_author">by {{ this.medalistReportsData.overall_written_analysis_author }}</span>
                    <span id="etf_date">{{
              this.medalistReportsData.overall_rating_publish_date_utc | formatDateVal
            }}</span>
                </div>
                <div class="print-button non-printable-area">
                    <mds-button-container>
                        <mds-button variation="flat" size="small" icon="print" type="button" @click="printPage()">Print</mds-button>
                    </mds-button-container>
                    <!-- <mds-icon class="etf-print" name="print" size="small" ></mds-icon> Print -->
                </div>
            </div>
            <div v-if="this.medalistReportsData.process_pillar_written_analysis !=null" style="margin-top: 48px">
                <span class="analyst-report-etf-heading">Summary</span>
    
                <span id="overallSymbol" style="display:none">
                    {{this.medalistReportsData.overall_rating_description_english_united_states_of_america}}
                </span>
                <!-- <span v-if="this.medalistReportsData.overall_written_analysis_author !=null" class="analyst-report-etf-text">
                    | by {{ this.medalistReportsData.overall_written_analysis_author }}</span>
                <span v-if="this.medalistReportsData.overall_rating_publish_date_utc !=null" class="analyst-report-etf-text">
                    | Updated
                    {{
            this.medalistReportsData.overall_rating_publish_date_utc | formatDateVal}}</span> -->
    
                <p v-if="this.medalistReportsData.overall_written_analysis !=null" class="analyst-report-etf-text-paragraph">
                    <span id="etf_overall" v-html="this.medalistReportsData.overall_written_analysis">
                        <!-- {{ this.medalistReportsData.overall_written_analysis }} -->
                    </span>
                </p>
            </div>
            <div v-if="this.medalistReportsData.process_pillar_written_analysis !=null">
                <span class="analyst-report-etf-heading">Process</span>
    
                <span v-if="this.medalistReportsData.process_rating_description_english_united_states_of_america =='ABOVE_AVERAGE'">
                    <span class="icon-above-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Above Average</span>
                </span>
                <span v-if="this.medalistReportsData.process_rating_description_english_united_states_of_america =='AVERAGE'">
                    <span class="icon-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Average</span>
                </span>
                <span v-if="this.medalistReportsData.process_rating_description_english_united_states_of_america =='BELOW_AVERAGE'">
                    <span class="icon-below-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Below Average</span>
                </span>
    
                <span v-if="this.medalistReportsData.process_rating_description_english_united_states_of_america =='HIGH'">
                    <span class="icon-high"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">High</span>
                </span>
                <span v-if="this.medalistReportsData.process_rating_description_english_united_states_of_america =='LOW'">
                    <span class="icon-low"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Low</span>
                </span>
                <span v-if="this.medalistReportsData.process_rating_description_english_united_states_of_america =='NOT_APPLICABLE'">
                    <span class="icon-not-rated"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Not Applicable</span>
                </span>
    
                <!-- <span v-if="this.medalistReportsData.process_rating_description_english_united_states_of_america !=null" class="analyst-report-etf-text">
                  {{this.medalistReportsData.process_rating_description_english_united_states_of_america}}</span> -->
                <span v-if="this.medalistReportsData.process_pillar_author !=null" class="analyst-report-etf-text">
                    | by <span id="process_author"> {{ this.medalistReportsData.process_pillar_author }}</span>
                </span>
                <span id="process_date" v-if="this.medalistReportsData.process_pillar_score_date_utc !=null" class="analyst-report-etf-text">
                    | Updated
                    {{
                this.medalistReportsData.process_pillar_score_date_utc | formatDateVal}}</span>
    
                <p id="process_written" v-html="this.medalistReportsData.process_pillar_written_analysis" v-if="this.medalistReportsData.process_pillar_written_analysis !=null" class="analyst-report-etf-text-paragraph">
                    <!-- {{ this.medalistReportsData.process_pillar_written_analysis }} -->
                </p>
            </div>
    
            <div v-if="this.medalistReportsData.people_pillar_written_analysis !=null">
                <span class="analyst-report-etf-heading">People</span>
                <span v-if="this.medalistReportsData.people_rating_description_english_united_states_of_america =='ABOVE_AVERAGE'">
                    <span class="icon-above-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Above Average</span>
                </span>
                <span v-if="this.medalistReportsData.people_rating_description_english_united_states_of_america =='AVERAGE'">
                    <span class="icon-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Average</span>
                </span>
                <span v-if="this.medalistReportsData.people_rating_description_english_united_states_of_america =='BELOW_AVERAGE'">
                    <span class="icon-below-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Below Average</span>
                </span>
    
                <span v-if="this.medalistReportsData.people_rating_description_english_united_states_of_america =='HIGH'">
                    <span class="icon-high"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">High</span>
                </span>
                <span v-if="this.medalistReportsData.people_rating_description_english_united_states_of_america =='LOW'">
                    <span class="icon-low"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Low</span>
                </span>
                <span v-if="this.medalistReportsData.people_rating_description_english_united_states_of_america =='NOT_APPLICABLE'">
                    <span class="icon-not-rated"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Not Applicable</span>
                </span>
                <span v-if="this.medalistReportsData.people_pillar_author !=null" class="analyst-report-etf-text">
                    | by <span id="people_author">{{ this.medalistReportsData.people_pillar_author }}</span> </span>
                <span id="people_date" v-if="this.medalistReportsData.people_pillar_score_date_utc !=null" class="analyst-report-etf-text">
                    | Updated
                    {{
              this.medalistReportsData.people_pillar_score_date_utc | formatDateVal}}</span>
    
                <p id="people_written" v-html="this.medalistReportsData.people_pillar_written_analysis" v-if="this.medalistReportsData.people_pillar_written_analysis !=null" class="analyst-report-etf-text-paragraph">
                    <!-- {{ this.medalistReportsData.people_pillar_written_analysis }} -->
                </p>
            </div>
    
            <div v-if="this.medalistReportsData.parent_pillar_written_analysis !=null">
                <span class="analyst-report-etf-heading">Parent</span>
                <span v-if="this.medalistReportsData.parent_rating_description_english_united_states_of_america =='ABOVE_AVERAGE'">
                    <span class="icon-above-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Above Average</span>
                </span>
                <span v-if="this.medalistReportsData.parent_rating_description_english_united_states_of_america =='AVERAGE'">
                    <span class="icon-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Average</span>
                </span>
                <span v-if="this.medalistReportsData.parent_rating_description_english_united_states_of_america =='BELOW_AVERAGE'">
                    <span class="icon-below-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Below Average</span>
                </span>
    
                <span v-if="this.medalistReportsData.parent_rating_description_english_united_states_of_america =='HIGH'">
                    <span class="icon-high"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">High</span>
                </span>
                <span v-if="this.medalistReportsData.parent_rating_description_english_united_states_of_america =='LOW'">
                    <span class="icon-low"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Low</span>
                </span>
                <span v-if="this.medalistReportsData.parent_rating_description_english_united_states_of_america =='NOT_APPLICABLE'">
                    <span class="icon-not-rated"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Not Applicable</span>
                </span>
                <span v-if="this.medalistReportsData.parent_pillar_author !=null" class="analyst-report-etf-text">
                    | by <span id="parent_author"> {{ this.medalistReportsData.parent_pillar_author }}</span></span>
                <span id="parent_date" v-if="this.medalistReportsData.parent_pillar_score_date_utc !=null" class="analyst-report-etf-text">
                    | Updated
                    {{
            this.medalistReportsData.parent_pillar_score_date_utc | formatDateVal}}</span>
    
                <p v-html="this.medalistReportsData.parent_pillar_written_analysis" v-if="this.medalistReportsData.parent_pillar_written_analysis !=null" class="analyst-report-etf-text-paragraph">
                    <!-- {{ this.medalistReportsData.parent_pillar_written_analysis }} -->
                </p>
            </div>
    
            <div v-if="this.medalistReportsData.performance_pillar_written_analysis !=null">
                <span class="analyst-report-etf-heading">Performance</span>
                <span v-if="this.medalistReportsData.performance_rating_description_english_united_states_of_america =='ABOVE_AVERAGE'">
                    <span class="icon-above-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Above Average</span>
                </span>
                <span v-if="this.medalistReportsData.performance_rating_description_english_united_states_of_america =='AVERAGE'">
                    <span class="icon-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Average</span>
                </span>
                <span v-if="this.medalistReportsData.performance_rating_description_english_united_states_of_america =='BELOW_AVERAGE'">
                    <span class="icon-below-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Below Average</span>
                </span>
    
                <span v-if="this.medalistReportsData.performance_rating_description_english_united_states_of_america =='HIGH'">
                    <span class="icon-high"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">High</span>
                </span>
                <span v-if="this.medalistReportsData.performance_rating_description_english_united_states_of_america =='LOW'">
                    <span class="icon-low"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Low</span>
                </span>
                <span v-if="this.medalistReportsData.performance_rating_description_english_united_states_of_america =='NOT_APPLICABLE'">
                    <span class="icon-not-rated"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Not Applicable</span>
                </span>
                <span v-if="this.medalistReportsData.performance_pillar_author !=null" class="analyst-report-etf-text">
                    | by <span id="performance_author">{{ this.medalistReportsData.performance_pillar_author }}</span></span>
                <span id="performance_date" v-if="this.medalistReportsData.performance_pillar_score_date_utc !=null" class="analyst-report-etf-text">
                    | Updated
                    {{
          this.medalistReportsData.performance_pillar_score_date_utc | formatDateVal}}</span>
    
                <p id="performance_written" v-html="this.medalistReportsData.performance_pillar_written_analysis" v-if="this.medalistReportsData.performance_pillar_written_analysis !=null" class="analyst-report-etf-text-paragraph">
                    <!-- {{ this.medalistReportsData.performance_pillar_written_analysis }} -->
                </p>
            </div>
    
            <div v-if="this.medalistReportsData.price_pillar_written_analysis !=null">
                <span class="analyst-report-etf-heading">Price</span>
                <span v-if="this.medalistReportsData.price_rating_description_english_united_states_of_america =='ABOVE_AVERAGE'">
                    <span class="icon-above-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Above Average</span>
                </span>
                <span v-if="this.medalistReportsData.price_rating_description_english_united_states_of_america =='AVERAGE'">
                    <span class="icon-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Average</span>
                </span>
                <span v-if="this.medalistReportsData.price_rating_description_english_united_states_of_america =='BELOW_AVERAGE'">
                    <span class="icon-below-average"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Below Average</span>
                </span>
    
                <span v-if="this.medalistReportsData.price_rating_description_english_united_states_of_america =='HIGH'">
                    <span class="icon-high"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">High</span>
                </span>
                <span v-if="this.medalistReportsData.price_rating_description_english_united_states_of_america =='LOW'">
                    <span class="icon-low"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Low</span>
                </span>
                <span v-if="this.medalistReportsData.price_rating_description_english_united_states_of_america =='NOT_APPLICABLE'">
                    <span class="icon-not-rated"></span>
                    <span class="analyst-report-etf-text" style="margin-left: 5px;">Not Applicable</span>
                </span>
                <span v-if="this.medalistReportsData.price_pillar_author !=null" class="analyst-report-etf-text">
                    | by <span id="price_author">{{ this.medalistReportsData.price_pillar_author }}</span></span>
                <span id="price_date" v-if="this.medalistReportsData.price_pillar_score_date_utc !=null" class="analyst-report-etf-text">
                    | Updated
                    {{
        this.medalistReportsData.price_pillar_score_date_utc | formatDateVal}}</span>
    
                <p id="price_written" v-html="this.medalistReportsData.price_pillar_written_analysis" v-if="this.medalistReportsData.price_pillar_written_analysis !=null" class="analyst-report-etf-text-paragraph">
                    <!-- {{ this.medalistReportsData.price_pillar_written_analysis }} -->
                </p>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import MdsLoader from "@mds/loader";
    import MdsEmptyState from "@mds/empty-state";
    import {
        MdsButton,
        MdsButtonContainer
    } from "@mds/button";
    //import MdsAnalystRating from "@mds/analyst-rating";
    import MdsMedalistRating from '@mds/medalist-rating';
    import MdsStarRating from "@mds/star-rating";
    export default {
        name: "analyst-reports-right-panel",
        props: {
            medalistReportsData: {
                type: Object,
                required: true,
            },
            isLoadingState: {
                type: Boolean,
                required: true,
            },
            isErrorState: {
                type: Boolean,
                required: true,
            },
            onLoadState: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            MdsLoader,
            MdsEmptyState,
            MdsButton,
            MdsButtonContainer,
            MdsMedalistRating,
            MdsStarRating,
        },
        data() {
            return {
                CurrentProduct: this.$session.get("current-product").toLowerCase(),
                analystRatingClassMap: {
                    1: "not-ratable",
                    2: "under-review",
                    3: "negative",
                    4: "neutral",
                    5: "bronze",
                    6: "silver",
                    7: "gold",
                }
    
            };
        },
        methods: {
            printPage() {
                setTimeout(() => {
                    var printContents = document.getElementById("printable-area").innerHTML;
                    var originalContents = document.body.innerHTML;
                    document.body.innerHTML = "<html><head><title></title></head><body>" + printContents + "</body>";
                    window.print();
                    document.body.innerHTML = originalContents;
                    location.reload();
                }, 500);
            },
        },
        mounted() {
    
        }
    };
    </script>
    
    <style scoped>
    @media print {
        .non-printable-area {
            visibility: hidden;
        }
    }
    
    .analyst-reports-right-section {
        max-width: 625px;
        margin-top: 33px;
        /*margin-left: 55px;*/
        text-align: left;
    }
    
    .analyst-report-title {
        height: auto;
        padding-top: 4px;
    }
    
    .analyst-report-title span {
        color: #1e1e1e;
        font-size: 24px;
        font-weight: 700;
    }
    
    .analyst-report-sub-title {
        margin-bottom: 19px;
    }
    
    .analyst-report-sub-title span {
        font-size: 18px;
        line-height: 22px;
        color: #5E5E5E;
    }
    
    .analyst-report-sub-title span::after {
        content: ' ';
    }
    
    .border-separator {
        background-color: grey;
        height: 1.11px;
        margin-top: 12px;
    }
    
    .analyst-report-authorName-and-date {
        height: 28px;
        margin-top: 7px;
    }
    
    .analyst-report-authorName-and-date span:first-child {
        font-weight: 700;
    }
    
    .analyst-report-authorName-and-date span:nth-child(2) {
        margin-left: 10px;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
    }
    
    .analyst-reports-content-section {
        margin-top: 48px;
    }
    
    .analyst-report-sub-heading-and-content span {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: #1e1e1e;
        text-align: left;
    }
    
    .analyst-report-sub-heading-and-content p {
        margin-top: 10px;
        margin-bottom: 15px;
    }
    
    .analyst-report-sub-heading-and-content div span:nth-child(2) {
        font-size: 20px;
        font-weight: 300;
        line-height: 21px;
        margin-left: 0px;
    }
    
    .analyst-report-sub-heading-and-content div span:nth-child(3) {
        font-size: 20px;
        font-weight: 300;
        line-height: 21px;
        margin-left: 0px;
    }
    
    .analyst-report-sub-heading-and-content div span:nth-child(4) {
        font-size: 20px;
        font-weight: 300;
        line-height: 21px;
        margin-left: 0px;
    }
    
    .analyst-report-sub-heading-and-content p {
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        text-align: left;
        font-weight: 300;
    }
    
    .analyst-main-loader-style {
        margin-left: 50%;
        margin-top: 150px;
    }
    
    .analyst-loader-style {
        /*margin-left: 350px;*/
        margin-left: 30%;
        margin-top: 150px;
    }
    
    .analyst-no-report-message {
        display: flex;
        justify-content: center;
        align-items: Center;
        margin-top: 33px;
        /*margin-left: 255px;*/
        margin-bottom: 10%;
    }
    
    .analyst-report-authorName-and-date>div>button {
        margin-top: -10px;
        margin-left: 300px;
    }
    
    .print-button {
        float: right;
        margin-top: -35px;
    }
    
    .print-button:hover {
        cursor: pointer;
    }
    
    .analyst-report-etf-heading {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: #1e1e1e;
        text-align: left;
    }
    
    .analyst-report-etf-text {
        font-size: 20px;
        font-weight: 300;
        line-height: 21px;
        color: #1e1e1e;
    }
    
    .analyst-report-etf-text-paragraph {
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        text-align: left;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    
    .analyst-report-analyst-icon {
        display: inline-flex;
        margin-left: 5px;
        position: relative;
        top: 6px;
    }
    
    .etf-print {
        pointer-events: all;
    }
    
    .icon-above-average {
        height: 26px;
        width: 26px;
        position: relative;
        top: 6px;
        left: 5px;
        margin-right: 5px;
        display: inline-block;
        background-image: url("../../../assets/analyst-rating-icon-above-average.svg");
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
    }
    
    .icon-average {
        height: 26px;
        width: 26px;
        position: relative;
        top: 6px;
        left: 5px;
        margin-right: 5px;
        display: inline-block;
        background-image: url("../../../assets/analyst-rating-icon-average.svg");
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
    }
    
    .icon-below-average {
        height: 26px;
        width: 26px;
        position: relative;
        top: 6px;
        left: 5px;
        margin-right: 5px;
        display: inline-block;
        background-image: url("../../../assets/analyst-rating-icon-below-average.svg");
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
    }
    
    .icon-high {
        height: 26px;
        width: 26px;
        position: relative;
        top: 6px;
        left: 5px;
        margin-right: 5px;
        display: inline-block;
        background-image: url("../../../assets/analyst-rating-icon-high.svg");
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
    }
    
    .icon-low {
        height: 26px;
        width: 26px;
        position: relative;
        top: 6px;
        left: 5px;
        margin-right: 5px;
        display: inline-block;
        background-image: url("../../../assets/analyst-rating-icon-low.svg");
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
    }
    
    .icon-not-rated {
        height: 26px;
        width: 26px;
        position: relative;
        top: 6px;
        left: 5px;
        margin-right: 5px;
        display: inline-block;
        background-image: url("../../../assets/analyst-rating-icon-not-rated.svg");
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
    }
    
    @media only screen and (min-width:320px) and (max-width:1000px) {
        .analyst-loader-style {
            margin-left: 50%;
        }
    
        .analyst-reports-right-section {
            width: 100%;
            max-width: 100%;
            margin-left: 0px;
            margin-top: 25px;
        }
    
        .analyst-report-title span {
            color: #1e1e1e;
            font-size: 20px;
            line-height: 23px;
            font-weight: 700;
        }
    
        .analyst-report-sub-title span {
            font-size: 16px;
            color: #5E5E5E;
        }
    
        .print-button {
            display: none;
        }
    
        .analyst-report-sub-heading-and-content p,
        .analyst-report-sub-heading-and-content span,
        .analyst-report-sub-heading-and-content div span:nth-child(2),
        .analyst-report-sub-heading-and-content div span:nth-child(3),
        .analyst-report-sub-heading-and-content div span:nth-child(4) {
            font-size: 16px;
            line-height: 21px;
            color: #1E1E1E;
        }
    
        .analyst-reports-content-section {
            margin-top: 20px;
        }
    }
    
    @media only screen and (min-width:1001px) and (max-width:1400px) {
        .analyst-reports-right-section {
            margin-left: 55px;
        }
    }
    </style><style>
    .analyst-report-etf-text-paragraph p {
        margin-top: 20px;
    }
    
    .analyst-report-sub-title .mds-icon,
    .analyst-report-sub-title .mds-medalist-rating {
        fill: #5E5E5E;
    }
    </style>