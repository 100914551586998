
<template>
  <div>
  <div v-if="isMobileContentToggle" class="commentary-editor-container-responsive">
    <div style="margin-top: 15px" class="commentary-home-readmore">
                  <editor-read-more></editor-read-more>
                </div>
                <div>
                <articles></articles>
              </div>
  </div>
    <div v-else class="commentary-editor-container">
      <div>
        <mds-layout-grid class="commentary-home-grid-class">
        <mds-row>
            <mds-col :cols="3" :cols-at-s="3" :cols-at-m="3" :cols-at-l="3" :cols-at-xl="3">
              <div class="commentary-home-divborder"></div>
            </mds-col>
        </mds-row>
          <mds-row>
            <mds-col :cols="3" :cols-at-s="9" :cols-at-m="9" :cols-at-l="3" :cols-at-xl="3">
              
              <div style="margin-top: 10px" class="commentry-main-div-for-600px">
                <div style="margin-top: 15px" class="commentary-home-readmore">
                  <editor-read-more></editor-read-more>
                </div>
              </div>
            </mds-col>
          
            <mds-col style="margin-left: 12px" :cols="9" :cols-at-s="12" :cols-at-m="11" :cols-at-l="6" :cols-at-xl="9" >
              <div class="commentary-home-divborder-for-commentaory"></div>
              <div class="main-corousal-div">

                  
      <div
        class="articles-opacity-right"
        v-if="this.$store.state.article.ArticleCounter != 4"
      ></div>
      <div
        class="articles-opacity-left"
        v-if="this.$store.state.article.ArticleCounter > 0"
      >
        <!-- && this.$store.state.article.ArticleCounter < 4 -->
      </div>
              
              <div class="main-div-carousal-for-600px">
              <articles></articles>
            </div>
    </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
  import EditorReadMore from "../Editor/EditorReadMore.vue";
  import Articles from "../Blog/Articles.vue";
  import { mapState } from "vuex";
  export default {
    name: "editor-section",
    components: {
      Articles,
      MdsLayoutGrid,
      MdsRow,
      MdsCol,
      EditorReadMore
    },
    data() {
      return {
        items: [
          {
            title: "The Terrific 30",
            author: "Russel Kinnel ",
            publishedDate: "03-11-22",
            publishedTime: "10:59 AM",
            content: `<p>It turns out that 30 funds passed my tests for the "Fantastic Funds" feature. American Funds Income Fund of America AMECX was excluded from the July 2022 issue because of a data error on manager investment. We have updated the PDF on mfi.morningstar.com to correct the error.</p>
  <p><strong>Rekenthaler on Sequence Risk in Retirement<br /></strong>John Rekenthaler has reviewed sequence risk <a href="https://www.morningstar.com/articles/1111313/sequence-risk-during-retirement">in an interesting piece</a>.</p>
  <p><strong>Three Recent Upgrades<br /></strong><a href="https://www.morningstar.com/articles/1110279/3-upgraded-funds-for-the-long-haul">In this video</a>, I discuss three funds we upgraded.&nbsp;</p>
  <p><strong>The Hidden Costs of IRA Rollovers<br /></strong>Finally, <a href="https://www.morningstar.com/articles/1108061/the-hidden-costs-of-ira-rollovers">Mark Miller looks at IRA Rollovers</a>.&nbsp;</p>`,
          },
          {
            title: "Great Economic News, but Storm Clouds Are Gathering",
            author: "Russel Kinnel ",
            publishedDate: "03-11-22",
            publishedTime: "10:59 AM",
            content: `<p>Wow, that&rsquo;s a lot of good news. In July, <a href="https://www.bls.gov/news.release/cpi.nr0.htm">inflation was zero</a>, and <a href="https://www.cnbc.com/2022/08/05/jobs-report-july-2022-528000.html">the economy added more than 500,000 jobs</a>. <a href="https://www.cnbc.com/2022/08/05/jobs-report-july-2022-528000.html"></a> So much for talk of a recession. And stocks have rallied. Curiously, speculative growth stocks have really rallied.</p>
  <p>Markets are focused on the Federal Reserve and its plans for rate hikes. Thus, the jobs boost was bad in that it made more rate hikes likely. On the other hand, the inflation figure made them less likely.</p>
  <p>But skeptics argue markets have overreacted and the Fed will hike more aggressively than current prices suggest. I have to say they have a point. If the economy can get a second wind, then so can inflation. True, gas prices have fallen every day this summer, but generally low unemployment leads to inflation, though we may never know exactly how low it must get to spur inflation or how much inflation.</p>
  <p>Moreover, inflation and interest rates partly reflect the market's faith in the Fed's dedication to fighting inflation. Since it was too slow to react earlier this year, the Fed may have little choice but to be the bad cop this time around with continued tightening. And if that's the case, then it's worrisome for stocks. As you hear all the time, don't fight the Fed.</p>
  <p>Value investor Cliff Asness says that speculative growth is back to ridiculous levels relative to value. Though he is also quick to point out that there's no telling when that will correct, and it certainly won't be a smooth ride for value investors. I would agree with that general principle. Growth had been more attractive to me when it was getting pummeled, but now value is looking like the better proposition.</p>
  <p>One good bit of news is that yields have risen on bonds, so that if you nudge your bond stake higher, you might get paid decently to wait this out. My last investment (outside of regular 401(k) contributions) was in a bond fund, but it was a small incremental move.</p>
  <p><strong>Vanguard's New Climate Fund<br /></strong>On a completely different topic, <a href="https://corporate.vanguard.com/content/corporatesite/us/en/corp/who-we-are/pressroom/press-release-vanguard-to-introduce-environmental-opportunities-fund-081622.html">Vanguard is set to launch an actively managed climate fund</a> run by a subadvisor it hasn't used before. That firm is South Africa-based Ninety One.&nbsp;</p>`,
          },
          {
            title: "New Issue of FundInvestor Is Available (PDF)",
            author: "Russel Kinnel ",
            publishedDate: "03-11-22",
            publishedTime: "10:59 AM",
            content: `<p>The August 2022 issue of <em>FundInvestor</em> is out <a href="https://mfi.morningstar.com/Newsletter.aspx">in PDF form</a>.&nbsp;</p>
  <p><strong>Funds in a Tight Spot:</strong> I look at funds where asset bloat and flows are making it hard for managers to run their funds.</p>
  <p><strong>There Is Only One Reason to Have Diamond Hands:</strong> John Rekenthaler considers what asset classes you really should hold.</p>
  <p><strong>Three Foreign Large-Value Funds Worth a Look:</strong> Bill Rocco highlights some of our favorite overseas funds amid a rising dollar.</p>
  <p><strong>Bond Funds With Tax Issues:</strong> Paul Olmsted waves a red flag on funds that may give you a big tax bill.</p>
  <p><strong>How Is the Bucket Strategy Holding Up?</strong> Christine Benz looks at how this bear market is stress-testing the Bucket strategy.</p>
  <p><strong>How Have Balanced Funds Navigated Choppy Seas?</strong> Greg Carlson looks at returns from Morningstar Medalist balanced funds.</p>
  <p><strong>Equity-Income Funds Playing Good Defense:</strong> Paul Ruppe explains why equity-income funds protect on the downside.</p>
  <p><strong>A Super Aggressive Fund Joins the Morningstar 500</strong>: I&rsquo;ve added a bold growth fund for those feeling contrarian.</p>`,
          },
          {
            title: "Inflation and the Fed Keep Stocks in the Red",
            author: "Russel Kinnel ",
            publishedDate: "03-11-22",
            publishedTime: "10:59 AM",
            content: `<p>We got another scary inflation number on Wednesday. A 9.1% 12-month increase in the Consumer Price Index led investors <a href="https://www.morningstar.com/articles/1102130/bond-market-is-flashing-a-recession-warning-again">to price in more aggressive tightening by the Federal Reserve</a>.&nbsp;</p>
  <p>Because investors are looking ahead, their focus now turns to a likely recession owing to the Fed rate hikes. Indeed, more reports are coming of job cuts. And there are even signs of inflation declining, such as the steady fall in gas prices the past 30 days. Consensus seems to be building <a href="https://www.morningstar.com/articles/1102067/did-the-june-cpi-report-mark-peak-inflation">that inflation has peaked</a>, but that the Fed has more rate hikes to come.&nbsp;</p>
  <p>You might guess that news on inflation is bad for the dollar, but it just hit <a href="https://www.nytimes.com/2022/07/13/business/euro-dollar-parity.html">a 20-year high versus the euro</a>. Elsewhere, it's clear that the extreme drop in the economy because of the coronavirus continues to make it difficult for some sectors to get back on line. For example, <a href="https://view.em.freightwaves.com/?qs=a6c3b6ab978351dfb112542abed6a5b69ad894376c215378cc30cf9a11a45a2baf5970d3f549469620f4625c83b479440e2ffdbfe56c59a052e8bbd8130f21b00a038d0ecbd304bb28712592e0a19422">the freight industry</a> slashed so many jobs that it can&rsquo;t handle the surge in demand.&nbsp;</p>
  <p>The fund industry continues to be deflationary as we found in <a href="https://www.morningstar.com/articles/1055229/fund-fees-continued-decline-is-a-win-for-investors">our latest survey of fund costs</a>.&nbsp;</p>
  <p>Another bright spot is that Social Security&rsquo;s cost-of-living adjustment will be about 10.5%, according to Mark Miller, an expert on Social Security.</p>
  <p>One old maxim that has proved wise is "Don't Fight the Fed." When the Fed is raising rates, it&rsquo;s tough on stocks and bonds alike. And it figures to slow the economy considerably.</p>
  <p>At midday on Friday, the stock market is actually enjoying a good day&mdash;up 1.5%. However, that leaves it down about 20% for the year and pessimism is firmly in place. If you own cash, it's a comfortable feeling knowing that you can take advantage of the selloff by buying at cheaper prices. The believers in "Don't Fight the Fed" argue that we have still more market declines ahead before it will be safe to buy.</p>`,
          },
          {
            title: "New Issue of FundInvestor Is Available (PDF)",
            author: "Russel Kinnel ",
            publishedDate: "03-11-22",
            publishedTime: "10:59 AM",
            content: `<p>The July 2022 issue of <em>FundInvestor</em> is out <a href="https://mfi.morningstar.com/Newsletter.aspx">in PDF form</a>.</p>
  <p>Here's what's in the issue:</p>
  <p><strong>The Terrific 29:</strong> It's time again for my annual report in which I apply a few very demanding screens to whittle the fund world down to just 29 funds. You&rsquo;ll notice many repeats from last year but a few new ones, too.</p>
  <p><strong>Why Diversification Is Getting Harder:</strong> Yes, bonds and stocks are both down this year, but they are part of a broader trend. Amy Arnott takes a deep dive into the changing landscape of portfolio building. We also published her complete report on diversification on the <em>FundInvestor</em> homepage so you can make that deep dive for yourself. It's on the left-hand side under <a href="https://mfi.morningstar.com/Default.aspx">Bonus Reports</a>.&nbsp;</p>
  <p><strong>Bold Calls in a New Economy:</strong> Bobby Blue examines some big asset-allocation bets that fund managers have made in light of the tectonic shifts in investing.</p>
  <p><strong>Defensive Funds That Disappointed:</strong> Adam Sabban finds that some funds you hoped would hold up well in a downturn failed this time around.</p>
  <p><strong>Three Funds Reopen:</strong> In News, I look at three funds that announced they are reopening.</p>
  <p><strong>What if This Turns Out to Be a Terrible Time to Retire?</strong> Christine Benz talks about how to protect your portfolio in a rough environment.</p>
  <p><strong>A Comeback for Munis:</strong> Elizabeth Templeton sees some rebounds among our favorite municipal-bond funds.</p>
  <p><strong>Are Bonds Back?</strong> Eric Jacobson says this might be a good time to get into bonds.</p>
  <p><strong>Three Medalists Join the 500:</strong> Bill Hench's new fund is on the board.</p>`,
          },
        ],
  
        isMobileContentToggle: null,
          viewPortWidth: null,
      };
    }, 
    methods: {
      newWindows(value) {
        var newValue = "";
        if (value.includes("<a")) {
          newValue = "<a target=_blank";
          return value.replaceAll("<a", newValue);
        }
      },
    },

    mounted() {
            this.isMobileContentToggle=this.$store.state.responsive.isMobileContent;
           
        },
  
        computed:mapState({
    isMobileContent: state => state.responsive.isMobileContent,
    isMobileContentAlias:'isMobileContent',
    isMobileContentState(state){
      return state.responsive.isMobileContent
    }

  }),

  watch:{
    isMobileContentState(){
      this.isMobileContentToggle = this.isMobileContentState;
    }
  }
  
  };
  </script>
  
  <style scoped>
  
  


  @media only screen and (min-width: 1280px) and (max-width: 128000px) {
  .articles-opacity-right {
  position: absolute;
  width: 196px;
  height: 315px;
  margin-top: 60px;
  margin-left: 750px;
  /* background-color: aqua;*/
  background: linear-gradient(
    90deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
}
.articles-opacity-left {
  position: absolute;
  width: 196px;
  height: 310px;
  margin-top: 60px;
  margin-left: -30px;
  /* background:aquamarine;*/
  background: linear-gradient(
    85.79deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
}

  }

  @media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .articles-opacity-right {
  position: absolute;
  width: 146px;
  height: 315px;
  margin-top: 60px;
  margin-left: 60%;
  /* background-color: aqua;*/
  background: linear-gradient(
    90deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
}
.articles-opacity-left {
  position: absolute;
  width: 146px;
  height: 310px;
  margin-top: 60px;
  margin-left: 10px;
  /* background:aquamarine;*/
  background: linear-gradient(
    85.79deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
}

  }

  @media only screen and (min-width: 1000px) and (max-width: 1100px) {
 
.articles-opacity-left {
  position: absolute;
  width: 50px;
  height: 310px;
  margin-top: 60px;
  margin-left: -20px;
  /* background:aquamarine;*/
  background: linear-gradient(
    85.79deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
}

  }

  
  @media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .articles-opacity-right {
  position: absolute;
  width: 110px;
  height: 315px;
  margin-top: 60px;
  margin-left: 52%;
  /* background-color: aqua;*/
  background: linear-gradient(
    90deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
}
.articles-opacity-left {
  position: absolute;
  width: 80px;
  height: 310px;
  margin-top: 60px;
  margin-left: -25px;
  /* background:aquamarine;*/
  background: linear-gradient(
    85.79deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
}

  }

.main-corousal-div{
width: 100%;
}
  .commentary-home-divborder {
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }

  .commentary-home-divborder-for-commentaory {
    margin-left: -4px;
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }
  .border-seperator-style{
    margin-left:7px;
  }
  .commentary-home-grid-class {
    margin: 20px 0px 20px 0px;
  }
  .commentary-home-readmore {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */
  
    color: #5e5e5e;
  }
  
  a {
    color: #000000;
  }
  
  /*
  .fade:after{
    position: absolute;
    bottom: 0;  
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to left,
       rgba(255,255,255, 1) 5%,
       rgba(255,255,255, 0) 95%
    );
    pointer-events: none; /* so the text is still selectable 
  }*/
  /* .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
    max-width: 24.3% !important;
  }
  @media only screen and (min-width: 1001px) and (max-width: 1200px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
      max-width: 24.3% !important;
    }
  }
  @media only screen and (min-width: 1201px) and (max-width: 1600px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
      max-width: 24.3% !important;
    }
  } */
  
  .mds-layout-grid .mds-layout-grid__col--9-at-1200{
    max-width: 74%;
  }
  
  
  @media only screen and (min-width: 1280px) and (max-width: 12000px) {
  .commentary-editor-container{
    width: 1280px;
    margin: auto;
    padding: auto;
    margin-top:80px;
  }

}
  
  @media only screen and (min-width: 1201px) and (max-width: 1280px) {
    .commentary-editor-container{
    width: 105%;
    margin: auto;
    padding: auto;
    margin-top:80px;
    margin-left: 0%;
    margin-right: 16px;
  }
  
  }


  @media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .commentary-editor-container{
    width: 121%;
    margin: auto;
    padding: auto;
    margin-top:80px;
    margin-left: 5%;
    margin-right: 16px;
  }
  
  }

  @media only screen and (min-width: 0px) and (max-width: 600px) {
    .commentary-editor-container{
    display: none !important;;
  }
}

  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    .commentary-editor-container{
    width: 95%;
    
    margin-top:80px;
    margin-left: 5%;
    margin-right: 16px;
  }
  

  .commentry-main-div-for-600px{
    width: 121%;
  }

  .main-div-carousal-for-600px{
    width: 127%;
  }
  
  }

  @media only screen and (min-width: 0px) and (max-width: 600px) {
  .commentary-editor-container-responsive{
    width: 95%;
    margin: auto;
    padding: auto;
    margin-top:80px;
    margin-left: 0;
    margin-right: 16px;
  }

}

@media only screen and (min-width: 600px) and (max-width: 10000px) {
  .commentary-editor-container-responsive{
   display: none !important;
  }

}



  @media only screen and (min-width: 600px) and (max-width: 1000px) {
  .commentary-home-divborder {
    width: 430%;
    margin-left: 0%;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }
  .commentary-home-divborder-for-commentaory {
    margin-left: -10px;
    width: 130%;
    margin-top: 20px;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .commentary-home-divborder {
    width: 100%;
    margin-left: 0%;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }
  .commentary-home-divborder-for-commentaory {
    margin-left: -10px;
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .commentary-home-divborder {
    width: 100%;
    margin-left: 50px;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }
  .commentary-home-divborder-for-commentaory {
    margin-left: 35px;
    width: 87%;
    margin-top: -1px;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
  }
  }
  </style>
  <style>
  .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
    width: 23.3% !important;
  }
  .mds-layout-grid .mds-layout-grid__col--3-at-1000{
    width: 23.3% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1600px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
      max-width: 23.3%;
    }
  }
  </style>