<template>
    <div class="main-product-selector-div">
        <div v-if="this.$store.state.user.LoggedIn" :key="this.$store.state.product.CurrentProduct" class="product-dropdown">
            <mds-button :class="this.CurrentProduct.toUpperCase() + '-selected-product product-selected-text'" on-dark
                size="small" @click="openProductSelector = !openProductSelector" id="product-selector">
                <span id="cross_sell" :target="CurrentProduct"
                    v-if="currentProductFullName == 'FUND' || currentProductFullName == 'STOCK' || currentProductFullName == 'DIVIDEND' || currentProductFullName == 'ETF'"
                    style="margin-left: 10px;">
                    <span class="product-dropdown-text-firstWord">{{ currentProductFullName }}</span>
                    <span class="product-dropdown-text-secondWord">INVESTOR</span>
                </span>
                <span v-if="currentProductFullName == 'NEWSLETTERS HOME'" style="margin-left: 10px;">
                    <span class="product-dropdown-text-secondWord">{{ currentProductFullName }}</span>
                </span>
                <mds-icon name="angle-down" size="small" class="product-angle-down"></mds-icon>
            </mds-button>
            <mds-popover triggered-by="product-selector" v-model="openProductSelector"
                :position="['bottom-right', 'bottom-center']" class="product-dropdown-popover">
                <mds-list-group on-dark no-rules icon-right="angle-down">
                    <mds-list-group-item v-for="(product, index) in this.Products" :key="index" :to="{
                        name: product.access ? 'home' : 'marketing-landing',
                        params: { currentproduct: product.access ? product.name.toLowerCase() : product.name.toLowerCase() }
                    }" :class="product.access ? 'product-dropdown-text' : 'product-dropdown-disabled-text'">
                        <template>
                            <div id="cross_sell" :target="CurrentProduct">
                                <span style="font-weight: 700;letter-spacing: 0.2em;margin-right:0.5;"
                                    :class="product.access ? 'product-enable-text' : 'product-disabled-text'"
                                    @click="openProductSelector = !openProductSelector">{{ product.text
                                    }}</span>
                                <span style="letter-spacing: 0.2em;"
                                    :class="product.access ? 'product-enable-text' : 'product-disabled-text'"
                                    @click="openProductSelector = !openProductSelector">INVESTOR</span>
                            </div>
                        </template>
                        <template #mds-list-item-right v-if="!product.access">
                            <mds-icon class="product-lock-icon" name="lock-close" size="small"></mds-icon>
                        </template>
                    </mds-list-group-item>
                    <mds-list-group-item 
                        :to="{ name: 'combined-home', params: { currentproduct: 'Common' } }" exact
                        class="product-dropdown-text newsletters-home-link" separator-before
                        style="padding-top: 10px;letter-spacing: 0.2em;">
                        <span @click="openProductSelector = !openProductSelector">NEWSLETTERS HOME</span>
                    </mds-list-group-item>
                </mds-list-group>
            </mds-popover>
        </div>
    </div>
</template>
    
<script>
import MdsIcon from "@mds/icon";
import {
    MdsListGroup,
    MdsListGroupItem
} from '@mds/list-group';
import {
    MdsButton
} from "@mds/button";
import MdsPopover from "@mds/popover";

export default {
    name: "product-selector",
    components: {
        MdsButton,
        MdsIcon,
        MdsListGroup,
        MdsListGroupItem,
        MdsPopover
    },
    data() {
        return {
            openProductSelector: false,
            ProductName: '',
            Products: [],
            ProductsRoles: '',
            CurrentProduct: this.$store.state.product.CurrentProduct,
            MEIAccess: false,
            MDIAccess: false,
            MSIAccess: false,
            MFIAccess: false,
            viewPortWidth: null,
        }
    },
    methods: {
        addProductOrder() { 
            this.Products.push({ name: 'MFI', text: 'FUND', access: this.MFIAccess, product: 'FundInvestor' });
            this.Products.push({ name: 'MSI', text: 'STOCK', access: this.MSIAccess, product: 'StockInvestor' });
            this.Products.push({ name: 'MDI', text: 'DIVIDEND', access: this.MDIAccess, product: 'DividendInvestor' });
            this.Products.push({ name: 'MEI', text: 'ETF', access: this.MEIAccess, product: 'ETFInvestor' });
        },

      
    },
    computed: {
        currentProductFullName() {
            if (this.$session.get("current-product").toLowerCase() == "mfi") {
                return "FUND";
            } else if (this.$session.get("current-product").toLowerCase() == "msi") {
                return "STOCK";
            } else if (this.$session.get("current-product").toLowerCase() == "mdi") {
                return "DIVIDEND";
            } else if (this.$session.get("current-product").toLowerCase() == "mei") {
                return "ETF";
            } else {
                return "NEWSLETTERS HOME";
            }
        }
    },
    mounted() {
        if (this.$auth0.isAuthenticated && this.$session.exists()) {
            this.$store.state.user.LoggedIn = true;
        } else {
            this.$store.state.user.LoggedIn = false;
        }
        if (this.$store.state.user.LoggedIn) {
            this.ProductsRoles = this.$auth0.user['https://morningstar.com/uim_roles'];
            if(this.ProductsRoles != undefined && this.ProductsRoles !=null) {
            this.MFIAccess = this.ProductsRoles.includes('FI_MEMBER');
            this.MSIAccess = this.ProductsRoles.includes('SI_MEMBER');
            this.MDIAccess = this.ProductsRoles.includes('DI_MEMBER');
            this.MEIAccess = this.ProductsRoles.includes('EI_MEMBER');
            }
            this.CurrentProduct = this.$session.get("current-product");
            this.$store.state.product.CurrentProduct = this.CurrentProduct;
            this.addProductOrder();
            //if (this.currentProductFullName !== 'NEWSLETTERS HOME') {
            //this.Products.splice(this.Products.map(product => product.text).indexOf(this.currentProductFullName), 1);
            // }
        }
        this.$forceUpdate();
    },



};
</script>
    
<style lang="scss" scoped>
body {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

@import "@mds/constants";
@import "@mds/icon/src/icon_mixins";

.product-lock-icon {
    /*@include mds-icon-color($mds-background-color-white);*/
    position: absolute;
    top: 14.5px;
}

.product-lock-icon-responsive {
    /*@include mds-icon-color($mds-background-color-white);*/
    position: absolute;
    top: 7.5px;
}

@media only screen and (min-width: 1000px) and (max-width: 7000px) {

.main-product-selector-div{
    width: 1280px;
    margin: auto;
    padding: auto;
}

}

@media only screen and (min-width: 1280px) and (max-width: 1415px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -633px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 1415px) and (max-width: 1705px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -632px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 1705px) and (max-width: 1905px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -631px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 1905px) and (max-width: 2015px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -630px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 2015px) and (max-width: 2275px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -629px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 2275px) and (max-width: 2400px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -628px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 2400px) and (max-width: 2600px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -627px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 2600px) and (max-width: 2860px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -626px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 2860px) and (max-width: 3155px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -625px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 3155px) and (max-width: 3345px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -624px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 3345px) and (max-width: 3555px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -623px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 3555px) and (max-width: 3745px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -622px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 3745px) and (max-width: 4000px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -621px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 4000px) and (max-width: 5000px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -618px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}

@media only screen and (min-width: 5000px) and (max-width: 6000px) {
.product-dropdown {
    left: 49.5%;
    right: 49.5%;
    margin-left: -615px;
    margin-right: 200px;
    position: absolute !important;
    margin-top: -25px !important;

}
}
.product-dropdown-popover {
    width: 230px !important;
    background-color: $mds-background-color-black !important;
    /*  background: #333333 !important;*/
    border-radius: none !important;
    height: 240px;
    top: 0px;
    margin-top: 5px;
    padding-top: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
}

.product-dropdown-popover-responsive {
    width: 165px !important;
    background-color: $mds-background-color-black !important;
    /*  background: #333333 !important;*/
    border-radius: none !important;
    height: 12%;
    top: 0px;
    margin-top: 5px;
    padding-top: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
}

.product-dropdown-popover-responsive .mds-list-group-item__text {
    /* font-weight: 700 !important;*/
    margin-left: 10px !important;
    line-height: 19px !important;
    cursor: pointer !important;
}

.product-dropdown-popover-responsive .mds-list-group-item__text:hover {
    cursor: pointer !important;
}

.product-dropdown-popover-responsive {
    /* top: 10px !important;*/
    top: 0px !important;
    border: #333333 !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
}

@media only screen and (min-width: 0px) and (max-width: 1000px) {

.product-dropdown {
    display: none !important;

}

}

@media only screen and (min-width: 1000px) and (max-width: 1020px) {

.product-dropdown {
    left: 5.1%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}

@media only screen and (min-width: 1020px) and (max-width: 1040px) {

.product-dropdown {
    left: 5%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1040px) and (max-width: 1060px) {

.product-dropdown {
    left: 4.9%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1060px) and (max-width: 1080px) {

.product-dropdown {
    left: 4.8%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1080px) and (max-width: 1100px) {

.product-dropdown {
    left: 4.7%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1100px) and (max-width: 1120px) {

.product-dropdown {
    left: 4.6%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1120px) and (max-width: 1140px) {

.product-dropdown {
    left: 4.5%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}

@media only screen and (min-width: 1140px) and (max-width: 1160px) {

.product-dropdown {
    left: 4.4%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1160px) and (max-width: 1180px) {

.product-dropdown {
    left: 4.3%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1180px) and (max-width: 1200px) {

.product-dropdown {
    left: 4.3%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1200px) and (max-width: 1220px) {

.product-dropdown {
    left: 4.2%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1220px) and (max-width: 1240px) {

.product-dropdown {
    left: 4.1%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}
@media only screen and (min-width: 1240px) and (max-width: 1280px) {

.product-dropdown {
    left: 4.1%;
    
    position: absolute !important;
    margin-top: -25px !important;

}

}



.product-dropdown-responsive {
    left: 2%;
    position: absolute !important;
    margin-top: -25px !important;

}

.product-dropdown-text {
    font-style: normal !important;
    /*font-weight: 300 !important;*/
    color: #FFFFFF !important;
    font-size: 16px !important;
    line-height: 18px !important;
    /*letter-spacing: 0.17em !important;*/
    mix-blend-mode: normal !important;
    background-color: $mds-background-color-black !important;
    /*background-color: #333333 !important;*/
    padding-left: 0px !important;


}

.product-dropdown-text-home-responsive {
    font-style: normal !important;
    /*font-weight: 300 !important;*/
    color: #FFFFFF !important;
    font-size: 12px !important;
    line-height: 10px !important;
    /*letter-spacing: 0.17em !important;*/
    mix-blend-mode: normal !important;
    background-color: $mds-background-color-black !important;
    /*background-color: #333333 !important;*/
    padding-left: 0px !important;


}


.product-dropdown-text:hover {
    cursor: pointer;
}

.product-dropdown-text-responsive:hover {
    cursor: pointer;
}

.product-selected-text {
    font-style: normal !important;
    font-weight: 300 !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    line-height: 18px !important;
    /*letter-spacing: 0.17em !important;*/
    mix-blend-mode: normal !important;
    background-color: $mds-background-color-black !important;
    /* background-color: #333333 !important;*/
    border-radius: 0px !important;
    width: 230px !important;
    padding-left: 10px !important;
    margin-top: 8px;
}

.product-selected-text-responsive {
    font-style: normal !important;
    font-weight: 300 !important;
    color: #FFFFFF !important;
    font-size: 10px !important;
    line-height: 18px !important;
    /*letter-spacing: 0.17em !important;*/
    mix-blend-mode: normal !important;
    background-color: $mds-background-color-black !important;
    /* background-color: #333333 !important;*/
    border-radius: 0px !important;
    width: 165px !important;
    padding-left: 0px !important;
}

.product-dropdown-disabled-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: #ABABAB !important;
    mix-blend-mode: normal;
    flex: none;
    order: 0;
    align-self: stretch;
    padding-left: 0px !important;
}

.product-dropdown-disabled-text-responsive {
    opacity: 50%;
    font-weight: 300;
    font-size: 10px;
    line-height: 10px;
    color: #ABABAB !important;
    mix-blend-mode: normal;
    flex: none;
    order: 0;
    align-self: stretch;
    padding-left: 0px !important;
}

.product-enable-text {
    color: #FFFFFF !important;
}

.product-disabled-text {
    color: #ABABAB !important;
}
</style>
<style>
.MDI-selected-product>span:nth-child(1) {
    margin-right: 12px !important;
}

.MEI-selected-product>span:nth-child(1) {
    margin-right: 62px !important;
}

.MSI-selected-product>span:nth-child(1) {
    margin-right: 42px !important;
}

.MFI-selected-product>span:nth-child(1) {
    margin-right: 47px !important;
}

.Common-selected-product>span:nth-child(1) {
    margin-right: 2px !important;
}

.newsletters-home-link::before {
    border-top: solid 1px #ffffff !important;
    width: 100% !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.newsletters-home-link::after {
    /*
        margin-top: 0px !important;
        padding-top: 0px !important;
        */
}

.product-dropdown-popover .mds-list-group-item__text {
    /* font-weight: 700 !important;*/
    margin-left: 10px !important;
    line-height: 39px !important;
    cursor: pointer !important;
}

.product-dropdown-popover .mds-list-group-item__text:hover {
    cursor: pointer !important;
}

.product-dropdown-popover {
    /* top: 10px !important;*/
    top: 0px !important;
    border: #333333 !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
}

#product-selector {
    position: relative;
    z-index: 1;
    box-shadow: none !important;
    border: none !important;
    height: 47px !important;
    border-radius: none !important;
    letter-spacing: 0.2em !important;
}

.product-selector-opacity {
    position: absolute;
    top: 0px;
    width: 50px;
    height: 29px;
    /* box-shadow: -3px 0px 5px #0077CF;*/
    width: 249px;
    height: 50px;
    z-index: 2;
    /*background-color: #1F2F42;*/
}

.product-dropdown-text a:focus {
    box-shadow: none !important;
}

.product-dropdown-text-responsive a:focus {
    box-shadow: none !important;
}

.product-dropdown-disabled-text a:focus {
    box-shadow: none !important;
}

.product-dropdown-disabled-text-responsive a:focus {
    box-shadow: none !important;
}
</style>
<style>
body {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

.product-dropdown-text-firstWord {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    /*letter-spacing: 0.17em;*/
    color: #FFFFFF;
    margin-right: 0.5px;


}

.product-dropdown-text-secondWord {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    /*letter-spacing: 0.2em !important;*/
    color: #FFFFFF;


}

.product-dropdown-text-firstWord-responsive {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 10px;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    /*letter-spacing: 0.17em;*/
    color: #FFFFFF;
    margin-right: 0.5px;

}

.product-dropdown-text-secondWord-responsive {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 10px;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    /*letter-spacing: 0.2em !important;*/
    color: #FFFFFF;


}

.product-angle-down {
    color: #FFFFFF !important;
    position: absolute;
    right: 12px;
    z-index: 88888;
    top: 17px;

}

.newsletters-home-link .mds-list-group__link {
    margin-top: -5px !important;
}

.newsletter-home-text {
    font-size: 12px;
}
</style>
<style lang="scss">
@import "@mds/constants";
@import "@mds/icon/src/icon_mixins";

.product-angle-down {
    @include mds-icon-color($mds-color-white);
}





/*Responsive*/
</style>

    