import Vue from "vue";
import moment from 'moment';

export const formatDateVal = (value) => {
  if (value) {
      return moment(String(value)).format('MMM DD, YYYY')
  }
};
export const formatDateRegistration = (value) => {
  if (value) {
      return moment(String(value)).format('MMMM DD, YYYY')
  }
};

export const formatDateValForDataTables = (value) => {
  if (value) {
      return moment(String(value)).format('MM/DD/YY')
  }
};

  export const formatDateValForFundSpy = (value) => {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
  
};

export const customDataFilter = (value) => {
    if(value ===" NaN ")
    {
        return this.value="N/A";
    }
    else
    {
    return (Math.round(value * 100) / 100).toFixed(2);
    }
  };

  export const dataCoverageFilter = (value) => {
    if(value ==="NaN" || value === "  " || value === null)
    {
        return this.value= 0;
    }
    else if(value === "_")
    {
      return value;
    }
    else
    {
    return (Math.round(value * 100) / 100).toFixed(2);
    }
  };

  /*Replace numbers with comma separated value*/
  export const amountFilter = (amount) => {
    if (amount !== '' || amount !== undefined || amount !== 0  || amount !== '0.00' || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
      return amount;
  }
  };

  export const riskRatingFilter = (value) => {
    var riskRating = "N/A";
      if (value == 5) {
        riskRating = "Low";
      }
      if (value == 10) {
        riskRating = "Medium";
      }
      if (value == 15) {
        riskRating = "High";
      }
      if (value == 25) {
        riskRating = "Very High";
      }
      if (value == 35) {
        riskRating = "Extreme";
      }
      if (value == "&mdash;") {
        riskRating = "&mdash;";
      }
      return riskRating;
};
  
export const formatDateValForHomePage = (value) => {
    if (value) {
        return moment(String(value)).format('MM-DD-YY')
    }
};

export const formatTimeValForHomePage = (value) => {
    if (value) {
        return moment(String(value)).format('hh:MM A')
    }
};
export const formatTime = (value) =>{
  if(value){
    var date = new Date(value);
    var convertIn12HourFormat = date.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
    return convertIn12HourFormat;
  }
}

Vue.filter("riskRatingFilter", riskRatingFilter);
Vue.filter("customDataFilter", customDataFilter);
Vue.filter("formatDateVal",formatDateVal);
Vue.filter("amountFilter", amountFilter);
Vue.filter("formatDateValForDataTables",formatDateValForDataTables);
Vue.filter("formatDateValForFundSpy", formatDateValForFundSpy);
Vue.filter("formatTimeValForHomePage", formatTimeValForHomePage);
Vue.filter("formatTime", formatTime);
Vue.filter("formatDateValForHomePage", formatDateValForHomePage);
Vue.filter("dataCoverageFilter",dataCoverageFilter);
Vue.filter("formatDateRegistration",formatDateRegistration);