export default{
    methods:{
        Encrypt(str){
            if(str){
            //  const json = JSON.stringify(str);
              return Buffer.from(str).toString("base64");
            }
          },
          Decrypt(str){
            if(str){
              const json = Buffer.from(str, "base64").toString();
              return json;
            }
          },
    }
}