<template>
  <div class="main-cover-image-class">
    <div v-if="isMobileContentToggle" class="main-responsive-div">
      <div class="home-pdf-image-loader-responsive">
        <mds-loader
          v-if="isLoadingImage"
          aria-label="Medium Loader"
        ></mds-loader>
      </div>
      <div class="home-pdf-image-div-responsive" v-if="isDisplayImage">
        <div class="cover-image-responsive">
         
          <div>
            <img :src="imgSrc3" class="home-pdf-image3-responsive" />
          </div>
          <div>
            <img :src="imgSrc4" class="home-pdf-image4-responsive" />
          </div>
          <div>
            <img :src="imgSrc5" class="home-pdf-image5-responsive" />
          </div>
        </div>

        <div class="pdf-info-main-div">
        <div class="new-issue-responsive-pdf">
          <p class="home-pdf-image-span">NEW ISSUE</p>
        </div>
        <div style="margin-top: 3px">
          <div class="home-pdf-image-title" v-html="Title"></div>
          <div class="home-pdf-image-author" v-html="Author"></div>
          <div class="home-pdf-image-description" v-html="Deck"></div>
          <div style="padding-top: 28px">
            <mds-button variation="secondary" class="cover-download-button" @click="DownloadReport"
              >Download PDF</mds-button
            >
          </div>
        </div>
      </div>
      </div>
    </div>

    <div v-else  class="main-div-for-image-and-title">
      <div class="home-pdf-image-loader">
        <mds-loader
          v-if="isLoadingImage"
          aria-label="Medium Loader"
        ></mds-loader>
      </div>
      <div class="home-pdf-image-div" v-if="isDisplayImage">
        <mds-layout-grid>
          <mds-row>
            <mds-col :cols="8" :cols-at-s="12" :cols-at-m="8" :cols-at-l="8" :cols-at-xl="8" class="top-img-text-div">
              <img :src="imgSrc1" class="home-pdf-image1" />
              <img :src="imgSrc2" class="home-pdf-image2" />
              <img :src="imgSrc3" class="home-pdf-image3" />
              <img :src="imgSrc4" class="home-pdf-image4" />
              <img :src="imgSrc5" class="home-pdf-image5" />
            </mds-col>
            <mds-col :cols="4" :cols-at-s="12" :cols-at-m="4" :cols-at-l="4" :cols-at-xl="4"  class="top-text-div">
              <div>
                <p class="home-pdf-image-span">NEW ISSUE</p>
              </div>
              <div style="margin-top: 36px">
                <div class="home-pdf-image-title" v-html="Title"></div>
                <div class="home-pdf-image-author" v-html="Author"></div>
                <div class="home-pdf-image-description" v-html="Deck"></div>
                <div  class="download-button-main" >
                  <mds-button variation="secondary" @click="DownloadReport"
                    >Download PDF</mds-button
                  >
                </div>
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsButton } from "@mds/button";
import MdsLoader from "@mds/loader";
import { mapState } from "vuex";
Vue.use(VueAxios, axios);

export default {
  name: "cover-images",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsLoader,
  },
  props: [],
  data() {
    return {
      LatestIssueUrl:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_LatestIssue,
      DownloadPdfUrl:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DownloadPdfUrl,
      CurrentProduct: this.$session.get("current-product"),
      imgSrc1: "",
      imgSrc2: "",
      imgSrc3: "",
      imgSrc4: "",
      imgSrc5: "",
      isLoadingImage: true,
      isDisplayImage: false,
      Title: "",
      Author: "",
      Deck: "",
      FileName: "",
      btnDownload: true,
      btnLoading: false,
      isMobileContentToggle: null,
      viewPortWidth: null,
      CurrentDate: new Date(),
    };
  },
  mounted() {
    this.isMobileContentToggle=this.$store.state.responsive.isMobileContent;
    this.getImages();
    this.CurrentProduct = this.$session.get("current-product");
  },
  methods: {
    getImages() {
      try {
        var CurrentProduct = "";
        if (
          this.CurrentProduct.toUpperCase() == "MEI" ||
          this.CurrentProduct.toUpperCase() == "ETF"
        ) {
          CurrentProduct = "ETF";
        } else {
          CurrentProduct = this.CurrentProduct;
        }
        var URL = this.LatestIssueUrl;
        URL = URL.replace("{ProductCode}", CurrentProduct);
        Vue.axios
          .get(URL)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  //this.rowsResultRearch = response.data;
                  var pfData = response.data;
                  if (pfData) {
                    for (var i = 0; i < pfData.length; i++) {
                      var obj = pfData[i];
                      if (obj) {
                        if (i == 0) {
                          if (obj.ImageId) {
                            this.imgSrc5 = obj.ImageId;
                          }
                          if (obj.Title) {
                            this.Title = obj.Title;
                          }
                          if (obj.Author) {
                            this.Author = obj.Author;
                          }
                          if (obj.Deck) {
                            this.Deck = obj.Deck;
                          }
                          if (obj.FileName) {
                            this.FileName = obj.FileName;
                          }
                        } else if (i == 1) {
                          if (obj.ImageId) {
                            this.imgSrc4 = obj.ImageId;
                          }
                        } else if (i == 2) {
                          if (obj.ImageId) {
                            this.imgSrc3 = obj.ImageId;
                          }
                        } else if (i == 3) {
                          if (obj.ImageId) {
                            this.imgSrc2 = obj.ImageId;
                          }
                        } else if (i == 4) {
                          if (obj.ImageId) {
                            this.imgSrc1 = obj.ImageId;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(
            () => ((this.isLoadingImage = false), (this.isDisplayImage = true))
          );
      } catch (ex) {
        console.log(ex.message);
      }
    },
    DownloadReport() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title': this.FileName,
        'type': "New"
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName,
        'type': "New"
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName,
        'type': "New"
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName,
        'type': "New"
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName);
        Url = Url.replace("{Type}", "ISSUES");

        Vue.axios
          .get(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      link.setAttribute("download", this.FileName);
                      link.click();

                      //  console.log(results.FilePath);
                      //  var oReq = new XMLHttpRequest();
                      //   var URLToPDF = results.FilePath;
                      //   oReq.open("GET", URLToPDF, true);
                      //   oReq.responseType = "blob";

                      //   //var fileName = this.FileName;
                      //   oReq.onload = function() {
                      //       var file = new Blob([oReq.response], {
                      //           type: 'application/pdf'
                      //       });

                      //       saveAs(file, "test.pdf");

                      //   };

                      //   oReq.send();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },
   
  },
  computed:mapState({
    isMobileContent: state => state.responsive.isMobileContent,
    isMobileContentAlias:'isMobileContent',
    isMobileContentState(state){
      return state.responsive.isMobileContent
    }

  }),

  watch:{
    isMobileContentState(){
      this.isMobileContentToggle = this.isMobileContentState;
    }
  }
};
</script>

<style scoped>


@media only screen and (min-width: 1280px) and (max-width: 12800px) {
.home-pdf-image-div {
  width: 100%;
  margin-top: 55px;
  margin-left: 0%;
  margin-right: 0%;
}
}

.download-button-main{
  padding-top: 48px; 
}

.home-pdf-image1 {
  width: 83px;
  height: 359px;
  object-fit: cover;
  object-position: 50% 0%;
}

.home-pdf-image2 {
  width: 83px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 50% 0%;
}

.home-pdf-image3 {
  width: 83px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 50% 0%;
}

.home-pdf-image4 {
  width: 83px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 50% 0%;
}



.home-pdf-image-span {
  /*
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.67px;
    text-transform: uppercase;
    color: #1E1E1E;
    */
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 4px;
  font-size: 10px;
}

.home-pdf-image-div-border {
  border-top: 2px solid #1e1e1e;
  width: 62px;
}

.home-pdf-image-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.home-pdf-image-author {
  width: 90%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  padding-top: 3px;
}

.home-pdf-image-description {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  max-height: 190px;
}

.mds-layout-grid .mds-layout-grid__col--7 {
  flex-basis: 53.33333%;
  max-width: 53.33333%;
}

/*Responsive*/
.cover-image-responsive {
  display: flex;
}

.home-pdf-image-div-responsive {
  width: 100%;
  margin-top: 0px;
  margin-left: 0%;
  margin-right: 0%;
}

.home-pdf-image3-responsive {
  width: 128px;
  height: 369px;
  margin-left: 16px;
  object-fit: cover;
  object-position: 65% 0%;
}

.home-pdf-image4-responsive {
  width: 128px;
  height: 369px;
  margin-left: 16px;
  object-fit: cover;
  object-position: 65% 0%;
}


.main-responsive-div{
  margin-top: 20px;
}

.home-pdf-image5-responsive {
  width: 280px;
  height: 369px;
  margin-left: 16px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

.pdf-info-main-div{
  margin-left: 20px;
}

.cover-download-button{
    width: 155px;
    height: 42px;
    font-weight: 300;
font-size: 20px;
line-height: 26px;
padding-left: 23px;
}





.home-pdf-image1 {
  width: 83px;
  height: 359px;
  object-fit: cover;
  object-position: 50% 0%;
}

.home-pdf-image2 {
  width: 83px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 50% 0%;
}

.home-pdf-image3 {
  width: 83px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 50% 0%;
}

.home-pdf-image4 {
  width: 83px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 50% 0%;
}



.home-pdf-image-span {
  /*
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1E1E1E;
  */
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 4px;
  font-size: 10px;
  
}

.home-pdf-image-div-border {
  border-top: 2px solid #1E1E1E;
  width: 62px;
}

.home-pdf-image-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1E1E1E;
}

.home-pdf-image-author {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1E1E1E;
  padding-top: 3px;

}

.home-pdf-image-description {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1E1E1E;
  padding-top: 13px;
  max-height: 190px;
  width: 439px;

}

.mds-layout-grid .mds-layout-grid__col--7 {
  flex-basis: 53.33333%;
  max-width: 53.33333%;
}

.main-cover-image-clas{
  margin:auto;
  padding: auto;
  width: 1280px;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
.top-text-div{
 display: none !important;
}
}

@media only screen and (min-width: 1280px) and (max-width: 6000px) {
.top-text-div{
  margin-left: -185px;
  padding-left: 60px;
}
}

@media only screen and (min-width: 585px) and (max-width: 600px) {
  .home-pdf-image3-responsive {
  width: 128px;
  height: 369px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 64% 0%;
}

.home-pdf-image4-responsive {
  width: 128px;
  height: 369px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 64% 0%;
}


.main-responsive-div{
  margin-top: 20px;
}

.home-pdf-image5-responsive {
  width: 305px;
  height: 369px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  width: 10%;
  margin-top: 30px;
}

}


@media only screen and (min-width: 570px) and (max-width: 585px) {
  .home-pdf-image3-responsive {
  width: 124px;
  height: 369px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 62% 0%;
}

.home-pdf-image4-responsive {
  width: 124px;
  height: 369px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 62% 0%;
}


.main-responsive-div{
  margin-top: 20px;
}

.home-pdf-image5-responsive {
  width: 300px;
  height: 369px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

}

@media only screen and (min-width: 555px) and (max-width: 570px) {
  .home-pdf-image3-responsive {
  width: 120px;
  height: 369px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 61% 0%;
}

.home-pdf-image4-responsive {
  width: 120px;
  height: 369px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 61% 0%;
}


.main-responsive-div{
  margin-top: 20px;
}

.home-pdf-image5-responsive {
  width: 290px;
  height: 369px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

}

@media only screen and (min-width: 540px) and (max-width: 555px) {
  .home-pdf-image3-responsive {
  width: 118px;
  height: 369px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 60% 0%;
}

.home-pdf-image4-responsive {
  width: 118px;
  height: 369px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 60% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 280px;
  height: 369px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

}

@media only screen and (min-width: 525px) and (max-width: 540px) {
  .home-pdf-image3-responsive {
  width: 115px;
  height: 369px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 59% 0%;
}

.home-pdf-image4-responsive {
  width: 115px;
  height: 369px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 59% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 270px;
  height: 369px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

}
@media only screen and (min-width: 510px) and (max-width: 525px) {
  .home-pdf-image3-responsive {
  width: 107px;
  height: 369px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 57% 0%;
}

.home-pdf-image4-responsive {
  width: 107px;
  height: 369px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 57% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 275px;
  height: 369px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

}

@media only screen and (min-width: 490px) and (max-width: 510px) {
  .home-pdf-image3-responsive {
  width: 103px;
  height: 349px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 58% 0%;
}

.home-pdf-image4-responsive {
  width: 103px;
  height: 349px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 58% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 265px;
  height: 349px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

}

@media only screen and (min-width: 470px) and (max-width: 490px) {
  .home-pdf-image3-responsive {
  width: 95px;
  height: 320px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 57% 0%;
}

.home-pdf-image4-responsive {
  width: 95px;
  height: 320px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 57% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 260px;
  height: 320px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

}

@media only screen and (min-width: 450px) and (max-width: 470px) {
  .home-pdf-image3-responsive {
  width: 93px;
  height: 300px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 59% 0%;
}

.home-pdf-image4-responsive {
  width: 93px;
  height: 300px;
  margin-left: 19px;
  object-fit: cover;
  object-position: 59% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 235px;
  height: 300px;
  margin-left: 19px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

.home-pdf-image-description{
  width: 340px;
}

}

@media only screen and (min-width: 430px) and (max-width: 450px) {
  .home-pdf-image3-responsive {
  width: 90px;
  height: 294px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 58% 0%;
}

.home-pdf-image4-responsive {
  width: 90px;
  height: 294px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 58% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 225px;
  height: 294px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

.home-pdf-image-description{
  width: 340px;
}

}

@media only screen and (min-width: 410px) and (max-width: 430px) {
  .home-pdf-image3-responsive {
  width: 82px;
  height: 294px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 55% 0%;
}

.home-pdf-image4-responsive {
  width: 82px;
  height: 294px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 55% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 230px;
  height: 294px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

.home-pdf-image-description{
  width: 340px;
}

}

@media only screen and (min-width: 330px) and (max-width: 390px) {
  .home-pdf-image3-responsive {
  width: 68px;
  height: 294px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 50% 0%;
}

.home-pdf-image4-responsive {
  width: 68px;
  height: 294px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 50% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 220px;
  height: 294px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

.home-pdf-image-description{
  width: 340px;
}

.pdf-info-main-div{
  margin-left: 16px;
}

}
@media only screen and (min-width: 390px) and (max-width: 410px) {
  .home-pdf-image3-responsive {
  width: 75px;
  height: 294px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 52% 0%;
}

.home-pdf-image4-responsive {
  width: 75px;
  height: 294px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 52% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 225px;
  height: 294px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

.home-pdf-image-description{
  width: 340px;
}

.pdf-info-main-div{
  margin-left: 16px;
}

}

@media only screen and (min-width: 290px) and (max-width: 330px) {
  .home-pdf-image3-responsive {
  width: 58px;
  height: 204px;
  margin-left: 0px;
  object-fit: cover;
  object-position: 56% 0%;
}

.home-pdf-image4-responsive {
  width: 58px;
  height: 204px;
  margin-left: 14px;
  object-fit: cover;
  object-position: 56% 0%;
}


.main-responsive-div{
  margin-top: 25px;
}

.home-pdf-image5-responsive {
  width: 170px;
  height: 204px;
  margin-left: 14px;
}

.new-issue-responsive-pdf {
  margin-top: 30px;
}

.home-pdf-image-description{
  width: 250px;
}

.pdf-info-main-div{
  margin-left: 16px;
}

}

@media only screen and (min-width: 600px) and (max-width: 610px) {



.home-pdf-image1 {
  display: none;
}



.home-pdf-image-div {
  width: 100%;
  margin: auto;
  padding: auto;
 
  
}
.new-issue-responsive-pdf {
  margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
  margin-top: -20px;
  padding-top: 50px;
}

.pdf-info-main-div{
  margin-left: 0px;
}

.top-text-div{
  width: 90%;
  margin-top: 30px;
  margin-left: 6px;
  padding-left: 10px;
}


.home-pdf-image2 {
  width: 90px;
  height: 359px;
  margin-left: -30px;
  object-fit: cover;
  object-position: 52% 0%;
}

.home-pdf-image3 {
  width: 90px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 52% 0%;
}

.home-pdf-image4 {
  width: 90px;
  height: 359px;
  margin-left: 17px;
  object-fit: cover;
  object-position: 52% 0%;
}

.home-pdf-image5 {
  width: 270.67px;
  height: 359px;
  margin-left: 17px;
}

}
@media only screen and (min-width: 610px) and (max-width: 620px) {



.home-pdf-image1 {
display: none;
}



.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 6px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 93px;
height: 359px;
margin-left: -30px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image3 {
width: 93px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image4 {
width: 93px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image5 {
width: 270.67px;
height: 359px;
margin-left: 17px;
}

}

@media only screen and (min-width: 600px) and (max-width: 768px) {

.home-pdf-image-title {
  width: 500px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}
.download-button-main{
  padding-top: 48px; 
  width: 200px;
}

}

@media only screen and (min-width: 768px) and (max-width: 830px) {

.home-pdf-image-title {
  width: 230px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.download-button-main{
  padding-top: 48px; 
  width: 200px;
}



}

@media only screen and (min-width: 830px) and (max-width: 900px) {

.home-pdf-image-title {
  width: 250px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.download-button-main{
  padding-top: 48px; 
  width: 200px;
}

}

@media only screen and (min-width: 620px) and (max-width: 630px) {



.home-pdf-image1 {
display: none;
}



.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 6px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 93px;
height: 359px;
margin-left: -30px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image3 {
width: 93px;
height: 359px;
margin-left: 19px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image4 {
width: 93px;
height: 359px;
margin-left: 19px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image5 {
width: 272.67px;
height: 359px;
margin-left: 19px;
}

}

@media only screen and (min-width: 630px) and (max-width: 640px) {



.home-pdf-image1 {
display: none;
}



.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 6px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 93px;
height: 359px;
margin-left: -30px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image3 {
width: 93px;
height: 359px;
margin-left: 23px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image4 {
width: 93px;
height: 359px;
margin-left: 23px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image5 {
width: 270.67px;
height: 359px;
margin-left: 23px;
}

}
@media only screen and (min-width: 640px) and (max-width: 651px) {



.home-pdf-image1 {
display: none;
}



.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 6px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 93px;
height: 359px;
margin-left: -30px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image3 {
width: 93px;
height: 359px;
margin-left: 25px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image4 {
width: 93px;
height: 359px;
margin-left: 25px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image5 {
width: 274.67px;
height: 359px;
margin-left: 25px;
}


}
@media only screen and (min-width: 651px) and (max-width: 680px) {




.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 6px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 90px;
height: 359px;
margin-left: 8px;
object-fit: cover;
object-position: 52% 0%;
}

.home-pdf-image3 {
width: 90px;
height: 359px;
margin-left: 8px;
object-fit: cover;
object-position: 52% 0%;
}

.home-pdf-image4 {
width: 90px;
height: 359px;
margin-left: 8px;
object-fit: cover;
object-position: 52% 0%;
}

.home-pdf-image5 {
width: 257.67px;
height: 359px;
margin-left: 10px;
}
.home-pdf-image1 {
  width: 75px;
  height: 359px;
  object-fit: cover;
  margin-left: -30px;
  object-position: 48% 0%;
}

}
@media only screen and (min-width: 680px) and (max-width: 700px) {




.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 6px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 93px;
height: 359px;
margin-left: 14px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image3 {
width: 93px;
height: 359px;
margin-left: 14px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image4 {
width: 93px;
height: 359px;
margin-left: 14px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image5 {
width: 254.67px;
height: 359px;
margin-left: 14px;
}
.home-pdf-image1 {
  width: 75px;
  height: 359px;
  object-fit: cover;
  margin-left: -30px;
  object-position: 48% 0%;
}



}
@media only screen and (min-width: 700px) and (max-width: 728px) {




.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 6px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 93px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image3 {
width: 93px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image4 {
width: 93px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 53% 0%;
}

.home-pdf-image5 {
width: 260.67px;
height: 359px;
margin-left: 17px;
}

.home-pdf-image1 {
  width: 80px;
  height: 359px;
  object-fit: cover;
  margin-left: -30px;
  object-position: 50% 0%;
}

}

@media only screen and (min-width: 728px) and (max-width: 755px) {



.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 90%;
margin-top: 30px;
margin-left: 10px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 100px;
height: 359px;
margin-left: 14px;
object-fit: cover;
object-position: 55% 0%;
}

.home-pdf-image3 {
width: 100px;
height: 359px;
margin-left: 14px;
object-fit: cover;
object-position: 55% 0%;
}

.home-pdf-image4 {
width: 100px;
height: 359px;
margin-left: 14px;
object-fit: cover;
object-position: 55% 0%;
}

.home-pdf-image5 {
width: 254.67px;
height: 359px;
margin-left: 14px;
}
.home-pdf-image1 {
  width: 100px;
  height: 359px;
  object-fit: cover;
  margin-left: -30px;
  object-position: 55% 0%;
}

}

@media only screen and (min-width: 755px) and (max-width: 768px) {




.home-pdf-image-div {
width: 100%;
margin: auto;
padding: auto;


}
.new-issue-responsive-pdf {
margin-top: 30px;
}

.home-pdf-image-description{
  width: 540px;
margin-top: -20px;
padding-top: 50px;
}

.pdf-info-main-div{
margin-left: 0px;
}

.top-text-div{
width: 95%;
margin-top: 30px;
margin-left: 16px;
padding-left: 10px;
}


.home-pdf-image2 {
width: 101px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 55% 0%;
}

.home-pdf-image3 {
width: 98px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 55% 0%;
}

.home-pdf-image4 {
width:101px;
height: 359px;
margin-left: 17px;
object-fit: cover;
object-position: 55% 0%;
}

.home-pdf-image5 {
width: 270.67px;
height: 359px;
margin-left: 17px;
}

.home-pdf-image1 {
  width:101px;
  height: 359px;
  object-fit: cover;
  margin-left: -30px;
  object-position: 55% 0%;
}

}


@media only screen and (min-width: 768px) and (max-width: 770px){
.home-pdf-image5 {
  width: 220.67px;
  height: 359px;
  margin-left: 17px;
}
}

@media only screen and (min-width: 770px) and (max-width: 900px){
.home-pdf-image5 {
  width: 245.67px;
  height: 359px;
  margin-left: 17px;
}
}



@media only screen and (min-width: 768px) and (max-width: 900px) {
.home-pdf-image1{
  display: none;
}
.home-pdf-image2{
  display: none;
}
.home-pdf-image-div {
  width: 90%;
  margin: auto;
  padding: auto;
  margin-top: 0px;
  
}
.top-text-div{
 
  margin-left: 0px;
  padding-left: 0px;
}


}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
.home-pdf-image5 {
  width: 240.67px;
  height: 359px;
  margin-left: 17px;
}
}

@media only screen and (min-width: 1000px) and (max-width: 10000px) {
.home-pdf-image5 {
  width: 274.67px;
  height: 359px;
  margin-left: 17px;
}
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
.home-pdf-image5 {
display: none !important;
}

.home-pdf-image1{
  display: none !important;
}
.home-pdf-image2{
  display: none !important;
}

.home-pdf-image3{
  display: none !important;
}
.home-pdf-image4{
  display: none !important;
}
}


@media only screen and (min-width: 768px) and (max-width: 800px) {
.home-pdf-image-description {
width:95%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 10%;
  max-height: 190px;
}
}

@media only screen and (min-width: 800px) and (max-width: 840px) {
.home-pdf-image-description {
width:90%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 10%;
  max-height: 190px;
}
}

@media only screen and (min-width: 840px) and (max-width: 870px) {
.home-pdf-image-description {
width:90%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 10%;
  max-height: 190px;
}
}

@media only screen and (min-width: 870px) and (max-width: 900px) {
.home-pdf-image-description {
width:90%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 10%;
  max-height: 190px;
}
}

@media only screen and (min-width: 900px) and (max-width: 950px) {
.home-pdf-image-description {
width: 90%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 10%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 230px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}
}

@media only screen and (min-width: 950px) and (max-width: 975px) {
.home-pdf-image-description {
width: 90%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 10%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 280px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}
}
@media only screen and (min-width: 975px) and (max-width: 1000px) {
.home-pdf-image-description {
width: 90%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 10%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 310px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}
}

@media only screen and (min-width: 1000px) and (max-width: 1025px) {
.home-pdf-image-description {
width: 70%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 20%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 220px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}
}

@media only screen and (min-width: 1025px) and (max-width: 1060px) {

.home-pdf-image-description {
width: 320px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 20%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 250px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}


}

@media only screen and (min-width: 1060px) and (max-width: 1100px) {

.home-pdf-image-description {
width: 350px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 20%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 250px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}


}


@media only screen and (min-width: 1100px) and (max-width: 1150px) {

.home-pdf-image-description {
width: 400px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 20%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 300px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}


}

@media only screen and (min-width: 1150px) and (max-width: 1200px) {

.home-pdf-image-description {
width: 450px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #1e1e1e;
  padding-top: 13px;
  padding-right: 20%;
  max-height: 190px;
}

.home-pdf-image-title {
  width: 370px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}


}


@media only screen and (min-width: 900px) and (max-width: 1000px) {
.home-pdf-image1{
  display: none;
}

.home-pdf-image-div {
  width: 95%;
  margin: auto;
  padding: auto;
  margin-top: 0px;
  
}
.top-text-div{
  width: 10%;
  margin-left: 0px;
  padding-left: 5px;
}

.download-button-main{
  padding-top: 48px; 
  width: 200px;
}

}


@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .home-pdf-image-div {
  width: 95%;
  margin: auto;
  padding: auto;
  padding-top: 0px;
  
}
.download-button-main{
  padding-top: 48px; 
  width: 200px;
}
}


@media only screen and (min-width: 1000px) and (max-width: 1280px) {



.top-text-div{
  width: 10%;
  margin-left: -110px;
  padding-left: 5px;
}

}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
.home-pdf-image-div {
  width: 95%;
  margin: auto;
  padding: auto;
  margin-top: 50px;
  
}

}

@media only screen and (min-width: 1000px) and (max-width: 1280px) {
.home-pdf-image-div {
  width: 94%;
  margin: auto;
  padding: auto;
  margin-left: 4%;
  margin-right: 5%;
  margin-top: 35px;
  
}

}

@media only screen and (min-width: 880px) and (max-width: 1000px) {
.main-cover-image-class {
    margin-top: 70px;
}

}

@media only screen and (min-width: 600px) and (max-width: 600000px) {

.main-responsive-div{
display: none;

}

}

@media only screen and (min-width: 600px) and (max-width: 1000px) {

.top-img-text-div{
 
}
.home-pdf-image-div>div>div>div{
  padding-right: 0px;
}


}


@media only screen and (min-width: 0px) and (max-width: 600px) {
.new-issue-responsive-pdf {
  width: 250px;
  font-size: 10px;
  line-height: 12px;
 
}

.main-responsive-div{
  width: 100%;
margin-top: 25px;

}

.home-pdf-image-title {
  width: 60%;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.home-pdf-image-author {
  width: 50%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1E1E1E;
  padding-top: 3px;

}

.pdf-info-main-div{
 width: 100%;
}

.home-pdf-image-description {
  width: 90%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  padding-top: 13px;
  max-height: 190px;
}

.main-cover-image-class {
    width: 100%;
   margin: auto;
   padding: auto;
}

.home-pdf-image-loader-responsive{
width: 100%;
}

}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
.main-div-for-image-and-title{
  width: 100%;
}

.home-pdf-image-loader-responsive{
display: none !important;;
}

.home-pdf-image-loader{
  width: 100%;
}
}

@media only screen and (min-width: 1000px) and (max-width: 10000px) {
.main-cover-image-class {
    width: 1280px;
   margin: auto;
   padding: auto;
}

.main-div-for-image-and-title{
  width: 100%;
}
}

@media only screen and (min-width: 0px) and (max-width: 1000px) {

}

</style>