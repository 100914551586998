<template>
<div class="main-div">
    <div class="newsletter-editor-heading-container">
        <span class="newsletter-editor-commentry">Editors and Featured Commentary</span>
        <div class="editor-headline-border"></div>
    </div>
    <div class="featured-commentry-loader">
        <mds-loader v-if="isLoadingCommentry" aria-label="Medium Loader"></mds-loader>
    </div>
    <div v-if="isDisplay">
        <mds-layout-grid class="newsletter-editor-layout">
            <mds-row v-for="(latestArticles, index) in latestArticles" :key="index" class="newsletter-editor-layout-row">
                <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="4" :cols-at-xl="4" class="newsletter-editor-layout-col">
                    <div class="editor-info-container">
                        <div class="editor-product-name-at-top">
                            <span>{{ latestArticles.productname }}</span>
                        </div>
                        <div class="newsletter-editor-border">
                            <div class="editor-image">
                                <img :src="latestArticles.editorimage" class="editor-image-style" />
                            </div>
                            <div class="editor-text">
                                <div>
                                    <b class="editor-text-header">{{
                    latestArticles.editortextheader
                  }}</b>
                                    <br />
                                    <div class="editor-text-style">
                                        <span v-html="latestArticles.editortext"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="editor-description">
                            <span v-html="latestArticles.editordescription"></span>
                        </div>
                    </div>
                    <div class="editor-read-more-container">
                        <span v-if="latestArticles.productname == 'FundInvestor'" @click=" FundInvestortoggle=!FundInvestortoggle" class="editor-readmore">
                            Read More
                            <span class="newsletter-editor-readmore">
                                <mds-icon name="angle-right" size="small"></mds-icon>
                            </span>
                        </span>
                        <span v-if="latestArticles.productname == 'StockInvestor'" @click=" StockInvestortoggle=!StockInvestortoggle" class="editor-readmore">
                            Read More
                            <span class="newsletter-editor-readmore">
                                <mds-icon name="angle-right" size="small"></mds-icon>
                            </span>
                        </span>
                        <span v-if="latestArticles.productname == 'DividendInvestor'" @click=" DividendInvestortoggle=!DividendInvestortoggle" class="editor-readmore">
                            Read More
                            <span class="newsletter-editor-readmore">
                                <mds-icon name="angle-right" size="small"></mds-icon>
                            </span>
                        </span>
                        <span v-if="latestArticles.productname == 'ETFInvestor'" @click=" ETFInvestortoggle=!ETFInvestortoggle" class="editor-readmore">
                            Read More
                            <span class="newsletter-editor-readmore">
                                <mds-icon name="angle-right" size="small"></mds-icon>
                            </span>
                        </span>
                    </div>
                    <div class="border-after-editor-info"></div>
                </mds-col>
                <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="8" :cols-at-xl="8" class="newsletter-mdc-card-col">
                    <div class="editor-product-name">
                        <span>{{ latestArticles.productname }}</span>
                    </div>
                    <mdc-card class="newsletter-mdc-card">
                        <template slot="secondary-text">
                            <div class="newsletter-mdc-card-title">
                                {{ latestArticles.Subject }}
                            </div>
                        </template>
                        <template slot="metadata">
                            <span class="articles-authorname-common">{{
                latestArticles.PostAuthorName
              }}</span>
                            <span class="articles-authorname-common">{{
                latestArticles.PostDate | formatDateValForHomePage
              }}</span>
                            <span class="articles-authorname-common">{{
                latestArticles.PostDate | formatTime
              }}</span>
                        </template>
                        <template slot="caption">
                            <!-- <div class="newsletter-editor-caption">
                <span
                  v-html="latestArticles.FormattedBody"
                  class="combine-editor-article-content"
                ></span>
              </div> -->
                            <div v-if="latestArticles.productname == 'FundInvestor'" class="newsletter-editor-caption-mfi">
                                <span v-html="trimContent(latestArticles.FormattedBody)" class="combine-editor-article-content"></span>
                            </div>

                            <div v-if="latestArticles.productname == 'FundInvestor'" class="combine-editor-fade-effect"></div>

                            <div v-if="latestArticles.productname == 'StockInvestor'" class="newsletter-editor-caption-msi">
                                <span v-html="trimContent(latestArticles.FormattedBody)" class="combine-editor-article-content"></span>
                            </div>

                            <div v-if="latestArticles.productname == 'StockInvestor'" class="combine-editor-fade-effect"></div>

                            <div v-if="latestArticles.productname == 'DividendInvestor'" class="newsletter-editor-caption-mdi">
                                <span v-html="trimContent(latestArticles.FormattedBody)" class="combine-editor-article-content"></span>
                            </div>

                            <div v-if="latestArticles.productname == 'DividendInvestor'" class="combine-editor-fade-effect"></div>

                            <div v-if="latestArticles.productname == 'ETFInvestor'" class="newsletter-editor-caption-etf">
                                <span v-html="trimContent(latestArticles.FormattedBody)" class="combine-editor-article-content"></span>
                            </div>

                            <div v-if="latestArticles.productname == 'ETFInvestor'" class="combine-editor-fade-effect"></div>

                        </template>

                        <template slot="readmore">
                            <div class="article-readmore-container" v-if="latestArticles.productname == 'FundInvestor'">
                                <span v-if="!latestArticles.access">
                                    <img class="newsletter-lock-icon" src="../../../assets/icons/lock-red.svg" />

                                    <router-link to="/" v-if="!latestArticles.access" class="articles-readmore-common" @click.native="getCurrentProduct(latestArticles.productNameShort)" id="lock" target="read_more_mfi">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </router-link>
                                </span>
                                <div v-if="latestArticles.access">
                                    <span class="articles-readmore-common" @click="sendPostToCommentaryPage(latestArticles.productname)">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </span>

                                </div>
                            </div>

                            <div class="article-readmore-container" v-if="latestArticles.productname == 'StockInvestor'">
                                <span v-if="!latestArticles.access">
                                    <img class="newsletter-lock-icon" src="../../../assets/icons/lock-red.svg" />

                                    <router-link to="/" v-if="!latestArticles.access" class="articles-readmore-common" @click.native="getCurrentProduct(latestArticles.productNameShort)" id="lock" target="read_more_msi">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </router-link>
                                </span>
                                <div v-if="latestArticles.access">
                                    <span class="articles-readmore-common" @click="sendPostToCommentaryPage(latestArticles.productname)">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </span>

                                </div>
                            </div>

                            <div class="article-readmore-container" v-if="latestArticles.productname == 'DividendInvestor'">
                                <span v-if="!latestArticles.access">
                                    <img class="newsletter-lock-icon" src="../../../assets/icons/lock-red.svg" />

                                    <router-link to="/" v-if="!latestArticles.access" class="articles-readmore-common" @click.native="getCurrentProduct(latestArticles.productNameShort)" id="lock" target="read_more_mdi">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </router-link>
                                </span>
                                <div v-if="latestArticles.access">
                                    <span class="articles-readmore-common" @click="sendPostToCommentaryPage(latestArticles.productname)">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </span>

                                </div>
                            </div>

                            <div class="article-readmore-container" v-if="latestArticles.productname == 'ETFInvestor'">
                                <span v-if="!latestArticles.access">
                                    <img class="newsletter-lock-icon" src="../../../assets/icons/lock-red.svg" />

                                    <router-link to="/" v-if="!latestArticles.access" class="articles-readmore-common" @click.native="getCurrentProduct(latestArticles.productNameShort)" id="lock" target="read_more_mei">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </router-link>
                                </span>
                                <div v-if="latestArticles.access">
                                    <span class="articles-readmore-common" @click="sendPostToCommentaryPage(latestArticles.productname)">
                                        Read More
                                        <span class="newsletter-editor-readmore">
                                            <mds-icon name="angle-right" size="small"></mds-icon>
                                        </span>
                                    </span>

                                </div>
                            </div>

                        </template>
                    </mdc-card>
                </mds-col>
                <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="12" :cols-at-xl="12" class="newsletter-mdc-card-col">
                    <div :class="'editor-commentry-border editor-commentry-border-'+index"></div>
                </mds-col>
            </mds-row>
            <div class="editor-commentry-end-border"></div>
            <mds-modal v-model="FundInvestortoggle" :width="'600px'" aria-labelledby="title-id" class="featured-commentary-editor-read-more-pop-up">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">About the Editor</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="FundInvestortoggle = !FundInvestortoggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" class="editor-commentry-content-section">
                        <p class="paragraphe-one-editor">
                            Russel Kinnel is director of manager research
                            for Morningstar Research Services LLC and editor of Morningstar
                            FundInvestor, a monthly print newsletter
                            for individual investors. He also writes the Fund
                            Spy column for Morningstar.com, the
                            company's investment website.
                        </p>

                        <p>
                            Since joining the company in 1994, Kinnel has covered the Fidelity, Janus, T. Rowe Price, and
                            Vanguard mutual fund families. He helped develop the new Morningstar Rating for funds and the
                            new Morningstar Style Box methodology. He also is co-author of the company's first book, The
                            Morningstar Guide to Mutual Funds: 5-Star Strategies for Success, which was published in January
                            2003.
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>
            <mds-modal v-model="StockInvestortoggle" :width="'600px'" aria-labelledby="title-id" class="featured-commentary-editor-read-more-pop-up">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">About the Editor</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="StockInvestortoggle = !StockInvestortoggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" class="editor-commentry-content-section">
                        <p class="paragraphe-one-editor">
                            David Harrell is the editor of the <i>Morningstar StockInvestor,</i> a monthly newsletter that
                            focuses on a
                            wide-moat stock investing strategy. For illustration purposes, issues highlight activities
                            pertaining
                            to accounts owned by Morningstar, Inc. invested in accordance with a strategy that seeks to
                            focus on
                            companies with stable or growing competitive advantages. David served in several senior research
                            and
                            product development roles and was part of the editorial team that created and launched
                            Morningstar.com.
                            He was the co-inventor of Morningstar's first investment advice software.
                        </p>

                        <p>
                            David joined Morningstar in 1994. He holds a bachelor's degree in biology from Skidmore College
                            and a
                            master's degree in biology from the University of Illinois at Springfield.
                        </p>

                        <span class="common-portfoliomanager_subheading">Our Portfolio Manager</span>

                        <p style="margin-top:0px;">
                            Michael Corty, CFA, is the Head of U.S. Equity Strategies and a Portfolio Manager for
                            Morningstar
                            Investment Management LLC. Michael joined the group as a portfolio manager in December 2013.
                        </p>

                        <p>
                            Previously, he was a senior equity analyst in Morningstar, Inc.'s equity research department
                            where
                            he also served as a voting member of the economic moat committee. Michael holds a bachelor's
                            degree
                            from Loyola Marymount University and an MBA from Johnson Graduate School of Management at
                            Cornell University.
                        </p>

                        <p>
                            Grady Burkett, CFA, is a Portfolio Manager with Morningstar Investment Management LLC. Grady
                            joined the group as a portfolio manager in December 2022.
                        </p>

                        <p>
                            Prior to joining Morningstar Investment Management, Grady was an analyst and portfolio manager
                            at Diamond Hill,
                            an independent and registered investment adviser. Grady started his investment career in
                            Morningstar,
                            Inc.'s equity research department where he progressed in several roles on the technology sector
                            team as an equity
                            analyst, strategist, and director of the team. Grady received his B.S. and M.S. in Mathematics
                            from Wright
                            State University. He is a member of the CFA Institute and the CFA Society of Columbus, Ohio
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>
            <mds-modal v-model="DividendInvestortoggle" :width="'600px'" aria-labelledby="title-id" class="featured-commentary-editor-read-more-pop-up">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">About the Editor</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="DividendInvestortoggle = !DividendInvestortoggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" class="editor-commentry-content-section">
                        <p class="paragraphe-one-editor">
                            David Harrell is the editor of <i>Morningstar DividendInvestor,</i> a monthly newsletter that
                            highlights
                            activities pertaining to a Morningstar, Inc. account invested in accordance with a strategy that
                            takes a concentrated, best-ideas approach when investing in select common stocks of
                            dividend-paying
                            companies and other securities. The strategy seeks firms with wide or narrow moats that we
                            believe
                            are in a stronger competitive position than their peers and that are trading at a reasonable
                            price.
                        </p>

                        <p>
                            David served in several senior research and product development roles and was part of the
                            editorial
                            team that created and launched Morningstar.com. He was the co-inventor of Morningstar's first
                            investment advice software. David joined Morningstar in 1994. He holds a bachelor's degree in
                            biology
                            from Skidmore College and a master's degree in biology from the University of Illinois at
                            Springfield.
                        </p>

                        <span class="common-portfoliomanager_subheading">Our Portfolio Manager</span>
                        <p style="margin-top:0px;">
                            George Metrou is an equity portfolio manager for Mornigstar Investment Management LLC. Metrou
                            joined
                            the team as a portfolio manager in August 2018. Before joining Morningstar Investment
                            Management,
                            he was an equity portfolio manager with Perritt Capital, and as a portfolio manager with Perritt
                            Capital Management. Prior to that he served as Director of Research and as an equity analyst at
                            Perritt Capital, and as a portfolio manager with Windgate Wealth Management. He holds a
                            Bachelor's
                            degree in finance form DePaul University, and he also holds the Chartered Financial Analyst®
                            designation.
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>
            <mds-modal v-model="ETFInvestortoggle" :width="'600px'" aria-labelledby="title-id" class="featured-commentary-editor-read-more-pop-up">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">About the Editor</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="ETFInvestortoggle = !ETFInvestortoggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" class="editor-commentry-content-section">
                        <p class="paragraphe-one-editor">
                            Bryan Armour is director of passive strategies research for North America at Morningstar
                            Research
                            Services LLC, a wholly owned subsidiary of Morningstar, Inc.
                        </p>

                        <p>
                            Before joining Morningstar in 2021, Armour spent seven years working for the Financial Industry
                            Regulatory Authority, conducting regulatory trade surveillance and investigations, specializing
                            in exchange-traded funds. Prior to Finra, he worked for a proprietary trading firm as an options
                            trader at the Chicago Mercantile Exchange.
                        </p>

                        <p>
                            Armour holds a bachelor's degree in economics from the University of Illinois at
                            Urbana-Champaign.
                            He also holds the Chartered Financial Analyst® designation.
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>
        </mds-layout-grid>
    </div>
    <common-overlay v-if="overlayStatus" :current-product="productSelected" :overlay-status="overlayStatus" @resetOverlayStatusValue="resetOverlayStatus"></common-overlay>
</div>
</template>

<script>
import {
    MdsButton
} from "@mds/button";
import MdsModal from "@mds/modal";
import MdsSection from "@mds/section";
import PlaceholderImageMSI_MDI from "@/assets/DavidHarrell.png";
import PlaceholderImageMFI from "@/assets/RussKinnel.png";
import PlaceholderImageMEI from "@/assets/BryanArmour.gif";
import MdsIcon from "@mds/icon";
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MdcCard from "@/components/CustomControls/MdcCard.vue";
import MdsLoader from "@mds/loader";
import CommonOverlay from "@/components/Common/Overlay/CommonOverlay.vue";
Vue.use(VueAxios, axios);

export default {
    name: "editor-featured-commentary",
    props: [],
    data() {
        return {
            isLoadingCommentry: true,
            isDisplay: false,
            MEIAccess: false,
            MDIAccess: false,
            MSIAccess: false,
            MFIAccess: false,
            FundInvestortoggle: false,
            StockInvestortoggle: false,
            DividendInvestortoggle: false,
            ETFInvestortoggle: false,
            articles: [],
            latestArticles: [],
            LatestArticlesURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_LatestArticlesURL,
            mfiEditorImage: "https://im.morningstar.com/im/mfi_editor.png",
            msiEditorImage: "https://im.morningstar.com/im/msi_editor.png",
            mdiEditorImage : "https://im.morningstar.com/im/mdi_editor.png",
            etfEditorImage : "https://im.morningstar.com/im/mei_editor.png",
            productSelected:'',
            overlayStatus:false,

        };
    },
    components: {
        MdsLoader,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsButton,
        MdsModal,
        MdsSection,
        MdsIcon,
        MdcCard,
        CommonOverlay,
    },
    computed: {
        PlaceholderImageMSI_MDI() {
            return PlaceholderImageMSI_MDI;
        },
        PlaceholderImageMFI() {
            return PlaceholderImageMFI;
        },
        PlaceholderImageMEI() {
            return PlaceholderImageMEI;
        },
    },
    methods: {
        getEditorImage(editorName) {
            var images = require.context("../../../assets", false, /\.png$/);
            return images("./" + editorName + ".png");
        },
        addProductOrder() {
            if (this.$store.state.user.LoggedIn) {
                if (this.MFIAccess) {
                    this.latestArticles.push({
                        editorimage: this.mfiEditorImage,
                        editortextheader: "Russel Kinnel",
                        editortext: `Director of
                    Manager Research and 
                    Editor, <i>Morningstar FundInvestor</i>`,
                        editordescription: `Russel Kinnel is director of manager research for Morningstar
              Research Services LLC and editor of Morningstar FundInvestor, a
              monthly print newsletter for individual investors. He also writes
              the Fund Spy column for Morningstar.com, the company's investment
              website.`,
                        productname: "FundInvestor",
                        productNameShort:'mfi',
                        Subject: this.articles[0].Subject,
                        PostAuthorName: this.articles[0].PostAuthorName,
                        PostDate: this.articles[0].PostDate,
                        FormattedBody: this.articles[0].FormattedBody,
                        access: true
                    });
                }
                if (this.MSIAccess) {
                    this.latestArticles.push({
                        editorimage: this.msiEditorImage,
                        editortextheader: "David Harrell",
                        editortext: `Editor, <i>Morningstar StockInvestor</i>`,
                        editordescription: `David Harrell is the editor of the <i>Morningstar StockInvestor</i>, 
                               a monthly newsletter that focuses on a wide-moat stock 
                               investing strategy. For illustration purposes, issues highlight 
                               activities pertaining to Morningstar, Inc. portfolios.`,
                        productname: "StockInvestor",
                        productNameShort:'msi',
                        Subject: this.articles[1].Subject,
                        PostAuthorName: this.articles[1].PostAuthorName,
                        PostDate: this.articles[1].PostDate,
                        FormattedBody: this.articles[1].FormattedBody,
                        access: true
                    });
                }
                if (this.MDIAccess) {
                    this.latestArticles.push({
                        editorimage: this.mdiEditorImage,
                        editortextheader: "David Harrell",
                        editortext: `Editor, <i>Morningstar DividendInvestor</i>`,
                        editordescription: `David Harrell is the editor of <i>Morningstar DividendInvestor</i>, 
                              a monthly newsletter that highlights activities pertaining to a 
                              Morningstar, Inc. account invested in accordance with a 
                              strategy that takes a concentrated, best-ideas approach when 
                              investing in select common stocks of dividend-paying 
                              companies and other securities.`,
                        productname: "DividendInvestor",
                        productNameShort:'mdi',
                        Subject: this.articles[2].Subject,
                        PostAuthorName: this.articles[2].PostAuthorName,
                        PostDate: this.articles[2].PostDate,
                        FormattedBody: this.articles[2].FormattedBody,
                        access: true
                    });
                }
                if (this.MEIAccess) {
                    this.latestArticles.push({
                        editorimage: this.etfEditorImage,
                        editortextheader: "Bryan Armour, CFA",
                        editortext: `Director,
                    Passive Strategies 
                    North America and Editor,
                    <i>Morningstar ETFInvestor</i>`,
                        editordescription: `Bryan Armour is director of passive strategies research for 
                               North America at Morningstar Research Services LLC, 
                               a wholly owned subsidiary of Morningstar, Inc. Before joining 
                               Morningstar in 2021, Armour spent seven years working 
                               for the Financial Industry Regulatory Authority, conducting 
                               regulatory trade surveillance and investigations, specializing
                               in exchange-traded funds.`,
                        productname: "ETFInvestor",
                        productNameShort:'mei',
                        Subject: this.articles[3].Subject,
                        PostAuthorName: this.articles[3].PostAuthorName,
                        PostDate: this.articles[3].PostDate,
                        FormattedBody: this.articles[3].FormattedBody,
                        access: true
                    });
                }
                if (!this.MFIAccess) {
                    this.latestArticles.push({
                        editorimage: this.mfiEditorImage,
                        editortextheader: "Russel Kinnel",
                        editortext: `Director of
                    Manager Research and Editor, <i>Morningstar FundInvestor</i>`,
                        editordescription: `Russel Kinnel is director of manager research for Morningstar
              Research Services LLC and editor of Morningstar FundInvestor, a
              monthly print newsletter for individual investors. He also writes
              the Fund Spy column for Morningstar.com, the company's investment
              website.`,
                        productname: "FundInvestor",
                        productNameShort:'mfi',
                        Subject: this.articles[0].Subject,
                        PostAuthorName: this.articles[0].PostAuthorName,
                        PostDate: this.articles[0].PostDate,
                        FormattedBody: this.articles[0].FormattedBody,
                        access: false
                    });
                }
                if (!this.MSIAccess) {
                    this.latestArticles.push({
                        editorimage: this.msiEditorImage,
                        editortextheader: "David Harrell",
                        editortext: `Editor, <i>Morningstar StockInvestor</i>`,
                        editordescription: `David Harrell is the editor of the <i>Morningstar StockInvestor</i>, 
                               a monthly newsletter that focuses on a wide-moat stock 
                               investing strategy. For illustration purposes, issues highlight 
                               activities pertaining to Morningstar, Inc. portfolios.`,
                        productname: "StockInvestor",
                        productNameShort:'msi',
                        Subject: this.articles[1].Subject,
                        PostAuthorName: this.articles[1].PostAuthorName,
                        PostDate: this.articles[1].PostDate,
                        FormattedBody: this.articles[1].FormattedBody,
                        access: false
                    });
                }
                if (!this.MDIAccess) {
                    this.latestArticles.push({
                        editorimage: this.mdiEditorImage,
                        editortextheader: "David Harrell",
                        editortext: `Editor, <i>Morningstar DividendInvestor</i>`,
                        editordescription: `David Harrell is the editor of <i>Morningstar DividendInvestor</i>, 
                              a monthly newsletter that highlights activities pertaining to a 
                              Morningstar, Inc. account invested in accordance with a 
                              strategy that takes a concentrated, best-ideas approach when 
                              investing in select common stocks of dividend-paying 
                              companies and other securities.`,
                        productname: "DividendInvestor",
                        productNameShort:'mdi',
                        Subject: this.articles[2].Subject,
                        PostAuthorName: this.articles[2].PostAuthorName,
                        PostDate: this.articles[2].PostDate,
                        FormattedBody: this.articles[2].FormattedBody,
                        access: false
                    });
                }
                if (!this.MEIAccess) {
                    this.latestArticles.push({
                        editorimage: this.etfEditorImage,
                        editortextheader: "Bryan Armour, CFA",
                        editortext: `Director,
                    Passive Strategies 
                    North America and Editor
                    <i>Morningstar ETFInvestor</i>`,
                        editordescription: `Bryan Armour is director of passive strategies research for 
                               North America at Morningstar Research Services LLC, 
                               a wholly owned subsidiary of Morningstar, Inc. Before joining 
                               Morningstar in 2021, Armour spent seven years working 
                               for the Financial Industry Regulatory Authority, conducting 
                               regulatory trade surveillance and investigations, specializing
                               in exchange-traded funds.`,
                        productname: "ETFInvestor",
                        productNameShort:'mei',
                        Subject: this.articles[3].Subject,
                        PostAuthorName: this.articles[3].PostAuthorName,
                        PostDate: this.articles[3].PostDate,
                        FormattedBody: this.articles[3].FormattedBody,
                        access: false
                    });
                }
            } else {
                this.latestArticles.push({
                    editorimage: this.mfiEditorImage,
                    editortextheader: "Russel Kinnel",
                    editortext: `Director of Manager Research and
                         Editor, <i>Morningstar FundInvestor</i>`,
                    editordescription: `Russel Kinnel is director of manager research for Morningstar
              Research Services LLC and editor of Morningstar FundInvestor, a
              monthly print newsletter for individual investors. He also writes
              the Fund Spy column for Morningstar.com, the company's investment
              website.`,
                    productname: "FundInvestor",
                    productNameShort:'mfi',
                    Subject: this.articles[0].Subject,
                    PostAuthorName: this.articles[0].PostAuthorName,
                    PostDate: this.articles[0].PostDate,
                    FormattedBody: this.articles[0].FormattedBody,
                    access: false
                }, {
                    editorimage: this.msiEditorImage,
                    editortextheader: "David Harrell",
                    editortext: `Editor, <i>Morningstar StockInvestor</i>`,
                    editordescription: `David Harrell is the editor of the <i>Morningstar StockInvestor</i>, 
                               a monthly newsletter that focuses on a wide-moat stock 
                               investing strategy. For illustration purposes, issues highlight 
                               activities pertaining to Morningstar, Inc. portfolios.`,
                    productname: "StockInvestor",
                    productNameShort:'msi',
                    Subject: this.articles[1].Subject,
                    PostAuthorName: this.articles[1].PostAuthorName,
                    PostDate: this.articles[1].PostDate,
                    FormattedBody: this.articles[1].FormattedBody,
                    access: false
                }, {
                    editorimage: this.mdiEditorImage,
                    editortextheader: "David Harrell",
                    editortext: `Editor, <i>Morningstar DividendInvestor</i>`,
                    editordescription: `David Harrell is the editor of <i>Morningstar DividendInvestor</i>, 
                              a monthly newsletter that highlights activities pertaining to a 
                              Morningstar, Inc. account invested in accordance with a 
                              strategy that takes a concentrated, best-ideas approach when 
                              investing in select common stocks of dividend-paying 
                              companies and other securities.`,
                    productname: "DividendInvestor",
                    productNameShort:'mdi',
                    Subject: this.articles[2].Subject,
                    PostAuthorName: this.articles[2].PostAuthorName,
                    PostDate: this.articles[2].PostDate,
                    FormattedBody: this.articles[2].FormattedBody,
                    access: false
                }, {
                    editorimage: this.etfEditorImage,
                    editortextheader: "Bryan Armour, CFA",
                    editortext: `Director,Passive Strategies
                    North America and Editor,
                    <i>Morningstar ETFInvestor</i>`,
                    editordescription: `Bryan Armour is director of passive strategies research for 
                               North America at Morningstar Research Services LLC, 
                               a wholly owned subsidiary of Morningstar, Inc. Before joining 
                               Morningstar in 2021, Armour spent seven years working 
                               for the Financial Industry Regulatory Authority, conducting 
                               regulatory trade surveillance and investigations, specializing
                               in exchange-traded funds.`,
                    productname: "ETFInvestor",
                    productNameShort:'mei',
                    Subject: this.articles[3].Subject,
                    PostAuthorName: this.articles[3].PostAuthorName,
                    PostDate: this.articles[3].PostDate,
                    FormattedBody: this.articles[3].FormattedBody,
                    access: false
                });
            }

        },
        GetLatestArticles() {
            try {
                Vue.axios
                    .get(this.LatestArticlesURL + "COMMON")
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    this.articles = response.data;                           
                                    if (this.$store.state.user.LoggedIn) {
                                        this.articlesRoles =
                                            this.$auth0.user["https://morningstar.com/uim_roles"];
                                            if(this.articlesRoles != undefined && this.articlesRoles !=null) {
                                                this.MEIAccess = this.articlesRoles.includes("EI_MEMBER");
                                                this.MDIAccess = this.articlesRoles.includes("DI_MEMBER");
                                                this.MSIAccess = this.articlesRoles.includes("SI_MEMBER");
                                                this.MFIAccess = this.articlesRoles.includes("FI_MEMBER");
                                            }
                                    }
                                    this.addProductOrder();
                                    this.$forceUpdate();
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => ((this.isLoadingCommentry = false), (this.isDisplay = true)));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        sendPostToCommentaryPage(newsletterType) {
            let productSelected = null;
            if (newsletterType == "FundInvestor") {
                productSelected = "/mfi";
            } else if (newsletterType == "StockInvestor") {
                productSelected = "/msi";
            } else if (newsletterType == "DividendInvestor") {
                productSelected = "/mdi";
            } else {
                productSelected = "/mei";
            }
            this.$router.push({
                path: productSelected + '/commentary'
            }).catch(err => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(err);
                }
            });
            window.scrollTo(0, 0);
        },
        getCurrentProduct(productName){
            if(this.$session.exists() && this.$store.state.user.LoggedIn){
            const userProductAccess = this.$auth0.user["https://morningstar.com/uim_roles"];
            if(userProductAccess != undefined && userProductAccess !=null) {
                this.MEIAccess = userProductAccess.includes("EI_MEMBER");
                this.MDIAccess = userProductAccess.includes("DI_MEMBER");
                this.MSIAccess = userProductAccess.includes("SI_MEMBER");
                this.MFIAccess = userProductAccess.includes("FI_MEMBER");
            }
            if (productName.toUpperCase() == 'MFI' && this.MFIAccess){
                this.redirectToIndividualHome(productName);
            }
            else if(productName.toUpperCase() == 'MSI' && this.MSIAccess){
                this.redirectToIndividualHome(productName);
            }
            else if(productName.toUpperCase() == 'MDI' && this.MDIAccess){
                this.redirectToIndividualHome(productName);
            }
            else if(productName.toUpperCase() == 'MEI' && this.MEIAccess){
                this.redirectToIndividualHome(productName);
            }
            else{
                this.productSelected = productName;
                this.overlayStatus = true;
            } 
            }
            else
            {
                this.productSelected = productName;
                this.overlayStatus = true;
            }
        },
        resetOverlayStatus(toggleValue){
            this.overlayStatus = toggleValue;
        },
        redirectToIndividualHome(productType){
            this.$router.push({
            name: 'home',
            params: {
                currentproduct: productType
            },
            })
        },
        trimContent(content) {
        if (content != null) {
            var htmlData = content.replace(/<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g, '');
            //eslint-disable-next-line
            var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, '');
            return trimInlineCss;
            //return htmlData;
        }
        },
    },
    created() {
        this.GetLatestArticles();
    },
};
</script>

<style lang="scss" scoped>
@import "@mds/icon/src/icon_mixins";

.featured-commentry-loader {
    width: "276px";
    height: "69px";
}

.main-div {
    margin-top: 12px;
}

.newsletter-mdc-card-title {
    height: 34px;
    margin-top: 4px;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.newsletter-mdc-card {
    word-wrap: break-word;
    overflow: hidden;
}
.article-readmore-container{
    margin-top:14px;
}
.editor-read-more-container{
    margin-top:14px;
}
.newsletter-editor-readmore {
    position: absolute;
    margin-top: 3px;
}

.newsletter-editor-caption-mfi {
    height: 160px !important;
    width: 100% !important;
    word-wrap: break-word;
    overflow: hidden;
    margin-top: 21px;
}

.newsletter-editor-caption-msi {
    height: 164px !important;
    width: 100% !important;
    word-wrap: break-word;
    overflow: hidden;
    margin-top: 21px;
}

.newsletter-editor-caption-mdi {
    height: 160px !important;
    width: 100% !important;
    word-wrap: break-word;
    overflow: hidden;
    margin-top: 21px;
}

.newsletter-editor-caption-etf {
    height: 160px !important;
    width: 100% !important;
    word-wrap: break-word;
    overflow: hidden;
    margin-top: 21px;
}
.combine-editor-fade-effect{
    position:absolute;
    z-index:2;
    width:66%;
    height:100px;
    margin-top:-99px;
    background: linear-gradient(0deg, #FFFFFF 6%, rgba(0, 0, 0, 0) 59.75%);
}
.custom-icon {
    @include mds-icon-color($mds-color-red-50);
}

.common-portfoliomanager_subheading {
    font-weight: bold;
}

.editor-image {
    display: flex;
}

.editor-text {
    display: flex;
    margin-left: 25px;
}

.editor-image-style {
    width: 126px;
    height: 126px;
    border-radius: 50%;
}

.editor-text-header {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #5e5e5e;
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif !important;
}

.editor-text-style {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #5e5e5e;
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif !important;
}

.editor-description {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif !important;
    margin-top: 23px;
}

.editor-readmore {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    cursor: pointer;
}

.newsletter-editor-commentry {
    font-weight: 400;
    font-size: 23px;
    line-height: 18px;
    color: #1e1e1e;
}

.editor-commentry-border{
    margin-top: 45px;
    width: 100% !important;
    height: 2px;
    background: #D8D8D8;
}
.editor-commentry-border-3{
    display:none;
}

.editor-product-name {
    margin-top: 13px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
}

.newsletter-editor-layout {
    margin-top: 29px;
    width: 100% !important;
}

.newsletter-editor-border {
    display: flex;
    margin-top: 19px;
}

.newsletter-lock-icon {
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    margin-left:-2px;
}

.mds-thead .mds-thead__group .mds-th__inner {
    border-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.editor-product-name-at-top {
    display: none;
}
.overlay-modal-sign-in-register-container{
    margin-top:60px;
    margin-bottom:30px;
}
.featured-commentary-modal-close-button{
    width:30px;
    left:94%;
}
.editor-commentry-content-section p{
    margin-bottom:21px;
}
@media only screen and (min-width:1000px) and (max-width:1068px){
    .newsletter-mdc-card-title {
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 23px;
        color: #1E1E1E;
        height: auto;
    }
}
@media only screen and (min-width:320px) and (max-width:999px){
    .main-div{
        width:90%;
        margin-left:5%;
    }
    .newsletter-editor-heading-container{
        margin-top: 45px;
        margin-bottom:-30px;
    }
    .editor-headline-border {
        width: 100%;
        height: 0px;
        margin-top: 14px;
        margin-bottom: 0;
        border: 1px solid #5E5E5E;
    }
    .newsletter-editor-commentry {
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 27px;
        color: #1E1E1E;
    }
    .newsletter-editor-layout-row {
        width:100%;
        margin:0;
        padding:0;
    }
    .newsletter-editor-layout-col {
        width:100%;
        margin:0 !important;
        padding:0 !important;
    }
    .editor-info-container {
        width: 100%;
    }
    .editor-product-name {
        display: none;
    }
    .editor-product-name-at-top {
        display: block;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #1E1E1E;
        margin-top: 6px;
    }
    .editor-image-style {
        width: 82px;
        height: 82px;
        border-radius: 50%;
    }
    .editor-text {
        width: 100%;
        margin-left: 25px;
    }
    .editor-description {
        width: 100%;
        margin: 27px 0px 0px 0px;
    }
    .article-readmore-container{
        margin-top:20px;
    }
    .editor-read-more-container{
        margin-top:20px;
    }
    .border-after-editor-info {
        width: 100%;
        height: 0;
        border: 1px solid #E5E5E5;
        margin-top: 20px;
    }
    .newsletter-mdc-card-col {
        width:100%;
        margin:0;
        padding:0;
    }
    .newsletter-mdc-card-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #1E1E1E;
        height: auto;
    }
    .editor-commentry-border {
        width: 100%;
        height: 0;
        margin-top: 20px;
        border: 1px solid #5E5E5E;
    }
    .combine-editor-fade-effect{
        width:90%;
    }
}
@media only screen and (min-width:320px) and (max-width:900px){
    /*.editor-commentry-end-border {
        display: none;
    }*/


}

.paragraphe-one-editor{
    margin-top: -16px;
}

@media only screen and (min-width:600px) and (max-width:900px){
    .editor-image-style{
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }
    .newsletter-mdc-card-title{
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 26px;
        color: #1E1E1E;
        height: auto;
    }
}
@media only screen and (max-width:320px){
    .newsletter-editor-commentry {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 27px;
        color: #1E1E1E;
    }
}
@media only screen and (min-width:901px) and (max-width:1290px){
    .main-div {
        width:90%;
        margin-left:5%;
        margin-right:5%;
        margin-top: 12px;
    }  
}
@media only screen and(min-width:320px) and (max-width:600px){
    .newsletter-editor-caption-etf {
        overflow-wrap: break-word;
        overflow: hidden;
        margin-top: 21px;
        height: 168px !important;
        width: 100% !important;
    }
    .newsletter-editor-caption-mdi {
        height: 168px !important;
        width: 100% !important;
        word-wrap: break-word;
        overflow: hidden;
        margin-top: 21px;
    }
    .newsletter-editor-caption-msi {
        height: 176px !important;
        width: 100% !important;
        word-wrap: break-word;
        overflow: hidden;
        margin-top: 21px;
        margin-left:2px;
    }
    .newsletter-editor-caption-mfi {
        height: 168px !important;
        width: 100% !important;
        word-wrap: break-word;
        overflow: hidden;
        margin-top: 21px;
    }
}
.articles-authorname-common {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5E5E5E;
}

.articles-readmore-common {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5E5E5E;
    cursor: pointer;
    /*position: absolute;
    margin-top: -1px;
    margin-left: 4px;*/
}

.newsletter-mdc-card .mdc-card__secondary-text {
    padding-left: 0px !important;
}

.editor-commentry-end-border {
    height:2px;
    background-color: #333333;
    margin-top: 50px;
}

.featured-commentary-editor-read-more-pop-up>div:first-child {
    max-height: 95vh !important;
}
.featured-commentary-editor-read-more-pop-up > div > div > section::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.featured-commentary-editor-read-more-pop-up > div > div > section::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
}
#title-id {

    font-style: normal;
    font-weight: 250;
    font-size: 45px;
    line-height: 50px;
    width: 521px;
    color: #000000;
}

</style>
<style>
.newsletter-mdc-card .mdc-card__secondary-text{
  padding-left:0px !important;
}
.combine-editor-article-content {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    color: #000000 !important;
}
.combine-editor-article-content ul,ol {
    padding-left:20px;
}
.combine-editor-article-content>p>u>span {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    /* line-height: 26px !important;*/
    color: #000000 !important;
    text-decoration: none !important;
}

.combine-editor-article-content>p>strong>span {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    /*line-height: 26px !important;*/
    color: #000000 !important;
    font-weight: 300 !important;
}
.combine-editor-article-content>p {
    margin-left: 0pt !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    /*line-height: 26px !important;*/
    color: #000000 !important;
    margin-bottom:15px !important;
}
.combine-editor-article-content > div > p{
    margin-bottom:15px !important;
}

.combine-editor-article-content>p>strong {
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    /* line-height: 26px !important;*/
    color: #000000 !important;
    font-weight: 700 !important;
}
.combine-editor-article-content a {
    color: #000000 !important;
    text-decoration: none !important;
    border-bottom: 1px solid #ababab !important;
}
@media only screen and (max-width: 480px){
    .featured-commentary-editor-read-more-pop-up > div > div > section{
        overflow-y:scroll;
    }
    
    .featured-commentary-editor-read-more-pop-up > div > div > section > div:first-child > h2 > span{
        font-size:35px !important;
        font-weight:700 !important;
    }
    .featured-commentary-editor-read-more-pop-up > div{
       scroll-margin: 20px !important;
    }
    .featured-commentary-editor-read-more-pop-up>div:first-child {
        max-height: 65vh !important;
    }
}
@media only screen and (max-width: 480px){
    .featured-commentary-editor-read-more-pop-up > div > div > section{
        overflow-y:scroll;
    }
    .featured-commentary-editor-read-more-pop-up > div > div > section > div:first-child > h2 > span{
        font-size:20px !important;
        font-weight:700 !important;
    }
}

  .featured-commentary-editor-read-more-pop-up > div > div > section > div:first-child > h2 > span{
        font-size:20px !important;
        font-weight:700 !important;
    }

@media only screen and (min-width:320px) and (max-width:600px){
    .combine-editor-article-content {
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        line-height: 24.5px !important;
        color: #000000 !important;
    }
    .combine-editor-article-content>p {
        margin-left: 0pt !important;
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
            sans-serif !important;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        /*line-height: 21px !important;*/
        line-height: 23.5px !important;
        color: #000000 !important;
    }

    .combine-editor-article-content>p>strong {
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
            sans-serif !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 16px !important;
        line-height: 21px !important;
        color: #000000 !important;
        font-weight: 700 !important;
    }

    .combine-editor-article-content>p>u>span {
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
            sans-serif !important;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        line-height: 21px !important;
        color: #000000 !important;
        text-decoration: none !important;
    }

    .combine-editor-article-content>p>strong>span {
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
            sans-serif !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 16px !important;
        line-height: 21px !important;
        color: #000000 !important;
        font-weight: 300 !important;
    }
}
@media only screen and (max-height:800px){
    .featured-commentary-editor-read-more-pop-up > div > div > section{
        overflow-y:scroll;
    }
}
</style>
