<template>
  <div>
    <div v-if="!this.$auth0.isLoading">
      <header-section
        v-if="this.$store.state.layout.isHeaderRequired"
      ></header-section>
      <router-view></router-view>
      <footer-section
        v-if="this.$store.state.layout.isFooterRequired"
      ></footer-section>
    </div>
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-KXKC2HP"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>
  </div>
</template>
<script
  async
  :src="googleGtagUrl"
></script>
<script>
import HeaderSection from "./Headers/HeaderSection.vue";
import FooterSection from "./FooterSection.vue";

export default {
  name: "master-page",
  components: {
    HeaderSection,
    FooterSection,
  },
  data() {
    return {
      dataLayer: [],
      googleGtagId : process.env.VUE_APP_GoogleGtagId,
      googleGtagUrl : process.env.VUE_APP_GoogleGtagScriptUrl,
    };
  },
  mounted() {
    document.title = "Morningstar Investor Newsletters";
    window.scrollTo(0, 0);

    // ******* G Tag *********************
    window.dataLayer = window.dataLayer || [];
    this.dataLayer = window.dataLayer;
    this.gtag("js", new Date());
    this.gtag("config", this.googleGtagId);
    // ******* G Tag *********************

    // ********** GTM Tag *****************
    this.GTMFunc(window, document, "script", "dataLayer", "GTM-KXKC2HP");
    // ********** GTM Tag *****************
  },
  methods: {
    gtag() {
      this.dataLayer.push(arguments);
    },
    GTMFunc(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    },
  },
  watch: {
    $route(to, from) {
      this.$store.state.search.InvalidSearchTerm = false;
      if (
        to.name == "autorenew" ||
        to.name == "billingaddress" ||
        to.name == "purchase" ||
        to.name == "subscription" ||
        to.name == "subscriptionexists" ||
        to.name == "reviewsubscription" ||
        to.name == "subscriptionconfirmation" ||
        to.name == "print-registration" ||
        to.name == "print-registration-subscribe" ||
        to.name == "print-registration-confirmation"
      ) {
        this.$store.state.layout.isHeaderRequired = false;
        this.$store.state.layout.isFooterRequired = false;
      } else {
        this.$store.state.layout.isHeaderRequired = true;
        this.$store.state.layout.isFooterRequired = true;
        //Registrations all data clear
        localStorage.removeItem("userDetails");
        localStorage.removeItem("MFIProductSubscription");
        localStorage.removeItem("MSIProductSubscription");
        localStorage.removeItem("MDIProductSubscription");
        localStorage.removeItem("MEIProductSubscription");
        localStorage.removeItem("ChaseErrorCode");
        localStorage.removeItem("CompleteChasePaymentData");
        localStorage.removeItem("PrintRegistrationDetails");
        //END
      }
      if (this.$store.state.access.PageNotFound) {
        this.$store.state.access.PageNotFound = false;
        this.$router
          .replace({
            name: "pageNotFound",
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(err);
            }
          });
      } else if (
        to.name != "print-registration" &&
        to.name != "print-registration-subscribe" &&
        to.name != "print-registration-confirmation" &&
        to.name != "myAccount" &&
        to.name != "subscriptionconfirmation" &&
        to.name != "reviewsubscription" &&
        to.name != "autorenew" &&
        to.name != "billingaddress" &&
        to.name != "subscriptionexists" &&
        to.name != "subscription" &&
        to.name != "purchase" &&
        to.name != "marketing-landing" &&
        to.name != "searchResults" &&
        to.name != "videos" &&
        to.name != "videoTranscript" &&
        to.name != "msi-disclosure" &&
        to.name != "mdi-disclosure" &&
        to.hash == "" &&
        to.path !== from.path
      ) {
        this.$router
          .replace({
            path: to.path.toLowerCase(),
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(err);
            }
          });
        if (this.$auth0.isAuthenticated && this.$session.exists()) {
          this.$store.state.user.LoggedIn = true;
        } else {
          this.$store.state.user.LoggedIn = false;
        }

        if (this.$store.state.user.LoggedIn) {
          this.$store.state.article.ArticleCounter = 0;
          if (
            to.params.currentproduct != undefined &&
            to.params.currentproduct !== this.$session.get("current-product")
          ) {
            if (
              to.params.currentproduct.toUpperCase() == "MSI" ||
              to.params.currentproduct.toUpperCase() == "MDI" ||
              to.params.currentproduct.toUpperCase() == "MEI" ||
              to.params.currentproduct.toUpperCase() == "MFI" ||
              to.params.currentproduct == "Common"
            ) {
              if (to.params.currentproduct != "Common") {
                to.params.currentproduct =
                  to.params.currentproduct.toLowerCase();
              }
              this.$session.set("current-product", to.params.currentproduct);
              this.$store.state.product.CurrentProduct =
                to.params.currentproduct;
            } else if (to.name !== "accessDenied" && to.name !== "logout") {
              this.$router
                .push({
                  name: "pageNotFound",
                  params: {
                    currentproduct: this.$store.state.product.CurrentProduct,
                  },
                })
                .catch((err) => {
                  if (
                    err.name !== "NavigationDuplicated" &&
                    !err.message.includes(
                      "Avoided redundant navigation to current location"
                    )
                  ) {
                    console.log(err);
                  }
                });
            }
          }

          if (from.name == "analyst" && to.name != "analyst") {
            this.$session.set("CurrentSecID","");
            this.$session.set("CurrentPortfolioName","");
          }

          if (to.name == "combined-home" && from.name == "home") {
            this.$session.set("current-product", "Common");
            this.$store.state.product.CurrentProduct =
              this.$session.get("current-product");
          } else if (to.path == "/logout") {
            this.$auth0.logout();
          } else if (
            this.$session.get("current-product") == "Common" ||
            this.$session.get("current-product") == "" ||
            this.$session.get("current-product") == undefined
          ) {
            this.$router
              .push({
                name: "combined-home",
                params: {
                  currentproduct: "Common",
                },
              })
              .catch((err) => {
                if (
                  err.name !== "NavigationDuplicated" &&
                  !err.message.includes(
                    "Avoided redundant navigation to current location"
                  )
                ) {
                  console.log(err);
                }
              });
            this.$store.state.product.CurrentProduct =
              this.$session.get("current-product");
          } else {
            this.$store.state.product.CurrentProduct =
              this.$session.get("current-product");
          }
          this.$forceUpdate();
          //Registrations all data clear
          localStorage.removeItem("userDetails");
          localStorage.removeItem("MFIProductSubscription");
          localStorage.removeItem("MSIProductSubscription");
          localStorage.removeItem("MDIProductSubscription");
          localStorage.removeItem("MEIProductSubscription");
          localStorage.removeItem("ChaseErrorCode");
          localStorage.removeItem("CompleteChasePaymentData");
          localStorage.removeItem("PrintRegistrationDetails");
          //END
        } else {
          this.$store.state.product.CurrentProduct = "";
          this.$router
            .push({
              name: "combined-home",
              params: {
                currentproduct: "Common",
              },
            })
            .catch((err) => {
              if (
                err.name !== "NavigationDuplicated" &&
                !err.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(err);
              }
            });
        }
        if(to.name !="marketing-landing"){
            this.$store.state.marketing.isMarketing = false;
        }
      }
    },
  },
};
</script>