<template>
	<img
		v-if="isResponsive"
		class="mdc-image mdc-image--responsive"
		:loading="loading"
		:sizes="sizes"
		:srcset="srcset"
		:alt="alt"
		:role="presentational ? 'presentation' : null"
	/>
	<img
		v-else
		class="mdc-image"
		:loading="loading"
		:src="src"
		:alt="alt"
		:role="presentational ? 'presentation' : null"
	/>
</template>

<script>
import filter from 'lodash/filter';
import inRange from 'lodash/inRange';
import range from 'lodash/range';

const columnCount = 12;
const breakpointSizes = {
	sm: 600,
	md: 768,
	lg: 1092,
	xl: 1304
};

const validColsOrPx = v => {
	if (/^\d+px$/.test(v)) return true;
	return inRange(parseInt(v), 1, columnCount + 1);
};

export default {
	props: {
		src: {
			type: String,
			description: 'Image Source',
			required: true,
			default: undefined
		},
		size: {
			type: [Number, String],
			description: 'Default width of the image for selecting responsive image, expressed in columns or absolute px',
			default: undefined,
			validator: validColsOrPx
		},
		alt: {
			type: String,
			description: 'Alt text for image',
			default: ''
		},
		presentational: {
			type: Boolean,
			description: 'Mark the image as presentational-only (i.e. not contentful); if false, should provide alt text',
			default: false
		},
		sm: {
			type: [Number, String],
			description: 'Width of the image at the tablet breakpoint (≥600px), expressed in columns or absolute px',
			default: undefined,
			validator: validColsOrPx
		},
		md: {
			type: [Number, String],
			description: 'Width of the image at the computer breakpoint (≥768px), expressed in columns or absolute px',
			default: undefined,
			validator: validColsOrPx
		},
		lg: {
			type: [Number, String],
			description: 'Width of the image at the large screen breakpoint (≥1000px), expressed in columns or absolute px',
			default: undefined,
			validator: validColsOrPx
		},
		xl: {
			type: [Number, String],
			description: 'Width of the image at the xlarge screen breakpoint (≥1200px), expressed in columns or absolute px',
			default: undefined,
			validator: validColsOrPx
		},
		lazy: {
			type: Boolean,
			description: 'Indicate image may be lazy loaded',
			default: false
		},
		aspectRatio: {
			type: String,
			description: 'Adds in auto aspect-ratio cropping if supported by provider',
			default: undefined
		}
	},
	computed: {
		loading() {
			if (this.lazy) return 'lazy';
			return null;
		},
		isResponsive() {
			return this.size && (this.src || '').includes('images.contentstack.io') && !this.src.endsWith('.svg');
		},
		sizes() {
			const sizes = [];

			let currentSize = this.size;
			sizes.push(this.toMediaQueryString(currentSize, 'vw'));

			['sm', 'md', 'lg', 'xl'].forEach(breakpoint => {
				currentSize = this[breakpoint] || currentSize;
				sizes.push(this.toMediaQueryString(currentSize, 'px', breakpointSizes[breakpoint]));
			});

			return filter(sizes).reverse();
		},
		srcset() {
			const cropQuery = this.aspectRatio ? `crop=${this.aspectRatio}` : undefined;
			return range(100, 1300, 100).map(width => {
				const widthQuery = `width=${width}`;
				const queryStr = filter([cropQuery, widthQuery]).join('&');
				return `${this.src}?${queryStr} ${width}w`;
			}).join(', ');
		}

	},
	mounted() {
		this.polyfillSrcset();
		this.$on('updated', this.polyfillSrcset);
	},
	methods: {
		toMediaQueryString(size, unit = 'px', breakpointSize) {
			if (!size) return null;

			const mediaQuery = breakpointSize ? `(min-width: ${breakpointSize}px)` : '';
			if (/^\d+px$/.test(size)) {
				return `${mediaQuery} ${size}`.trim();
			}

			const vwPercent = parseInt(size) / columnCount;
			const unitlessWidth = Math.ceil(breakpointSize ? vwPercent * breakpointSize : vwPercent * 100);
			return `${mediaQuery} ${unitlessWidth}${unit}`.trim();
		},
		polyfillSrcset() {
			if (this.isResponsive && typeof this.$picturefill === 'function') {
				this.$picturefill({
					elements: [this.$el]
				});
			}
		}
	}
};
</script>
