var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mdi-income-bellwethers-container"},[_c('div',{staticClass:"mdi-income-bellwethers-table-loader"},[(_vm.isLoading)?_c('mds-loader',{attrs:{"aria-label":"Medium Loader"}}):_vm._e()],1),_c('div',{staticClass:"mdi-income-bellwethers-header-text"},[_c('div',{staticClass:"mdi-income-bellwethers-middle-content"},[_c('div',{staticClass:"mdi-income-bellwethers-title"},[_vm._v("Income Bellwethers")]),_c('div',{staticClass:"mdi-income-bellwethers-download-holding"},[_c('mds-button',{staticClass:"mdi-income-bellwethers-download-data",attrs:{"variation":"flat","size":"small","icon":"document"},on:{"click":_vm.ExportExcel}},[_vm._v(" Download data ")])],1)]),_c('div',{staticClass:"mdi-income-bellwethers-analyst-text"},[_vm._v(" Click on company name for latest Morningstar Analyst Report. ")])]),_c('div',{staticStyle:{"padding-top":"24px"}},[_c('mds-table',{staticClass:"mdi-income-bellwethers-data-table",staticStyle:{"max-height":"600px"},attrs:{"fixed-first-col":"","show-sortable":""}},[_c('mds-thead',{attrs:{"fixed-header":""}},_vm._l((_vm.headers),function(header,index){return _c('mds-th',{key:index,style:(header.style),attrs:{"right-aligned":header.align === 'right',"sortable":header.sortable,"sorted":header.sorted},on:{"mds-th-sort-changed":function($event){return _vm.handleSortEvent(index, header.fieldName, $event)}}},[_c('span',{staticClass:"mdi-income-bellwethers-table-header",domProps:{"innerHTML":_vm._s(header.text)}})])}),1),(_vm.isDisplay)?_c('mds-tbody',_vm._l((_vm.rows),function(row,index){return _c('mds-tr',{key:'A' + index},[_c('mds-td',[(
                  row.Symbol == 'N/A' ||
                  row.Symbol == 'NaN' ||
                  row.Symbol == '0' ||
                  row.Symbol == '' ||
                  row.Symbol == '&mdash;'||
                  row.Symbol == null||
                  row.Symbol == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',{staticClass:"mdi-income-bellwethers-ticker",on:{"click":function($event){return _vm.redirectToStockQuote(row.ExchangeId,row.Symbol)}}},[_vm._v(" "+_vm._s(row.Symbol)+" ")])]),_c('mds-td',{staticStyle:{"padding-left":"16px"}},[(
                  row.StandardName == 'N/A' ||
                  row.StandardName == 'NaN' ||
                  row.StandardName == '0' ||
                  row.StandardName == '' ||
                  row.StandardName == '&mdash;'||
                  row.StandardName == null||
                  row.StandardName == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('a',{staticClass:"mdi-income-bellwethers-first-row",attrs:{"target":"_blank","href":_vm.CurrentURL},domProps:{"innerHTML":_vm._s(row.StandardName)},on:{"mouseover":function($event){return _vm.openMedalistReport(row.SecId,row.Symbol)},"touchstart":function($event){return _vm.openMedalistReport(row.SecId,row.Symbol)}}})])]),_c('mds-td',{staticStyle:{"padding-left":"25px"},attrs:{"left-align":""}},[(
                  row.StockStarRating == 'N/A' ||
                  row.StockStarRating == 'NaN' ||
                  row.StockStarRating == '0' ||
                  row.StockStarRating == '' ||
                  row.StockStarRating == '&mdash;'||
                  row.StockStarRating == null||
                  row.StockStarRating == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('mds-star-rating',{attrs:{"rating":parseInt(row.StockStarRating),"custom-size":14}})],1)]),_c('mds-td',{staticStyle:{"padding-left":"1px"},attrs:{"left-aligned":""}},[(
                  row.EconomicMoat == 'N/A' ||
                  row.EconomicMoat == 'NaN' ||
                  row.EconomicMoat == '0' ||
                  row.EconomicMoat == '' ||
                  row.EconomicMoat == '&mdash;'||
                  row.EconomicMoat == null||
                  row.EconomicMoat == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.EconomicMoat)}})])]),_c('mds-td',{staticStyle:{"padding-left":"0px"},attrs:{"left-aligned":""}},[(
                  row.StockRiskRating == 'N/A' ||
                  row.StockRiskRating == 'NaN' ||
                  row.StockRiskRating == '0' ||
                  row.StockRiskRating == '' ||
                  row.StockRiskRating == '&mdash;'||
                  row.StockRiskRating == null||
                  row.StockRiskRating == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.StockRiskRating)}})])]),_c('mds-td',{staticStyle:{"padding-right":"0px"},attrs:{"right-aligned":""}},[(
                  row.FairValue == 'N/A' ||
                  row.FairValue == 'NaN' ||
                  row.FairValue == '0' ||
                  row.FairValue == '' ||
                  row.FairValue == '&mdash;'||
                  row.FairValue == null||
                  row.FairValue == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.FairValue)}})])]),_c('mds-td',{staticStyle:{"padding-right":"0px"},attrs:{"right-aligned":""}},[(
                  row.PriceOverFairValue == 'N/A' ||
                  row.PriceOverFairValue == 'NaN' ||
                  row.PriceOverFairValue == '0' ||
                  row.PriceOverFairValue == '' ||
                  row.PriceOverFairValue == '&mdash;'||
                  row.PriceOverFairValue == null||
                  row.PriceOverFairValue == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.PriceOverFairValue)}})])]),_c('mds-td',{staticStyle:{"padding-right":"0px"},attrs:{"right-aligned":""}},[(
                  row.LastPrice == 'N/A' ||
                  row.LastPrice == 'NaN' ||
                  row.LastPrice == '0' ||
                  row.LastPrice == '' ||
                  row.LastPrice == '&mdash;'||
                  row.LastPrice == null||
                  row.LastPrice == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.LastPrice)}})])]),_c('mds-td',{staticStyle:{"padding-right":"0px"},attrs:{"right-aligned":""}},[(
                  row.DividendRate == 'N/A' ||
                  row.DividendRate == 'NaN' ||
                  row.DividendRate == '0' ||
                  row.DividendRate == '' ||
                  row.DividendRate == null||
                  row.DividendRate == '&mdash;' ||
                  row.DividendRate == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.DividendRate)}})])]),_c('mds-td',{staticStyle:{"padding-right":"0px"},attrs:{"right-aligned":""}},[(
                  row.ForwardDividendYield == 'N/A' ||
                  row.ForwardDividendYield == 'NaN' ||
                  row.ForwardDividendYield == '0' ||
                  row.ForwardDividendYield == '' ||
                  row.ForwardDividendYield == null||
                  row.ForwardDividendYield == '&mdash;'
                  ||
                  row.ForwardDividendYield == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.ForwardDividendYield)}})])]),_c('mds-td',{staticStyle:{"padding-left":"20px"}},[(
                  row.Sector == 'N/A' ||
                  row.Sector == 'NaN' ||
                  row.Sector == '0' ||
                  row.Sector == '' ||
                  row.Sector == null||
                  row.Sector == '&mdash;'
                  ||
                  row.Sector == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.Sector)}})])]),_c('mds-td',{staticStyle:{"padding-left":"0px"}},[(
                  row.Category == 'N/A' ||
                  row.Category == 'NaN' ||
                  row.Category == '0' ||
                  row.Category == '' ||
                  row.Category == null||
                  row.Category == '&mdash;'
                  ||
                  row.Category == '&nbsp;'
                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.Category)}})])])],1)}),1):_vm._e()],1)],1),_vm._m(0),_c('div',{staticClass:"mdi-income-bellwethers-second-border"}),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mdi-income-bellwethers-cash-holdings-text"},[_vm._v(" Portfolio table does not include cash holdings or dividends receivable."),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mdi-income-bellwethers-balance-text"},[_vm._v(" The above information is provided for illustrative purposes only. The data and statistics presented are based on the most recent information available from Morningstar, Inc. and may or may not be an accurate reflection of current data. Investing in securities is subject to investment risk, including possible loss of principal. Past performance does not guarantee future results. There can be no assurance that any financial strategy will be successful. "),_c('br'),_c('br'),_vm._v(" The information, data, analyses, and opinions presented herein do not constitute investment advice, are provided as of the date written, are provided solely for informational purposes and therefore are not an offer to buy or sell a security. Please note that references to specific securities or other investment options within this piece should not be considered an offer (as defined by the Securities and Exchange Act) to purchase or sell that specific investment. "),_c('br'),_c('br'),_vm._v(" The Income Bellwethers list is created using quantitative analysis. Other than setting the methodology for inclusion on the list, the editors of "),_c('i',[_vm._v(" DividendInvestor")]),_vm._v(" have no discretion in choosing which companies appear on this list. The Income Bellwethers list is provided for informational purposes only in order to help you research other companies that may fit into your investing strategy. "),_c('br'),_c('br'),_vm._v(" Source: Morningstar Rating, rankings, categorizations, and fair value estimates are based on the analysis and estimates of Morningstar analysts. ")])
}]

export { render, staticRenderFns }