import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist'
import ProductModule from './product';
import UserModule from "./user";
import HoldingModule from './holding';
import ArticleModule from './article';
import ResponsiveModule from './responsive'
import HeaderModule from './header';
import AccessProductModule from './accessProduct';
import LayoutModule from'./layout';
import CountryModule from './countryandstates';
import MarketingModule from './marketing'
import SearchModule from "./search";
import Fund500Module from "./fund500";
Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage
})

export default new Vuex.Store({
  modules:{
    product: ProductModule,
    user: UserModule,
    holding : HoldingModule,
    article:ArticleModule,
    responsive:ResponsiveModule,
    header:HeaderModule,
    access:AccessProductModule,
    layout:LayoutModule,
    country:CountryModule,
    marketing: MarketingModule,
    search: SearchModule,
    fund500: Fund500Module
  },
  plugins: [vuexLocal.plugin]
});

