<template>
<div v-if="this.$store.state.search.InvalidSearchTerm" class="search-error-message"> 
      <span >{{ this.errormessageText }}</span>
    </div>
</template>

<script>
export default {
    name: "search-error",
    components: {
    },
    data() {
        return {
            errormessageText: "Please enter a valid search term",
        }
    }
}
</script>

<style scoped>
.search-error-message {
  position:absolute;
  font-size: 14px;
  color: red;  
  margin-top:-11px;
  right:8%;
}
@media only screen and (min-width:320px) and (max-width:1000px){
  .search-error-message{
    position:relative;
    left:5%;
    margin-top:-9px;
  }
}
@media only screen and (min-width:1001px) and (max-width:1200px){
  .search-error-message{
    right:5%;
  }
}
@media only screen and (min-width:1680px) and (max-width:1899px){
  .search-error-message{
    right:7%;
  }
}
@media only screen and (min-width:1900px) and (max-width:2499px){
  .search-error-message{
    right:5%;
  }
}
@media only screen and (min-width:2500px) and (max-width:3000px){
  .search-error-message{
    right:4%;
  }
}
@media only screen and (min-width:3001px){
  .search-error-message{
    right:3%;
  }
}
</style>