import first from 'lodash/first';
import last from 'lodash/last';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

const MdcFocusTrapMixin = {
	data() {
		return {
			initialActiveElement: null,
			container: '',
			observeChanges: false,
			mutationObserver: null,
			focusableNodes: null
		};
	},
	computed: {
		firstFocusableElement() {
			return first(this.focusableNodes);
		},
		lastFocusableElement() {
			return last(this.focusableNodes);
		}
	},
	methods: {
		// Start focus
		enableFocusTrap({
			container, observeChanges = false, returnFocus = true, focusFirstChild = true
		}) {
			// Get last active element and store reference for later
			if (returnFocus && !this.initialActiveElement) {
				this.initialActiveElement = document.activeElement;
			}

			this.observeChanges = observeChanges;
			this.container = container;
			this.setTrapElementTabIndex();

			// Set up focusable elements ready for tabbing
			this.setFocusableElements();
			this.setFocus(focusFirstChild);
			if (this.observeChanges) this.setupObserver();

			// Add listener for tabbing
			document.addEventListener('keydown', this.trapFocusKeyDownEvents, true);
		},
		// Remove focus
		disableFocusTrap() {
			if (this.initialActiveElement) {
				this.initialActiveElement.focus();

				// Reset
				this.initialActiveElement = null;
			}
			this.focusableNodes = null;
			this.setTrapElementTabIndex(false);
			document.removeEventListener('keydown', this.trapFocusKeyDownEvents, true);
		},
		// Set the trap element tab index such that it cannot be focused
		setTrapElementTabIndex(set = true) {
			if (set) {
				if (this.container) {
					this.container.setAttribute('tabindex', '-1');
				} else {
					this.$el.setAttribute('tabindex', '-1');
				}
			} else if (this.container) {
				this.container.removeAttribute('tabindex');
			} else {
				this.$el.removeAttribute('tabindex');
			}
		},
		// Set all the focusable elements
		// https://github.com/jkup/focusable/blob/master/index.js
		setFocusableElements() {
			const elements = [
				'a[href]',
				'area[href]',
				'input:not([disabled]):not([type="hidden"])',
				'select:not([disabled])',
				'textarea:not([disabled])',
				'button:not([disabled])',
				'iframe',
				'object',
				'embed',
				'[contenteditable]',
				'audio[controls]',
				'video[controls]',
				'summary',
				'[tabindex="0"]:not([disabled])',
				'[tabindex^="0"]:not([disabled])',
				'[tabindex^="1"]:not([disabled])',
				'[tabindex^="2"]:not([disabled])',
				'[tabindex^="3"]:not([disabled])',
				'[tabindex^="4"]:not([disabled])',
				'[tabindex^="5"]:not([disabled])',
				'[tabindex^="6"]:not([disabled])',
				'[tabindex^="7"]:not([disabled])',
				'[tabindex^="8"]:not([disabled])',
				'[tabindex^="9"]:not([disabled])'
			];

			const nodes = this.container
				? this.container.querySelectorAll(elements)
				: this.$el.querySelectorAll(elements);

			this.focusableNodes = values(nodes);
		},
		// Set the starting focus
		setFocus(targetFirstChild) {
			// Don't focus anything if we already have a focus within the container
			const focusIsInSubtree = this.container
				? this.container.contains(document.activeElement)
				: this.$el.contains(document.activeElement);

			// Shift focus
			if (!focusIsInSubtree && !isEmpty(this.focusableNodes) && !this.container) {
				// auto-focus the first
				this.$el.focus();
			} else if (!focusIsInSubtree && !isEmpty(this.focusableNodes) && this.container && !targetFirstChild) {
				this.container.focus();
			} else if (!focusIsInSubtree && !isEmpty(this.focusableNodes) && this.container) {
				// focus the first in the container
				this.firstFocusableElement.focus();
			}
		},
		// Initiate an observer to recalculate the focusable elements when a change is detected
		setupObserver() {
			this.mutationObserver = new MutationObserver(mutations => {
				const shouldRefreshTabList = some(mutations, (({ type }) => type === 'childList' || type === 'attributes'));

				if (shouldRefreshTabList) {
					this.setFocusableElements();
					this.setFocus();
				}
			});

			this.mutationObserver.observe(this.container, {
				attributes: true,
				childList: true,
				characterData: false,
				subtree: true
			});
		},
		// Event listener for tabbing
		trapFocusKeyDownEvents(event) {
			if (event.key === 'Tab' || event.keyCode === 9) {
				this.maintainFocus(event);
			}
		},
		// Maintain focus during tabbing
		maintainFocus(event) {
			if (event.shiftKey) {
				if (document.activeElement.isEqualNode(this.firstFocusableElement)) {
					this.lastFocusableElement.focus();
					event.preventDefault();
				}
			} else if (document.activeElement.isEqualNode(this.lastFocusableElement)) {
				this.firstFocusableElement.focus();
				event.preventDefault();
			}
		},
		// Cleanup observer
		disconnectObserver() {
			if (this.mutationObserver) {
				this.mutationObserver.disconnect();
				this.mutationObserver = null;
			}
		}
	},
	beforeDestroy() {
		this.disconnectObserver();
		document.removeEventListener('keydown', this.trapFocusKeyDownEvents, true);
	}
};

export default MdcFocusTrapMixin;
