<template>
    <div v-if="this.currentProduct.toLowerCase() == 'common' && this.loadAd" class="ad-parent-container">
        <div class="ad-container">
            <div class="ad-primary-container">
                <iframe :src="primaryAdUrl" class="primary-ad"></iframe>
            </div>
            <div class="ad-secondary-container">
                <iframe :src="secondaryAdUrl" class="secondary-ad"></iframe>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "ad",
    props: {
        currentPage: {
            type: String,
            required: true
        },
    },
    components: {
    },
    data() {
        return {
            MEIAccess: false,
            MDIAccess: false,
            MSIAccess: false,
            MFIAccess: false,
            loadAd: false,
            isResponsiveToggle: null,
            currentProduct: "common",
            productsRoles: "",
            primaryAdUrl: process.env.VUE_APP_ADURL + "/area=homepage/size=728x90/pos=Top/random=3333/Viewid=876533333/pgid=",
            secondaryAdUrl: process.env.VUE_APP_ADURL + "/area=homepage/size=195x90/pos=Top/random=3333/Viewid=876533333/pgid=",
            productsForAd: "MFI,MDI,MEI,MSI",
        };
    },
    mounted() {
        this.primaryAdUrl = this.primaryAdUrl.replace("homepage", this.currentPage == undefined || this.currentPage == null ? 'homepage' : this.currentPage);
        this.secondaryAdUrl = this.secondaryAdUrl.replace("homepage",  this.currentPage == undefined || this.currentPage == null ? 'homepage' : this.currentPage);
        this.loadAd = true;
        this.isResponsiveToggle = this.$store.state.responsive.isResponsive;
        if (this.$store.state.user.LoggedIn) {
            this.currentProduct = this.$session.get("current-product");
            this.productsRoles = this.$auth0.user["https://morningstar.com/uim_roles"];
            if(this.productsRoles != undefined && this.productsRoles !=null && this.productsRoles!= "") {
                this.MEIAccess = this.productsRoles.includes("EI_MEMBER");
                this.MDIAccess = this.productsRoles.includes("DI_MEMBER");
                this.MSIAccess = this.productsRoles.includes("SI_MEMBER");
                this.MFIAccess = this.productsRoles.includes("FI_MEMBER");
            }
            this.productsForAd = !this.MEIAccess ? "MEI" : "";
            this.productsForAd = !this.MDIAccess ? this.productsForAd == "" ? "MDI" : this.productsForAd + ",MDI" : this.productsForAd;
            this.productsForAd = !this.MSIAccess ? this.productsForAd == "" ? "MSI" : this.productsForAd + ",MSI" : this.productsForAd;
            this.productsForAd = !this.MFIAccess ? this.productsForAd == "" ? "MFI" : this.productsForAd + ",MFI" : this.productsForAd;
        }
        this.primaryAdUrl = this.primaryAdUrl + this.productsForAd;
        this.secondaryAdUrl = this.secondaryAdUrl + this.productsForAd;
    }
}
</script>
  
<style  scoped>
.ad-parent-container {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
    display:block;
    overflow:hidden;
    background:#f2f2f2;
    max-height:106px;
    /*margin-top: -105px;*/
    padding-bottom:3px;
}
.ad-container {
    max-width:1280px;
    margin: 0 auto;
    display:flex;
    justify-content:center;
}
.ad-primary-container{
    width:728px;
    height:100px;
}
.primary-ad{
    width:728px;
    border:none;
}
.ad-secondary-container{
    width:220px;
    height:100px;
}
.secondary-ad{
    width:195px;
    margin-left:16px;
    border:none;
}
@media only screen and (max-width:600px) {
    .ad-parent-container{
        margin-top: 0px;
        height:55px;
    }
    .ad-container{
        width:313px !important;
        height:39px !important;
        margin-top:7px;
        justify-content:initial;
    }
    .ad-primary-container{
        width:200px;
        height:39px;
    }
    .ad-primary-container > iframe {
        transform: scale(.43);
        transform-origin: 0 0;
    }
    .ad-secondary-container{
        display:none;
    }
}
@media only screen and (min-width:601px) and (max-width:767px){
    .ad-container{
        width:600px !important;
        height:80px;
    }
    .ad-primary-container{
        width:580px;
    }
    .ad-primary-container > iframe {
        transform: scale(0.8);
        transform-origin: 0 0;
    }
    .ad-secondary-container{
        display:none;
    }
}
@media only screen and (min-width:768px) and (max-width:1000px){
    .ad-primary-container > iframe {
        transform: scale(1);
        transform-origin: 0 0;
    }
    .ad-secondary-container{
        display:none;
    }  
}
</style>
  