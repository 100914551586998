<template>
    <div>
        <div v-if="isMobileContentToggle"></div>
        <div>
            <mds-button-container right-aligned>
                <mds-button variation="primary" size="small" @click="toggleClick" icon-right="caret-down" id="header" :target="productName" v-if="!this.$store.state.user.LoggedIn && !isMarketingPage" class="profile-subscription-button">
                    Subscribe
                </mds-button>
                <mds-button @click="toggleClick" v-if="this.$store.state.user.LoggedIn && this.$route.name != 'myAccount' && this.SearchFieldForResponsiveAndDesktop == true && !isMarketingPage" id="header" :target="productName" variation="primary" size="small"  icon-right="caret-down" class="profile-subscription-button-modal-overlay">
                    Subscribe
                </mds-button>
    
                <mds-button @click="redirectToRegistration(productName)" style="margin-right:45px" variation="primary" size="small" id="header" :target="productName" v-if="!this.$store.state.user.LoggedIn && isMarketingPage" class="profile-subscription-marketing-button">
                    Subscribe
                </mds-button>
                <mds-button @click="redirectToRegistration(productName)" style="margin-right:10px" variation="primary" size="small" id="header" :target="productName" v-if="this.$store.state.user.LoggedIn && isMarketingPage && this.$route.name != 'myAccount'" class="profile-subscription-marketing-button">
                    Subscribe
                </mds-button>
                <mds-button v-if="this.$store.state.user.LoggedIn" variation="icon-only" @click="openUserProfileClick" icon="person" type="button" class="profile-person-icon" id="profile-icon-trigger">
                </mds-button>
                <mds-button v-if="!LoggedIn && !isMobileContentToggle" variation="flat" @click="login()" icon="person" type="button" class="profile-sign-in">
                    Sign In</mds-button>
                <mds-button v-if="!LoggedIn && isMobileContentToggle" variation="flat" @click="login()" icon="person" type="button" size="medium" class="profile-sign-in-responsive">
                    Sign In</mds-button>
            </mds-button-container>
            <mds-popover triggered-by="profile-icon-trigger" v-model="openUserProfile" :position="['bottom-left', 'bottom-center']" width="200px" class="Account-popover">
                <mds-list-group size="medium" withIcon rightAligned>
                    <mds-list-group-item @click.native="openMyAccount">
                        <span>Account</span>
                    </mds-list-group-item>
                    <mds-list-group-item to="/logout" text="Logout"></mds-list-group-item>
                </mds-list-group>
            </mds-popover>
            <mds-popover  triggered-by="header" v-model="toggle" class="subscribe-popover" :position="['bottom-left']">
                <p class="subscribe-day-trial">START A NEWSLETTER TRIAL</p>
                <mds-list-group size="medium">
                    <mds-list-group-item><span @click="redirectToRegistration('Morningstar FundInvestor')">
                            <p class="subscribe-product-name">Morningstar FundInvestor</p>
                            <p class="subscribe-product-discription">
                                Morningstar FundInvestor is dedicated to helping investors pick mutual funds and build portfolios to meet their financial goals.
                            </p>
                        </span>
                        <template #mds-list-item-right>
                            <img class="subscribe-product-image" src="../../../../assets/Subscribe_FundInvestor.png" @click="redirectToRegistration('Morningstar FundInvestor')" />
                        </template>
                    </mds-list-group-item>
                    <mds-list-group-item><span @click="redirectToRegistration('Morningstar StockInvestor')">
                            <p class="subscribe-product-name">Morningstar StockInvestor</p>
                            <p class="subscribe-product-discription">
                                Morningstar StockInvestor focuses on companies that we believe have competitive advantages that are trading at discounts to their intrinsic values.
                            </p>
                        </span>
                        <template #mds-list-item-right>
                            <img class="subscribe-product-image" src="../../../../assets/Subscribe_StockInvestor.png" @click="redirectToRegistration('Morningstar StockInvestor')" />
                        </template>
                    </mds-list-group-item>
                    <mds-list-group-item><span @click="redirectToRegistration('Morningstar DividendInvestor')">
                            <p class="subscribe-product-name">Morningstar DividendInvestor</p>
                            <p class="subscribe-product-discription">
                                Morningstar DividendInvestor features the Dividend Select six-figure real-money portfolio that aims to produce income while taking on lower risk.
                            </p>
                        </span>
                        <template #mds-list-item-right>
                            <img class="subscribe-mdi-product-image" style="margin-top=10px;" src="../../../../assets/Subscribe_DividendInvestor.png" @click="redirectToRegistration('Morningstar DividendInvestor')" />
                        </template>
                    </mds-list-group-item>
                    <mds-list-group-item><span @click="redirectToRegistration('Morningstar ETFInvestor')">
                            <p class="subscribe-product-name">Morningstar ETFInvestor</p>
                            <p class="subscribe-product-discription">
                                Morningstar ETFInvestor focuses on the cost-efficiency of exchange-traded funds as demonstrated in the model portfolios.
                            </p>
                        </span>
                        <template #mds-list-item-right>
                            <img class="subscribe-product-image" src="../../../../assets/Subscribe_ETFInvestor.png" @click="redirectToRegistration('Morningstar ETFInvestor')" />
                        </template>
                    </mds-list-group-item>
    
                    <mds-list-group-item><span>
                            <a href="https://investor.morningstar.com/mm/sign-up" target="_blank" style="text-decoration:none;">
                                <p class="subscribe-day-trial subscribe-day-trial-sub-heading">Start a 7-Day Free Trial</p>
                                <p class="subscribe-product-name">Morningstar Investor</p>
                                <p class="subscribe-product-discription">
                                    Make more informed investment decisions with our industry-leading research.
                                </p>
                            </a>
                        </span>
                        <template #mds-list-item-right>
                            <a href="https://investor.morningstar.com/mm/sign-up" target="_blank">
                                <img class="subscribe-product-image" src="../../../../assets/Subscribe_Menu_Investor_Image.png" />
                            </a>
                        </template>
                    </mds-list-group-item>
                </mds-list-group>
            </mds-popover>
        </div>
    </div>
    </template>
    
    <script>
    import MdsPopover from "@mds/popover";
    import {
        mapState
    } from "vuex";
    import {
        MdsListGroup,
        MdsListGroupItem
    } from "@mds/list-group";
    import {
        MdsButton,
        MdsButtonContainer
    } from '@mds/button'
    
    export default {
        name: "user-subscription",
        components: {
            MdsButton,
            MdsPopover,
            MdsListGroup,
            MdsListGroupItem,
            MdsButtonContainer
        },
        data() {
            return {
                toggle: false,
                isMobileContentToggle: null,
                viewPortWidth: null,
                isMarketingPage: false,
                openUserProfile: false,
                LoggedIn: false,
                currentProduct: this.$session.get("current-product"),
                productName: null,
                SearchFieldForResponsiveAndDesktop: false,
            };
        },
    
        methods: {
            login() {
                this.$auth0.loginWithRedirect();
            },
            logout() {
                this.$auth0.logout();
            },
            Search() {
                if (this.modelSearchField != "") {
                    this.$router.push({
                        name: "searchResults",
                        params: {
                            currentproduct: this.$session.get("current-product"),
                            keyword: this.modelSearchField,
                        },
                    }).catch(err => {
                        if (
                            err.name !== 'NavigationDuplicated' &&
                            !err.message.includes('Avoided redundant navigation to current location')
                        ) {
                            console.log(err);
                        }
                    });
                }
            },
    
            getReferID(ProductName) {
                if (ProductName.toUpperCase() == 'MORNINGSTAR ETFINVESTOR' || ProductName.toUpperCase() == 'MEI')
                    return "ETFGEN1";
                else if (ProductName.toUpperCase() == 'MORNINGSTAR DIVIDENDINVESTOR' || ProductName.toUpperCase() == 'MDI')
                    return "ADIGRDG7";
                else if (ProductName.toUpperCase() == 'MORNINGSTAR STOCKINVESTOR' || ProductName.toUpperCase() == 'MSI')
                    return "AMSGRDG7";
                else if (ProductName.toUpperCase() == 'MORNINGSTAR FUNDINVESTOR' || ProductName.toUpperCase() == 'MFI')
                    return "AFNGRDG7";
            },
            getProductCode(ProductName) {
                if (ProductName.toUpperCase() == 'MORNINGSTAR ETFINVESTOR' || ProductName.toUpperCase() == 'MEI')
                    return "NET0000001";
                else if (ProductName.toUpperCase() == 'MORNINGSTAR DIVIDENDINVESTOR' || ProductName.toUpperCase() == 'MDI')
                    return "NDI0000001";
                else if (ProductName.toUpperCase() == 'MORNINGSTAR STOCKINVESTOR' || ProductName.toUpperCase() == 'MSI')
                    return "NM00000001";
                else if (ProductName.toUpperCase() == 'MORNINGSTAR FUNDINVESTOR' || ProductName.toUpperCase() == 'MFI')
                    return "NF00000001";
    
            },
            redirectToRegistration(productName) {
                var productCode = this.getProductCode(productName);
                var productReferId = "";
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                if( urlParams.has('referid') ){
                    productReferId = urlParams.get('referid');
                }
                if(productReferId == "")
                productReferId = this.getReferID(productName);
                this.$router.push({
                    name: 'purchase',
                    params: {
                        referid: productReferId,
                        productcode: productCode
                    },
                })
            },
            openMyAccount() {
                if (this.currentProduct) {
                    this.$router.push({
                        name: 'myAccount',
                        params: {
                            currentproduct: this.currentProduct.toLowerCase()
                        }
                    });
                    this.openUserProfile = false;
    
                }
            },
            toggleClick() {
                this.toggle = !this.toggle;
                this.openUserProfile = false;
            },
            openUserProfileClick() {
                this.openUserProfile = !this.openUserProfile;
                this.toggle = false;
            }
        },
        mounted() {
            this.isMarketingPage = this.$store.state.marketing.isMarketing;
            this.isMobileContentToggle = this.$store.state.responsive.isMobileContent;
            this.productName = this.$route.params.currentproduct;
            this.LoggedIn = this.$auth0.isAuthenticated && this.$session.exists();
            this.$store.state.user.LoggedIn = this.LoggedIn;
            var element = document.getElementById('header');
            if (this.$store.state.user.LoggedIn) {
          this.productDataRoles =
            this.$auth0.user["https://morningstar.com/uim_roles"];
            if(this.productDataRoles != undefined && this.productDataRoles !=null) {
          this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
          this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
          this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
          this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
            }
                if (!this.MEIAccess && !this.MFIAccess && !this.MSIAccess && !this.MDIAccess) {
                    this.SearchFieldForResponsiveAndDesktop = true;
                    if (element != null && element.classList != null) {
                        element.classList.add("subscriber-btn-block");
                    }
                }
                else{
                         if (element != null && element.classList != null) {
                            element.classList.add("subscriber-btn-hide");
                        }
                }
            }
            else{
                if (element != null && element.classList != null) {
                    this.SearchFieldForResponsiveAndDesktop = false;
                    element.classList.add("subscriber-btn-block");
                }
            }
        },
    
        computed: mapState({
            isMobileContent: state => state.responsive.isMobileContent,
            isMobileContentAlias: 'isMobileContent',
            isMobileContentState(state) {
                return state.responsive.isMobileContent
            },
            isMarketing: state => state.marketing.isMarketing,
            isMarketingAlias: 'isMarketing',
            isMarketingState(state) {
                return state.marketing.isMarketing
            }
    
        }),
    
        watch: {
            isMobileContentState() {
                this.isMobileContentToggle = this.isMobileContentState;
            },
            isMarketingState() {
                this.isMarketingPage = this.isMarketingState;
            },
            "$route.params.currentproduct"(value) {
                this.productName = value;
            },
        }
    
    };
    </script>
    
    <style lang="scss" scoped>
    .subscribe-popover {
        width: 340px !important;
        margin-left: 15px;
    }
    
    .subscribe-product-image {
        width: 72px;
        height: 92.19px;
        margin-top: 20px;
        align-items: right;
        margin-left: 40px;
        //filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    }
    
    .subscribe-mdi-product-image {
        width: 70px;
        height: 92.19px;
        margin-top: 20px;
        align-items: right;
        margin-left: 20px;
        //filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    }
    
    .subscribe-day-trial {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 1.67px;
        text-transform: uppercase;
        color: #5e5e5e;
        padding-bottom: 8px;
    }
    
    .subscribe-day-trial-sub-heading {
        padding-bottom: 4px;
        padding-top: 10px;
    }
    
    .subscribe-product-discription {
        margin-top: -15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
    }
    
    .subscribe-product-name {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        padding-bottom: 18px;
        color: #333333;
    }
    
    .profile-subscription-button.mds-button.mds-button--primary {
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #ffffff;
        padding: 4px 16px;
        top: 0px;
        right: 20px;
        background: #0077cf;
        border-radius: 24px;
    }
    
    .profile-subscription-button-modal-overlay.mds-button.mds-button--primary {
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #ffffff;
        padding: 4px 16px;
        top: 0px;
        right: 55px;
        background: #0077cf;
        border-radius: 24px;
    }
    
    .profile-subscription-marketing-button.mds-button.mds-button--primary {
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #ffffff;
        padding: 4px 16px;
        position: absolute;
        height: 29px;
        right: 50px;
        top: 17px;
        background: #0077cf;
        border-radius: 24px;
    }
    
    //sign in
    
    .profile-person-icon {
        position: absolute;
        right: 22px;
        cursor: pointer;
        float: right;
        top: 18px;
    }
    
    .profile-sign-in {
        float: right;
        right: 20px;
        bottom: 0px;
    }
    
    @media only screen and (min-width: 598px) and (max-width: 602px) {
        .profile-sign-in {
            float: right;
            right: 20px;
            bottom: 0px;
            font-size: 12px;
        }
    }
    
    @media only screen and (min-width: 602px) and (max-width: 610px) {
        .profile-sign-in {
            float: right;
            right: 20px;
            bottom: 0px;
            font-size: 13px;
        }
    }
    
    @media only screen and (min-width: 610px) and (max-width: 628px) {
        .profile-sign-in {
            float: right;
            right: 20px;
            bottom: 0px;
            font-size: 14px;
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 480px) {
    
        .profile-person-icon {
            position: absolute;
            right: 2%;
            /* top: 11px; */
            cursor: pointer;
    
        }
    }
    
    @media only screen and (max-width: 480px) {
        .user-main {
            margin-top: 500px;
        }
    
        .profile-person-icon {
            position: absolute;
            right: 4%;
            /* top: 11px; */
            cursor: pointer;
            top: 5.8%;
        }
    }
    
    .profile-sign-in-responsive {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    
    .Account-popover {
        margin-top: 5px;
    }
    
    .subscriber-btn-block {
        display: block;
    }
    
    .subscriber-btn-hide {
        display: none;
    }
    </style><style>
    .subscribe-popover>div>ul>li>span>div,
    .subscribe-popover>div>ul>li>a>div {
        padding: 4px 0px !important;
    }
    .subscribe-popover>div>ul>li>span:focus{
        box-shadow: none !important;
    }
    .profile-subscription-button > span > svg{
        margin-top: 2px;
    }
    </style>
    