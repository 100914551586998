<template>
  <div class="mei-model-portfolio-footer-container">
    <div >
      <p class="mei-footer-note">
        <sup>1 </sup>When the inception date of the portfolio precedes the
        inception date of a fund, we used the performance of the index that the
        fund tracks, less its earliest expense ratio to backfill the performance
        record.
        <br />
        <sup>2 </sup>Beta and correlation figures were measured over the past 3
        years against the Russell 3000 Index.
        <br />
        <sup>3 </sup>Effective July 1, 2019, iShares Broad USD Investment Grade
        Corporate Bond ETF USIG replaced iShares iBoxx $ Investment Grade
        Corporate Bond ETF LQD in the Income Portfolio. Performance prior to
        that date is based on the inclusion of LQD.
        <br />
        <sup>4 </sup>The Income Portfolio used Vanguard Total International Stock
        Index for the foreign allocation between 12/31/2007 and the inception of
        VYMI's index on 12/31/2008.
      </p>
    </div>

    <!--Disclosures-->
    <div class="mei-sub-border">
      <p class="mei-footer-disclosure-note">
        Data displayed is month-end with the exception of Return%, Morningstar
        Analyst Rating, and Allocation % which are through the previous day's
        end.
        <br /><br />
        Each of the above model portfolios (each, a "Model Portfolio") is
        designed to showcase a different investment strategy and how using
        different types of fund vehicles (each, a “Fund Vehicle”) can affect the
        performance of that strategy. These Model Portfolios are not designed to
        provide individualized recommendations/advice but instead are meant
        solely to be used for general, educational purposes. The actual
        inception date for each Model Portfolio is November 1, 2018. However,
        for purposes of this modeling exercise, Morningstar has derived
        hypothetical performance numbers for each Model Portfolio using the
        percentage-weighted performance numbers (back through December 2007) for
        each of the Fund Vehicles comprising that Model Portfolio. If, in any
        particular instance, a Fund Vehicle has an inception date after December
        2007, Morningstar uses a proxy performance number for any "gap period"
        (i.e., December 2007 to the Fund Vehicle’s actual inception date). This
        proxy performance number is calculated by taking the return performance
        numbers generated during the relevant gap period by the index that the
        Fund Vehicle initially used as its primary prospectus benchmark and
        reducing those numbers by the Fund Vehicle fees (measured in
        percentages) charged as of its inception date.
        <br />
        <br />
        Hypothetical performance is investment performance returns not actually
        achieved by any portfolio of a financial institution or professional.
        Hypothetical performance may include, but is not limited to, model
        performance returns, backtested performance returns, targeted or
        projected performance returns, and/or pre-inception returns.
        Hypothetical performance returns are theoretical, for illustrative
        purposes only, and are not reflective of an investor’s actual
        experience. Hypothetical performance returns are based on historic
        economic and market assumptions. Actual performance returns will vary.
        Hypothetical performance returns do not reflect actual trading and may
        not reflect the impact that material economic and market factors had on
        the decision-making process for this portfolio.
        <br /><br />
        ©{{ currentYear }} Morningstar. All rights reserved. The information,
        data, analyses and opinions contained herein (1) include the proprietary
        information of Morningstar, (2) may not be copied or redistributed, (3)
        do not constitute investment advice offered by Morningstar, (4) are
        provided solely for informational purposes and therefore are not an
        offer to buy or sell a security, and (5) are not warranted to be
        correct, complete or accurate. Except as otherwise required by law,
        Morningstar is not responsible for any trading decisions, damages or
        other losses resulting from, or related to, this information, data,
        analyses or opinions or their use. Past performance does not guarantee
        future results. Before making any investment decision, consider if the
        investment is suitable for you by referencing your own financial
        position, investment objectives, and risk profile. Always consult with
        your financial advisor before investing.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "model-portfolio-footer",
  data() {
    return {
      currentYear: Date,
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
  },
};
</script>     
<style scoped>
.mei-footer-note {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  /* line-height: 15px; */
  font-family: Univers, HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-bottom: 0px;
  margin-bottom: 18px;
  margin-top: -5px;
}

.mei-model-portfolio-footer-container {
    width: 1280px;
    margin: 0 auto;
} 

@media only screen and (min-width: 320px) and (max-width: 1415px) { 

.mei-model-portfolio-footer-container {
    width: 90%;
    margin-left:5%;
    margin-right:5%;
} 
}

 
.mei-footer-disclosure-note {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  font-family: Univers, HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-bottom: 0px;
  margin-bottom: 45px;
  margin-top: 16px;
}

.mei-sub-border {
  border-top: 1px solid #ccc;
}
</style>
  
  
      
      