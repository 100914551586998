<template>
  <div>
    <mds-page-shell
    :logo="getProductLogo()"
    :logo-responsive="getProductLogo()"
    logo-href="/"
    logo-alt="Back to home page"
   >
  <div class="product-subscription">
    <div class="product-subscription-main">
      <div class="product-subscription-form">
        <div class="product-subscription-form-container">
        <div>
          <h1 class="subcription-page-title">
            <span>You're already subscribed to </span> <br /><span
            ><i>Morningstar {{ currentproductName }}</i></span
          ></h1>
        </div>
        <div class="product-subscription-info">
          <span
            >Click Back to select a different subscription, or click Continue
            to navigate to <i>{{ currentproductName }}’s</i> homepage.</span
          >
        </div>
        <div class="product-subscription-container">
          <div>
            <mds-button-container>
              <!-- Icon On Left -->
              <mds-button
                variation="secondary"
                size="large"
                @click="
                $router.push({
                  name: 'combined-home',
                  params: { currentproduct: 'Common' },
                })
              "
              >
                Back
              </mds-button>
              <div class="product-subscription-cancel-button">
              <mds-button
              variation="secondary"
              size="large"
              type="button"
              @click="
              $router.push({
                name: 'combined-home',
                params: { currentproduct: 'Common' },
              })
            "
            >
              Cancel
            </mds-button>
          </div>
          <div class="product-subscription-continue-button">
              <mds-button
              variation="primary"
              size="large"
              type="button"
              right-aligned
              @click="
                $router.push({
                  name: 'home',
                  params: { currentproduct: currentproduct },
                })
              "
            >
              Continue</mds-button
            >
            </div>
            </mds-button-container>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </mds-page-shell>
</div>
</template>
<script>
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsPageShell from '@mds/page-shell'
export default {
  name: "product-subscription-check",
  components: { MdsButton, MdsButtonContainer, MdsPageShell },
  data() {
    return {
      currentproductName: null,
      currentproduct: null,
    };
  },
  methods: {
    getProductLogo() {
      var images = require.context(
        "../../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    getSelectedProduct(productCode) {
      if (productCode == "NM00000001") {
        this.currentproduct = "msi";
        this.currentproductName = "StockInvestor";
      } else if (productCode == "NDI0000001") {
        this.currentproduct = "mdi";
        this.currentproductName = "DividendInvestor";
      } else if (productCode == "NF00000001") {
        this.currentproduct = "mfi";
        this.currentproductName = "FundInvestor";
      } else if (productCode == "NET0000001") {
        this.currentproduct = "mei";
        this.currentproductName = "ETFInvestor";
      }
    },
  },
  created() {
    this.getSelectedProduct(this.$route.params.productcode);
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
  },
};
</script>
<style scoped>
.product-subscription-form-container{
  width:380px;
  margin:0 auto;
}
.subcription-page-title {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 44px;
  padding-top: 57px;
  font-weight: 250;
  font-size: 28px;
  line-height: 34px;
}
.product-subscription-cancel-button{
  margin-left:auto;
}
.product-subscription-continue-button{
  margin-left:auto;
  order:2;
}

.product-subscription{
  background-color: #f2f2f2;
  width:100%;
  height: 100vh;
}
.product-subscription-main {
  height: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}
.product-subscription-form {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
  min-height: 100vh;
}
.product-subscription-info {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 25%;
  width: 100%;
}
.product-subscription-container {
  margin-top: 10%;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .product-subscription-form-container{
  width:90%;
  margin:0 auto;
  }
  .subcription-page-title {
    height: 0;
    padding-top: 28px;
    font-weight: 250;
    font-size: 28px;
    line-height: 34px;
}
.product-subscription-info {
  margin-top: 10%;
}
}
</style>
