<template>
  <div>
    <mds-page-shell
    :logo="getProductLogo()"
    :logo-responsive="getProductLogo()"
    logo-href="/"
    logo-alt="Back to home page"
   >
  <div class="autorenew-registration-main">
    <div class="autorenew-registration-form">
      <div class="autorenew-registration-form-container">
      <div class="title-for-autorenew-registration-form">
        <h1 class="autorenew-page-title">
          Automatic renewal of Investor Newsletter subscription
        </h1>
      </div>
      <div class="auto-renew-div">
        <p class="auto-renew-text">
          The 30-day trial to your Investor Newsletter(s) subscription will
          begin on the date on which Morningstar accepts your completed
          registration. Unless cancelled by you or Morningstar during this trial
          period, the initial term of your regular paid Newsletter
          subscription(s) will start immediately after the trial period ends,
          and will continue for the length of time that you selected when you
          registered. If you have ever taken a trial to Investor Newsletters,
          you will not be eligible to receive another trial if your first trial
          occurred within three years of today's date. Instead, the initial term
          of your Newsletter subscription will begin immediately upon
          Morningstar accepting your registration, and your credit card will be
          charged for the subscription fee associated with the term length you
          selected when you registered. <br /><br />In those instances where you
          subscribe to the Investor Newsletters using a special or introductory
          offer, unless you notify us that you wish to cancel your subscription
          before the end of its initial term, your subscription will
          automatically renew for the period of time specified in that offer,
          and your credit card will be charged the subscription fee in effect at
          the time for that subscription term length. <br /><br />Other than as
          described elsewhere in this section, unless you notify us that you
          wish to cancel your Investor Newsletters subscription before the end
          of its then-current subscription term, your Investor Newsletters
          subscription will continue to automatically renew for successive terms
          of the same duration and your credit card will be charged for each
          such renewal at the subscription fee in effect at the time of that
          renewal.
        </p>
      </div>

      <div class="autorenew-page-border"></div>
      <div class="by-signing-div">
        <mds-checkbox size="small" value="accept" v-model="checked"
          >I have read and agree to the Automatic Renewal language (set out
          above and separately included in the
          <router-link class="terms-of-use-link" target="_blank" :to="{name:'user-agreement'}">Terms of Use</router-link>) that governs
          the automatic renewal of the Investor Newsletter Subscription(s).
        </mds-checkbox>
      </div>

      <div class="autorenew-registration-button-container">
        <div>
          <mds-button-container>
            <!-- Icon On Left -->
            <mds-button
              variation="secondary"
              size="large"
              @click="
                  $router.push({
                    name: 'billingaddress',
                    params: { referid: $route.params.referid, productcode: $route.params.productcode },
                  })
                "
            >
              Back
            </mds-button>
            <div class="autorenew-cancel-button">
            <mds-button
            variation="secondary"
            size="large"
            type="button"
            @click="
                $router.push({
                  name: 'combined-home',
                  params: { currentproduct: 'Common' },
                }) "
          >
            Cancel
          </mds-button>
          </div>
          <div class="autorenew-continue-button">
          <mds-button
              variation="primary"
              size="large"
              type="button"
              right-aligned
              :disabled="!checked"
              @click="ContinueForm"
              >Continue
            </mds-button>
          </div>
          </mds-button-container>
        </div>
      </div>
      <div class="autorenew-register-page-bottom"></div>
    </div>
    </div>
  </div>
  </mds-page-shell>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsCheckbox from "@mds/checkbox";
import MdsPageShell from '@mds/page-shell'
export default {
  name: "autorenew-section",
  components: {
    MdsCheckbox,
    MdsButton,
    MdsButtonContainer,
    MdsPageShell
  },
  data() {
    return {
      checked: false,
      referID: this.$route.params.referid,
      productCode: this.$route.params.productcode,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
  },
  methods:{
    getProductLogo() {
      var images = require.context(
        "../../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    ContinueForm()
    {
      if(this.currentProduct == "mfi"){
      this.$gtag.event('auto_renewal', {
        'product': "MFI",
        'term': this.subscriptionterm,
        'user_id': this.userID,
        
      })
        } if(this.currentProduct == "msi"){
      this.$gtag.event('auto_renewal', {
        'product': "MSI",
        'term': this.subscriptionterm,
        'user_id': this.userID,
                    })
                  } if(this.currentProduct == "mdi"){
      this.$gtag.event('auto_renewal', {
        'product': "MDI",
        'term': this.subscriptionterm,
        'user_id': this.userID,
                    })
                  } if(this.currentProduct == "mei"){
      this.$gtag.event('auto_renewal', {
        'product': "MEI",
        'term': this.subscriptionterm,
        'user_id': this.userID,
                    })
                  } if(this.currentProduct == "Common"){
      this.$gtag.event('auto_renewal', {
        'product': "Common",
        'term': this.subscriptionterm,
        'user_id': this.userID,
                    })
                  }

      this.$router.push({
        name: "reviewsubscription",
        params: { referid: this.referID, productcode: this.productCode },
      }).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      });
    }
  }
};
</script>

<style scoped>
.autorenew-continue-button {
  margin-left:auto;
  order:2;
}
.autorenew-cancel-button {
  margin-left:auto;
}
.autorenew-register-page-bottom {
  background: linear-gradient(
    360deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
  height: 50px;
}
.autorenew-registration-main {
  height: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.autorenew-registration-form {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
  min-height: 100vh;
}

.autorenew-page-title {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 78px;
  padding-top: 57px;
  font-weight: 250;
  font-size: 28px;
  line-height: 34px;
}

.title-for-autorenew-registration-form {
  width: 100%;
}
.autorenew-page-border {
  width: 100%;
  border-bottom: 1px solid #cccccc;
}

.by-signing-text {
  margin-top: 8px;
  font-weight: 300;
  font-size: 14.7776px;
  line-height: 19px;
  color: #5e5e5e;
}

.by-signing-div {
  width: 100%;
  margin-top: 2%;
}

.auto-renew-div {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.auto-renew-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top:20%;
}

.autorenew-registration-button-container {
  margin-top: 10%;
}

.terms-of-use-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
}
.autorenew-registration-form-container{
width:380px;
margin:0 auto;
}

@media only screen and (max-width: 480px) {
  .autorenew-registration-form-container{
  width:90%;
  margin:0 auto;
  }
  .autorenew-page-title {
    padding-top: 28px;
    margin-bottom: 15%;
}
.auto-renew-text {
  margin-top: 10%;
}
}
</style>
<style>
.by-signing-div .mds-checkbox__visible-wrap{
  box-shadow: none !important;
}
</style>
