var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-bottom-main-div"},[_c('div',{staticClass:"banner-bottom-container"},[(
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar DividendInvestor'
      )?_c('div',{staticClass:"combined-bottom-banner-img-gradient",staticStyle:{"position":"relative"},attrs:{"id":"MdiBanner"}}):_vm._e(),(
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar FundInvestor'
      )?_c('div',{staticClass:"combined-bottom-banner-img-gradient",staticStyle:{"position":"relative"},attrs:{"id":"MfiBanner"}}):_vm._e(),(
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar StockInvestor'
      )?_c('div',{staticClass:"combined-bottom-banner-img-gradient",staticStyle:{"position":"relative"},attrs:{"id":"MsiBanner"}}):_vm._e(),(
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar ETFInvestor'
      )?_c('div',{staticClass:"combined-bottom-banner-img-gradient",staticStyle:{"position":"relative"},attrs:{"id":"ETFBanner"}}):_vm._e(),_c('div',{staticClass:"combine-hero-image-text-container",staticStyle:{"position":"relative","z-index":"3"}},[_c('div',{staticClass:"combined-bottom-div-style"},[_c('div',{staticClass:"combined-bottom-banner-heading",domProps:{"innerHTML":_vm._s(_vm.bannerProductTitle)}}),(
            this.$store.state.product.SelectedProductBanner ==
            'Morningstar DividendInvestor'
          )?_c('div',{staticClass:"combined-bottom-mdi-statictext"},[_vm._v(" Discover the world of dividend-paying stocks. ")]):_vm._e(),(!_vm.Subscribed)?_c('div',{staticClass:"combined-bottom-banner-text"},[_c('div',{staticClass:"combined-bottom-divBorder"}),_c('div',{staticClass:"combined-bottom-productName"},[_vm._v(" "+_vm._s(this.$store.state.product.SelectedProductBanner)+" ")]),_c('div',[(
                this.$store.state.product.SelectedProductBanner ==
                'Morningstar FundInvestor' | this.$store.state.product.SelectedProductBanner ==
                'Morningstar StockInvestor')?_c('h1',{staticClass:"combined-bottom-productPrice"},[_vm._v(" $145 Annual Digital "),_c('span',{staticClass:"pipe-symbol"},[_vm._v("|")]),_vm._v(" $165 Annual Print + Digital ")]):_vm._e(),(
                this.$store.state.product.SelectedProductBanner ==
                'Morningstar DividendInvestor' |  this.$store.state.product.SelectedProductBanner ==
                'Morningstar ETFInvestor')?_c('h1',{staticClass:"combined-bottom-productPrice"},[_vm._v(" $199 Annual Digital "),_c('span',{staticClass:"pipe-symbol"},[_vm._v("|")]),_vm._v(" $219 Annual Print + Digital ")]):_vm._e()]),_c('mds-button',{staticClass:"combined-bottom-banner-button-color",attrs:{"id":"footer","target":this.$store.state.product.SelectedProductBanner,"variation":"secondary"},on:{"click":function($event){return _vm.redirectToRegistration()}}},[_vm._v(" Subscribe ")])],1):_vm._e(),(this.$store.state.user.LoggedIn)?_c('div',{staticClass:"combined-bottom-banner-button"},[(_vm.Subscribed)?_c('div',[_c('mds-button',{staticClass:"combined-bottom-banner-button-color",attrs:{"variation":"secondary"},on:{"click":function($event){return _vm.redirectHomePageClick()}}},[_vm._v(" "+_vm._s(this.currentProductText)+" ")])],1):_vm._e()]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }