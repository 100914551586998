<template>
  <div>
    <div v-if="isMobileContentToggle" class="responsive-search-main-div">
      <div v-if="this.$store.state.user.LoggedIn" class="responsive-search">
        <mds-button  v-if="this.$store.state.user.LoggedIn" variation="icon-only" @click="searchfield = !searchfield" icon="search" type="button" >
    </mds-button>
      </div>
      <div v-if="searchfield">
        <mds-search-field
            placeholder="Search..."
            label="Search"
            icon="search"
            size="large"
            class="search-div-responsive"
            @keyup.enter="Search()"
            v-model="searchKeyword"
          ></mds-search-field>
      </div>
    </div>
    <div v-if="SearchFieldForResponsiveAndDesktop">
      <div v-if="this.$store.state.user.LoggedIn" >
        <mds-search-field
          placeholder="Search..."
          label="Search"
          size="small"
          class="search-div"
          @keyup.enter="Search()"
          v-model="searchKeyword"
        ></mds-search-field>
      </div>
    </div>
    
  </div>
</template>

<script>
import MdsSearchField from "@mds/search-field";
import { mapState } from "vuex";
import {
    MdsButton
} from '@mds/button';
export default {
  name: "search",
  components: {
    MdsSearchField,
    MdsButton,
  },
  data() {
    return {
      searchKeyword: "",
      isMobileContentToggle: null,
      viewPortWidth: null,
      searchfield: false,
      productsubscriptionoverlayStatus : false,
      SearchFieldForResponsiveAndDesktop : true,
    };
  },
  methods: {
    Search() {
      if (this.searchKeyword != "" && !this.$store.state.search.InvalidSearchTerm) {
        this.$router.push({
          name: "searchResults",
          params: {
            currentproduct: this.$session.get("current-product"),
            keyword: this.searchKeyword,
          },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      else {
        this.errormessage = true;
        this.errormessageText = "Please enter a valid search term"
      }
    },
    

  },
  mounted() {
    this.isMobileContentToggle = this.$store.state.responsive.isMobileContent;
    if (this.$store.state.user.LoggedIn) {
      this.productDataRoles =
        this.$auth0.user["https://morningstar.com/uim_roles"];
        if(this.productDataRoles != undefined && this.productDataRoles !=null) {
      this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
      this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
      this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
      this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
    }
    if(!this.MEIAccess && !this.MFIAccess && !this.MSIAccess && !this.MDIAccess)
    {
      this.SearchFieldForResponsiveAndDesktop=false;
      
    }
  }
},

  computed:mapState({
    isMobileContent: state => state.responsive.isMobileContent,
    isMobileContentAlias:'isMobileContent',
    isMobileContentState(state){
      return state.responsive.isMobileContent
    }

  }),

  watch:{
    isMobileContentState(){
      this.isMobileContentToggle = this.isMobileContentState;
    },
    searchKeyword(newVal, oldVal) {
      if (newVal != "") {
        if (newVal != oldVal) {
          if (newVal.trim().length == 0) {
            this.$store.state.search.InvalidSearchTerm = true;
          } else if (!/^[A-Za-z0-9\s]*$/.test(newVal)) {
            this.$store.state.search.InvalidSearchTerm = true;
          } else {
            this.$store.state.search.InvalidSearchTerm = false;
          }
        }
      } else {
        this.$store.state.search.InvalidSearchTerm = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>

.search-error-message {
  font-size: 14px;
  color: red;
  //margin-left: auto;
  //margin-top: 8px;
}
.search-div {
  width: 250px;
  position: absolute;
  right: 57px;
}

.search-div-responsive {
  width: 95%;
  position: absolute;
  right: 10px;
  top: 65px;
 
}

.responsive-search-main-div{}




@media only screen and (min-width: 0px) and (max-width: 1000px) {
  .search-div {
   display: none !important;
  
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .search-div {
    width: 176px;
    height: 29px;
    position: absolute;
    right: 57px;
  
  }
}
</style>
<style>
.search-div .mds-search-field__input {
  height: 29px !important;
}

.search-div .mds-search-field.mds-search-field--small .mds-search-field__input {
  height: 29px !important;
}

@media only screen and (min-width: 0px) and (max-width: 1000px) {

.responsive-search {
 right: 50px;
 top: 13px;
 position: absolute;
}
}

@media only screen and (min-width: 1000px) and (max-width: 10000px) {

.responsive-search {
 display: none !important;;
}
}
</style>