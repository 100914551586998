<template>
	<div :class="{
		[$style['mdc-carousel']]: true,
		[$style['mdc-carousel--native-scroll']]: isNativeScroll
	}">
		<div ref="scroll" :class="$style['mdc-carousel__scroll']">
			<ul ref="items" :class="{
				[$style['mdc-carousel__items']]: true,
				[$style[`mdc-carousel__items--${gutter}-gutter`]]: gutter
			}">
				<slot />
			</ul>
		</div>

		<div :class="{
			[$style['mdc-carousel__buttons']]: true,
			[$style['mdc-carousel__buttons--inset']]: insetControls
		}">
			<div :class="{
				[$style['mdc-carousel__button-container--left']]: insetControls,
				[$style['mdc-carousel__button-container--on-gray']]: insetControls && onGray,
				[$style['mdc-carousel__button-container--on-dark']]: insetControls && onDark
			}">
			<slot  name="left-button" :previousPage="previousPage">
					<div v-if="btnPrevcheck" @click="previousPage">
						<mds-icon name="angle-left" size="medium" class="home-commentry-angle-left-article"></mds-icon>
					</div>
			</slot> 
			</div>

			<div :class="{
				[$style['mdc-carousel__button-container--right']]: insetControls,
				[$style['mdc-carousel__button-container--on-gray']]: insetControls && onGray,
				[$style['mdc-carousel__button-container--on-dark']]: insetControls && onDark
			}">
				<slot  name="right-button" :nextPage="nextPage">
					<div v-if="btnNextcheck" @click="nextPage">
					<mds-icon
                      name="angle-right"
                       size="medium" class="home-commentry-angle-right-article" 
                    ></mds-icon
                  >
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import anime from 'animejs';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import reduce from 'lodash/reduce';

import { mds_constants as mdsConstants } from '@mds/constants/dist/constants.json';
import MdsIcon from "@mds/icon";
import { mapState } from "vuex";
const ANIMATION_TIMING = parseInt(mdsConstants.animation.timing['2-x']);

export default {
	components: {
		MdsIcon
	},
	props: {
		small: {
			type: Boolean,
			description: 'Renders control elements in small size',
			default: false
		},
		gutter: {
			type: String,
			description: 'Gutter style between items',
			default: null,
			validator: v => ['narrow', 'wide'].includes(v)
		},
		snap: {
			type: Boolean,
			description: 'Force mandatory snapping to item',
			default: false
		},
		hideControls: {
			type: Boolean,
			description: 'Hide next/previous buttons in all contexts',
			default: false
		},
		insetControls: {
			type: Boolean,
			description: 'Push next/previous buttons into the scroll pane',
			default: false
		},
		onGray: {
			type: Boolean,
			description: 'Indicates carousel is placed against gray background',
			default: false
		},
		onDark: {
			type: Boolean,
			description: 'Indicates carousel is placed against dark background',
			default: false
		}
	},
	data() {
		return {
			touchSupport: false,
			resizeObserver: null,
			clientWidth: null,
			scrollWidth: null,
			viewportWidth: null,
			scrollLeft: 0,
			counters: 0,
			btnNextcheck:true,
			Counter_prev:0,
			btnPrevcheck:false,
			scrollLeftOne:0,
			scrollLeftTwo:0,
			scrollLeftThree:0,
            scrollLeftFourth:0,
            

		};
	},
	computed:mapState( {
		hasRightButton() {
			return Math.ceil(this.clientWidth) + Math.ceil(this.scrollLeft) < Math.ceil(this.scrollWidth);
		},
		hasLeftButton() {
			return this.scrollLeft > 0;
		},
		isNativeScroll() {
			return this.touchSupport && this.clientWidth < 800;
		},

		viewPortSize: state => state.responsive.viewPortSize,
		viewPortSizeAlias:'viewPortSize',
		viewPortSizeState(state){
		return state.responsive.viewPortSize
		}
	}),
	mounted() {
		this.viewPortWidth = this.$store.state.responsive.viewPortSize;
		this.shiftImagetoleftOnclick(this.viewPortWidth);
		const { scroll } = this.$refs;
		this.touchSupport = 'ontouchstart' in window;

		this.snapToNearestDebounce = debounce(this.snapToNearest, 100);

		this.onResize();
		this.onResizeDebounce = debounce(this.onResize, 50);
		this.resizeObserver = new ResizeObserver(this.onResizeDebounce);
		this.resizeObserver.observe(scroll);

		this.onScrollDebounce = debounce(this.onScroll, 30);
		scroll.addEventListener('scroll', this.onScrollDebounce);

		scroll.addEventListener('wheel', this.onScrollEnd); // for mouse input
	},
	updated() {
		this.onResizeDebounce();
	},
	beforeDestroy() {
		const { scroll } = this.$refs;

		if (this.resizeObserver) {
			this.resizeObserver.unobserve(scroll);
			this.resizeObserver = null;
		}
		scroll.removeEventListener('scroll', this.onScrollDebounce);
		scroll.removeEventListener('wheel', this.onScrollEnd);
	},
	methods: {
		
		calculateSnapPoint(targetOffset, roundForward = true) {
			// at beginning
			if (targetOffset <= 0) return 0;

			// at end
			if (targetOffset >= this.scrollWidth - this.clientWidth) return this.scrollWidth - this.clientWidth;

			const { items } = this.$refs;
			const { left: containerLeft } = items.getBoundingClientRect();
			let snapTarget = reduce(items.children, (accum, child) => {
				const { left, width } = child.getBoundingClientRect();
				const containerAdjustedLeft = left - containerLeft;

				if (!roundForward && containerAdjustedLeft > targetOffset) return accum;

				// more than halfway past element and rounding enabled
				if (roundForward && containerAdjustedLeft - (width * 0.5) > targetOffset) return accum;

				return containerAdjustedLeft;
			}, 0);

			// offset for inset control button
			if (this.insetControls && snapTarget > 0) snapTarget -= 33;

			return clamp(snapTarget, 0, this.scrollWidth - this.clientWidth);
		},
		nextPage() {
			//this.counters = this.counters + 1;
			this.$store.state.article.ArticleCounter +=1;
			const { scroll } = this.$refs;
			const targetOffset = this.scrollLeft + this.clientWidth;
			const snappedOffset = this.calculateSnapPoint(targetOffset, false);
			//const percentChange = (snappedOffset - this.scrollLeft) / this.clientWidth;
			// if (this.counters === 5) {
			// 	this.previousPage();
			// }
			if(this.$store.state.article.ArticleCounter == 1){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: this.scrollLeftOne,
				easing: 'easeInOutQuad'
			});
				this.btnNextcheck = true;
				this.btnPrevcheck=true;
			}
			else if(this.$store.state.article.ArticleCounter == 2){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: this.scrollLeftTwo,
				easing: 'easeInOutQuad'
			});
			this.btnNextcheck = true;
			this.btnPrevcheck=true;
            
			}
			else if(this.$store.state.article.ArticleCounter == 3){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: this.scrollLeftThree,
				easing: 'easeInOutQuad'
			});
			this.btnNextcheck = true;
			this.btnPrevcheck=true;
            console.log("snappedOffset 3=" + snappedOffset);
			}
            else if(this.$store.state.article.ArticleCounter == 4){
				anime({
				targets: scroll,
				duration: ANIMATION_TIMING * 0.61,
				scrollLeft: this.scrollLeftFourth,
				easing: 'easeInOutQuad'
			});
			this.btnNextcheck = false;
			this.btnPrevcheck=true;
            console.log("snappedOffset 3=" + snappedOffset);
			}
           
		},
		previousPage() {
			const { scroll } = this.$refs;
			//const targetOffset = 0;
			//const snappedOffset = 0;
			//const percentChange = (this.scrollLeft - snappedOffset) / this.clientWidth;
            
         if(this.$store.state.article.ArticleCounter == 5)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: this.scrollLeftFourth,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=true;
				this.btnNextcheck = false;
			}
           else if(this.$store.state.article.ArticleCounter == 4)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: this.scrollLeftThree,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=true;
				this.btnNextcheck = true;
			}

           else if(this.$store.state.article.ArticleCounter == 3)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: this.scrollLeftTwo,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=true;
				this.btnNextcheck = true;
			}
			else if(this.$store.state.article.ArticleCounter == 2)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: this.scrollLeftOne,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=true;
				this.btnNextcheck = true;
			}
			else if(this.$store.state.article.ArticleCounter  == 1)
			{

				anime({
					targets: scroll,
					duration: ANIMATION_TIMING * 0.61,
					scrollLeft: 0,
					easing: 'easeInOutQuad'
				});
				this.btnPrevcheck=false;
				this.btnNextcheck = true;
			}
			//this.counters = this.counters-1;
			this.$store.state.article.ArticleCounter -=1;

		},
		snapToNearest() {
			const { scroll } = this.$refs;
			const snappedOffset = this.calculateSnapPoint(scroll.scrollLeft, true);
			const percentChange = Math.abs(this.scrollLeft - snappedOffset) / this.clientWidth;

			anime({
				targets: scroll,
				duration: ANIMATION_TIMING * Math.max(percentChange, 0.33),
				scrollLeft: snappedOffset,
				easing: 'easeInOutQuad'
			});
		},
		onResize() {
			const { scroll } = this.$refs;
			this.clientWidth = scroll.clientWidth;
			this.scrollWidth = scroll.scrollWidth;
			this.innerWidth = window.innerWidth;
		},
		onScroll() {
			const { scroll } = this.$refs;
			this.scrollLeft = scroll.scrollLeft;
		},
		onScrollEnd() {
			if (!this.snap) return;

			this.snapToNearestDebounce.cancel();
			this.snapToNearestDebounce();
		},
		shiftImagetoleftOnclick(width){
			if(width >=300 && width <= 479)
			{
				this.scrollLeftOne = 350;
				this.scrollLeftTwo = 970;
				this.scrollLeftThree =1520;
                this.scrollLeftFourth = 2300;
               
			}

			else if(width >=480 && width <= 490)
			{
				this.scrollLeftOne = 510;
				this.scrollLeftTwo = 1000;
				this.scrollLeftThree =1490;
                this.scrollLeftFourth = 2230;
               
			}

			else if(width >=491 && width <= 500)
			{
				this.scrollLeftOne = 510;
				this.scrollLeftTwo = 1020;
				this.scrollLeftThree =1525;
                this.scrollLeftFourth = 2230;
               
			}

			else if(width >=501 && width <= 510)
			{
				this.scrollLeftOne = 530;
				this.scrollLeftTwo = 1040;
				this.scrollLeftThree =1555;
                this.scrollLeftFourth = 2230;
               
			}
            else if(width >=511 && width <= 520)
			{
				this.scrollLeftOne = 540;
				this.scrollLeftTwo = 1060;
				this.scrollLeftThree = 1580;
                this.scrollLeftFourth = 2250;
               
			}
            else if(width >=521 && width <= 530)
			{
				this.scrollLeftOne = 540;
				this.scrollLeftTwo = 1070;
				this.scrollLeftThree = 1595;
                this.scrollLeftFourth = 2260;
               
			}
            else if(width >=531 && width <= 540)
			{
				this.scrollLeftOne = 550;
				this.scrollLeftTwo = 1080;
				this.scrollLeftThree = 1600;
                this.scrollLeftFourth = 2270;
               
			}
            else if(width >=541 && width <= 550)
			{
				this.scrollLeftOne = 560;
				this.scrollLeftTwo = 1110;
				this.scrollLeftThree = 1650;
                this.scrollLeftFourth = 2280;
               
			}
            else if(width >=551 && width <= 560)
			{
				this.scrollLeftOne = 570;
				this.scrollLeftTwo = 1120;
				this.scrollLeftThree = 1660;
                this.scrollLeftFourth = 2280;
               
			}
            else if(width >=561 && width <= 570)
			{
				this.scrollLeftOne = 580;
				this.scrollLeftTwo = 1130;
				this.scrollLeftThree = 1700;
                this.scrollLeftFourth = 2280;
               
			}
            else if(width >=571 && width <= 580)
			{
				this.scrollLeftOne = 590;
				this.scrollLeftTwo = 1140;
				this.scrollLeftThree = 1720;
                this.scrollLeftFourth = 2290;
               
			}
            else if(width >=581 && width <= 590)
			{
				this.scrollLeftOne = 600;
				this.scrollLeftTwo = 1170;
				this.scrollLeftThree = 1750;
                this.scrollLeftFourth = 2300;
               
			}
            else if(width >=591 && width <= 600)
			{
				this.scrollLeftOne = 610;
				this.scrollLeftTwo = 1190;
				this.scrollLeftThree = 1780;
                this.scrollLeftFourth = 2310;
               
			}
			
		}
	},
	watch:{
		viewPortSizeState(){
			this.shiftImagetoleftOnclick(this.viewPortSizeState);
		}
	}
};
</script>

<style lang="scss" module>
@import "@mds/constants";

.mdc-carousel {
	position: relative;

	&__scroll {
		display: flex;
		min-width: 0;
		max-width: 100%;
		overflow: scroll visible;
		scrollbar-width: none;
		-ms-overflow-style: none;
		padding-top: $mds-space-half-x;
		padding-bottom: $mds-space-half-x;
		margin-top: -$mds-space-half-x;
		margin-bottom: -$mds-space-half-x;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			display: none;
			background: transparent;
		}
	}

	&__items {
		position: relative;
		flex-grow: 1;
		display: flex;
		flex-wrap: nowrap;
		margin: 0;
		padding: 0;
		width: 100px;
		min-width: 0;
		list-style: none;

		>* {
			flex-shrink: 0;
			flex-grow: 0;

			&:not(:last-child) {
				margin-right: $mds-space-2-x;
			}
		}

		&--narrow-gutter {
			>*:not(:last-child) {
				margin-right: $mds-space-1-x;
			}
		}

		&--wide-gutter {
			>*:not(:last-child) {
				margin-right: $mds-space-4-x;
			}
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		top: 0;
		left: -15px;
		right: -15px;
		bottom: 0;
		z-index: $mds-z-index-sticky;
		pointer-events: none;

		&--inset {
			left: 0;
			right: 0;
		}
	}

	&__button-container {
		&--left {
			display: flex;
			align-items: center;
			height: 100%;
			background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		}

		&--left.mdc-carousel__button-container--on-gray {
			background: linear-gradient(90deg, rgba($mds-background-color-light-gray, 1) 0%, rgba($mds-background-color-light-gray, 0) 100%);
		}

		&--left.mdc-carousel__button-container--on-dark {
			background: linear-gradient(90deg, rgba($mds-background-color-dark-gray, 1) 0%, rgba($mds-background-color-dark-gray, 0) 100%);
		}

		&--right {
			display: flex;
			align-items: center;
			height: 100%;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		}

		&--right.mdc-carousel__button-container--on-gray {
			background: linear-gradient(90deg, rgba($mds-background-color-light-gray, 0) 0%, rgba($mds-background-color-light-gray, 1) 100%);
		}

		&--right.mdc-carousel__button-container--on-dark {
			background: linear-gradient(90deg, rgba($mds-background-color-dark-gray, 0) 0%, rgba($mds-background-color-dark-gray, 1) 100%);
		}
	}

	&__button {
		background-color: $mds-background-color-white !important;
		box-shadow: $mds-box-shadow-drop;
		border-radius: 50% !important;
		border: $mds-border-container !important;
		height: 29px;
		padding: 3px;
		transition: opacity $mds-animation-timing-1-x;
		width: 29px;
		pointer-events: auto;

		&--small {
			height: 26px;
			width: 26px;
		}
	}
}

</style>
<style>
.home-commentry-angle-right-article{
	cursor: pointer;
	z-index:10;
	pointer-events: all;
}
.home-commentry-angle-left-article{
    margin-left: 8px;
	cursor: pointer;
	z-index:10;
	pointer-events: all;
	
}
</style>
