<template>
  <div class="common-overlay">
    <mds-modal size="small" width="600px" aria-label="Small Modal" v-model="toggleOverlay" actionRequired
      class="common-overlay-modal">
      <mds-button-container right-aligned>
        <mds-button @click="resetOverlaryStatus()" variation="icon-only" icon="remove">Close</mds-button>
      </mds-button-container>
      <div class="overlay-content-container">
        <div class="overlay-heading">
          <span>Subscribe to Morningstar {{ productName }}</span>
        </div>
        <div class="overlay-border-seperator"></div>
        <div class="overlay-descriptive-text">
          <span>
            Get access to monthly issues, our sought-after analyst research,
            watchlist data, insights from our editors, and more.
          </span>
        </div>
        <div class="overlay-subscribe-button">
          <mds-button variation="primary" size="large"
            @click="redirectToRegistrationOrMarketing(productName, 'digitalReg')" id="overlay" :target="productName"> Subscribe </mds-button>
          <mds-button variation="secondary" size="large"
            @click="redirectToRegistrationOrMarketing(productName, 'learnmore')"> Learn More </mds-button>
        </div>
        <div class="overlay-sign-in-button-print-digital">
          <mds-layout-grid>
            <mds-row>
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6"
                class="overlay-sign-in-button">
                <div class="overlay-border-seperator"></div>
                <p class="overlay-sign-in-button-text">{{ overlaySignIntext }}</p>
                <mds-button variation="secondary" @click="signInbuttonAction()"> {{ overlaySignInButtonText }}
                </mds-button>
              </mds-col>
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6">
                <div class="overlay-border-seperator"></div>
                <p class="overlay-sign-in-button-text">Print subscriber visiting for the first time?</p>
                <mds-button variation="secondary" @click="redirectToRegistrationOrMarketing(productName, 'printReg')">
                  Register Now </mds-button>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </div>
      </div>
    </mds-modal>
  </div>
</template>

<script lang="js">
import MdsModal from '@mds/modal';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'common-overlay',
  props: {
    currentProduct: {
      type: String,
      required: true,
    },
    overlayStatus: {
      type: Boolean,
      required: true,
    }
  },
  mounted() {
    this.populateOverlayData()
  },
  components: {
    MdsModal,
    MdsButton,
    MdsButtonContainer,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  data() {
    return {
      productName: this.currentProduct,
      toggleOverlay: this.overlayStatus,
      overlaySignIntext: null,
      overlaySignInButtonText: null,
    }
  },
  methods: {
    populateOverlayData() {
      if (this.productName.toUpperCase() == 'MFI') {
        this.productName = 'FundInvestor';
      }
      else if (this.productName.toUpperCase() == 'MSI') {
        this.productName = 'StockInvestor';
      }
      else if (this.productName.toUpperCase() == 'MDI') {
        this.productName = 'DividendInvestor';
      }
      else if (this.productName.toUpperCase() == 'MEI') {
        this.productName = 'ETFInvestor';
      }

      if (this.$session.exists() && this.$store.state.user.LoggedIn) {
        this.overlaySignIntext = "Continue to Newsletters homepage";
        this.overlaySignInButtonText = "Newsletters Home";
      } else {
        this.overlaySignIntext = "Already registered as a digital subscriber?";
        this.overlaySignInButtonText = "Sign In";
      }
    },
    getReferID(ProductName) {
      if (ProductName.toUpperCase() == 'ETFINVESTOR')
        return "ETFGEN1";
      else if (ProductName.toUpperCase() == 'DIVIDENDINVESTOR')
        return "ADIGRDG7";
      else if (ProductName.toUpperCase() == 'STOCKINVESTOR')
        return "AMSGRDG7";
      else if (ProductName.toUpperCase() == 'FUNDINVESTOR')
        return "AFNGRDG7";
    },
    getProductCode(ProductName) {
      if (ProductName.toUpperCase() == 'ETFINVESTOR')
        return "NET0000001";
      else if (ProductName.toUpperCase() == 'DIVIDENDINVESTOR')
        return "NDI0000001";
      else if (ProductName.toUpperCase() == 'STOCKINVESTOR')
        return "NM00000001";
      else if (ProductName.toUpperCase() == 'FUNDINVESTOR')
        return "NF00000001";
    },
    redirectToRegistrationOrMarketing(product, typeOfReg) {
      if(this.currentProduct == "mfi"){
      this.$gtag.event('archive_overlay', {
        'click_id': 'archive_overlay',
        'click_target': "MFI",

      })
        } if(this.currentProduct == "msi"){
      this.$gtag.event('archive_overlay', {
        'click_id': 'archive_overlay',
        'click_target': "MSI",
        
                    })
                  } if(this.currentProduct == "mdi"){
      this.$gtag.event('archive_overlay', {
        'click_id': 'archive_overlay',
        'click_target': "MDI",
        
                    })
                  } if(this.currentProduct == "mei"){
      this.$gtag.event('archive_overlay', {
        'click_id': 'archive_overlay',
        'click_target': "MEI",
       
                    })
                  }

      if (typeOfReg == 'digitalReg') {
        var productCode = this.getProductCode(product);
        var productReferId = this.getReferID(product);
        this.$router.push({
          name: 'purchase',
          params: {
            referid: productReferId,
            productcode: productCode
          },
        })
      } else if (typeOfReg == 'learnmore') {
        this.resetOverlaryStatus();
        this.$router.push({
          name: 'marketing-landing',
          params: {
            currentproduct: this.currentProduct,
          },
        })
      }
      else {
        this.$router.push({
          name: 'print-registration',
          params: {
            currentproduct: this.currentProduct,
          },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
    },
    resetOverlaryStatus() {
      this.toggleOverlay = false;
      this.$emit('resetOverlayStatusValue', false);
    },
    signInbuttonAction() {
      if (this.$session.exists() && this.$store.state.user.LoggedIn) {
        this.resetOverlaryStatus();
        this.$router.push({
          name: 'combined-home'
        });
        window.scrollTo(0,0);
      } else {
        this.$router.push({
          name: 'home',
          params: {
            currentproduct: this.currentProduct.toLowerCase()
          }
        });
      }
    }
  },
  computed: {

  }
}


</script>

<style scoped>
.overlay-content-container {
  max-width: 520px;
  margin: 0 auto;
}

.overlay-heading {
  color: #000;
  font-size: 45px;
  font-style: normal;
  font-weight: 250;
  line-height: 50px;
  margin-bottom: 38px;
}

.overlay-border-seperator {
  height: 1px;
  background: #808080;
  margin-bottom: 9px;
}

.overlay-descriptive-text {
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 19px;
}

.overlay-subscribe-button {
  margin-bottom: 82px;
  display: flex;
  gap: 19px;
}

.overlay-sign-in-button-text {
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 19px;
}

.overlay-sign-in-button-print-digital {
  margin-bottom: 38px;
}

@media only screen and (max-width:320px) {
  .overlay-subscribe-button button {
    font-size: 18px;
  }
}

@media only screen and (max-width:767px) {
  .common-overlay-modal .mds-modal__wrapper {
    max-height: 60vh;
  }

  .overlay-heading {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 35px;
  }

  .overlay-descriptive-text {
    font-size: 20px;
    font-weight: 25px;
  }

  .overlay-subscribe-button {
    margin-bottom: 50px;
  }

  .overlay-sign-in-button {
    margin-bottom: 30px;
  }

  .overlay-sign-in-button-text {
    font-size: 18px;
    font-weight: 23px;
  }
}</style>
<style>@media only screen and (max-width:767px) {
  .common-overlay-modal .mds-modal__content {
    overflow-y: scroll;
  }
  .common-overlay-modal .mds-modal__content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.common-overlay-modal .mds-modal__content::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}
}</style>
