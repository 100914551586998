<template lang="html">
  <div class="draft-post-main-container">
    <div class="draft-post-heading-container">
      <div class="draft-post-heading">
        <h1>{{currentProduct.toUpperCase()}} Drafts</h1>
      </div>
      <div class="draft-post-back-button">
        <mds-button variation="flat" size="medium" icon="angle-left" @click.prevent="$router.back ()">Back</mds-button>
      </div>
    </div>
    <div v-if="isAdmin" class="draft-posts-wrapper">
        <div v-if="isDraftsLoading">
          <mds-loader aria-label="Medium Loader"></mds-loader>
        </div>
        <div v-else-if="draftsData.length == 0" class="drafts-post-empty-container">
          <mds-empty-state size="large" title="No Drafts available" message="Currently there are no drafts to show.">
              <template #mds-empty-state-icon>
                  <img src="@/assets/happy-face-100.svg" role="presentation" />
              </template>
          </mds-empty-state>
        </div>
        
        <div v-else class="draft-posts-container">
          <div v-for="(drafts, index) in draftsData" :key="index" class="draft-posts-section">
            <div class="draft-post-border-seperator"></div>
            <div class="draft-title-and-delete-button-container">
              <div class="draft-post-title" @click="sendPostToUpdate(draftsData[index].PostID)">
                <a>{{drafts.Subject}}</a>
              </div>
              <div class="draft-post-delete-button-container">
                <mds-button variation="flat" size="medium" icon="trash" @click="deleteDraftToggle(index)">Delete</mds-button>
              </div>
            </div>
            <div class="draft-post-author-name-and-date">
              <span class="articles-authorname-common">{{
                drafts.PostAuthorName
              }}</span>
              <span class="draft-author-name-date-seperator-circles"></span>
              <span class="articles-authorname-common">{{
                drafts.PostDate | formatDateValForHomePage
              }}</span>
              <span class="draft-author-name-date-seperator-circles"></span>
              <span class="articles-authorname-common">{{
                drafts.PostDate | formatTime
              }}</span>
            </div>
            <div class="draft-post-article-content" @click="sendPostToUpdate(draftsData[index].PostID)">
              <p v-html="trimContent(drafts.FormattedBody)"></p>
            </div>
          </div>
        </div>
    </div>
    <div v-else class="draft-post-no-admin-access">
      <h1>You do not have access to this content</h1>
    </div>
    <mds-dialog v-model="deleteDraftConfirmationToggle" action-required title="Are you sure, you want to delete this draft ?" class="draft-delete-confirmation">
      <p class="draft-delete-modal-title">{{ draftPostTitle }}</p>
      <mds-input label="Confirm" placeholder="delete" microcopy-above="To delete the draft, type delete." v-model="deleteDraftConfirmationText" :error-text="['Please enter delete']" :error="deleteDraftErrorMessage"  @input="deleteDraftButtonState()" class="delete-post-input"></mds-input>
        <template #mds-dialog-actions-right>
            <mds-button-container right-aligned>
                <mds-button type="button" variation="secondary" @click="deleteDraftReset('cancel')"> Cancel </mds-button>
                <mds-button type="button" variation="primary" @click="deleteDraftAction('delete')" :disabled="deleteDraftbuttonDisabled"> Yes, Proceed </mds-button>
            </mds-button-container>
        </template>
        <mds-alert :variation="deleteDraftAlertVariationType" tinted mds-alert-dismissed :text="deleteDraftMessage" v-if="displayDeleteDraftAlert" style="margin-top:10px;">
        </mds-alert>
    </mds-dialog>
  </div>
</template>

<script lang="js">
  import Vue from "vue";
  import axios from "axios";
  import VueAxios from "vue-axios";
  import MdsLoader from "@mds/loader";
  import MdsEmptyState from "@mds/empty-state";
  import { MdsButton, MdsButtonContainer } from '@mds/button';
  import MdsDialog from '@mds/dialog';
  import MdsInput from '@mds/input';
  import MdsAlert from '@mds/alert';
  Vue.use(VueAxios, axios);
  export default  {
    name: 'draft-post',
    props: [],
    components:{
      MdsLoader,
      MdsEmptyState,
      MdsButton, 
      MdsButtonContainer,
      MdsDialog,
      MdsInput,
      MdsAlert,
    },
    mounted () {
      this.getDraftPosts();
    },
    data () {
      return {
        currentProduct : this.$session.get("current-product"),
        getDraftPostsAPI:process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetDraftArticle,
        deleteDraftURL:process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DeleteArticle,
        draftsData:[],
        isDraftsLoading:false,
        deleteDraftConfirmationToggle:false,
        deleteDraftConfirmationText:null,
        deleteDraftErrorMessage:false,
        deleteDraftbuttonDisabled:true,
        displayDeleteDraftAlert:false,
        deleteDraftAlertVariationType:null,
        deleteDraftMessage:null,
        draftPostTitle:null,
        draftPostID:null,
        isAdmin: false,
        DI_MEMBER_ADMIN:false,
        SI_MEMBER_ADMIN:false,
        EI_MEMBER_ADMIN:false,
        FI_MEMBER_ADMIN:false,
      }
    },
    methods: {
      getDraftPosts(){
        const getDraftPostsURL = this.getDraftPostsAPI + this.currentProduct;
        this.isDraftsLoading = true;
        try{
          Vue.axios
          .get(getDraftPostsURL)
          .then((response)=>{
            if(response){
              if(response.status == 200){
                this.isDraftsLoading=false;
                this.draftsData = response.data;
              }
            }
          })
          .catch((error)=>{
            console.log(error);
          })
        }catch(ex){
          console.log(ex.message);
        }
      },
      trimContent(content) {
        if (content != null) {
          var htmlData = content.replace(/<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g, '');
          //eslint-disable-next-line
          var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, '');
          return trimInlineCss;
          //return htmlData;
        }
      },
      sendPostToUpdate(postId){
        this.$router.push({ name: 'updatePost', params: { pid: postId } }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      },
      deleteDraftToggle(index){
        this.deleteDraftConfirmationToggle=true;
        this.draftPostTitle=this.draftsData[index].Subject;
        this.draftPostID=this.draftsData[index].PostID;
      },
      deleteDraftAction(opsType){
        if(!this.deleteDraftConfirmationText || this.deleteDraftConfirmationText !='delete')
        {
          this.deleteDraftErrorMessage=true;
        }
        else
        {
          const pid = this.draftPostID;
          const deletePostURL = this.deleteDraftURL + pid;
          this.displayDeleteDraftAlert = true;
          this.deleteDraftbuttonDisabled = true;
          this.deleteDraftAlertVariationType="informational";
          this.deleteDraftMessage = "Please wait while the record is getting deleted.";
          try{
            Vue.axios
            .delete(deletePostURL)
            .then((response) =>{
              if(response){
                if(response.status == 200){
                  this.displayDeleteDraftAlert = true;
                  this.deleteDraftAlertVariationType="success";
                  this.deleteDraftMessage = "Draft deleted successfully.";
                  var self = this;
                  setTimeout(function () { self.deleteDraftReset(opsType) } , 2000)
                }
              }
            })
            .catch((error) => {
              this.displayDeleteDraftAlert = true;
              this.deleteDraftbuttonDisabled = false;
              this.deleteDraftAlertVariationType="error";
              this.deleteDraftMessage = "Unable to delete the draft, try again later.";
              console.log(error);
            })
          }catch (ex) {
            console.log(ex.message);
          }
          this.deleteDraftErrorMessage=false;
        }
      },
      deleteDraftButtonState(){
        if(!this.deleteDraftConfirmationText)
        {
          this.deleteDraftbuttonDisabled=true;
        }
        else
        {
          this.deleteDraftbuttonDisabled=false;
        }
      },
      deleteDraftReset(opsType){
        this.deleteDraftbuttonDisabled=true;
        this.deleteDraftConfirmationText = "";
        this.deleteDraftErrorMessage = false;
        this.deleteDraftConfirmationToggle=!this.deleteDraftConfirmationToggle;
        this.displayDeleteDraftAlert=false;
        if(opsType === 'delete')
        {
          this.$router.push({ name: 'draftsPost' }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
          location.reload();
        }
      },
    },
    created() {
      const isAdminRoles = this.$auth0.user["https://morningstar.com/uim_roles"];
      if(isAdminRoles != undefined && isAdminRoles !=null) {
        this.DI_MEMBER_ADMIN = isAdminRoles.includes("DI_MEMBER_ADMIN");
        this.SI_MEMBER_ADMIN = isAdminRoles.includes("SI_MEMBER_ADMIN");
        this.EI_MEMBER_ADMIN = isAdminRoles.includes("EI_MEMBER_ADMIN");
        this.FI_MEMBER_ADMIN = isAdminRoles.includes("FI_MEMBER_ADMIN");
      }
      if (this.currentProduct.toUpperCase() == 'MSI' && this.SI_MEMBER_ADMIN) {
        this.isAdmin = true;
      }
      else if (this.currentProduct.toUpperCase() == 'MDI' && this.DI_MEMBER_ADMIN) {
        this.isAdmin = true;
      }
      else if (this.currentProduct.toUpperCase() == 'MFI' && this.FI_MEMBER_ADMIN) {
        this.isAdmin = true;
      }
      else if (this.currentProduct.toUpperCase() == 'MEI' && this.EI_MEMBER_ADMIN) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
  }
}


</script>

<style scoped>
  .draft-post-main-container {
    max-width:1280px;
    margin:0 auto;
    padding-top:50px;
    padding-bottom:50px;
  }
  .draft-posts-wrapper{
    width:90%;
    margin:0 auto;
    box-sizing: border-box;
  }
  .draft-post-heading-container{
    width:90%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .draft-post-back-button{
    margin-left:auto;
    order:2;

  }
  .draft-post-heading{
    width:100%;
    box-sizing: border-box;
    padding:10px 0px 10px 0px;
  }
  .draft-post-heading h1{
    font-size:20px;
    line-height:24px;
    font-weight:700;
  }
  .draft-posts-container{
    width:100%;
  }
  .draft-posts-section{
    width:100%;
    margin-top:10px;
  }
  .draft-title-and-delete-button-container{
    display:flex;
    align-items:center;
  }
  .draft-post-delete-button-container{
    margin-left:auto;
    order:2;
    cursor:pointer;
  }
  .draft-post-title{
    cursor:pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1E1E1E;
    margin-bottom:5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .draft-post-title a{
    text-decoration:none;
    color:#1E1E1E;
  }
  .draft-post-author-name-and-date{
    margin-bottom:5px;
    display:flex;
    align-items:center;
  }
  .draft-post-author-name-and-date span{
    margin-right:10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5E5E5E;
  }
  .draft-author-name-date-seperator-circles{
    width:5px;
    height:5px;
    border-radius:50%;
    background-color:#5E5E5E;
  }
  .draft-post-article-content{
    cursor:pointer;
    margin-bottom:10px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 21px;
    color: #1E1E1E;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .draft-post-border-seperator{
    height:1px;
    background-color: #D8D8D8;
    margin-bottom:5px;
  }
  .drafts-post-empty-container{
    display:flex;
    padding:20px;
    justify-content:center;
    text-align:center;
  }
  .draft-delete-modal-title{
    margin-bottom:10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .draft-post-no-admin-access{
    width:90%;
    margin:0 auto;
    padding:20px;
    text-align:center;
  }
  @media only screen and (min-width:320px) and (max-width:1000px){
    .draft-posts-wrapper{
      max-height:600px;
      overflow-y:scroll;
      box-sizing: border-box;
    }
    .draft-posts-wrapper::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      overflow-x: overlay;
    }

    .draft-posts-wrapper::-webkit-scrollbar-thumb {
      background: rgba(171, 171, 171, 0.3);
      border-radius: 6px;
    }
  }
</style>
<style>
  .draft-delete-confirmation .mds-dialog {
    max-width: 500px!important;
  }
</style>
