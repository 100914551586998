<template>
    <div>
        <mds-table :fixed-first-col="addScroll" style="box-shadow: none;" class="mei-portfolio-holding-table">
            <mds-thead :fixed-header="addScroll">
                <mds-th v-for="(header, index) in headers" :key="index" :right-aligned="header.align === 'right'"
                    :style="header.style">
                    <span class="mei-portfolio-holdings-head">{{ header.text }}</span>
                </mds-th>
            </mds-thead>
            <mds-tbody>
                <mds-tr v-for="(portfolio, index) in portfolios" :key="index">
                    <mds-td v-for="(header, i) in headers" :key="i" :right-aligned="header.align === 'right'">
                        <template v-if="header.fieldName === 'Name'">
                            <a class="mei-portfolio-Holdings-titles"
                                @mouseover="openMedalistReport(portfolio.SecId, portfolio.PortfolioName,portfolio.Ticker)"
                                @touchstart="openMedalistReport(portfolio.SecId, portfolio.PortfolioName,portfolio.Ticker)"
                                target="_blank"
                                :href="CurrentURL" >                            
                                {{ portfolio[header.fieldName] }}
                            </a>
                        </template>

                        <template v-else-if="header.fieldName === 'MorningstarRating'">
                            <h1 class="mei-portfolio-Holdings-rating">
                                <mds-star-rating :rating="portfolio[header.fieldName]" />
                            </h1>
                        </template>

                        <template v-else-if="header.fieldName === 'MorningstarMedalistRating'">
                            <h1 class="mei-portfolio-Holdings-medalistrating" style="display: inline-table;">
                                <mds-analyst-rating :rating="analystRatingClassMap[portfolio.MorningstarMedalistRating]
                                    " />
                            </h1>
                        </template>

                        <template v-else-if="header.fieldName === 'MorningstarRating'">
                            <h1 class="mei-portfolio-Holdings-rating">
                                <mds-star-rating :rating="portfolio[header.fieldName]" />
                            </h1>
                        </template>

                        <template v-else-if="header.fieldName === 'DataCoverage'">
                            <h1 class="mei-portfolio-Holdings-datacoverage">
                                {{ portfolio[header.fieldName] | dataCoverageFilter }}
                            </h1>
                        </template>

                        <template v-else-if="header.fieldName === 'AnalystDriven'">
                            <h1 class="mei-portfolio-Holdings-analystdriven">
                                {{ portfolio[header.fieldName] }}
                            </h1>
                        </template>

                        <template v-else-if="header.fieldName === 'InceptionDate'">
                            <h1 class="mei-portfolio-Holdings-date">
                                {{ portfolio[header.fieldName] | formatDateValForDataTables }}
                            </h1>
                        </template>

                        <template v-else-if="header.fieldName === 'Target'">
                            <h1 class="mei-portfolio-Holdings-target">
                                {{ portfolio[header.fieldName] }}
                            </h1>
                        </template>

                        <template v-else>
                            <h1 class="mei-portfolio-Holdings">
                                {{ portfolio[header.fieldName] }}
                            </h1>
                        </template>
                    </mds-td>
                </mds-tr>

            </mds-tbody>
        </mds-table>
        <template>
            <div class="mei-portfolio-holdings-one-div">
                <p class="mei-portfolio-holdings-one">
                    Holdings as of {{ holdingDate | formatDateValForDataTables }}
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MdsStarRating from "@mds/star-rating";
import MdsAnalystRating from "@mds/analyst-rating";
import { mapState } from "vuex";

Vue.use(VueAxios, axios);

import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
} from "@mds/data-table";
import EncryptDecryptMixin  from "../mixins/EncryptDecryptMixin.js";

export default {
    props: ["portfolios"],
    name: "portfolio-holdings",
    components: {
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTbody,
        MdsTr,
        MdsTd,
        MdsStarRating,
        MdsAnalystRating,
    },
    mixins: [
		EncryptDecryptMixin
	],
    data() {
        return {
            CurrentURL :  "#",
            analystRatingClassMap: {
                1: "not-ratable",
                2: "under-review",
                3: "negative",
                4: "neutral",
                5: "bronze",
                6: "silver",
                7: "gold",
            },
            holdingDate: Date,
            isDividentDeferred: true,
            isRearchDisplay: false,
            addScroll: false,
            headers: [
                {
                    text: "Ticker",
                    fieldName: "Ticker",
                    style: {
                        width: "58px",
                        align: "left",
                        "padding-left": "0px",
                        "padding-right": "2px"
                    },
                },
                {
                    text: "Name",
                    fieldName: "Name",
                    style: {
                        width: "320px",
                        align: "left",
                        "padding-left": "12px"
                    },
                },
                {
                    text: "Category",
                    fieldName: "MorningstarCategory",
                    style: {
                        width: "160px",
                        align: "left",
                        "padding-left": "2px"
                    },
                },
                {
                    text: "Morningstar Rating",
                    fieldName: "MorningstarRating",
                    style: {
                        width: "94px",
                        align: "left",
                        "padding-left": "2px"
                    },
                },
                {
                    text: "Morningstar Medalist Rating",
                    fieldName: "MorningstarMedalistRating",
                    align: "left",
                    style: {
                        width: "90px",
                        align: "left",
                        "padding-right": "2px"
                    },
                    sortable: true,
                },
                {
                    text: "Analyst-Driven %",
                    fieldName: "AnalystDriven",
                    sortable: true,
                    align: "right",
                    style: {
                        width: "56px",
                        "padding-left": "2px",
                    },
                },
                {
                    text: "Data Coverage %",
                    fieldName: "DataCoverage",
                    sortable: true,
                    align: "right",
                    style: {
                        width: "64px",
                        "padding-left": "4px",
                    },
                },
                {
                    text: "Expense Ratio %",
                    fieldName: "ExpenseRatio",
                    align: "right",
                    style: {
                        width: "80px",
                        align: "left",
                        "padding-left": "2px"
                    },
                },
                {
                    text: "Beta²",
                    fieldName: "Beta",
                    align: "right",
                    style: {
                        width: "48px",
                        align: "left",
                        "padding-left": "2px",
                        "padding-right": "0px"
                    },
                },
                {
                    text: "Cor. with Russell 3000²",
                    fieldName: "Correlation",
                    align: "right",
                    style: {
                        width: "70px",
                        align: "left",
                        "padding-left": "2px"
                    },
                },
                {
                    text: "Yield %",
                    fieldName: "FundYield",
                    align: "right",
                    style: {
                        width: "40px",
                        align: "left",
                        "padding-left": "2px"
                    },
                },
                {
                    text: "Alloc %",
                    fieldName: "AssetAllocation",
                    align: "right",
                    style: {
                        width: "40px",
                        align: "left",
                        "padding-left": "2px"
                    },
                },
                {
                    text: "Target %",
                    fieldName: "Target",
                    align: "right",
                    style: {
                        width: "50px",
                        align: "left",
                        "padding-left": "0px",
                        "padding-right": "10px"
                    },
                },
                {
                    text: "Inception Date",
                    fieldName: "InceptionDate",
                    align: "left",
                    style: {
                        width: "80px",
                        align: "left",
                        "padding-left": "2px"
                    },
                },
            ],
            isLoading: true,
            isDisplay: false,
        };
    },
    methods: {
        openMedalistReport(secId, portfolioName,Symbol) {
            this.$session.set("CurrentSecID",this.Encrypt(secId));
            this.$session.set("CurrentPortfolioName",this.Encrypt(portfolioName));
            if(this.$store.state.responsive.isMobileContent)
            { 
                // this.$router
                // .push({ name: "analyst", params: { currentproduct: "mei",ticker:Symbol}})
                // .catch((err) => {
                // if (
                //     err.name !== "NavigationDuplicated" &&
                //     !err.message.includes(
                //     "Avoided redundant navigation to current location"
                //     )
                // ) {
                //     console.log(err);
                // }
                // });
                let resolvedRoute = this.$router.resolve({
                name: "analyst",
                params: {currentproduct: "mei",ticker:Symbol}
                });
                this.CurrentURL = resolvedRoute.href;
            }
            else
            {
                let resolvedRoute = this.$router.resolve({
                name: "analyst",
                params: {currentproduct: "mei",ticker:Symbol}
                });
                // window.open(resolvedRoute.href, '_blank');
                this.CurrentURL = resolvedRoute.href;
            }
      }
    },
    mounted() {
        this.addScroll = this.$store.state.responsive.isModelPortfolioResponsive;
        const date = new Date();
        this.holdingDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            0
        ).toLocaleDateString();
    },
    computed: mapState({
        isModelPortfolioResponsive: state => state.responsive.isModelPortfolioResponsive,
        isModelPortfolioResponsiveAlias: 'isModelPortfolioResponsive',
        isModelPortfolioResponsiveState(state) {
            return state.responsive.isModelPortfolioResponsive
        },

        isMobileContent: state => state.responsive.isMobileContent,
        isMobileContentAlias:'isMobileContent',
        isMobileContentState(state){
        return state.responsive.isMobileContent
    }
    }),
    watch: {
        isModelPortfolioResponsiveState() {
            this.addScroll = this.isModelPortfolioResponsiveState;
        }
    }
};
</script>

<style scoped>
body {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

.mei-portfolio-Holdings-date {
    margin-bottom: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    padding-left: 2px;
    width: 80px;
}

.mei-portfolio-Holdings {
    margin-bottom: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    padding-left: 2px;
    padding-right: 1px;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.mei-portfolio-Holdings-target {
    margin-bottom: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    padding-left: 2px;
    padding-right: 9px;
    width: 50px;
}

.mei-portfolio-Holdings-medalistrating {   
    margin-top: 15px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
}

.mei-portfolio-Holdings-rating {
    margin-bottom: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
}

.mei-portfolio-Holdings-datacoverage {
    margin-bottom: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    padding-left: 0px;
}

.mei-portfolio-Holdings-analystdriven {
    margin-bottom: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    padding-left: 0px;
}

.mei-portfolio-holdings-head {
    margin-top: 0px;
    height: 55px;
    font-size: 16px;
    margin-bottom: 0px;
}

.mei-portfolio-Holdings-titles {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
    margin-top: 12px;
    line-height: 21px;
    cursor: pointer;
    width: 320px;
    padding-left: 12px;
    color: #1e1e1e;
}

.mei-portfolio-holding-table .mei-portfolio-Holdings-titles:hover {
    color: #006fba;
}

.mei-portfolio-holdings-one {
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: -6px;
    color: #5E5E5E;
    width: 300px;
}

.mei-portfolio-holdings-one {
    padding-bottom: 41px;
    margin-top: 8px;
}

.mds-td {
    padding: 0px;
    vertical-align: unset !important;
}
.mei-portfolio-holding-table > tbody > tr > td:nth-child(2) > a:focus{ 
    box-shadow: none;
}
.mei-portfolio-holding-table > tbody > tr > td:nth-child(2) > a:focus:hover{
    cursor: pointer !important;
}
@media only screen and (min-width:320px) and (max-width:1000px){
    .mei-portfolio-holding-table > table >  tbody > tr > td:nth-child(2) > a:focus{ 
        box-shadow: none;
    }
}
</style>