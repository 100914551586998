<template>
  <div class="access-denied">
    <div class="access-denied-message-container">
      <div class="access-denied-content">
        <h1 class="access-denied-error">403 ERROR</h1>
        <div class="access-denied-message-heading">Access Denied !</div>
        <div class="access-denied-message-heading">It looks like you aren't allowed to view this page.</div>
        <div class="access-denied-message-link"> Try refreshing this page or <span @click="accessDeniedRedirect">sign in
            to your Morningstar Investor Newsletters account.</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "access-denied",
  methods: {
    accessDeniedRedirect() {
      this.$router.push({
        name: "home",
        params: {
          currentproduct: this.$session.get("current-product")
        },
      }).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      });
    }
  }
}
</script>
<style scoped>
.access-denied {
  width: 100%;
  background: #f2f2f2;
  margin: 0px;
  padding: 0px;
  min-height: calc(100vh - 50px);
}

.access-denied-message-container {
  width: 100%;
  padding-top: 50px;

}

.access-denied-content {
  width: 900px;
  padding: 10px;
  margin: auto;
  padding: auto;
}

.access-denied-error {
  font-size: 14px;
  letter-spacing: 1.67px;
  line-height: 12px;
  margin-bottom: 30px;
}

.access-denied-message-heading {
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}

.access-denied-message-link {
  font-style: normal;
  font-size: 30px;
  font-weight: 200;
  line-height: 36px;
}

.access-denied-message-link span {
  color: #5e5e5e;
  font-weight: inherit;
  outline: 0;
  text-decoration: none;
  border-bottom: 1px solid #ababab;
  font-style: inherit;
}

.access-denied-message-link span:hover {
  border-color: #006fba;
  color: #006fba
}</style>